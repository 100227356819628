import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

import useStyles from "./Deposit.styles";
import {
  getGeneratedAddressStart,
  chargeMoneyCreateStart,
  resetTarget,
  validatePromoCodeStart,
} from "../../../redux-saga/redux/payments";
import { getUserProfileDetailsStart } from "../../../redux-saga/redux/profile/myProfile";

const Deposit = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [Amount, setAmount] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeValidated, setPromoCodeValidated] = useState(false); // New state
  const [validatingPromoCode, setValidatingPromoCode] = useState(false);
  const [validationAttempted, setValidationAttempted] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState("");

  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const {
    depositLoading,
    redirectUrl,
    creditCardDepositLoading,
    creditCardRedirectUrl,
    promoCodeValidation,
    error,
  } = useSelector((state) => state.payments);
  const defaultCode = useSelector(
    (state) => state.myProfile?.userDetails?.Wallet?.currencyCode || ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    const status = searchParams.get("status");

    if (status === "0" || status === "fail") {
      toast.error(t("PAYMENT.PAYMENT_FAILED_MSG"));
    } else if (status === "success") {
      toast.success(t("PAYMENT.PAYMENT_SUCCESS_MSG"));
    }

    dispatch(getUserProfileDetailsStart());

    navigate("/user/deposit");
  }, [searchParams, dispatch, navigate, t]);

  useEffect(() => {
    redirectUrl && window.open(redirectUrl, "_blank", "noopener,noreferrer");
    creditCardRedirectUrl && window.open(creditCardRedirectUrl, "_self");
    dispatch(resetTarget());
  }, [redirectUrl, creditCardRedirectUrl]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!validationAttempted) {
      return;
    }

    setValidatingPromoCode(false);

    if (promoCodeValidation) {
      if (
        promoCodeValidation.message &&
        promoCodeValidation.message.includes("promocode: OK") &&
        promoCodeValidation.message.includes("Kind: OK")
      ) {
        const successMessage = t("PROMO_CODE.VALID");
        toast.success(successMessage);
        setPromoCodeValidated(true);
        setPromoCodeError(successMessage);
      } else {
        handleErrorMessage(promoCodeValidation.message || "Unknown error");
      }
    } else if (error) {
      handleErrorMessage(error);
    }
  }, [promoCodeValidation, error, validationAttempted]);

  const handleErrorMessage = (message) => {
    let errorMessage = t("PROMO_CODE.INVALID");
    if (typeof message === "string") {
      if (
        message.includes("User already has an active join or deposit bonus")
      ) {
        errorMessage = t("PROMO_CODE.BONUS_ACTIVATED");
      } else if (message.includes("User has already claimed this promocode")) {
        errorMessage = t("PROMO_CODE.BONUS_CLAIMED");
      } else if (message.includes("Promocode is invalid or has expired")) {
        errorMessage = t("PROMO_CODE.INVALID");
      } else if (message.includes("promocode: OK Kind: NO")) {
        errorMessage = t("PROMO_CODE.INVALID_KIND");
      }
    }
    toast.error(errorMessage);
    setPromoCodeError(errorMessage);
    setPromoCodeValidated(false);
  };

  const validatePromoCode = () => {
    if (!promoCode) {
      toast.error(t("PROMO_CODE.EMPTY"));
      return;
    }
    setValidatingPromoCode(true);
    setPromoCodeValidated(false);
    setValidationAttempted(true);
    setPromoCodeError("");
    dispatch(
      validatePromoCodeStart({
        promoCode: promoCode,
        bonusKind: "2",
      })
    );
  };

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        <Typography variant="h4" gutterBottom>
          DEPOSIT
        </Typography>
        <Grid className={classes.myprofileForm}></Grid>
        <Grid className={classes.customDepositWrap}>
          <Grid>
            <div className={classes.signupFromFname}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.formGroup}>
                    <span>{`${t(
                      "TRANSACTIONS.AMOUNT"
                    )} (${defaultCode})`}</span>
                    <TextField
                      id="customAmount"
                      placeholder={`${t(
                        "TRANSACTIONS.AMOUNT"
                      )} (${defaultCode})`}
                      type={"number"}
                      variant="outlined"
                      onChange={(e) => {
                        if (e.target.value?.length > 50) {
                          toast.error(
                            "Deposit amount length can't be greater than 50"
                          );
                          return;
                        }

                        setAmount(e.target.value);
                        if (!e.target.value) {
                          dispatch(resetTarget());
                        }
                      }}
                      value={Amount}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formGroup}>
                    <span>{t("TRANSACTIONS.PROMO_CODE")}</span>
                    <div className={classes.promoCodeContainer}>
                      <TextField
                        id="promoCode"
                        placeholder={t("TRANSACTIONS.ENTER_PROMO_CODE")}
                        variant="outlined"
                        onChange={(e) => {
                          if (!promoCodeValidated) {
                            setPromoCode(e.target.value);
                            if (validationAttempted) {
                              setValidationAttempted(false);
                            }
                          }
                        }}
                        error={
                          validationAttempted &&
                          !promoCodeValidated &&
                          !validatingPromoCode
                        }
                        helperText={
                          validatingPromoCode
                            ? t("PROMO_CODE.VALIDATING")
                            : promoCodeError || ""
                        }
                        sx={{
                          "& .MuiFormHelperText-root": {
                            color: promoCodeValidated
                              ? "green"
                              : promoCodeError
                              ? "red"
                              : "inherit",
                          },
                        }}
                        value={promoCode}
                        className={classes.textFieldStylePromo}
                        InputProps={{
                          readOnly: promoCodeValidated,
                        }}
                      />
                      <Button
                        className={classes.validateButton}
                        disabled={
                          !promoCode ||
                          promoCodeValidated ||
                          validatingPromoCode
                        }
                        onClick={validatePromoCode}
                      >
                        {validatingPromoCode ? (
                          <CircularProgress size={24} />
                        ) : promoCodeValidated ? (
                          t("PROMO_CODE.VALIDATED")
                        ) : (
                          t("PROMO_CODE.VALIDATE")
                        )}
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Button
                      className={classes.btnPrimary}
                      disabled={
                        !Amount ||
                        (promoCode && !promoCodeValidated) ||
                        depositLoading
                      }
                      onClick={() => {
                        if (Amount < 10) {
                          toast.error(
                            t("PAYMENT.MINIMUM_AMOUNT_ERROR_MSG") +
                              "10" +
                              defaultCode
                          );
                          console.log("reached here");
                          return;
                        }
                        dispatch(
                          getGeneratedAddressStart({
                            amount: Amount,
                            promoCode: promoCode,
                          })
                        );
                      }}
                    >
                      {depositLoading ? (
                        <CircularProgress size={25} />
                      ) : (
                        t("PAYMENT.REDIRECT_TO_CRYPTO_PAYMENT")
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.btnPrimary}
                      disabled={
                        !Amount ||
                        (promoCode && !promoCodeValidated) ||
                        creditCardDepositLoading
                      }
                      onClick={() => {
                        if (Amount < 10) {
                          toast.error(
                            t("PAYMENT.MINIMUM_AMOUNT_ERROR_MSG") +
                              "10" +
                              defaultCode
                          );
                          console.log("reached here");
                          return;
                        }
                        dispatch(
                          chargeMoneyCreateStart({
                            amount: Amount,
                            promoCode: promoCode,
                          })
                        );
                      }}
                    >
                      {creditCardDepositLoading ? (
                        <CircularProgress size={25} />
                      ) : (
                        t("PAYMENT.REDIRECT_TO_CREDIT_CARD_PAYMENT")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Deposit;
