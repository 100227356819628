import React, { useEffect } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import useStyles from "../Providers/Providers.styles";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { homePageGamesLimit, PROVIDERIMAGES } from "../../../utils/constants";
// import CasinoLoader from "../CasinoLoader";

const Providers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    providers,
    // loading,
  } = useSelector((state) => state.casinoLobby);
  const { loading: globalLoader } = useSelector((state) => state.casinoGames);
  useEffect(() => {
    dispatch(
      getGamesStart({
        limit: homePageGamesLimit,
        isMobile,
      })
    );
  }, [dispatch]);

  const LeftButton = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
    return (
      <Button
        className={classes.leftArrow}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);
    return (
      <Button
        disabled={isLastItemVisible}
        className={classes.rightArrow}
        onClick={() => scrollNext()}
      >
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  // const providerList = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <>
      <Box className={classes.contentWrap}>
        {globalLoader ? null : (
          <>
            <Typography className={classes.subHeading}>
              <img src="/images/live.svg" alt="live icon" />
              Providers
            </Typography>
            <Grid container spacing={2}>
              {
                <ScrollMenu
                  LeftArrow={providers?.length > 5 && LeftButton}
                  RightArrow={providers?.length > 5 && RightButton}
                  wrapperClassName="horizontalScrollWrapper"
                >
                  {providers.map((provider, val) => {
                    return (
                      <Grid
                        item
                        xs={2}
                        key={provider?.masterCasinoProviderId}
                        style={{ marginRight: "20px", marginBottom: "20px" }}
                        onClick={() => {
                          navigate(
                            `/view-all/${provider?.masterCasinoProviderId}/false/${provider?.name}`
                          );
                        }}
                      >
                        {PROVIDERIMAGES[provider?.masterCasinoProviderId] ? (
                          <Box className={classes.providerBg}>
                            <img
                              src={
                                PROVIDERIMAGES[provider?.masterCasinoProviderId]
                              }
                              alt="application providers"
                              className={classes.providerImgBg}
                            />
                          </Box>
                        ) : (
                          provider?.name
                        )}
                      </Grid>
                    );
                  })}
                </ScrollMenu>
              }
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default Providers;
