import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  copyicon: {
    position: "absolute",
    top: "19px",
    right: "8px",
    bottom: "0",
    color: "#000",
    fontSize: "28px",
    cursor: "pointer",
    margin: "auto",
  },
  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  accountRight: {
    height: "100%",
  },
  btnPrimary: {
    color: "#fff",
    background: "#F7616A",
    borderRadius: "50px",
    padding: "6px 20px",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  validateButton: {
    color: "#fff",
    background: "#F7616A",
    borderRadius: "50px",
    padding: "6px 20px",
    fontSize: "11px",
    fontWeight: "600",
    height: "40px",
    textTransform: "capitalize",
    width: "20%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },

  promoCodeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  myprofileForm: {
    maxWidth: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  textFieldStylePromo: {
    width: "30%",
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    margin: "0 auto",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "13px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  myprofileBottom: {
    background: "#0D2867",
    borderRadius: "10px",
    padding: "30px 55px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "#000",
      height: "40px",
      padding: "5px 10px",
      backgroundColor: "#fff",
      color: "#25262B",
      fontSize: "14px",
      borderRadius: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F7616A",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiTypography-h4": {
      fontWeight: "600",
      textTransform: "capitalize",
      marginBottom: "50px",
      fontSize: "35px",
      color: "#F7616A",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },
    },
  },
  authBtnWrap: {
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    padding: "40px 0 20px 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
    "& button": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  customDepositWrap: {
    // borderTop: "1px solid rgba(255, 255, 255, 0.4)",

    padding: "40px 0 0 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
  },
  amountInfo: {
    color: "rgba(255, 255, 255, 0.3)",
    marginTop: "-16px",
    marginBottom: "20px",
  },
  selectBtnBox: {
    "& button": {
      background: "transparent",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      color: "rgba(255, 255, 255, 0.3)",
      "&:hover, &:focus, &:active, &.active": {
        background: "#BCC3DB",
        color: "#25262B",
      },
    },
  },
}));
