import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  hasNext: false,
  featuredMatches: [],
  currentPage: 0,
  totalPage: 0,
};

const featuredMatchesSlice = createSlice({
  name: "featuredMatches",
  initialState,
  reducers: {
    getFeaturedMatchesStart: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    getFeaturedMatchesSuccess: (state, action) => {
      let currentPage = parseInt(action.payload.currentPage);
      let totalPage = parseInt(action.payload.totalPage);
      state.hasNext = currentPage < totalPage;

      if (currentPage > 1) {
        state.featuredMatches = [
          ...state.featuredMatches,
          ...action.payload.findFeatured,
        ];
      }

      if (currentPage === 1) {
        state.featuredMatches = action.payload?.findFeatured;
      }

      state.loading = false;
      state.success = true;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
    },
    getFeaturedMatchesFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  getFeaturedMatchesStart,
  getFeaturedMatchesSuccess,
  getFeaturedMatchesFailure,
} = featuredMatchesSlice.actions;

export default featuredMatchesSlice.reducer;
