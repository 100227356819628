import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  // homeBanner: {
  //   backgroundImage: "url('/images/banner.jpg')",
  //   backgroundSize: "cover",
  //   backgroundPosition: "center top",
  //   backgroundRepeat: "no-repeat",
  //   minHeight: "360px",
  // },
  bannerContentSubText: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-start",
    },
  },
  btnWrap: {
    margin: "10px 0",
    display: "block",
  },
  btnPrimary: {
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    borderRadius: "50px",
    padding: "6px 30px",
    // color: "#0D2867",
    fontSize: "14px",
    fontWeight: "400",
    height: "43px",
    textTransform: "capitalize",
    width: "auto",
    margin: "0 10px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "82px",
      height: "43px",
    },
  },
  btnSecondary: {
    background: "#BCC3DB",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#25262B",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    "&:hover": {
      background: "#BCC3DB",
      color: "#25262B",
    },
  },
  bannerContent: {
    padding: "30px 0 30px 60px",
    "& .MuiTypography-h1": {
      fontWeight: "bold",
      fontSize: "50px",
      // fontFamily: "Rammetto One !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "35px",
      },
      "& br": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& img": {
      marginRight: "5px",
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    "& .MuiButton-textPrimary": {
      padding: "6px 40px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
    },
  },
  homeFilter: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 15px",
    "& .MuiTextField-root": {
      "& .MuiInputBase-colorPrimary": {
        background: "#BCC3DB",
        color: "#0D2867",
      },
    },

    "& .MuiInputBase-colorPrimary": {
      minWidth: "333px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
      borderRadius: "50px",
      // color: "#25262B",
      fontSize: "12px",
      height: "43px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
      [theme.breakpoints.down("lg")]: {
        marginRight: "0px",
        width: "auto",
        fontSize: "12px",
        minWidth: "auto",
      },
      "&:focus-visible": {
        outline: "none",
      },
      "&:hover": {
        // background:
        //   "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        // color: "#fff",
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      outline: "none",
      borderColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#25262B",
    },
    "& a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      margin: "0",
      "& p": {
        // fontWeight: "600",
        fontSize: "14px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      width: "100%",
    },
  },
  contentWrap: {
    marginTop: "91px",
    padding: "0px 15px 0px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px",
    },
  },
  // casinoCard: {
  //   height: "260px",
  //   borderRadius: "10px",
  //   overflow: "hidden",
  //   position: "relative",
  //   cursor: "pointer",
  //   [theme.breakpoints.down("lg")]: {
  //     height:"100%",
  //   },

  //   "& img": {
  //     height: "100%",
  //     width: "100%",
  //  },
  //   "& .MuiTypography-body1": {
  //     [theme.breakpoints.down("sm")]: {
  //       fontSize: "12px",
  //       overflowX: "hidden",
  //       whiteSpace: "nowrap",
  //       textOverflow: "ellipsis",
  //       maxWidth: "120px",
  //     },
  //   },
  // },

  innerHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-start",
    },
  },

  gamesWrapper: {
    "& .MuiGrid-item": {
      float: "left",
      width: "calc(100% / 10) !important",
      marginRight: "0 !important",
      [theme.breakpoints.down("xl")]: {
        width: "calc(100% / 6) !important",
      },

      [theme.breakpoints.down("lg")]: {
        width: "calc(100% / 5) !important",
      },

      [theme.breakpoints.down("md")]: {
        width: "calc(100% / 4) !important",
      },

      [theme.breakpoints.down("sm")]: {
        width: "calc(100% / 2) !important",
      },
    },
  },

  innerHeadingLeft: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
      width: "30px",
      [theme.breakpoints.down("sm")]: {
        width: "15px",
      },
    },
    "& .MuiTypography-h6": {
      margin: "0",
      color: "#0D2867",
      fontWeight: "600",
      fontSize: "16px",
    },
    "& a": {
      textDecoration: "none",
      margin: "0",
      height: "43px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-body1": {
        fontWeight: "600",
        fontSize: "14px",
      },
    },
  },
  viewAllSearch: {
    marginLeft: "auto",

    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      // background: "#F7616A",
      borderRadius: "50px",
      marginRight: "0",
      color: "#25262B",
    },
    "& .MuiFormControl-root": {
      background: "#F7616A",
      borderRadius: "50px",
      marginRight: "10px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
        width: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: "100%",
      marginBottom: "10px",
    },
  },
  noGamesMsg: {
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    color: "#0D2867",
  },
  circulerLoader: {
    margin: "auto",
  },
  loadMoreButton: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  moreGamesLoader: {
    marginBottom: "-20px",
    marginTop: "40px",
  },

  providerDropDown: {
    marginLeft: "8px",
  },
  mr20: {
    margin: "20px",
  },
  viewAllGrid: {
    "& .MuiGrid-item": {
      width: "100% !important",
      height: "auto !important",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  viewAllCtaWrap: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      margin: "10px 0 0",
    },
    "& .MuiButtonBase-root": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "0 10px 0 0",
      },
    },
    "& a": {
      width: "100%",
      justifyContent: "center",
    },
    "& button": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
}));
