import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class MybetsService {
  static async getMybets(params) {
    if (params.betStatus === "all") delete params.betStatus;
    try {
      const { data } = await axiosClient.get("/betting/myBets", { params });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async cashOut(body) {
    try {
      const { data } = await axiosClient.post("/betting/cashOut", body);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  ///betting/getCashoutAmount?betslipId=1
  static async getCashOutAmount(params) {
    try {
      const { data } = await axiosClient.get("/betting/getCashoutAmount", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
