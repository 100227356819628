import { put, takeLatest } from "redux-saga/effects";

import transactionsService from "../../../services/transactions/index.js";
import {
  getTransactionsStart,
  getTransactionsSuccess,
  getTransactionsFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsStartFailure,
  getSportBookTransactionsStart,
  getSportBookTransactionsSuccess,
  getSportBookTransactionsFailure,
} from "../../redux/profile/transactions";
import { showErrorMessage } from "../../redux/toast";

function* transactionsWatcher() {
  yield takeLatest(getTransactionsStart.type, getTransactionsWorker);
  yield takeLatest(getAllBetsStart.type, getAllBetsWorker);
  yield takeLatest(
    getSportBookTransactionsStart.type,
    getSportBookTransactionsWorker
  );
}

function* getSportBookTransactionsWorker(action) {
  try {
    const { data, error } = yield transactionsService.getSportbookTransactions(
      action.payload
    );
    if (!error) {
      yield put(getSportBookTransactionsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getSportBookTransactionsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getSportBookTransactionsFailure());
  }
}

function* getTransactionsWorker(action) {
  try {
    const { data, error } = yield transactionsService.getTransactions(
      action.payload
    );
    if (!error) {
      yield put(getTransactionsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getTransactionsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getTransactionsFailure());
  }
}

function* getAllBetsWorker(action) {
  try {
    const { data, error } = yield transactionsService.getAllBets(
      action.payload
    );
    if (!error) {
      yield put(getAllBetsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getAllBetsStartFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getAllBetsStartFailure());
  }
}

export default transactionsWatcher;
