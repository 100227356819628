import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
// import usePrevious from "../hooks/usePrevious.js";
import { useTranslation } from "react-i18next";

import useStyle from "./MoreMarket.style.js";
import EachMarket from "../components/EachMarket/index.js";

function MarketContainer({ betStatus }) {
  const { t } = useTranslation();
  // console.log("MarketContainer ...");
  const classes = useStyle();
  const [show, setShow] = useState();
  const [isExpand, setIsExpand] = useState({});
  const matchMarket = useSelector(
    (state) => state.sportbook.moreMarkets.matchMarket
  );
  // const prevMoreMarkets = usePrevious(matchMarket || []);
  const handleChange = (changeShow) => {
    setShow(!changeShow);
    const tempArray = {};
    matchMarket &&
      matchMarket?.length &&
      matchMarket.forEach((match) => {
        tempArray[match.matchMarketId] = !changeShow;
      });

    setIsExpand(tempArray);
  };

  return (
    <>
      <Grid style={{ display: "flex", justifyContent: "end" }}>
        <Button
          className={classes.btnPrimary}
          onClick={() => handleChange(show)}
        >
          {show ? "Collapse" : "Expand"}
        </Button>
      </Grid>

      <Grid className={classes.liveMatchOddsSection}>
        {matchMarket && matchMarket?.length ? (
          matchMarket?.map((market, i) => {
            return (
              <EachMarket
                key={matchMarket?.matchMarketId}
                market={market}
                expandAll={show}
                isExpand={isExpand}
                setIsExpand={setIsExpand}
                betStatus={betStatus}
                // prevMarket={prevMoreMarkets?.[i]}
              />
            );
          })
        ) : (
          <span className={classes.noMarket}>
            {t("MORE_MARKETS.NO_MARKETS_AVAILABLE")}
          </span>
        )}
      </Grid>
    </>
  );
}

export default React.memo(MarketContainer);
