import { getErrorObj } from "../../../utils/helper";
import session from "../../../utils/session";
import axiosClient from "../../axios";

export default class lobbyService {
  static async getBannersData(params) {
    try {
      const { data } = await axiosClient.get("user/getBanner", {
        params,
      });
      return data?.data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async getProviders() {
    try {
      const { data } = await axiosClient.get("/casino/getAllProvider");
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getSubCategory() {
    try {
      const { data } = await axiosClient.get("/casino/getAllSubCategory");
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async getAllGames(params) {
    if (session.getSessionCred) {
      const user = session.getSessionCred;
      if (user?.userId) params.userId = user?.userId;
    }
    try {
      const { data } = await axiosClient.get("/casino/getAllGames", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getGameLink(payload) {
    // const lang=localStorage.getItem("i18nextLng")
    try {
      const { data } = await axiosClient.post("gsoft/start", payload, {
        headers: { language: "en_US" },
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getAllSearchedGames(params) {
    if (session.getSessionCred) {
      const user = session.getSessionCred;
      if (user?.userId) params.userId = user?.userId;
    }
    try {
      const { data } = await axiosClient.get("/casino/getAllFilteredGames", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async getfavorites(params) {
    try {
      const { data } = await axiosClient.get("/casino/getAllFavoriteGames", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async addRemoveFavorites(params) {
    try {
      const { data } = await axiosClient.post(
        "/user/makeCasinoGameFavorite?gameId=" + params.gameId
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
