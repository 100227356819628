import React, { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./ForgotForm.styles";
import { isMatchPassword, validatePassword } from "../../../utils/helper";
import {
  resetError,
  resetSuccess,
  verifyPasswordTokenStart,
} from "../../../redux-saga/redux/auth/authentication";

const ForgotForm = ({ id, token }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const { loading, success, error } = useSelector(
    (state) => state.authentication
  );

  const handleUpdatePassword = () => {
    if (!isMatchPassword(password, confirmPassword)) {
      toast.error(t("CHANGE_PASSWORD.PASSWORD_MATCH_VALIDATION"));
      dispatch(resetError());
      return;
    }
    if (!validatePassword(password)) {
      toast.error(t("COMMON.PASSWORD_VALIDATION"));
      dispatch(resetError());
      return;
    }

    dispatch(
      verifyPasswordTokenStart({
        id,
        token,
        password: window.btoa(password),
      })
    );
  };

  useEffect(() => {
    if (success && !loading) {
      dispatch(resetSuccess());
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (error && !loading) {
      toast.error(error);
      dispatch(resetError());
    }
  }, [loading, success, error, navigate, dispatch, t]);

  return (
    <div className={classes.container}>
      <Grid className={classes.forgotFormContainer}>
        <Typography variant="h4" gutterBottom>
          {t("HEADER.FORGOT_PASSWORD_UCASE")}
        </Typography>
        <Grid className={classes.forgotForm}>
          <Grid className={classes.forgotFormWrap}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className={classes.formGroup}>
                  <span className={classes.labelTag}>
                    {t("CHANGE_PASSWORD.NEW_PASSWORD")}*
                  </span>
                  <form noValidate>
                    <TextField
                      autoComplete="new-password"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("CHANGE_PASSWORD.NEW_PASSWORD")}
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Grid className={classes.showLink}>
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginTop: "25px", color: "#002965" }}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          onMouseDown={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </Grid>
                  </form>
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className={classes.formGroup}>
                  <span className={classes.labelTag}>
                    {t("CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD")}*
                  </span>
                  <TextField
                    autoComplete="new-password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={t("CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD")}
                    variant="outlined"
                    onChange={(e) => setconfirmPassword(e.target.value)}
                  />

                  <Grid className={classes.showLink}>
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginTop: "25px", color: "#002965" }}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                        onMouseDown={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                      >
                        {!showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.authBtnWrap}>
            <Button
              className={classes.btnPrimary}
              onClick={handleUpdatePassword}
            >
              {loading ? <CircularProgress /> : t("HEADER.UPDATE_PASSWORD")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotForm;
