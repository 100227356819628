import { combineReducers } from "@reduxjs/toolkit";

import authentication from "./auth/authentication";
import resetPassword from "./auth/resetPassword";
import myProfile from "./profile/myProfile";
import transactions from "./profile/transactions";
import toast from "./toast";
import casinoLobby from "./casino/lobby/casinoLobby";
import casinoGames from "./casino/lobby/casinoGames";
import cms from "./cms";
import payments from "./payments";
import advertisement from "./advertisement";
import sportbook from "../../sport-book-saga/redux";
import favorites from "./casino/lobby/favorites";
import settings from "./settings";
const rootReducer = combineReducers({
  authentication,
  myProfile,
  transactions,
  toast,
  resetPassword,
  casinoLobby,
  casinoGames,
  cms,
  favorites,
  advertisement,
  payments,
  sportbook,
  settings,
});

export default rootReducer;
