import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mybetModal: {
    "& .MuiPaper-elevation": {
      backgroundColor: "#1b214f",
      borderRadius: "20px",
      backgroundImage: "none",
      minWidth: "550px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },
    },

    "& .MuiDialogContent-dividers": {
      border: "none",
    },
    "& h2": {
      color: "#F7616A",
      fontSize: "20px",
      fontWeight: "bold",
      textTransform: "capitalize",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiDialogContent-root": {
      overflowY: "visible",
    },
  },
  closeBtn: {
    color: "#F7616A",
    width: " 22px",
    border: "1px solid #F7616A",
    cursor: " pointer",
    height: " 22px",
    display: " flex",
    opacity: " 1",
    padding: " 8px",
    alignItems: " center",
    borderRadius: "100%",
    justifyContent: " center",

    position: "static",
    "& svg": {
      fontSize: "12px",
    },
  },
}));
