import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useStyle from "../../MoreMarkets/MoreMarket.style.js";
import EachoutCome from "../EachoutCome/index.js";
import { getMarketName } from "../../Utils/common.js";
import usePrevious from "../../hooks/usePrevious.js";

const { v4: uuid } = require("uuid");

function Eachmarket({
  market,
  expandAll,
  isExpand,
  setIsExpand,
  betStatus,
  // prevMarket,
}) {
  const prevMarket = usePrevious(market);
  const classes = useStyle();
  const { language } = useSelector((state) => state.sportbook.language);
  // console.log("market rendring ...");
  const handleChange = () => {
    setIsExpand({
      ...isExpand,
      [market?.matchMarketId]: !isExpand[market?.matchMarketId],
    });
  };

  let marketName = useMemo(
    () => getMarketName(market, language),
    [market, language]
  );

  return (
    <Accordion
      className={classes.liveMatchAccordion}
      expanded={!!isExpand?.[market?.matchMarketId]}
      onChange={handleChange}
      value={undefined}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          {
            marketName
            // + "--" + market?.matchMarketId
          }
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className={classes.liveMatchOddsContent}>
          <Grid container spacing={2}>
            {market?.outcome?.length &&
              market.outcome.map((outcome) => {
                return (
                  <EachoutCome
                    market={market}
                    outcome={outcome}
                    key={uuid()}
                    marketName={marketName}
                    betStatus={betStatus}
                    prevMarket={prevMarket}
                  />
                );
              })}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(Eachmarket);
