import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  hasNext: false,
  myBets: [],
  currentPage: 0,
  totalPage: 0,
  cashoutAmount: 0,
  amountLoading: false,
  cashoutLoading: false,
};

const MyBetsSlice = createSlice({
  name: "MyBets",
  initialState,
  reducers: {
    getMyBetsStart: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    getMyBetsSuccess: (state, action) => {
      let currentPage = parseInt(action.payload.currentPage);
      let totalPage = parseInt(action.payload.totalPage);
      state.hasNext = currentPage < totalPage;

      if (currentPage > 1) {
        state.myBets = [...state.myBets, ...action.payload.myBets];
      }

      if (currentPage === 1) {
        state.myBets = action.payload?.myBets;
      }

      state.loading = false;
      state.success = true;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
    },
    getMyBetsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    cashOutStart: (state, action) => {
      state.cashoutLoading = true;
      state.error = false;
      state.success = false;
      state.cashout = false;
    },
    cashOutSuccess: (state, action) => {
      state.cashoutAmount = action.payload?.payoutValue;
      state.cashoutLoading = false;
      state.success = true;
      state.cashout = true;
    },
    cashOutFailure: (state) => {
      state.cashoutLoading = false;
      state.error = true;
    },
    getCashoutAmountStart: (state, action) => {
      state.cashoutAmount = "";
      state.amountLoading = true;
      state.amountError = false;
      state.betslipId = action.payload.betslipId;
    },
    getCashoutAmountSuccess: (state, action) => {
      state.cashoutAmount = action.payload?.payoutValue;
      state.amountLoading = false;
      state.success = true;
    },
    getCashoutAmountError: (state, action) => {
      state.amountLoading = false;
      state.amountError = true;
    },
    resetCashout: (state) => {
      state.cashout = false;
    },
  },
});

export const {
  getCashoutAmountStart,
  getCashoutAmountSuccess,
  getCashoutAmountError,
  resetCashout,
  getMyBetsStart,
  getMyBetsSuccess,
  getMyBetsFailure,
  cashOutStart,
  cashOutSuccess,
  cashOutFailure,
} = MyBetsSlice.actions;

export default MyBetsSlice.reducer;
