import {
  LIVE_MATCH_NAMESPACE,
  PRE_LIVE_MATCH_NAMESPACE,
} from "../../namespaces/index";
//for live match
export const CHANGE = `${LIVE_MATCH_NAMESPACE}/changeStatus`;
export const CHANGE_DASHBOARD = `${LIVE_MATCH_NAMESPACE}/changeStatus/dashboard`;

// for prelive match
export const PRE_CHANGE = `${PRE_LIVE_MATCH_NAMESPACE}/ChangeStatus`;
export const PRE_CHANGE_DASHBOARD = `${PRE_LIVE_MATCH_NAMESPACE}/preMatChangeUpdate/dashboard`;
