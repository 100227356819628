import React from "react";

import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import useStyle from "./loadmore.style.js";
import SportBookLoader from "../sportbookLoader/index.js";

function LoadMore({ hasNext, loading, loadmoreMatches }) {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    hasNext &&
    (!loading ? (
      <Grid className={classes.loadMoreContainer}>
        <Button
          className={classes.loadMoreBtn}
          variant="outlined"
          onClick={loadmoreMatches}
        >
          {t("SHARED.LOAD_MORE")}
        </Button>
      </Grid>
    ) : (
      <Grid
        className={
          classes.iframeCirculerLoader + " " + classes.loadMoreContainer
        }
        textAlign="center"
      >
        <SportBookLoader />
      </Grid>
    ))
  );
}

export default LoadMore;
