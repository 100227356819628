import { createSlice } from "@reduxjs/toolkit";

// import { getName } from "../../../container/SportsBook/Utils/common";

const initialState = {
  loading: false,
  success: false,
  error: false,
  bets: [],
  bettype: 1,
  stake: 0,
  // userId: 1,
  totalOdds: 0.0,
  possibleWinAmount: 0.0,
  isFastBet: true,
  multiPrice: "",
  NotBetPlaced: false,
  limit: "",
  isFirstTime: true,
  betlimit: {
    maxWinAmount: 0,
    maxBetAmount: 0,
    minBetAmount: 0,
    maxBetCount: 0,
  },
  isTouched: false,
};

const BetSlipSlice = createSlice({
  name: "BetSlip",
  initialState,
  reducers: {
    addBet: (state, action) => {
      state.bets.push(action.payload);
      state.totalOdds = state.bets.reduce((prev, curr) => prev * curr.odds, 1);
      state.possibleWinAmount = (state.totalOdds * state.stake).toFixed(2);
      if (
        state.bets?.length &&
        state.bets?.filter((bet) => {
          return bet.matchId == action.payload?.matchId;
        })?.length >= 2
      ) {
        state.bets = state.bets.map((bet) => {
          if (action.payload.matchId == bet?.matchId) {
            return {
              ...bet,
              NotBetPlaced: "This pick can not be combined",
            };
          }
          return bet;
        });
      }
    },
    removeBet: (state, action) => {
      state.bets = state.bets.filter(
        (bet) =>
          !(
            bet.matchMarketId == action.payload?.matchMarketId &&
            bet.providerOutcomeId === action.payload?.providerOutcomeId &&
            bet?.specialBetValue === action?.payload?.specialBetValue &&
            bet?.variant === action.payload?.variant
          )
      );
      state.totalOdds = state.bets.reduce((prev, curr) => {
        return prev * curr.odds;
      }, 1);
      state.possibleWinAmount = (state.totalOdds * state.stake).toFixed(2);

      if (
        !(
          state.bets?.filter((bet) => {
            return bet.matchId == action.payload?.matchId;
          })?.length >= 2
        )
      ) {
        state.bets = state.bets.map((bet) => {
          if (action.payload.matchId == bet?.matchId) {
            return {
              ...bet,
              NotBetPlaced: null,
            };
          }
          return bet;
        });
      }

      if (state.bets?.length === 0) {
        state.stake = 0.0;
      }
    },
    resetBet: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.bets = [];
      state.bettype = 1;
      state.stake = 0;
      // state.userId = 1;
      state.totalOdds = 0;
      state.possibleWinAmount = 0;
      state.isFastBet = true;
      state.multiPrice = "";
    },
    ClearAllBat: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.bets = [];
      state.bettype = 1;
      state.stake = 0;
      // state.userId = 1;
      state.totalOdds = 0;
      state.possibleWinAmount = 0;
      state.isFastBet = true;
      state.multiPrice = "";
    },

    placeBetStart: (state) => {
      state.loading = true;
    },
    placeBetComplete: (state) => {
      state.loading = false;
    },
    placeBetFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    updateStake: (state, action) => {
      state.stake = action.payload;
      state.possibleWinAmount = (state.totalOdds * state.stake).toFixed(2);
      state.isTouched = true;
    },

    updateBetType: (state, action) => {
      state.bettype = action.payload;
    },
    updateBetsWithSocketData: (state, action) => {
      state.bets = state.bets.map((bet) => {
        if (
          action?.payload &&
          action?.payload?.matchMarket &&
          action?.payload?.matchMarket?.length
        ) {
          let filteredMarketdata = action.payload.matchMarket.filter(
            ({ matchMarketId }) =>
              bet.matchMarketId == matchMarketId &&
              action?.payload?.matchId == bet.matchId
          );
          if (filteredMarketdata && filteredMarketdata?.length) {
            // const tournament =
            //   getName(action?.payload?.tournaments?.tournamentName, "en") ||
            //   getName(action?.payload?.tournaments?.tournamentName, "BET");

            const filteredBet = filteredMarketdata[0]?.outcome.filter(
              ({ providerOutcomeId, specialBetValue, variant }) =>
                bet.providerOutcomeId === providerOutcomeId &&
                bet?.specialBetValue === specialBetValue &&
                bet?.variant === variant
            );
            const isFilterBetExist = filteredBet && filteredBet?.length;
            return {
              ...bet,
              // tournament: tournament || bet?.tournament,
              // tournamentId: action?.payload?.tournaments?.tournamentId,
              odds: isFilterBetExist ? filteredBet[0]?.odd : bet.odds,
              // outcomeId: isFilterBetExist
              //   ? filteredBet[0]?.providerOutcomeId
              //   : bet.outcomeId,
              betStatus: action?.payload?.betStatus
                ? action?.payload?.betStatus
                : bet?.betStatus,
              // marketId: filteredMarketdata[0]?.marketId || bet?.marketId,
              // market: getName(filteredMarketdata[0]?.Market?.marketName, "en"),
              // matchId: action?.payload?.matchId
              //   ? action?.payload?.matchId
              //   : bet?.matchId,
              // matchMarketId:
              //   filteredMarketdata[0]?.matchMarketId || bet?.matchMarketId,
              // providerOutcomeId: isFilterBetExist
              //   ? filteredBet[0]?.providerOutcomeId
              //   : bet.providerOutcomeId,
              isSettled: filteredMarketdata[0]?.isSettled,
              active: filteredBet[0]?.active,
            };
          }
          return bet;
        }
        return bet;
      });

      state.totalOdds = state.bets.reduce((prev, curr) => prev * curr.odds, 1);
      state.possibleWinAmount = (state.totalOdds * state.stake).toFixed(2);
    },
    setIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload;
    },
    betLimitStart: (state, action) => {
      state.betlimit = initialState.betlimit;
    },
    betLimitSuccess: (state, action) => {
      state.betlimit = { ...action.payload };
    },
    betLimitFailure: (state) => {
      state.betlimit = initialState.betlimit;
    },
    resetBetLimit: (state) => {
      state.betlimit = initialState.betlimit;
    },
  },
});

export const {
  betLimitStart,
  betLimitSuccess,
  betLimitFailure,
  addBet,
  removeBet,
  updateStake,
  placeBetComplete,
  placeBetStart,
  placeBetFailure,
  resetBet,
  ClearAllBat,
  updateBetsWithSocketData,
  setIsFirstTime,
  resetBetLimit,
} = BetSlipSlice.actions;

export default BetSlipSlice.reducer;
