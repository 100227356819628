import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useParams } from "react-router-dom";
// import Parser from "html-react-parser";
import { Button, Grid } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import useStyles from "./Promotion.style";
import {
  getAdvertisementStart,
  recordAdvertisementClickStart,
} from "../../redux-saga/redux/advertisement";
import CasinoLoader from "../../container/Casino/CasinoLoader";

const Advertisement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, advertisementData } = useSelector(
    (state) => state.advertisement
  );
  // const { state } = useLocation();
  // const { id } = useParams();

  useEffect(() => {
    dispatch(getAdvertisementStart());
  }, [dispatch]);

  const LeftButton = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
    return (
      <Button
        className={classes.leftArrow}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);
    return (
      <Button
        disabled={isLastItemVisible}
        className={classes.rightArrow}
        onClick={() => scrollNext()}
      >
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  const handleClick = (id) => {
    dispatch(
      recordAdvertisementClickStart({
        advertisementsId: id,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Grid textAlign="center">
          <CasinoLoader />
        </Grid>
      ) : (
        <Grid container>
          <ScrollMenu
            LeftArrow={LeftButton}
            RightArrow={RightButton}
            wrapperClassName="horizontalScrollWrapper"
          >
            {advertisementData.map((advertisementData, index) => (
              <button
                onClick={() => handleClick(advertisementData.id)}
                // style={{ all: "unset" }}
                key={advertisementData.id}
              >
                <img src={advertisementData?.webAdImage} alt="Casino Card" />
              </button>
            ))}
          </ScrollMenu>
        </Grid>
      )}
    </>
  );
};

export default Advertisement;
