import React from "react";

import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";

const FavoritesDropDown = ({ onDropDownOption, currentValue }) => {
  const { t } = useTranslation();
  return (
    <Select
      value={currentValue}
      sx={{ color: "#F7616A" }}
      onChange={onDropDownOption}
    >
      <MenuItem value={"sportsBook"}>{t("HEADER.SPORTSBOOK")}</MenuItem>
      <MenuItem value={"casino"}> {t("HEADER.CASINO")}</MenuItem>
    </Select>
  );
};

export default FavoritesDropDown;
