import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import usePrevious from "../../hooks/usePrevious.js";
import { oddsConverter } from "../../Utils/common.js";

import "./OddAnimation.css";

const checkAnimationCondition = (odd, prevOddValue) => {
  if (odd && prevOddValue && odd !== "-" && prevOddValue !== "-") {
    if (odd > prevOddValue) {
      return 1;
    } else if (odd < prevOddValue) {
      return 2;
    }
  }
  return 0;
};
function OddAnimation({ odd, isSocketUpdated, prevOddValue, style }) {
  const [check, setCheck] = useState();
  let previousValue = usePrevious(odd);
  if (prevOddValue) previousValue = prevOddValue;

  const selectedOddsFormat = useSelector(
    (state) => state.sportbook.sports.selectedOddsFormat
  );
  let calculateNewOdd = oddsConverter(odd, selectedOddsFormat);
  useEffect(() => {
    setCheck(checkAnimationCondition(odd, previousValue));
  }, [odd]);
  return (
    <Grid className={`${style}`}>
      {calculateNewOdd || <LockIcon className={"lockIcon"} />}
      <span
        className={isSocketUpdated && check === 2 ? "blink-down" : ""}
      ></span>
      <span className={isSocketUpdated && check === 1 ? "blink-up" : ""}></span>
    </Grid>
  );
}

export default React.memo(OddAnimation);
