import React from "react";

import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Search from "./Search";
import useStyles from "./SearchModalContent.styles";

const SearchComponent = ({ setShowSearch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.modal}>
        <Grid item xs={12} className={classes.modalheader}>
          <Typography variant="h6" textAlign="center">
            {t("COMMON.SEARCH_GAME")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Search setShowSearch={setShowSearch} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchComponent;
