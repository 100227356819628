import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "#feba19",
    borderRadius: "5px",
    padding: "6px 20px",
    color: "#000",
    height: "36px",
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "capitalize",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "6px 10px",
      height: "30px",
      fontSize: "10px",
    },
    "&:hover": {
      background: "#e8a812",
    },
    "& span": {
      marginRight: "5px",
    },
  },
  dialogcontainer: {
    maxWidth: "95%",
    margin: "0 auto",
    "& .MuiDialog-container": {
      display: "block",
    },
    zIndex: 10000,
  },
  customSearchbar: {
    position: "relative",
    "& img": {
      position: "absolute",
      left: "10px",
      top: "13px",
      [theme.breakpoints.down("md")]: {
        top: "6px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiFormControl-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        borderRadius: "10px",
      },
    },
  },
}));
