import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  List,
  ListItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { t } from "i18next";

import Languages from "./Languages.list.json";
import i18n from "../../../i18n";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./MobileSideBar.styles";
import { changeLanguageBE } from "../../../sport-book-saga/redux/language";

const MobileSidebar = ({ isActive, active, setActive, setIsActive }) => {
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsActive(!isActive);
  };
  const handleCloseLanguage = (event, language) => {
    i18n.changeLanguage(language);
    dispatch(changeLanguageBE(language));
    setAnchorElLanguage(null);
  };
  const openLanguage = Boolean(anchorElLanguage);
  return (
    <section>
      <Grid className={classes.sideBar}>
        <List>
          <ListItem>
            <Link
              onClick={() => {
                dispatch(setActive("/"));
                handleClose();
              }}
              to="/"
              className={active === "/" ? classes.bg_salmon : ""}
            >
              <img src="/images/sidebar-1.svg" alt="sidebar home icon" />
              <span>{t("HEADER.HOME")}</span>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              onClick={() => {
                dispatch(setActive("/lobby"));
                handleClose();
              }}
              className={active === "/lobby" ? classes.bg_salmon : "lobby"}
              to="/lobby"
            >
              <img src="/images/sidebar-2.svg" alt="sidebar home icon" />
              <span>{t("HEADER.SPORTS")}</span>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              onClick={() => {
                dispatch(setActive("/casino"));
                handleClose();
              }}
              className={active === "/casino" ? classes.bg_salmon : "/"}
              to="/casino"
            >
              <img src="/images/sidebar-3.svg" alt="sidebar home icon" />
              <span> {t("HEADER.CASINO")}</span>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              onClick={() => {
                dispatch(setActive("/blog"));
                handleClose();
              }}
              className={active === "/blog" ? classes.bg_salmon : "/"}
              to="/blog"
            >
              <img src="/images/blog.svg" alt="sidebar home icon" />
              <span> {t("HEADER.BLOG")}</span>
            </Link>
          </ListItem>

          {/* <ListItem>
            <Link
              onClick={() => {
                setIsActive(!isActive);
              }}
              to="/"
            >
              Promotion
            </Link>
          </ListItem> */}

          <ListItem>
            <Link
              onClick={() => {
                dispatch(setActive("/results"));
                handleClose();
              }}
              className={active === "/results" ? classes.bg_salmon : "results"}
              to="/results"
            >
              <img src="/images/sidebar-5.svg" alt="sidebar home icon" />
              <span>{t("HEADER.RESULTS")}</span>
            </Link>
          </ListItem>

          {isLoggedIn ? (
            <>
              <ListItem
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Accordion
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className={classes.accordianWrapper}
                >
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`${classes.mybetAccordian} ${
                      active === "/my-bets" ? classes.bg_salmon : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Link
                      to={"/my-bets"}
                      className={
                        active === "mybet" ? classes.bg_salmon : "mybet"
                      }
                      onClick={() => {
                        dispatch(setActive("/my-bets"));
                        handleClose();
                      }}
                    >
                      {/* <p className={classes.leftShap} /> */}
                      <img
                        src="/images/sidebar-4.svg"
                        alt="sidebar home icon"
                      />
                      <span>{t("HEADER.MY_BETS")}</span>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordianDetail}>
                    {/* <Link
                      to={"/user/betHistory"}
                      onClick={() => {
                        setIsActive(!isActive);
                      }}
                    >
                      {"Casino"}
                    </Link> */}
                    {/* <Link
                      to={"/my-bets"}
                      onClick={() => {
                        setIsActive(!isActive);
                        handleActivefunction("my-bets");
                      }}
                      className={
                        active === "my-bets" ? classes.bg_salmon : "my-bets"
                      }
                    >
                      {"Sportbook"}
                    </Link> */}
                  </AccordionDetails>
                </Accordion>
              </ListItem>

              {/* <ListItem>
                <Link
                  to="/user/bonus"
                  onClick={() => {
                    setIsActive(!isActive);
                  }}
                >
                  <img src="/images/sidebar-6.svg" alt="sidebar home icon" />
                  <span>Bonuses</span>
                </Link>
              </ListItem> */}

              <ListItem>
                <Link
                  onClick={() => {
                    dispatch(setActive("/favorites"));
                    handleClose();
                  }}
                  className={
                    active === "/favorites" ? classes.bg_salmon : "favorites"
                  }
                  to="/favorites"
                >
                  <img src="/images/sidebar-7.svg" alt="sidebar home icon" />
                  <span>{t("HEADER.FAVORITES")}</span>
                </Link>
              </ListItem>
            </>
          ) : null}

          {/* <ListItem>
            <Link
              onClick={() => {
                setIsActive(!isActive);
              }}
              to="/"
            >
              <img src="/images/sidebar-8.svg" alt="sidebar home icon" />
              <span>Language (EN)</span>
            </Link>
          </ListItem> */}

          <ListItem>
            <Button
              id="language-button"
              className={classes.languageBtn}
              aria-controls={openLanguage ? "language-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openLanguage ? "true" : undefined}
              onClick={(event) => setAnchorElLanguage(event.currentTarget)}
              startIcon={
                <img
                  align="right"
                  src={`/images/${
                    Languages?.[i18n.language?.toUpperCase() || "EN"]?.flag
                  }`}
                  alt="country flag"
                  className={classes.countryFlagImg}
                />
              }
              endIcon={
                openLanguage ? <KeyboardArrowUp /> : <KeyboardArrowDown />
              }
            >
              <span>
                {Languages?.[i18n.language?.toUpperCase() || "EN"]?.name}
              </span>
            </Button>
            <Menu
              id="language-menu"
              anchorEl={anchorElLanguage}
              open={openLanguage}
              onClose={() => setAnchorElLanguage(null)}
              className={classes.languageMenu}
              MenuListProps={{
                "aria-labelledby": "language-button",
              }}
            >
              {Object.keys(Languages).map((lan) => {
                return (
                  <MenuItem
                    onClick={(event) =>
                      handleCloseLanguage(event, lan.toLowerCase())
                    }
                    key={lan}
                  >
                    <img
                      src={`/images/${Languages[lan].flag}`}
                      alt="country flag"
                      className={classes.countryFlagImg}
                    />
                    {Languages[lan].name}
                  </MenuItem>
                );
              })}
            </Menu>
          </ListItem>
        </List>
      </Grid>
    </section>
  );
};

export default MobileSidebar;
