import betSlip from "./betSlip.json";
import liveMatches from "./liveMatches.json";
import preLiveMatches from "./preLiveMatches.json";
import lobby from "./lobby.json";
import myBet from "./myBet.json";
import favourites from "./favorites.json";
import MatchResult from "./matchResult.json";
import moreMarkets from "./moreMarkets.json";
import Sports from "./sports.json";
import EachoutCome from "./EachoutCome.json";
import Shared from "./shared.json";
import markets from "./markets.json";
export const sportsBookTrans = {
  ...betSlip,
  ...liveMatches,
  ...preLiveMatches,
  ...lobby,
  ...myBet,
  ...favourites,
  ...MatchResult,
  ...moreMarkets,
  ...Sports,
  ...EachoutCome,
  ...Shared,
  ...markets,
};
