export const acceptedImageFormats = ["image/jpg", "image/jpeg", "image/png"];
export const maxImageFileSize = 1024 * 1024; // 1 MB
export const viewAllGamesLimit = 18;
export const homePageGamesLimit = 12;
export const DATE_FORMAT = "MM/DD/YYYY";
export const TRANSACTION_LIMIT = 10;
export const EAR_TRANSACTION_TYPE = {
  0: "TRANSACTIONS.DEBIT",
  1: "TRANSACTIONS.CREDIT",
};
export const PROVIDERIMAGES = {
  1: "/images/BetSoft.png",
  2: "/images/Bgaming.png",
  3: "/images/Bluewhale.png",
  4: "/images/Evoplay.png",
  5: "/images/Felix.png",
  6: "/images/Orbital.png",
  7: "/images/TVBET.png",
  8: "/images/VibraGaming.png",
};
