import React, { useEffect, useRef } from "react";

import { Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import { useTranslation } from "react-i18next";

import useStyles from "./IframeGame.styles";
import {
  getGameLinkFailure,
  getGameLinkStart,
} from "../../../redux-saga/redux/casino/lobby/casinoGames";
import CasinoLoader from "../CasinoLoader";
import FavoriteButton from "../Lobby/FavoriteButton/FavoriteButton";
import { setActive } from "../../../redux-saga/redux/settings";

const IframeGame = () => {
  const classes = useStyles();
  const iframeRef = useRef();
  const { gameLink, loading, redirection } = useSelector(
    (state) => state.casinoGames
  );
  const { gameId } = useParams();
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  useEffect(() => {
    if (redirection) {
      dispatch(getGameLinkFailure({ redirection: false }));
      setTimeout(() => {
        navigate("/");
      }, 300);
    }
  }, [redirection, navigate, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getGameLinkStart({
        gameId: parseInt(gameId),
        device_type: isMobile ? "mobile" : "desktop",
        homeURL: process.env.REACT_APP_REFERRAL_BASE_URL,
      })
    );
  }, [dispatch, gameId]);

  const RequestFullScreen = () => {
    iframeRef.current.requestFullscreen();
  };

  return (
    <>
      {loading ? (
        <Grid className={classes.iframeCirculerLoader} textAlign="center">
          <CasinoLoader />
        </Grid>
      ) : (
        <>
          <div
            className={
              isGameStarted && isMobile ? classes.gameWrap : classes.iframeWrap
            }
          >
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Grid className={classes.iframeContent}>
                  <div ref={iframeRef} className={classes.iframeHeight}>
                    <iframe
                      src={gameLink}
                      title="casino"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    />
                  </div>
                  <Grid className={classes.iframeBtnWrap}>
                    <Button
                      className={classes.btnPrimary}
                      onClick={RequestFullScreen}
                    >
                      <img src="/images/full-screen.png" alt="Full Screen" />
                    </Button>
                    {/* <Button className={classes.btnPrimary}>
                      <img src="/images/iframe-heart.png" alt="Heart" />
                    </Button> */}
                    <Button
                      className={classes.btnPrimary}
                      onClick={() => {
                        dispatch(setActive("/casino"));
                        navigate("/casino");
                      }}
                    >
                      <img src="/images/iframe-close.png" alt="Cross" />
                    </Button>
                    {!isMobile && (
                      <Grid position={"relative"}>
                        <FavoriteButton
                          isFavorite={gameLink.isFavorite}
                          favBtnClass={classes.favIconWrap}
                          gameId={gameId}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* {!isMobile && (
                <Grid item xs={12} lg={3}>
                  <Grid className={classes.iframeGameWrap}>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-1.png" alt="game Card" />
                    </Grid>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-2.png" alt="game Card" />
                    </Grid>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-3.png" alt="game Card" />
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default IframeGame;
