import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  imgContainer: {
    minHeight: "24px",
    minWidth: "24px",
    maxHeight: "24px",
    maxWidth: "24px",
    overflow: "hidden",
    borderRadius: "100%",
    marginRight: "8px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center center",
    },
  },
  locationCard: {
    maxHeight: "60px",
    marginRight: "10px",
    minWidth: "150px",
    background: "#1b214f",
    borderRadius: "45px",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "&.clicked": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
}));
