import React, { useState, useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import useStyle from "./MoreMarket.style.js";
import {
  getMoreMarketsStart,
  getMoreMarketsSuccess,
  resetMoreMarket,
  setIsReloaded,
} from "../../../sport-book-saga/redux/more-markets";
import {
  containSettledMarket,
  decodeSocketData,
  getName,
  getTeamName,
  sportsDefault,
} from "../Utils/common.js";
import useLiveMatchSocketNameSpace, {
  usePreLiveMatchSocketNameSpace,
} from "../socket/hooks/index.js";
import { updateMarketsWithSocketData } from "../../../sport-book-saga/redux/matches/index.js";
import { status } from "../Utils/statusConstant.js";
import SBwidget from "./SBwidget.js";
import SportBookLoader from "../components/sportbookLoader/index.js";
import MarketContainer from "./MarketContainer.js";
// import { test1 } from "./test1.js";

const allMarketListener = {};

function MoreMarkets() {
  const { t } = useTranslation();
  const timeoutRef = useRef(null);
  const { language } = useSelector((state) => state.sportbook.language);
  const { listenAllPreLiveMatchMarketsUpdates } =
    usePreLiveMatchSocketNameSpace();
  const { listenAllMatchMarketsUpdates } = useLiveMatchSocketNameSpace();
  const classes = useStyle();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [top, setTop] = useState();
  const moreMarkets = useSelector(
    (state) => state.sportbook.moreMarkets.moreMarkets
  );

  const {
    loading,
    //  reloaded
  } = useSelector((state) => state.sportbook.moreMarkets);
  const marketDetails = location.state;
  // console.log("moremarket rendering ...");
  useEffect(() => {
    dispatch(getMoreMarketsStart({ ...marketDetails, page: 1, limit: 10 }));
    return () => {
      dispatch(resetMoreMarket());
    };
  }, [dispatch]);

  useEffect(() => {
    const matchRoomId =
      moreMarkets &&
      marketDetails?.sportId &&
      moreMarkets.providerTournamentId &&
      moreMarkets.providerMatchId &&
      `${marketDetails?.sportId}${moreMarkets.providerTournamentId}${moreMarkets.providerMatchId}`;

    if (matchRoomId && !allMarketListener[`dashboard_cb_${matchRoomId}`]) {
      const obj1 = {
        [`dashboard_cb_${matchRoomId}`]: async (value) => {
          let socketData = await decodeSocketData(value);
          socketData = socketData?.data;
          if (socketData) {
            const settledMarkets = containSettledMarket(socketData);

            if (value?.data?.providerMatchId)
              if (settledMarkets?.length) {
                dispatch(
                  updateMarketsWithSocketData({
                    ...socketData,
                    settledMarkets,
                  })
                );
              }

            dispatch(
              getMoreMarketsSuccess({ ...socketData, isDataFromScoket: true })
            );
          }
        },
      };
      allMarketListener[`dashboard_cb_${matchRoomId}`] = moreMarkets?.isLive
        ? listenAllMatchMarketsUpdates(
            matchRoomId,
            obj1[`dashboard_cb_${matchRoomId}`]
          )
        : listenAllPreLiveMatchMarketsUpdates(
            matchRoomId,
            obj1[`dashboard_cb_${matchRoomId}`]
          );
    }
    // eslint-disable-next-line
  }, [moreMarkets]);

  useEffect(() => {
    const element = document.getElementById("mainfooter");
    const widgetElement = document.getElementById("sbwidget");
    function getVisibleHeight() {
      if (widgetElement) {
        const elementRect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        const visibleHeight =
          Math.min(elementRect.bottom, viewportHeight) -
          Math.max(elementRect.top, 0);

        return parseInt(visibleHeight);
      }
    }

    // Function to handle the scroll event and log the visible height
    function handleScroll() {
      const visibleHeight = getVisibleHeight();
      // console.log("visibleHeight", visibleHeight);
      if (widgetElement) {
        // widgetElement.style.position = "fixed";
        // widgetElement.style.top =
        //   visibleHeight > 170 ? 170 - visibleHeight + "px" : "170px";
        // console.log("widgetElement.style.top", widgetElement.style.top);
        let h = visibleHeight > 170 ? 170 - visibleHeight - 18 + "px" : "170px";
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setTop(h);
        }, 500);
      }
    }

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);

      if (moreMarkets) {
        for (const [key] of Object.entries(allMarketListener)) {
          allMarketListener[`${key}`]();
        }
      }
      dispatch(setIsReloaded(true));
    };
  }, []);

  // useEffect(() => {
  //   if (reloaded) navigate("/lobby");
  // }, []);

  return loading ? (
    <Grid className={classes.iframeCirculerLoader}>
      <SportBookLoader />
    </Grid>
  ) : (
    <div className={classes.extraMarketWrap}>
      <Grid className={classes.BackBtnWrap}>
        <Button className={classes.btnPrimary} onClick={() => navigate(-1)}>
          <img src="/images/left.png" alt="Back" /> {t("MORE_MARKETS.BACK_BTN")}
        </Button>
      </Grid>
      <Grid className={classes.liveMatchBanner}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} lg={moreMarkets?.isLive ? 8 : 12}>
            <Grid className={classes.liveMatchLefttSection}>
              <Grid className={classes.liveMatchName}>
                <Typography>
                  <img
                    src={
                      moreMarkets?.tournaments?.sport?.icon ||
                      sportsDefault["default"]
                    }
                    alt="Soccer"
                  />
                  <span>{getName(moreMarkets?.countryName, language)}</span>/{" "}
                  <span>
                    {moreMarkets &&
                      moreMarkets?.tournaments &&
                      moreMarkets?.tournaments?.tournamentName &&
                      getName(
                        moreMarkets?.tournaments?.tournamentName,
                        language
                      )}
                  </span>
                </Typography>
              </Grid>
              <Grid className={classes.matchParticipantsWrap}>
                <Grid className={classes.participantsLeft}>
                  <Typography variant="h3">
                    {moreMarkets &&
                      moreMarkets?.teams &&
                      getTeamName(moreMarkets?.teams, "home", language)
                        .slice(0, 2)
                        .toUpperCase()}
                  </Typography>
                  <Typography>
                    {moreMarkets &&
                      moreMarkets?.teams &&
                      getTeamName(moreMarkets?.teams, "home", language)}
                  </Typography>
                </Grid>
                <Grid className={classes.liveMatchScoreboard}>
                  {moreMarkets?.isLive && (
                    <span>
                      {moreMarkets.status && status[moreMarkets.status] ? (
                        <>
                          <img src="/images/red-live.svg" alt="Live" />
                          {status[moreMarkets.status]}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                  <Grid className={classes.teamScoreboard}>
                    <Grid className={classes.teamScoreCardWrap}>
                      <Grid className={classes.teamScoreCard}>
                        {moreMarkets.score
                          ? moreMarkets.score?.split(":")?.[0]
                          : "-"}
                      </Grid>
                    </Grid>
                    <span>:</span>
                    <Grid className={classes.teamScoreCardWrap}>
                      <Grid className={classes.teamScoreCard}>
                        {moreMarkets.score
                          ? moreMarkets.score?.split(":")?.[1]
                          : "-"}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.participantsRight}>
                  <Typography variant="h3">
                    {" "}
                    {moreMarkets &&
                      moreMarkets?.teams &&
                      getTeamName(moreMarkets?.teams, "away", language)
                        .slice(0, 2)
                        .toUpperCase()}
                  </Typography>
                  <Typography>
                    {moreMarkets &&
                      moreMarkets?.teams &&
                      getTeamName(moreMarkets?.teams, "away", language)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              {isMobile
                ? moreMarkets?.isLive && (
                    <Grid item xs={12} lg={12} style={{ zIndex: "1" }}>
                      <Grid className={classes.liveMatchRightSection}>
                        {moreMarkets?.providerMatchId && (
                          <SBwidget matchID={moreMarkets?.providerMatchId} />
                        )}
                      </Grid>
                    </Grid>
                  )
                : null}
            </Grid>
            <MarketContainer betStatus={moreMarkets?.betStatus} />
          </Grid>

          {!isMobile && (
            <Grid
              item
              className={classes.sbWidget}
              id="sbwidget"
              top={top || "170px"}
              style={{
                top: top || "170px",
              }}
            >
              {moreMarkets?.isLive && (
                <Grid item xs={12} lg={12}>
                  <Grid className={classes.liveMatchRightSection}>
                    {moreMarkets?.providerMatchId && (
                      <SBwidget matchID={moreMarkets?.providerMatchId} />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(MoreMarkets);
