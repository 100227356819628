import React, { useMemo } from "react"; //  useState //  useEffect,

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import useStyle from "../../MoreMarkets/MoreMarket.style.js";
import {
  getName,
  getTeamId,
  getTeamName,
  oddTypeFormatter,
} from "../../Utils/common.js";
import {
  addBet,
  removeBet,
} from "../../../../sport-book-saga/redux/betslip/index.js";
import OddAnimation from "../OddAnimation/index.js";

function EachOutCome({
  outcome,
  market,
  marketName,
  featureMatch,
  betStatus,
  prevMarket,
}) {
  const { t } = useTranslation();
  const classes = useStyle();
  const bets = useSelector((state) => state.sportbook.betslip.bets);
  const { isLoggedIn } = useSelector((state) => state.authentication);
  let { moreMarkets } = useSelector((state) => state.sportbook.moreMarkets);
  const { maxBetCount, maxOddLimit, minOddLimit } = useSelector(
    (state) => state.sportbook.betslip.betlimit
  );

  if (featureMatch) {
    moreMarkets = featureMatch;
  }
  const { language } = useSelector((state) => state.sportbook.language);
  const dispatch = useDispatch();
  const alreadyInBets =
    bets?.length &&
    outcome &&
    bets.filter(
      (bet) =>
        bet?.providerOutcomeId === outcome?.providerOutcomeId &&
        bet.matchMarketId == market.matchMarketId &&
        bet?.specialBetValue === outcome?.specialBetValue &&
        bet?.variant === outcome?.variant
    )?.length
      ? "active"
      : "";
  let outcomeType = outcome ? oddTypeFormatter(outcome) : "";

  const oddSelectHandler = (
    marketData,
    providerOutcomeId,
    specialBetValue,
    variant
  ) => {
    if (!isLoggedIn) {
      toast.error(t("SHARED.LOGIN_CONTINUE"));
      return;
    }
    if (!marketData || !marketData?.matchMarketId) return null;
    const tournament = getName(
      moreMarkets?.tournaments?.tournamentName,
      language
    );

    // if it is already present in bets
    const betExist = bets.filter(
      (bet) =>
        bet.matchMarketId == marketData.matchMarketId &&
        bet?.providerOutcomeId === providerOutcomeId &&
        bet?.specialBetValue === specialBetValue &&
        bet?.variant === variant
    );
    if (betExist && betExist?.length) {
      dispatch(
        removeBet({
          matchMarketId: betExist[0]?.matchMarketId,
          providerOutcomeId: betExist[0]?.providerOutcomeId,
          isLive: betExist[0]?.isLive,
          specialBetValue: betExist[0]?.specialBetValue,
          variant: betExist[0]?.variant,
          matchId: betExist[0]?.matchId,
        })
      );
    } else {
      if (bets?.length >= parseInt(maxBetCount)) {
        toast.error(
          "More than " + maxBetCount + " bets not allowed in a betslip"
        );
        return;
      }
      let odd = marketData?.outcome.filter(
        (outcome) =>
          outcome.providerOutcomeId === providerOutcomeId &&
          outcome?.specialBetValue === specialBetValue &&
          outcome?.variant === variant
      );
      let oddvalue = odd[0]?.odd;
      if (oddvalue && parseFloat(oddvalue) > parseFloat(maxOddLimit)) {
        toast.error(
          t("EACH_OUT_COME.ODD_VALUE_NOT_TO_BE_GREATER_THAN") +
            parseFloat(maxOddLimit)
        );
        return;
      }
      if (oddvalue && parseFloat(oddvalue) < parseFloat(minOddLimit)) {
        toast.error(
          t("EACH_OUT_COME.ODD_VALUE_NOT_TO_BE_LESSER_THAN") +
            parseFloat(minOddLimit)
        );
        return;
      }
      let betData = {
        selectedSportId: moreMarkets?.providerSportId,
        providerTournamentId: moreMarkets?.tournaments?.providerTournamentId,
        providerMatchId: moreMarkets?.providerMatchId,
        outcomeType,
        tournament,
        tournamentId: moreMarkets?.tournamentId,
        odds: odd[0]?.odd,
        outcomeId: odd[0]?.providerOutcomeId,
        betStatus: betStatus,
        marketId: marketData?.Market?.marketId,
        market: marketName,
        matchId: moreMarkets?.matchId,
        matchMarketId: marketData?.matchMarketId,
        providerOutcomeId: odd[0]?.providerOutcomeId,
        homeTeamId: getTeamId(moreMarkets?.teams || market, "home"),
        awayTeamId: getTeamId(moreMarkets?.teams, "away"),
        isLive: marketData?.isLive,
        specialBetValue: odd[0]?.specialBetValue,
        variant: odd[0]?.variant,
        homeTeam: getTeamName(moreMarkets?.teams, "home", language),
        awayTeam: getTeamName(moreMarkets?.teams, "away", language),
        countryId: moreMarkets?.sportCountryId,
      };
      dispatch(addBet(betData));
    }
  };

  let prevOddValue = useMemo(() => {
    let prevVal = "";
    if (prevMarket && prevMarket.outcome && prevMarket.outcome?.length) {
      prevVal = prevMarket.outcome.filter((prevOutcome) => {
        return (
          prevOutcome.providerOutcomeId === outcome.providerOutcomeId &&
          prevOutcome.specialBetValue === outcome.specialBetValue
        );
      });
    }
    if (prevVal && prevVal?.length) return prevVal[0].odd;
  }, [prevMarket, outcome]);

  if (!outcome) {
    return (
      <Grid item xs={6} lg={4}>
        <Grid
          className={
            !featureMatch
              ? `${classes.liveMatchOddsWrap}`
              : classes.liveMatchOddsWrapProp
          }
        >
          <Grid
            className={
              !featureMatch
                ? `${classes.liveMatchOddsPropNA}`
                : classes.liveMatchOddsPropNAFeature
            }
          >
            <span>
              <LockIcon className={classes.lockIcon} />
            </span>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid item xs={6} lg={4}>
      <Grid
        className={
          !featureMatch
            ? `${classes.liveMatchOddsWrap}`
            : classes.liveMatchOddsWrapProp
        }
      >
        <Grid
          className={
            !featureMatch
              ? `${classes.liveMatchOdds} ${alreadyInBets}`
              : `${classes.liveMatchOddsProp} ${alreadyInBets}`
          }
          onClick={(event) => {
            event.stopPropagation();
            if (
              outcome?.odd &&
              outcome.type != -1 &&
              outcome.active != false &&
              outcomeType != -1 &&
              `${betStatus}` === "1" &&
              outcome?.odd !== "-" &&
              !market?.isDisabled
            ) {
              oddSelectHandler(
                market,
                outcome?.providerOutcomeId,
                outcome?.specialBetValue,
                outcome?.variant
              );
            }
          }}
        >
          {outcome.active == false ||
          outcome.type == -1 ||
          outcomeType == -1 ||
          `${betStatus}` !== "1" ||
          !outcomeType ||
          !outcome?.odd ||
          market?.isDisabled ? (
            <Grid justifyContent={"center"} display={"flex"} minWidth={"100%"}>
              <LockIcon className={classes.lockIcon} />
            </Grid>
          ) : (
            <>
              <span>{outcomeType}</span>
              <OddAnimation
                odd={outcome?.odd}
                isSocketUpdated={true}
                prevOddValue={prevOddValue}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(EachOutCome);
