import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  animation: {
    "& .blink-up": {
      right: "-16px",
      top: "8px",
    },
    "& .blink-down": {
      right: "-16px",
      top: "8px",
    },
  },
  place_bet_loader: {
    marginLeft: "1rem",
  },
  betslip_container: {
    // background: "#0D2867",
    minWidth: "450px",
    position: "fixed",
    right: "0",
    bottom: "0",
    height: "auto",
    marginBottom: "30px",
    padding: "30px 10px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      width: "100%",
    },
  },
  betslipContentWrap: {
    background: "#0D2867",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    "& .MuiAlert-standardError": {
      background: "transparent",
      padding: "0 0 0 0",
      color: "#f44336",
      "& .MuiAlert-message": {
        padding: "0",
      },
      "& .MuiAlert-icon": {
        padding: "0",
      },
    },
    "& .MuiAlert-standardWarning": {
      background: "transparent",
      padding: "0 0 0 0",
      color: "#rgb(255, 226, 183)",
      "& .MuiAlert-message": {
        padding: "0",
      },
      "& .MuiAlert-icon": {
        padding: "0",
      },
    },
  },
  betscroll_wrap: {
    maxHeight: "38vh",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      maxHeight: "27vh",
    },
  },
  betslip_listing: {
    padding: "5px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },
  bet_card: {
    background: "#fff",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  bet_card_disable: {
    background: "#fff",
    borderRadius: "10px",
    marginBottom: "10px",
    opacity: "0.6",
  },
  bet_card_content: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "10px 20px",
  },
  betslipHeader: {
    position: "relative",
    top: "10px",
    "& > img": {
      width: "100%",
    },
  },
  betslipArrow: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "20px",
    left: "50%",
    cursor: "pointer",
  },
  betslipClearbets: {
    cursor: "pointer",
    // marginTop: "20px",
    fontSize: "14px",
  },
  betslipHeaderContent: {
    position: "absolute",
    top: "10px",
    width: "100%",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  league_name: {
    display: "flex",
    justifyContent: "space-between",
    color: "#758DBA",
    "& .MuiButton-root": {
      padding: "0",
      justifyContent: "flex-end",
      marginBottom: "0",
      "&:hover": {
        background: "transparent",
      },
    },
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },
  bet_team_name: {
    display: "flex",
    alignItems: "center",
    "& p": {
      color: "#000",
      maxWidth: "160px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "14px",
      fontWeight: "600",
    },
    "& span": {
      color: "#000",
      padding: "0 5px",
    },
  },
  bet_odd: {
    color: "rgb(172, 67, 74)",
    fontSize: "12px",
    position: "relative",
  },
  balanceText: {
    width: "100%",
  },
  betslip_bottom: {
    marginTop: "1rem",
    flexDirection: "column",
    padding: "0 2rem 1rem",
    color: "#fff",
    // borderBottomLeftRadius:"10px",
    // borderBottomRightRadius:"10px",
    "& .MuiInputBase-root": {
      minWidth: "100%",
      maxHeight: "44px",
      color: "#fff",
      borderRadius: "30px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F7616A",
      },
    },
    "& .MuiGrid-root": {
      maxHeight: "44px",
      zIndex: "1",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem 20px",
    },
  },

  place_bet_btn_div: {
    "& .Mui-disabled": {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },

  place_bet_btn: {
    minWidth: "100%",
    padding: "10px 20px",
    borderRadius: "30px",
    color: "#fff",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    border: "none",
    fontWeight: "600",
  },
  balance_btn_container: {
    position: "absolute",
    right: "0",
    bottom: "0",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      paddingRight: "10px",
      paddingLeft: "0",
      minWidth: "auto",
    },
    "& .MuiInputBase-input": {
      paddingRight: "60px",
    },
    zIndex: 1000,
  },
  stakeInputWrap: {
    width: "100%",
  },

  notLoggedIn: {
    marginBottom: "16px",
    color: "#f44336",
    paddingLeft: "16px !important",
  },
  warning: {
    marginBottom: "16px",
    color: "#rgb(255, 226, 183)",
    paddingLeft: "16px !important",
  },
  quick_bet_btn_container: {
    marginTop: "16px",
    marginBottom: "16px",
    columnGap: "2px",
    justifyContent: "right",
    "& .MuiButtonBase-root": {
      padding: "6px 6px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "50px",
      },
    },
  },
}));
