import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";

import useStyle from "./MybetsDialog.styles";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const classes = useStyle();
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className={classes.closeBtn}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function MybetsDialog({ title, setShowAll, showAll, children }) {
  const classes = useStyle();
  return (
    <Dialog open={showAll} onClose={setShowAll} className={classes.mybetModal}>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowAll(false)}
        open={showAll}
      >
        {title}
        <Grid
          marginLeft={"auto"}
          marginRight={"1rem"}
          onClick={() => window.print()}
        >
          <PrintIcon />
        </Grid>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

export default MybetsDialog;
