import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cashPopup: {
    "& .MuiList-root": {
      background: "#1b224d",
      width: "100%",
      padding: "10px",
    },
    "& .MuiPaper-elevation": {
      [theme.breakpoints.down("md")]: {
        width: "250px",
      },
      width: "280px",
    },
    "& .MuiGrid-root": {
      width: "100%",
      minWidth: "100%",
      fontSize: "14px",
      padding: "0",
      gap: "5px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
      "& p": {
        fontSize: "14px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
    },
  },
  buttonDeposit: {
    marginRight: "10px",
    minWidth: "150px",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    borderRadius: "45px",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "&.clicked": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "& img": {
      marginRight: "5px",
    },
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
  profileImg: {
    width: "34px",
    height: "34px",
    borderRadius: "50%",
  },
  header: {
    background: "#F2F4FF",
    padding: "0px 36px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "21px",
    lineHeight: "60px",
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: "100",
    [theme.breakpoints.down("md")]: {
      padding: "10px 15px",
      minHeight: "50px",
      lineHeight: "inherit",
      marginBottom: "10px",
      zIndex: "9",
    },
    "& .MuiButton-endIcon": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "2px",
      },
    },
  },

  myAccountLink: {
    textDecoration: "none",
    color: "#FFFF",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      "&:before": {
        content: "' '",
        width: "4px",
        position: "absolute",
        left: "0px",
        height: "37px",
        clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
        background: "#fff",
      },
    },
  },
  headerLogo: {
    display: "flex",
    marginRight: "10px",
    "& img": {
      width: "150px",
      padding: "10px 0",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80px",
        padding: "0",
      },
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },

  menuLogo: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    "& img": {
      width: "18px",
      marginRight: "10px",
    },
  },

  headerRight: {
    display: "flex",
  },

  loginIcon: {
    width: "15px",
  },

  iconBg: {
    width: "34px",
    height: "34px",
    display: "flex",
    padding: "5px",
    background: "#8492B7",
    alignItems: "center",
    borderRadius: "50%",
    justifyContent: "center",
  },

  walletIcon: {
    background: "#8492B7",
    height: "34px",
    width: "34px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "25px",
    },
    "& img": {
      width: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "11px",
      },
    },
  },

  headerCenter: {
    display: "flex",
    gap: "50px",
  },

  headerLink: {
    fontWeight: "600",
    fontSize: "14px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#C7575E",
    overflowX: "initial",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderBottom: "2px solid transparent",
    lineHeight: "50px",
    "&.active, &:hover": {
      borderBottom: "2px solid #C7575E",
      color: "#C7575E",
    },
    "& img": {
      width: "25px",
    },
    // "&:hover": {
    //   background:
    //     "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    //   color: "#fff",
    // },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  btnPrimary: {
    minHeight: "50px",
    // background: "#F7616A",
    borderRadius: "36px",
    padding: "8px 20px",
    minWidth: "145px",
    color: "#25262B",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      padding: "6px 14px",
      fontSize: "10px",
      borderRadius: "5px",
      minWidth: "auto",
      whiteSpace: "nowrap",
    },

    "&:hover": {
      background: "#0D2867",
      color: "#fff",
    },
    "& span": {
      marginRight: "15px",
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
      },
      // "& img": {
      //   [theme.breakpoints.down("sm")]: {
      //     display: "none",
      //   },
      // },
    },
  },
  loginWrap: {
    // background: "#0d0d0d",
    borderRadius: "0px",
    width: "440px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#002965",
    },
  },
  languageMenu: {
    "& ul": {
      background: "#0D2867",
      padding: "0",
      minWidth: "106px",
      "& li": {
        minHeight: "3rem",
        minWidth: "12rem",
        borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
        fontSize: "14px",
        display: "flex",
        columnGap: "1rem",
        fontWeight: "600",
        "&:hover": {
          color: "#fff",
          background:
            "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
  accountMenu: {
    "& ul": {
      background: "#0D2867",
      borderRadius: "0px 0px 10px 10px",
      marginTop: "5px",
      padding: "0",
      "& li": {
        // borderBottom: "1px solid rgb(58 58 58 / 40%)",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        lineHeight: "45px",
        minHeight: "45px",
        fontWeight: "300",
        padding: "0",
        "& span": {
          marginRight: "18px",
          "& img": {
            width: "18px",
          },
        },
        "&:last-child": {
          borderBottom: "none",
          borderTop: "1px solid #fff",
          padding: "6px 25px",
          "&:hover": {
            background:
              "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
          },
        },
        "& a": {
          display: "flex",
          alignItems: "center",
          width: "100%",
          fontSize: "14px",
          lineHeight: "45px",
          fontWeight: "300",
          padding: "6px 25px",
          [theme.breakpoints.down("sm")]: {
            // padding: "6px 18px",
          },
          "&:hover": {
            background:
              "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
          },
          "&.active": {
            background:
              "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
            color: "#fff",
          },
        },
      },
    },
  },
  mobToggle: {
    marginRight: "8px",
    display: "none",
    "& img": {
      width: "20px",
      transform: "rotate(180deg)",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  mobSidebar: {
    background: "#F7616A",
    width: "280px",
    height: "100vh",
    position: "fixed",
    left: "-310px",
    transform: "translateX(-310px)",
    top: "50px",
    zIndex: "1",
    paddingTop: "10px",
    display: "none",
    transition: "all 400ms  ease-in-out",
    "& a": {
      display: "block",
      padding: "15px 20px",
      textDecoration: "none",
      color: "#fff",
      borderBottom: "1px solid #7b0899",
      cursor: "pointer",
      fontSize: "14px",

      "&:hover": {
        color: "#f578c6",
      },
    },
    "&.active": {
      left: "0",
      transform: "translateX(0px)",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  sidebarClose: {
    cursor: "pointer",
    position: "absolute",
    right: "-29px",
    top: "0",
    background: "rgb(247 247 248 / 30%)",
    padding: "8px",
    display: "flex",
    borderRadius: "100%",

    "& img": {
      width: "14px",
    },
  },
  btnPrimaryLang: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      fontSize: "12px",
    },
  },

  // balanceBtnWrap: {
  //   "& button": {
  //     fontSize: "18px",
  //   },
  // },

  accountBtn: {
    // fontSize: "18px",
    "& .MuiButton-startIcon": {
      "& svg": {
        width: "34px",
        height: "34px",
        display: "flex",
        background: "#8492B7",
        alignItems: "center",
        borderRadius: "50%",
        justifyContent: "center",
        padding: "5px",
        [theme.breakpoints.down("sm")]: {
          width: "25px",
          height: "25px",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
  },
  afterLogin: {
    alignItems: "center",
    gap: "5px",
    "& button": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        padding: "6px",
      },
      "& span": {
        [theme.breakpoints.down("sm")]: {
          marginRight: "5px",
        },
      },
      // "&:hover": {
      //   backgroundColor: "inherit",
      //   color: "#25262B",
      // },
      "&:nth-Child(2)": {
        paddingRight: "0",
        textTransform: "none",
      },
    },
  },

  mybetAccordian: {
    margin: "0px !important",
    minHeight: "inherit !important",
    background: "#0D2867",
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
  },

  accordianDetail: {
    padding: "0",
    background: "#0D2867",
    paddingLeft: "10px",
    paddingRight: "10px",
    "& p": {
      fontSize: "14px",
    },
  },
  accordianWrapper: {
    border: "0",
    boxShadow: "inherit",
    width: "100%",
    minHeight: "57px",
  },
}));
