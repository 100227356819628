import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class FavouriteService {
  static async addToFavourite(payload) {
    !payload.search && delete payload.search;
    try {
      const { data } = await axiosClient.post(
        "/betting/addToFavorites",
        payload
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async removeFromFavourite(payload) {
    try {
      const { data } = await axiosClient.post(
        "/betting/removeFavoriteMatches",
        payload
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getFavoriteMatches(payload) {
    try {
      const { data } = await axiosClient.get("/betting/getFavoriteMatches", {
        params: payload,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
