import React from "react";

import { Grid, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useStyle from "../../Sports/sportbook.styles";
import { getName, sportsDefault } from "../../Utils/common";

function Sports({
  handleTabClick,
  providerSportId,
  sportName,
  selectedTab,
  currentTab,
  icon,
}) {
  const { language } = useSelector((state) => state.sportbook.language);
  const classes = useStyle();
  let selectedSportimg = icon ? icon : sportsDefault["default"];
  return (
    <Tooltip title={getName(sportName, language)} placement="top">
      <Grid className={classes.sportContainer}>
        <Grid
          className={`${classes.sportsIconCard} ${
            selectedTab === currentTab ? "clicked" : ""
          }`}
          onClick={() =>
            handleTabClick({
              providerSportId,
              sportName,
              currentTab,
              selectedSportimg,
            })
          }
        >
          {" "}
          <img src={icon || sportsDefault["default"]} alt="Sports Icon" />
        </Grid>
        <Typography variant="h6">{getName(sportName, language)}</Typography>
      </Grid>
    </Tooltip>
  );
}

export default React.memo(Sports);
