import { getErrorObj } from "../../utils/helper";
import axiosClient from "../axios";

export default class paymentService {
  static async getCurrencies(payload) {
    try {
      const { data } = await axiosClient.get("payment/currencies", payload);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async depositRequest(payload) {
    try {
      const { data } = await axiosClient.post("/payment/createOrder", payload);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async withdrawRequest(payload) {
    try {
      const { data } = await axiosClient.post(
        "/payment/withdrawalRequest",
        payload
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getConvertedBalance(params) {
    try {
      const { data } = await axiosClient.get("/payment/getConvertedBalance", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getGenerateAddress(params) {
    try {
      const { data } = await axiosClient.get("/payment/generateAddress", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async chargeMoneyCreate(params) {
    try {
      const { data } = await axiosClient.get("/payment/chargeMoneyCreate", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async validateAddress(params) {
    try {
      const { data } = await axiosClient.get("/payment/validateAddress", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
  static async validatePromoCode(promoCode, bonusKind) {
    try {
      const { data } = await axiosClient.post("/user/validatePromoCode", {
        promoCode,
        bonusKind,
      });
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data; // Return the error data from the server
      }
      throw error;
    }
  }
}
