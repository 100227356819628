import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  iframeCirculerLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100%",
    minHeight: "40vh",
  },
  extraMarketWrap: {
    width: "100%",
  },
  btnPrimary: {
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "5px",
    textTransform: "capitalize",
    padding: "6px 10px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "20px",
    },
  },
  BackBtnWrap: {
    marginBottom: "10px",
  },
  liveMatchBanner: {
    background: "#F2F4FF",
    borderRadius: "18px",
    marginBottom: "10px",
    minHeight: "212px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  liveMatchRightSection: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
    "& img": {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        borderRadius: "10px",
      },
    },
  },
  liveMatchLefttSection: {
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },

  sbWidget: {
    position: "fixed",
    top: "170px",
    right: "20px",
    width: "30%",
    zIndex: "1",
  },

  liveMatchName: {
    marginBottom: "20px",
    textAlign: "center",
    color: "#25262B",
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& img": {
        width: "15px",
        marginRight: "5px",
      },
      "& span": {
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#25262B",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
  matchParticipantsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  participantsLeft: {
    textAlign: "right",
    width: "100%",
    "& .MuiTypography-h3": {
      fontWeight: "600",
      color: "#f1616b",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    "& .MuiTypography-body1": {
      color: "#25262B",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  participantsRight: {
    textAlign: "left",
    width: "100%",
    "& .MuiTypography-h3": {
      fontWeight: "600",
      color: "#f1616b",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    "& .MuiTypography-body1": {
      color: "#000",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  liveMatchScoreboard: {
    whiteSpace: "nowrap",
    padding: "0 10px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    "& span": {
      fontSize: "12px",
      "& img": {
        marginRight: "5px",
      },
    },
  },
  teamScoreboard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },

  teamScoreCard: {
    color: "#25262B",
    borderRadius: "9px",
    background: "#F2F4FF",
    padding: "5px 10px",
    border: "1px solid #0D2867",
    margin: "0 10px",
  },
  liveMatchTabsWrap: {
    margin: "20px 0",
    "& ul": {
      display: "flex",
      alignItems: "center",
      padding: "0",
      "& li": {
        background: "#8492B7",
        borderRadius: "30px",
        padding: "8px 30px",
        color: "#25262B",
        cursor: "pointer",
        marginRight: "10px",
        fontSize: "12px",
        "&:hover": {
          background: "#8EC759",
          color: "#fff",
        },
        "& .active": {
          background: "#8EC759",
          color: "#fff",
        },
      },
      [theme.breakpoints.down("md")]: {
        overflowX: "auto",
        whiteSpace: "nowrap",
      },
    },
  },

  liveMatchOdds: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#fff",
    border: "1px solid #BCC3DB",
    borderRadius: "9px",
    padding: "8px 10px",
    fontSize: "12px",
    fontWeight: "600",
    "& span": {
      color: "#25262B",
      fontWeight: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    "& .MuiTypography-h6": {
      color: "#25262B",
      fontSize: "14px",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6, & .MuiGrid-root": {
        color: "#fff",
      },
      "& .MuiSvgIcon-fontSizeMedium": {
        color: "#fff",
      },
    },
  },
  liveMatchAccordion: {
    background: "transparent",
    color: "#25262B",
    boxShadow: "none",
    paddingBottom: "10px",
    position: "relative",
    cursor: "pointer",
    borderBottom: "1px solid #F7616A",
    borderRadius: "0 !important",
    "& .MuiAccordionDetails-root": {
      padding: "0 10px",
    },
    "& .active": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6": {
        color: "#fff",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#F7616A",
    },
    "& .MuiAccordionSummary-root": {
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },
  liveMatchOddsSection: {
    padding: "10px 0 20px",
  },

  noMarket: {
    textAlign: "center",
    width: "100%",
    display: "inline-block",
  },
  lockIcon: {
    color: "gray",
    fontSize: "18px",
    cursor: "not-allowed",
  },

  liveMatchOddsProp: {
    borderRight: "1px solid #F7616A",
    marginRight: "-1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    padding: "5px",
    flexFlow: "column",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    "& span": {
      color: "#fff",
    },
    "& .MuiTypography-h6": {
      fontSize: "12px",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6": {
        color: "#fff",
      },
    },
    "&.active": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6": {
        color: "#fff",
      },
    },
  },

  liveMatchOddsPropNA: {
    borderRight: "1px solid #F7616A",
    marginRight: "-1px",
    minHeight: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    padding: "5px",
    flexFlow: "column",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    "& span": {
      color: "#fff",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6": {
        color: "#fff",
      },
    },
  },
  liveMatchOddsPropNAFeature: {
    borderRight: "1px solid #F7616A",
    marginRight: "-1px",
    minHeight: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    padding: "5px",
    flexFlow: "column",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    "& span": {
      color: "#fff",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      "& span, & h6": {
        color: "#fff",
      },
    },
  },
}));
