import React, { useEffect, useRef } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useStyles from "./CasinoFavorites.styles";
import CasinoLoader from "../../container/Casino/CasinoLoader";
import Game from "../../container/Casino/Game";
import { formatCategoryName } from "../../utils/helper";
import { getGamesStart } from "../../redux-saga/redux/casino/lobby/casinoGames";
import { viewAllGamesLimit } from "../../utils/constants";
import { getFavoritesStart } from "../../redux-saga/redux/casino/lobby/favorites";

const CasinoFavorites = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { provider, category, name } = params;
  const offset = useRef(1);

  let games = useSelector((state) => state.casinoGames.games);
  let loading = useSelector((state) => state.casinoGames.loading);
  let gamesCount = useSelector((state) => state.casinoGames.gamesCount);
  let moreGamesLoading = useSelector(
    (state) => state.casinoGames.moreGamesLoading
  );

  let favData = useSelector((state) => state.favorites);
  let fetchNewFavourites = "";
  games = favData?.favorites;
  loading = favData?.loading;
  gamesCount = favData?.favoritesCount;
  moreGamesLoading = favData?.loadMoreFavorites;
  fetchNewFavourites = favData?.fetchNewFavourites;

  const initialLoad = useRef(0);

  useEffect(() => {
    resetFilter();
  }, [isLoggedIn]);
  useEffect(() => {
    dispatch(
      getFavoritesStart({
        limit: 18,
        isMobile,
        page: 1,
        viewAll: true,
        isfav: true,
      })
    );

    initialLoad.current = 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, provider, category, name]);

  useEffect(() => {
    if (fetchNewFavourites) {
      offset.current = 1;
      dispatch(
        getFavoritesStart({
          limit: 18,
          isMobile,
          page: 1,
          viewAll: true,
          isfav: true,
        })
      );
    }
  }, [fetchNewFavourites]);

  const handleMoreGames = () => {
    offset.current += 1;

    dispatch(
      getFavoritesStart({
        limit: 18,
        isMobile,
        page: offset.current,
        viewAll: true,
        loadMoreFavorites: true,
      })
    );
  };

  const resetFilter = () => {
    offset.current = 1;
    dispatch(
      getGamesStart({
        limit: viewAllGamesLimit,
        providerId: provider !== "false" ? provider : null,
        subCategoryId: category !== "false" ? category : null,
        pageNumber: offset.current,
        isMobile,
        isHomePage: false,
      })
    );
  };
  return (
    <Grid className={classes.contentWrap}>
      <Grid container className={classes.gamesWrapper}>
        {loading ? (
          <Typography className={classes.circulerLoader}>
            <CasinoLoader />
          </Typography>
        ) : games?.length > 0 ? (
          games.map((game, index) => {
            return (
              <Game
                key={`${game.casinoGameId}${index}`}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                gameId={game.masterCasinoGameId}
                filterName={formatCategoryName(name)}
                filterBy={provider !== "false" ? "Provider" : "Category"}
                isViewAll={true}
                isFavorite={game.isFavorite}
                providerName={game?.MasterCasinoProvider?.name}
              />
            );
          })
        ) : (
          <h1 className={classes.noGamesMsg}>{t("COMMON.NO_GAMES_FOUND")}</h1>
        )}
      </Grid>
      <Grid textAlign="center">
        {!moreGamesLoading && !loading && gamesCount > games?.length && (
          <Button
            onClick={() => handleMoreGames()}
            className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
          >
            {t("COMMON.LOAD_MORE_GAME")}
          </Button>
        )}
        {moreGamesLoading && (
          <Typography>
            <CasinoLoader className={classes.moreGamesLoader} />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CasinoFavorites;
