import React, { useEffect, useRef } from "react";

import {
  fallbackAwayTeamImg,
  fallbackHomeTeamImg,
  teamImgUrl,
} from "../../Utils/common";

function TeamImg({ teamId, type, width, height, isLive }) {
  const imgRef = useRef();
  useEffect(() => {
    if (teamId) {
      const imageElement = document.getElementById(teamId);
      imageElement.addEventListener("load", function () {
        if (
          (imageElement.naturalHeight <= 2 || imageElement.clientHeight <= 2) &&
          !isLive
        ) {
          imageElement.src =
            type === "home" ? fallbackHomeTeamImg : fallbackAwayTeamImg;
        }
      });
    }
  }, []);
  if (!teamId)
    return (
      <div
        style={{ width: "30px", height: "30px", paddingBottom: "38px" }}
      ></div>
    );
  if (isLive) {
    return (
      <img
        src={type === "home" ? fallbackHomeTeamImg : fallbackAwayTeamImg}
        id={teamId}
        alt="Team Img"
        style={{ width: width || "1.2rem", height: height || "1rem" }}
      />
    );
  }
  return (
    <img
      src={`${teamImgUrl}/${teamId}.png`}
      id={teamId}
      alt="Team Img"
      style={{ width: width || "1.2rem", height: height || "1rem" }}
      onError={() => {
        imgRef.current.src =
          type === "home" ? fallbackHomeTeamImg : fallbackAwayTeamImg;
      }}
      ref={imgRef}
    />
  );
}

export default TeamImg;
