import React, { useEffect } from "react";

import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useStyles from "./Sidebar.styles";
import sidebarItems from "./Sidebar.list.json";
import { logoutStart } from "../../redux-saga/redux/auth/authentication";
import { setActive } from "../../redux-saga/redux/settings";
import {
  getUserProfileDetailsStart,
  resetMyprofileImage,
} from "../../redux-saga/redux/profile/myProfile";
import session from "../../utils/session";

const Sidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, metamaskLoggedIn, isLoggedIn } = useSelector(
    (state) => state.authentication
  );
  const { profileImage } = useSelector((state) => state.myProfile);
  const { active } = useSelector((state) => state.settings);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutStart());
  };
  useEffect(() => {
    return () => {
      dispatch(resetMyprofileImage());
    };
  }, [dispatch]);
  useEffect(() => {
    if (isLoggedIn) dispatch(getUserProfileDetailsStart());
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && !session.getSessionCred) {
      navigate("/lobby");
    }
  }, [isLoggedIn, navigate]);
  return (
    <div className={classes.accountWrap}>
      <div className={classes.sidebarWrap}>
        <Grid className={classes.sidebarUserIcon}>
          <img src={profileImage || "/images/user_icon.svg"} alt="User Icon" />
        </Grid>
        <Grid className={classes.sidebarUserName}>
          {/* <Typography>Johnviliam@gmail.com (id64718733)</Typography> */}
        </Grid>
        {sidebarItems.map((item) => {
          if (metamaskLoggedIn && item.to === "/user/resetPassword") {
            return null;
          }
          return (
            <div key={item.content}>
              <Grid className={classes.sidebarListing}>
                {!item?.isMyBet && (
                  <Link
                    to={item.to}
                    onClick={() => dispatch(setActive(item.to))}
                    className={`${active == item.to && "active"}`}
                  >
                    <span>
                      <img src={item.src} alt={item.alt} />
                    </span>
                    {t(item.content)}
                  </Link>
                )}
                {item?.isMyBet && (
                  <Accordion className={classes.accordianWrapper}>
                    {/* <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.mybetAccordian}
                    >
                      <Grid display={"flex"} gap={"20px"}>
                        <img src={item.src} alt={item.alt} />
                        <Typography>{t(item.content)}</Typography>
                      </Grid>
                    </AccordionSummary> */}
                    <AccordionDetails className={classes.accordianDetail}>
                      {/* <Link
                        to={item.to["casino"]}
                        onClick={() => {
                          setActive(item);
                        }}
                        className={`${active == item && "active"}`}
                      >
                        <span>
                          <img src={item.src} alt={item.alt} />
                        </span>
                        {item.contentData["casino"]}
                      </Link> */}
                      {/* 
                      <Link
                        to={item.to["sportbook"]}
                        onClick={() => {
                          dispatch(setActive(item.to["sportbook"]));
                        }}
                        className={`${
                          !active == item.to["sportbook"] && "active"
                        }`}
                      >
                        <span>
                          <img src={item.src} alt={item.alt} />
                        </span>
                        {item.contentData["sportbook"]}
                      </Link> */}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            </div>
          );
        })}
        <Grid className={classes.sidebarListing}>
          <Link onClick={handleLogout} to="#">
            <span>
              <img src="/images/logout.svg" alt="Logout Icon" />
            </span>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              t("HEADER.LOG_OUT")
            )}
          </Link>
        </Grid>
      </div>
      <div className={classes.accountRightWrap}>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
