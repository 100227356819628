import dayjs from "dayjs";

import axiosClient from "../axios";
import { getErrorObj } from "../../utils/helper";

export default class transactionsService {
  static async getSportbookTransactions({
    userId,
    transactionType = "",
    fromDate,
    endDate,
    ...remain
  }) {
    try {
      let currentDate = new Date(endDate);
      let nextDate = new Date(currentDate);
      const { data } = await axiosClient.get("/betting/getTransactions", {
        params: {
          userId: "1",
          transactionType: transactionType,
          startDate: fromDate
            ? dayjs(new Date(fromDate)).format("YYYY-MM-DD")
            : dayjs(new Date()).format("YYYY-MM-DD"),
          endDate: endDate
            ? dayjs(new Date(nextDate)).format("YYYY-MM-DD")
            : dayjs(new Date().setDate(new Date().getDate() + 1)).format(
                "YYYY-MM-DD"
              ),
          ...remain,
        },
      });
      return data;
    } catch (error) {
      if (error.data) {
        return getErrorObj(error);
      }
    }
  }

  static async getTransactions(params) {
    try {
      const { data } = await axiosClient.get("/user/transactions", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async getAllBets(params) {
    try {
      const { data } = await axiosClient.get("/casino/getAllBets", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
