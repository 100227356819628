import { toast } from "react-hot-toast";

// common constants for only sportbook
export const sportBookPaths = [
  "/lobby",
  "/results",
  "/my-bets",
  "/favorites",
  "/more-market",
  "/sportbookLobby",
];
export const casinoPaths = ["/casino"];
export const blogPaths = ["/blog"];

export const DashboardPreLiveProviderMarketIDs = [
  //1x2
  "1",
  //double_chance
  "10",
  // winner
  "186",
  //Total or over and under
  "18",
];
export const DashboardLiveProviderMarketIDs = [
  //1x2
  "2",
  //double_chance
  "8",
  // winner
  "7",
  //Total or over and under
  "5",
];

export const quickBetOptions = [10.0, 50.0, 100.0, 500.0, 1000.0, 2000.0];
export const betStatus = {
  all: "all",
  in_game: "in_game",
  won: "won",
  lost: "lost",
  cashout: "cashout",
};

export const sportsDefault = {
  default: "/images/sport-book/default-sports.svg",
};
export const countriesDefaultImages = "/images/sport-book/defaultcountry.svg";

export const fallbackHomeTeamImg = "/images/sport-book/homeTeam.svg";
export const fallbackAwayTeamImg = "/images/sport-book/awayTeam.svg";
export const teamImgUrl = "https://img.sportradar.com/ls/crest/medium";
//BELOW MARKET CONSTANTS FOR "EN" LANGLUAGE AND PRELIVE MATCH

export const PRELIVE_MATCH = {
  ODDS_TYPE_1X2: {
    1: "1",
    x: "x",
    2: "2",
  },
  MARKET_1X2: "1x2",
  MARKET_DOUBLE_CHANCE: "Double chance",
  ODDS_TYPE: {
    1: "{$competitor1}",
    x: "draw",
    2: "{$competitor2}",
  },
};

//BELOW MARKET CONSTANTS FOR "EN" LANGLUAGE AND LIVE MATCH
export const LIVE_MATCH = {
  ODDS_TYPE_1X2: {
    1: "1",
    x: "x",
    2: "2",
  },
  MARKET_1X2: "3way",
  MARKET_DOUBLE_CHANCE: "Double chance (1X - 12 - X2)",
  ODDS_TYPE: {
    1: "1",
    x: "x",
    2: "2",
  },
};
export const BET_SETTLEMENT_STATUS = {
  WON: 1,
  LOST: 0,
  CANCEL: 2,
  REFUND: 3,
};

export const allcountryObj = {
  sportCountryId: "all",
  providerCountryId: "all",
  countryId: "all",
  country: {
    countryName: [
      {
        name: "All",
        language: "en",
      },
    ],
    icon: null,
  },
};

// common functions for only sportbook
export function replaceCompetitor(str) {
  return str
    .replaceAll("{$competitor1}", " 1 ")
    .replaceAll("{$competitor2}", " 2 ")
    .replaceAll("&amp;", "")
    .replaceAll("draw", "X");
}
export function getName(obj, language) {
  if (!obj) return "";

  let filteredobj =
    obj && obj?.length && obj?.filter((item) => item.language === language);
  if (!filteredobj || !filteredobj?.length) {
    filteredobj = obj && obj?.filter((item) => item.language === "BET");
  }
  if (!filteredobj || !filteredobj?.length) {
    filteredobj =
      obj && obj?.length && obj?.filter((item) => item.language === "en");
  }
  return filteredobj[0]?.name;
}
export function getTeamId(teams, extarctTeamType) {
  return teams.filter((team) => team.type === extarctTeamType)[0]?.teamId;
}

export function getProviderTeamId(teams, extarctTeamType) {
  return teams.filter((team) => team.type === extarctTeamType)[0]?.team
    ?.providerTeamId;
}

export function getTeamName(teams, extarctTeamType, language) {
  return (
    getName(
      teams.filter((team) => team.type === extarctTeamType)[0]?.team?.teamName,
      language
    ) ||
    getName(
      teams.filter((team) => team.type === extarctTeamType)[0]?.team?.teamName,
      "BET"
    ) ||
    getName(
      teams.filter((team) => team.type === extarctTeamType)[0]?.team?.teamName,
      "en"
    )
  );
}

export const containSettledMarket = (match) => {
  if (!match && !match?.matchMarket && !match?.matchMarket?.length) return [];
  return match?.matchMarket?.filter((market) => market?.isSettled);
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const oddTypeFormatter = (outcome) => {
  if (!outcome || !outcome.type) return "";

  let outcomeType = "";
  let formattedOutComeType = outcome && replaceCompetitor(outcome?.type);
  formattedOutComeType = formattedOutComeType.replace(
    /\b([ou])\b/g,
    (match, char) => {
      if (char === "o") {
        return "over";
      } else if (char === "u") {
        return "under";
      }
    }
  );
  let matchFromOutcomeType =
    outcome && formattedOutComeType?.match(/\{([^}]+)\}/);
  // regex capture text enclosed within curly braces {}
  if (
    outcome &&
    outcome?.specialBetValue &&
    matchFromOutcomeType &&
    matchFromOutcomeType?.length > 1
  ) {
    outcomeType = formattedOutComeType?.replace(
      `${matchFromOutcomeType[0]}`,
      `(${outcome?.specialBetValue})`
    );
  } else {
    if (outcome?.specialBetValue && formattedOutComeType) {
      outcomeType = `${formattedOutComeType} (${outcome?.specialBetValue})`;
    } else outcomeType = formattedOutComeType;
  }

  return outcomeType.replace("((", "(").replace("))", ")");
};

export const getUpdatedData = (previousMatchmarket, newMatchMarket) => {
  if (!newMatchMarket || !newMatchMarket?.length) return previousMatchmarket;

  if (!previousMatchmarket || !previousMatchmarket?.length)
    return newMatchMarket;
  let tempPreviousMatchmarket = [];
  if (previousMatchmarket && previousMatchmarket?.length) {
    tempPreviousMatchmarket = previousMatchmarket.map((obj) => {
      return { ...obj };
    });

    for (let i = 0; i < newMatchMarket?.length; i++) {
      let findMarketIndex = previousMatchmarket.findIndex(
        ({ matchMarketId }) => newMatchMarket[i]?.matchMarketId == matchMarketId
      );

      if (findMarketIndex !== -1) {
        tempPreviousMatchmarket[findMarketIndex] = newMatchMarket[i];
      } else {
        tempPreviousMatchmarket.push(newMatchMarket[i]);
      }
    }
    return tempPreviousMatchmarket;
  }
};

export const copyTextToCursor = (textRef) => {
  const element = textRef.current;
  const text = element.innerText;
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success("Text copied to clipboard");
    })
    .catch((error) => {
      console.error("Failed to copy text to clipboard:", error);
    });
};

export const oddsFormats = ["Decimal", "American", "Fractional"];

export function oddsConverter(value, oddType) {
  if (!value || value === "-") return "-";
  if (oddType === oddsFormats[0]) return parseFloat(value).toFixed(2);
  if (oddType === oddsFormats[2])
    return `${parseFloat(value - 1).toFixed(2)}/1`;

  let val = value.toString();
  let dotIndex = val.indexOf(".");
  let roundedValue;
  if (dotIndex >= 0) {
    if (dotIndex === val?.length - 2) {
      roundedValue = val + "0";
    } else {
      roundedValue = val.slice(0, dotIndex + 3);
    }
  } else {
    roundedValue = val + ".00";
  }

  if (oddType === oddsFormats[1]) {
    roundedValue = parseFloat(roundedValue);
    if (roundedValue >= 2) {
      roundedValue = (roundedValue - 1) * 100;
    }
    if (value < 2) {
      roundedValue = -100 / (roundedValue - 1);
    }
    roundedValue = roundedValue.toFixed(0);
  }
  return roundedValue;
}

export function getMarketName(market, language) {
  let marketName = replaceCompetitor(
    getName(market.Market.marketName, language)
  );
  let regex = /\{([^}]+)\}/;
  let match = marketName.match(regex);
  let matchedContent = match?.length > 1 && match[1];

  if (
    market?.outcome?.length &&
    market?.outcome?.every(
      (outcome) =>
        outcome?.specialBetValue &&
        market?.outcome[0]?.specialBetValue === outcome?.specialBetValue
    ) &&
    matchedContent
  ) {
    marketName = marketName.replace(
      `{${matchedContent}}`,
      `(${market?.outcome[0]?.specialBetValue})`
    );
  } else if (
    matchedContent &&
    market.outcome.every(
      ({ variant }) => variant && market?.outcome[0]?.variant === variant
    )
  ) {
    let matchedTexts = [...marketName.matchAll(/\{([^}]+)\}/g)];
    // matchedTexts is 2d array
    let variant = market?.outcome[0]?.variant;
    matchedTexts.forEach((matchedText) => {
      let matchStr = matchedText?.length > 1 && matchedText[1];

      if (matchStr) {
        const find = `${matchStr?.replace(/[^a-zA-Z0-9]/g, "")}=`;

        const matchedContentIndexInVariant = variant.search(find);

        if (matchedContentIndexInVariant !== -1 && find?.length) {
          const matchStrValueInVariant = variant
            ?.slice(matchedContentIndexInVariant + find?.length)
            ?.match(/^[+-]?\d+(?::\d+)*/);

          if (matchStrValueInVariant?.length)
            marketName = marketName.replace(
              `{${matchStr}}`,
              `${matchStrValueInVariant?.[0]}`
            );
        }
      }
    });
  } else {
    if (matchedContent) {
      marketName = marketName.replace(`{${matchedContent}}`, "");
    }
  }

  return marketName;
}

export function getTimeWithStatus(time, status) {
  if (!status) return time;
  if (status.match(/paused|pause/g)) {
    return status.replaceAll(/paused|pause/g, "HT");
  } else if (status.match(/ended/g)) {
    return status;
  } else {
    return `${time}'`;
  }
}

export const decodeSocketData = async (value) => {
  try {
    // Dynamically import the json-url module
    const { default: jsonUrl } = await import("json-url");
    const codec = jsonUrl("lzma"); // Initialize codec with lzma
    const decodeVal = await codec.decompress(value);
    return decodeVal;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export function getErrorObj(error) {
  if (error?.data?.status) return { error: error?.data?.status };
  if (
    error &&
    error?.data &&
    error?.data?.errors &&
    error?.data?.errors?.length &&
    error?.data?.errors?.[0]?.description
  ) {
    return { error: error?.data?.errors?.[0]?.description };
  }
  return { error: "" };
}
