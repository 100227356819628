import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);
  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setTimeout(() => {
      setOnline(true);
    }, 5000);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  return (
    <>
      {isOnline && props.children}
      <Grid
        display={isOnline ? "none" : "flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src={"images/nointernet.png"}
          alt="nointernet"
          width={"50%"}
          height={"50%"}
        />
      </Grid>
    </>
  );
};

export default NoInternetConnection;
