import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class BetslipService {
  static async betSlip(payload) {
    try {
      const { data } = await axiosClient.post("/betting/place", payload);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async betLimit(params) {
    try {
      const { data } = await axiosClient.get("betting/getBetSetting", {
        params,
      });
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
