// import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

const HideAppSidebar = ({ children }) => {
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  return splitPath[1] === "user" ? null : children;
};

export default HideAppSidebar;
