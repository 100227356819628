import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  autocompletepopup: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  accountRight: {
    height: "100%",
  },
  myprofileTop: {
    background: "#0d0d0d",
    borderRadius: "10px",
    padding: "20px 0px",
  },
  myprofileBalance: {
    display: "flex",
    padding: "0px 40px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
      justifyContent: "center",
    },
  },
  myprofileBalanceLeft: {
    "& .MuiTypography-body1": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1",
      marginBottom: "10px",
      "& span": {
        marginRight: "10px",
      },
    },
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "30px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  balanceBtnWrap: {
    marginTop: "10px",
    "& button": {
      height: "40px",
      padding: "6px 40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& span": {
        marginRight: "15px",
        display: "flex",
      },
    },
  },
  myprofileBalanceRight: {
    textAlign: "center",
  },
  referFriend: {
    padding: "20px 40px",
    marginTop: "20px",
    textAlign: "center",
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    "& .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
    "& .MuiInputBase-root ": {
      background: "#292929",
      borderRadius: "10px",
    },
  },
  referFriendInput: {
    position: "relative",
    "& img": {
      position: "absolute",
      right: "30px",
      top: "30px",
      cursor: "pointer",
    },
  },
  myprofileForm: {
    maxWidth: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down(1090)]: {
      width: "100%",
    },
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
    "& .MuiGrid-container": {
      margin: "0 auto",
      maxWidth: "540px",
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiAutocomplete-root": {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: 0,
        backgroundColor: "#FFFFFF",
      },
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
          color: "#000",
          "& .MuiSvgIcon-root": { color: "#000" },
        },
      },
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "13px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
    "&.MuiButtonBase-root.MuiIconButton-root": {
      background: "black",
    },
  },
  myprofileBottom: {
    background: "#0D2867",
    borderRadius: "10px",
    // marginTop: "10px",
    padding: "30px 55px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "#000",
      height: "40px",
      padding: "5px 10px",
      fontSize: "14px",
      backgroundColor: "#fff",
      color: "#25262B",
      borderRadius: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F7616A",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiTypography-h4": {
      fontWeight: "600",
      textTransform: "capitalize",
      marginBottom: "50px",
      fontSize: "35px",
      color: "#F7616A",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },
    },
  },
  authBtnWrap: {
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    padding: "40px 0 20px 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
    "& button": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  customDepositWrap: {
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",

    padding: "40px 0 0 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
  },
  amountInfo: {
    color: "rgba(255, 255, 255, 0.3)",
    marginTop: "-16px",
    marginBottom: "20px",
  },
  selectBtnBox: {
    "& button": {
      background: "transparent",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      color: "rgba(255, 255, 255, 0.3)",
      "&:hover, &:focus, &:active, &.active": {
        background: "#BCC3DB",
        color: "#25262B",
      },
    },
  },
}));
