import { getErrorObj } from "../../utils/helper";
import axiosClient from "../axios";

export default class cmsService {
  static async getCmsData() {
    try {
      const { data } = await axiosClient.get("/user/getCms");
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
