import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import { showErrorMessage } from "../../../redux-saga/redux/toast";
import {
  betLimitFailure,
  betLimitStart,
  betLimitSuccess,
  placeBetComplete,
  placeBetFailure,
  placeBetStart,
  resetBet,
} from "../../redux/betslip";
import BetslipService from "../../../sport-book-services/betslip";

function* placebetslipWatcher() {
  yield takeEvery([placeBetStart.type, betLimitStart.type], placebetslipWorker);
}

function* placebetslipWorker(action) {
  try {
    switch (action.type) {
      case placeBetStart.type: {
        const { data, error } = yield BetslipService.betSlip(action.payload);
        if (data) {
          toast.success("Bets Placed Successfully");

          yield put(placeBetComplete());
          yield put(resetBet());
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(placeBetFailure());
        }
        break;
      }

      case betLimitStart.type: {
        const { data, error } = yield BetslipService.betLimit(action.payload);
        if (data) {
          yield put(betLimitSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(betLimitFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case placeBetStart.type: {
        yield put(placeBetFailure());
        break;
      }
      case betLimitStart.type: {
        yield put(betLimitFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default placebetslipWatcher;
