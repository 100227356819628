import React, { useState } from "react";

import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useStyles from "./Login.styles";
import {
  encodePassword,
  validateEmailOrUserName,
  validatePassword,
} from "../../../utils/helper";
import { loginStart } from "../../../redux-saga/redux/auth/authentication";
import ForgotPasswordModal from "./ForgotPasswordModal";

const Login = ({ handleOpenSignUp, handleCloseLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [userNameOrEmail, setUserNameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);

  const { loading } = useSelector((state) => state.authentication);

  const handleGoToSignUp = () => {
    handleCloseLogin();
    handleOpenSignUp();
  };

  const handleLoginAuth = () => {
    const validation = [];
    if (!validateEmailOrUserName(userNameOrEmail)) {
      validation.push(t("HEADER.USERNAME_EMAIL_ERROR_MSG"));
    }
    if (!validatePassword(password)) {
      validation.push(t("HEADER.PASSWORD_VALIDATION_FAILED"));
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message);
      });
      return;
    }

    dispatch(
      loginStart({
        userNameOrEmail,
        // password,
        password: encodePassword(password),
      })
    );
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setUserNameOrEmail(e.target.value);
  };

  const handleLoginOnEnter = (e) => {
    if (e.key === "Enter") {
      handleLoginAuth();
      return;
    }
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow((prevState) => !prevState);
  };

  const handleGoToForgotPassword = () => {
    setForgotPassword((prevState) => !prevState);
  };

  return (
    <div>
      {forgotPassword ? (
        <ForgotPasswordModal handleCloseLogin={handleCloseLogin} />
      ) : (
        <>
          <Grid className={classes.loginContent}>
            <Typography variant="h5">{t("HEADER.LOG_IN")}</Typography>
          </Grid>
          {/* <Grid className={classes.loginLogo}>
            <img src="/images/brand-logo.png" alt="Brand Logo" />
          </Grid> */}
          <Grid className={classes.loginFormWrap}>
            <Grid className={classes.formGroup}>
              <span className={classes.labelTag}>
                {t("COMMON.USERNAME") + "/" + t("COMMON.EMAIL_ADDRESS")}*
              </span>
              <TextField
                type="userNameOrEmail"
                id="userNameOrEmail"
                placeholder={
                  t("COMMON.USERNAME") + "/" + t("COMMON.EMAIL_ADDRESS")
                }
                InputLabelProps={{
                  style: {
                    color: "#8492B7",
                    fontWeight: "400",
                    lineHeight: "8px",
                    fontSize: "16px",
                    overflow: "visible",
                  },
                }}
                // label={t("COMMON.EMAIL_ADDRESS")}
                variant="outlined"
                onChange={handleEmail}
                onKeyDown={handleLoginOnEnter}
              />
            </Grid>
            <Grid className={classes.formGroup}>
              <span className={classes.labelTag}>{t("COMMON.PASSWORD")}*</span>
              <TextField
                type={show ? "text" : "password"}
                id="pwd"
                placeholder={t("COMMON.PASSWORD")}
                InputLabelProps={{
                  style: {
                    color: "#8492B7",
                    fontWeight: "400",
                    lineHeight: "37px",
                    fontSize: "17px",
                  },
                }}
                // label={t("COMMON.PASSWORD")}
                variant="outlined"
                onChange={handlePassword}
                onKeyDown={handleLoginOnEnter}
              />

              <Grid className={classes.showLink}>
                <InputAdornment position="end">
                  <IconButton
                    style={{ marginTop: "15px" }}
                    aria-label="toggle password visibility"
                    onClick={handleShow}
                    onMouseDown={handleShow}
                  >
                    {!show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              </Grid>
            </Grid>
            <Grid className={classes.authBtnWrap}>
              <Button className={classes.btnPrimary} onClick={handleLoginAuth}>
                {loading ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  t("HEADER.LOG_IN")
                )}
              </Button>
            </Grid>
            <Grid className={classes.forgotLink}>
              <Button onClick={handleGoToForgotPassword}>
                {t("HEADER.FORGOT_PASSWORD_MARK")}
              </Button>
            </Grid>

            <Grid className={classes.dividerSpace} />

            <Grid className={classes.signUpLink}>
              {t("HEADER.SIGN_UP_MSG")}
              <Button onClick={handleGoToSignUp}>
                {t("HEADER.SIGN_UP_MARK")}
              </Button>
            </Grid>

            <Grid text={"center"} className={classes.loginBottomText}>
              <p>Having issues logging in? </p>
              <p>
                Contact{" "}
                <a href="mailto:support@betworldsports.io">
                  {" "}
                  support@betworldsports.io{" "}
                </a>{" "}
              </p>
              <p>from your registered email for assistance.</p>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Login;
