import axios from "axios";

import config from "../../config";
import session from "../../utils/session";
import store from "../../redux-saga/store";
import {
  navigateTarget,
  showErrorMessage,
} from "../../redux-saga/redux/toast/index";
import { resetAuthenticationState } from "../../redux-saga/redux/auth/authentication";
import { resetMyProfileState } from "../../redux-saga/redux/profile/myProfile";
import { resetTransactionState } from "../../redux-saga/redux/profile/transactions";

const axiosClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    "Content-Type": "application/json",
    "domain-key": `${window.location.hostname}`,
  },
});
axiosClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (!window.navigator.onLine) {
      throw new Error("internet issue");
    }

    const originalRequest = err.config;

    if (
      err.response.status === 403 &&
      originalRequest.url === "/user/refreshToken"
    ) {
      store.dispatch(showErrorMessage({ tKey: "HEADER.SESSION_TIMEOUT" }));
      session.clearSessionCred();
      store.dispatch(navigateTarget("/"));
      store.dispatch(resetMyProfileState());
      store.dispatch(resetTransactionState());
      store.dispatch(resetAuthenticationState());
      return { data: "" };
    }
    if (err.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosClient.get("/user/refreshToken").then(({ data }) => {
        if (data) {
          const { token, resToken } = data.data;
          const { userId } = session.getSessionCred;
          session.saveSessionCred({
            token,
            resToken,
            userId,
          });
          // Change Authorization header
          axiosClient.defaults.headers.common["access-token"] = token;
          axiosClient.defaults.headers.common["refresh-token"] = resToken;
          // return originalRequest object with Axios.
          return axiosClient(originalRequest);
        }
      });
    }
    if (err?.response) {
      return Promise.reject(err.response);
    }
    return Promise.reject(err);
  }
);

axiosClient.interceptors.request.use((req) => {
  if (!window.navigator.onLine) {
    throw new Error("internet issue");
  }
  const tokens = session.getSessionCred;
  if (tokens) {
    req.headers["access-token"] = tokens.token;
    req.headers["refresh-token"] = tokens.resToken;
  }
  return req;
});

export default axiosClient;
