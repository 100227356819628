import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  signupBg: {
    backgroundImage: "url('/images/signup-banner.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    minHeight: "580px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#151515",
    flexFlow: "column",
    "& .MuiTypography-h5": {
      fontSize: "26px",
      fontWeight: "bold",
      color: "#fff",
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      marginTop: "10px",
      color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  // signupFromWrap: {
  //   padding: "0 0 20px",
  // },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },

  formFname: {
    marginRight: "10px",
  },
  loginContent: {
    textAlign: "center",
    margin: "0",
    // background: "#010101",
    color: "#F7616A",
    padding: "0 10px",
    "& .MuiTypography-h5": {
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: "30px",
      padding: "10px",
    },
  },
  signupLeft: {
    padding: "20px 40px",
    maxWidth: "500px",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-h5": {
      fontSize: "32px",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
      color: "#fff",
      textAlign: "center",
      "& .MuiLink-underlineAlways": {
        color: "#F7616A",
        textDecoration: "none",
        margin: "0 3px",
      },
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      background: "#F2F4FF",
      borderRadius: "7px",
      color: "#25262B",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      "&:focus": {
        "& fieldset": {
          display: "none",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "inherit !important",
          borderRadius: "7px",
          "&:hover": {
            borderColor: "inherit !important",
            borderRadius: "7px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "32px",
        fontSize: "12px",
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px",
      borderColor: "inherit !important",
    },
    // "& .MuiFormLabel-root": {
    //   top: "-5px",
    //   fontSize: "12px",
    //   color: "#fff",
    // },
    "& > .MuiGrid-root": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      "& ::placeholder": {
        fontSize: "10px",
        width: "125px",
        height: "20px",
      },
    },
  },

  formGroup: {
    position: "relative",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#200669",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      background: "#F2F4FF",
      borderRadius: "10px",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "50px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    [theme.breakpoints.down("sm")]: {
      top: "45px",
    },
  },
  signupCheck: {
    "& .MuiTypography-body1": {
      fontSize: "10px",
      textAlign: "left",
    },
  },
  emailSubscription: {
    "& .MuiTypography-body1": {
      fontSize: "10px",
      textAlign: "left",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    textAlign: "center",
    "& button": {
      background: "#F7616A",
      borderRadius: "90px",
      padding: "10px 20px",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "400",
      // height: "60px",
      textTransform: "capitalize",
      width: "100%",
      maxWidth: "440px",
      "&:hover": {
        color: "#fff",
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      },
    },
  },

  authText: {
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
  signupHeader: {
    background: "#010101",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 60px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  dividerSpace: {
    border: "1px solid #F2F4FF",
    marginTop: "15px",
    marginBottom: "15px",
  },

  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
}));
