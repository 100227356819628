import { put, takeEvery } from "redux-saga/effects";

import { showErrorMessage } from "../../../redux-saga/redux/toast";
import FeaturedMatchesService from "../../../sport-book-services/featuredMatches";
import {
  getFeaturedMatchesFailure,
  getFeaturedMatchesStart,
  getFeaturedMatchesSuccess,
} from "../../redux/featuredMatches";

function* featuredMatchesWatcher() {
  yield takeEvery([getFeaturedMatchesStart.type], featuredMatchesWorker);
}

function* featuredMatchesWorker(action) {
  try {
    switch (action.type) {
      case getFeaturedMatchesStart.type: {
        const { data, error } = yield FeaturedMatchesService.getFeaturedMatches(
          action.payload
        );
        if (data) {
          yield put(getFeaturedMatchesSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getFeaturedMatchesFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getFeaturedMatchesStart.type: {
        yield put(getFeaturedMatchesFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default featuredMatchesWatcher;
