import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { toast } from "react-hot-toast";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

import useStyle from "./markets.styles";
import {
  DashboardLiveProviderMarketIDs,
  DashboardPreLiveProviderMarketIDs,
  LIVE_MATCH,
  PRELIVE_MATCH,
  getName,
  oddTypeFormatter,
  replaceCompetitor,
} from "../../Utils/common";
import { addBet, removeBet } from "../../../../sport-book-saga/redux/betslip";
import OddAnimation from "../OddAnimation";

const get1x2OddsAndMatchMarketId = (marketData, betStatus) => {
  let providerMarketId = "";
  if (!marketData || !marketData?.length) return null;
  const dashboardProviderMarketIDs = marketData[0]?.isLive
    ? DashboardLiveProviderMarketIDs
    : DashboardPreLiveProviderMarketIDs;
  const oddsType = marketData[0]?.isLive
    ? LIVE_MATCH.ODDS_TYPE
    : PRELIVE_MATCH.ODDS_TYPE;
  let market1x2Data = marketData.filter((market) => {
    return market?.Market?.providerMarketId === dashboardProviderMarketIDs[0];
  });

  if (!market1x2Data?.length) {
    providerMarketId = dashboardProviderMarketIDs[2];
    market1x2Data = marketData.filter((market) => {
      return market?.Market?.providerMarketId === dashboardProviderMarketIDs[2];
    });
  } else {
    providerMarketId = dashboardProviderMarketIDs[0];
  }
  if (!market1x2Data || !market1x2Data?.length) return null;
  const matchMarketId1x2 = market1x2Data[0]?.matchMarketId;
  const outcomes = market1x2Data[0]?.outcome;
  if (!outcomes && !outcomes?.length) return null;
  let odd_1 = outcomes.filter((outcome) => outcome.type === oddsType["1"]);
  let odd_x = outcomes.filter((outcome) => outcome.type === oddsType["x"]);
  let odd_2 = outcomes.filter((outcome) => outcome.type === oddsType["2"]);
  const market1X2 = [
    odd_1 && odd_1?.length
      ? {
          ...odd_1[0],
          providerMarketId,
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        }
      : {
          providerMarketId,
          odd: "-",
          type: "1",
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        },
    odd_x && odd_x?.length
      ? {
          ...odd_x[0],
          providerMarketId,
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        }
      : {
          providerMarketId,
          odd: "-",
          type: "X",
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        },
    odd_2 && odd_2?.length
      ? {
          ...odd_2[0],
          providerMarketId,
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        }
      : {
          providerMarketId,
          odd: "-",
          type: "2",
          betStatus,
          isDisabled: market1x2Data?.[0]?.isDisabled,
        },
  ];

  return {
    market1X2,
    matchMarketId1x2,
  };
};

const getDoubleChanceOdds = (marketData, betStatus) => {
  if (!marketData && !marketData?.length) return null;
  const dashboardProviderMarketIDs = marketData[0]?.isLive
    ? DashboardLiveProviderMarketIDs
    : DashboardPreLiveProviderMarketIDs;

  const marketDoubleChanceData = marketData.filter((market) => {
    return market?.Market?.providerMarketId === dashboardProviderMarketIDs[1];
  });
  if (!marketDoubleChanceData || !marketDoubleChanceData?.length) return null;
  const outcomes = marketDoubleChanceData[0]?.outcome.map((outcomeData) => {
    return {
      ...outcomeData,
      providerMarketId: dashboardProviderMarketIDs[1],
      betStatus,
      isDisabled: marketDoubleChanceData?.[0]?.isDisabled,
    };
  });
  if (!outcomes && !outcomes?.length) return null;

  return {
    outcomes,
    matchMarketId: marketDoubleChanceData[0]?.matchMarketId,
  };
};

const getOverUnderMarket = (marketData, betStatus) => {
  if (!marketData && !marketData?.length) return null;
  const dashboardProviderMarketIDs = marketData[0]?.isLive
    ? DashboardLiveProviderMarketIDs
    : DashboardPreLiveProviderMarketIDs;

  const marketOverUnderData = marketData.filter((market) => {
    return market?.Market?.providerMarketId == dashboardProviderMarketIDs[3];
  });
  if (!marketOverUnderData || !marketOverUnderData?.length) return null;
  let outcomes = marketOverUnderData[0]?.outcome.map((outcomeData) => {
    return {
      ...outcomeData,
      providerMarketId: dashboardProviderMarketIDs[3],
      betStatus,
      isDisabled: marketOverUnderData?.[0]?.isDisabled,
    };
  });
  if (!outcomes && !outcomes?.length) return null;
  outcomes = outcomes.sort((outcome1, outcome2) => {
    if (outcome1?.specialBetValue > outcome2?.specialBetValue) return 1;
    else if (outcome1?.specialBetValue < outcome2?.specialBetValue) return -1;
    else return 0;
  });
  let outcomesLength = outcomes?.length - 2;
  if (outcomes?.length % 2 !== 0) outcomesLength = outcomes?.length - 1 - 2;
  let filteredOutcomes = [];
  for (let i = 0; i <= outcomesLength; i = i + 2) {
    if (
      outcomes[i]?.type != -1 &&
      outcomes[i]?.active != false &&
      `${outcomes[i]?.betStatus}` == "1" &&
      !outcomes[i]?.isDisabled &&
      outcomes[i + 1]?.type != -1 &&
      outcomes[i + 1]?.active != false &&
      `${outcomes[i + 1]?.betStatus}` == "1" &&
      !outcomes[i + 1]?.isDisabled
    ) {
      if (
        (`${outcomes[i].providerOutcomeId}` === "11" && outcomes[i]?.isLive) ||
        (`${outcomes[i].providerOutcomeId}` === "12" && !outcomes[i]?.isLive)
      ) {
        filteredOutcomes.push(outcomes[i]); //over
        filteredOutcomes.push(outcomes[i + 1]); //under
      } else {
        filteredOutcomes.push(outcomes[i + 1]); //under
        filteredOutcomes.push(outcomes[i]); //over
      }
    }
  }
  return {
    outcomes: filteredOutcomes,
    matchMarketId: marketOverUnderData[0]?.matchMarketId,
    providerMarketId: dashboardProviderMarketIDs[3],
  };
};

function Markets({
  matchMarkets,
  isMobile,
  totalMarket,
  homeTeam,
  awayTeam,
  homeTeamId,
  awayTeamId,
  sportId,
  countryId,
  sportCountryId,
  countryName,
  isSocketUpdated,
}) {
  const { t } = useTranslation();
  const [lineIndex, setLineindex] = useState(0);
  const navigate = useNavigate();
  const isLive = matchMarkets?.isLive;
  const matchMarket = matchMarkets?.matchMarket;
  const classes = useStyle();
  const { language } = useSelector((state) => state.sportbook.language);
  const odds1x2AndMatchMarketId = get1x2OddsAndMatchMarketId(
    matchMarket,
    matchMarkets?.betStatus
  );

  const odds_1x2 = odds1x2AndMatchMarketId?.market1X2;

  const matchMarketId1x2 = odds1x2AndMatchMarketId?.matchMarketId1x2;
  const double_chanceAndMatchMarketId = getDoubleChanceOdds(
    matchMarket,
    matchMarkets?.betStatus
  );
  const double_chance = double_chanceAndMatchMarketId?.outcomes;
  const matchMarketIdDoubleChance =
    double_chanceAndMatchMarketId?.matchMarketId;
  // console.table({ ID: matchMarket[0]?.matchMarketId, ...odds_1x2 });
  const overUnderAndMatchMarketId = getOverUnderMarket(
    matchMarket,
    matchMarkets?.betStatus
  );
  const overUnder = overUnderAndMatchMarketId?.outcomes;
  //console.log("overUnder", overUnder);
  const MatchMarketIdOverUnder = overUnderAndMatchMarketId?.matchMarketId;
  const dispatch = useDispatch();
  const bets = useSelector((state) => state.sportbook.betslip.bets);
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { maxBetCount, maxOddLimit, minOddLimit } = useSelector(
    (state) => state.sportbook.betslip.betlimit
  );
  const oddSelectHandler = (
    providerOutcomeId,
    specialBetValue,
    variant,
    providerMarketId
  ) => {
    if (!isLoggedIn) {
      toast.error(t("SHARED.LOGIN_CONTINUE"));
      return;
    }
    let marketData = matchMarkets?.matchMarket;
    if (!marketData || !marketData?.length) return null;
    const tournament = getName(
      matchMarkets?.tournaments?.tournamentName,
      language
    );

    const market_data = matchMarkets.matchMarket.filter((market) => {
      return market?.Market?.providerMarketId === providerMarketId;
    });
    if (!market_data || !market_data?.length) return null;

    // if it is already present in bets
    const betExist = bets.filter(
      (bet) =>
        bet.matchMarketId == market_data[0].matchMarketId &&
        bet?.providerOutcomeId === providerOutcomeId &&
        bet?.specialBetValue === specialBetValue &&
        bet?.variant === variant
    );
    if (betExist && betExist?.length) {
      dispatch(
        removeBet({
          matchMarketId: betExist[0]?.matchMarketId,
          providerOutcomeId: betExist[0]?.providerOutcomeId,
          isLive: betExist[0]?.isLive,
          specialBetValue: betExist[0]?.specialBetValue,
          variant: betExist[0]?.variant,
          matchId: betExist[0]?.matchId,
        })
      );
    } else {
      if (bets?.length >= parseInt(maxBetCount)) {
        toast.error(
          "More than " + maxBetCount + " bets not allowed in a betslip"
        );
        return;
      }

      let odd = market_data[0]?.outcome.filter(
        (outcome) =>
          outcome.providerOutcomeId === providerOutcomeId &&
          outcome?.specialBetValue === specialBetValue &&
          outcome?.variant === variant
      );
      let oddvalue = odd[0]?.odd;
      if (oddvalue && parseFloat(oddvalue) > parseFloat(maxOddLimit)) {
        toast.error(
          t("EACH_OUT_COME.ODD_VALUE_NOT_TO_BE_GREATER_THAN") + maxOddLimit
        );
        return;
      }
      if (oddvalue && parseFloat(oddvalue) < parseFloat(minOddLimit)) {
        toast.error(
          t("EACH_OUT_COME.ODD_VALUE_NOT_TO_BE_LESSER_THAN") + minOddLimit
        );
        return;
      }
      let betData = {
        selectedSportId: sportId,
        providerTournamentId: matchMarkets?.tournaments?.providerTournamentId,
        providerMatchId: matchMarkets?.providerMatchId,
        outcomeType: oddTypeFormatter(odd[0]),
        tournament,
        tournamentId: matchMarkets?.tournamentId,
        odds: odd[0]?.odd,
        outcomeId: odd[0]?.providerOutcomeId,
        betStatus: matchMarkets?.betStatus,
        marketId: market_data[0]?.Market?.marketId,
        market: replaceCompetitor(
          getName(market_data[0]?.Market?.marketName, language)
        ),
        matchId: matchMarkets?.matchId,
        matchMarketId: market_data[0]?.matchMarketId,
        providerOutcomeId: odd[0]?.providerOutcomeId,
        homeTeam,
        awayTeam,
        homeTeamId,
        awayTeamId,
        isLive,
        countryName: getName(countryName, language),
        specialBetValue: odd[0]?.specialBetValue,
        variant: odd[0]?.variant,
        countryId: matchMarkets?.sportCountryId,
      };
      dispatch(addBet(betData));
    }
  };

  return (
    <React.Fragment>
      {/* <h4 style={{ color: "#000" }}>{matchMarket[0]?.matchMarketId}</h4> */}
      {isMobile && (
        <Grid className={classes.tableMatchMob}>
          {
            //this 1x2 will appear on the mobile screen
          }
          <Grid className={classes.marketNameWrap}>
            <>
              <span className={classes.marketName}>{t("MARKETS.HOME")}</span>
              <span className={classes.marketName}>{t("MARKETS.DRAW")}</span>
              <span className={classes.marketName}>{t("MARKETS.AWAY")}</span>
            </>
          </Grid>

          <Grid className={classes.oddsWrap}>
            <Grid className={classes.matchDetailsCardOdds}>
              <ul className={classes.singleScoreLine}>
                {odds_1x2 && odds_1x2?.length ? (
                  <>
                    {odds_1x2.map((oddType) => {
                      return (
                        <li key={oddType.providerOutcomeId}>
                          <Button
                            onClick={() => {
                              oddSelectHandler(
                                oddType?.providerOutcomeId,
                                oddType?.specialBetValue,
                                oddType?.variant,
                                oddType?.providerMarketId
                              );
                            }}
                            className={`${
                              bets &&
                              bets?.length &&
                              bets.filter(
                                (bet) =>
                                  bet?.providerOutcomeId ===
                                    oddType?.providerOutcomeId &&
                                  bet.matchMarketId == matchMarketId1x2 &&
                                  bet?.specialBetValue ===
                                    oddType?.specialBetValue &&
                                  bet?.variant === oddType?.variant
                              )?.length
                                ? "active"
                                : ""
                            }`}
                            disabled={
                              oddType?.isDisabled ||
                              oddType?.active == false ||
                              oddType?.type == -1 ||
                              oddType?.odd === "-" ||
                              `${oddType?.betStatus}` !== "1"
                            }
                          >
                            {oddType?.active == false ||
                            oddType?.type == -1 ||
                            `${oddType?.betStatus}` !== "1" ||
                            oddType?.isDisabled ? (
                              <Grid>
                                <LockIcon className={classes.lockIcon} />
                              </Grid>
                            ) : (
                              <>
                                <span>
                                  {oddType.type &&
                                    replaceCompetitor(oddType.type)}
                                </span>
                                <h5>
                                  {odds_1x2 && oddType && (
                                    <OddAnimation
                                      odd={oddType?.odd}
                                      isSocketUpdated={isSocketUpdated}
                                    />
                                  )}
                                </h5>
                              </>
                            )}
                          </Button>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </Grid>
          </Grid>
          <Grid className={classes.marketBtnWrap}>
            <Button
              disabled={!parseInt(totalMarket)}
              className={classes.marketBtn}
              onClick={() => {
                navigate("/more-market", {
                  state: {
                    sportCountryId,
                    isLive: isLive,
                    sportId,
                    providerMatchId: matchMarkets?.providerMatchId,
                    countryId,
                  },
                });
              }}
            >
              <span> +{totalMarket ? totalMarket : 0} </span>
              <span className={classes.moremarket}>{t("MARKETS.MARKETS")}</span>
            </Button>
          </Grid>
        </Grid>
      )}

      {!isMobile && (
        <TableCell align="center">
          {
            //this 1x2 will appear on the desktop screen
          }
          <Grid className={classes.marketNameWrap}>
            <>
              <span className={classes.marketName}>{t("MARKETS.HOME")}</span>
              <span className={classes.marketName}>{t("MARKETS.DRAW")}</span>
              <span className={classes.marketName}>{t("MARKETS.AWAY")}</span>
            </>
          </Grid>
          <Grid className={classes.oddsWrap}>
            <Grid className={classes.matchDetailsCardOdds}>
              <ul className={classes.singleScoreLine}>
                {odds_1x2 && odds_1x2?.length ? (
                  <>
                    {odds_1x2.map((oddType) => {
                      return (
                        <li key={oddType.providerOutcomeId}>
                          <Button
                            onClick={() => {
                              oddSelectHandler(
                                oddType?.providerOutcomeId,
                                oddType?.specialBetValue,
                                oddType?.variant,
                                oddType?.providerMarketId
                              );
                            }}
                            className={`${
                              bets &&
                              bets?.length &&
                              bets.filter(
                                (bet) =>
                                  bet?.providerOutcomeId ===
                                    oddType?.providerOutcomeId &&
                                  bet.matchMarketId == matchMarketId1x2 &&
                                  bet?.specialBetValue ===
                                    oddType?.specialBetValue &&
                                  bet?.variant === oddType?.variant
                              )?.length
                                ? "active"
                                : ""
                            }`}
                            disabled={
                              oddType?.active == false ||
                              oddType?.type == -1 ||
                              oddType?.odd === "-" ||
                              `${oddType?.betStatus}` !== "1" ||
                              oddType?.isDisabled
                            }
                          >
                            {oddType?.active == false ||
                            oddType?.type == -1 ||
                            `${oddType?.betStatus}` !== "1" ||
                            oddType?.isDisabled ? (
                              <Grid
                                justifyContent={"center"}
                                display={"flex"}
                                minWidth={"100%"}
                              >
                                <LockIcon className={classes.lockIcon} />
                              </Grid>
                            ) : (
                              <>
                                <span>
                                  {oddType.type &&
                                    replaceCompetitor(oddType.type)}
                                </span>
                                <h5>
                                  {odds_1x2 && oddType && (
                                    <OddAnimation
                                      odd={oddType?.odd}
                                      isSocketUpdated={isSocketUpdated}
                                    />
                                  )}
                                </h5>
                              </>
                            )}
                          </Button>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </Grid>
          </Grid>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell align="center" className={classes.marketHideDoubleChange}>
          <Grid className={classes.marketNameWrap}>
            <span className={classes.marketName}>
              {t("MARKETS.DOUBLE_CHANCE")}
            </span>
          </Grid>
          <Grid className={classes.oddsWrap}>
            <Grid className={classes.matchDetailsCardOdds}>
              <ul className={classes.singleScoreLine}>
                {matchMarket && double_chance && double_chance?.length ? (
                  double_chance.map((outcome, i) => {
                    if (!outcome) return null;
                    return (
                      <li key={i}>
                        <Button
                          onClick={() =>
                            oddSelectHandler(
                              outcome?.providerOutcomeId,
                              outcome?.specialBetValue,
                              outcome?.variant,
                              outcome?.providerMarketId
                            )
                          }
                          className={`${
                            bets &&
                            bets?.length &&
                            bets.filter(
                              (bet) =>
                                bet?.providerOutcomeId ===
                                  outcome.providerOutcomeId &&
                                bet.matchMarketId ==
                                  matchMarketIdDoubleChance &&
                                bet?.specialBetValue ===
                                  outcome?.specialBetValue &&
                                bet?.variant === outcome?.variant
                            )?.length
                              ? "active"
                              : ""
                          }`}
                          disabled={
                            outcome?.type == -1 ||
                            outcome?.active == false ||
                            `${outcome?.betStatus}` !== "1" ||
                            outcome?.isDisabled
                          }
                        >
                          {!outcome?.type ||
                          outcome?.type == -1 ||
                          outcome?.active == false ||
                          `${outcome?.betStatus}` !== "1" ||
                          outcome?.isDisabled ? (
                            <Grid
                              justifyContent={"center"}
                              display={"flex"}
                              minWidth={"100%"}
                            >
                              <LockIcon className={classes.lockIcon} />
                            </Grid>
                          ) : (
                            <>
                              <span>{replaceCompetitor(outcome?.type)}</span>
                              <h5>
                                {
                                  <OddAnimation
                                    odd={outcome?.odd}
                                    isSocketUpdated={isSocketUpdated}
                                  />
                                }
                              </h5>
                            </>
                          )}
                        </Button>
                      </li>
                    );
                  })
                ) : (
                  <>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </Grid>
          </Grid>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell align="center" className={classes.marketHideOU}>
          <Grid className={classes.marketNameWrap}>
            <span className={classes.marketName}>
              {t("MARKETS.OVER_UNDER")}
            </span>
          </Grid>
          <Grid className={classes.oddsWrap}>
            <Grid className={classes.matchDetailsCardOdds}>
              <ul className={classes.singleScoreLine}>
                {matchMarket && overUnder && overUnder?.length ? (
                  <>
                    <li key={lineIndex} type="button">
                      <Button
                        onClick={() =>
                          oddSelectHandler(
                            overUnder[lineIndex]?.providerOutcomeId,
                            overUnder[lineIndex]?.specialBetValue,
                            overUnder[lineIndex]?.variant,
                            overUnder[lineIndex]?.providerMarketId
                          )
                        }
                      >
                        <Grid
                          className={`OverUnder ${
                            bets &&
                            bets?.length &&
                            bets.filter(
                              (bet) =>
                                bet?.providerOutcomeId ===
                                  overUnder[lineIndex].providerOutcomeId &&
                                bet.matchMarketId == MatchMarketIdOverUnder &&
                                bet?.specialBetValue ===
                                  overUnder[lineIndex]?.specialBetValue &&
                                bet?.variant === overUnder[lineIndex]?.variant
                            )?.length
                              ? "active"
                              : ""
                          }`}
                          disabled={
                            overUnder[lineIndex]?.type == -1 ||
                            overUnder[lineIndex]?.active == false ||
                            `${overUnder[lineIndex]?.betStatus}` !== "1"
                          }
                        >
                          {!overUnder[lineIndex]?.type ||
                          overUnder[lineIndex]?.type == -1 ||
                          overUnder[lineIndex]?.active == false ||
                          `${overUnder[lineIndex]?.betStatus}` !== "1" ? (
                            <Grid
                              justifyContent={"center"}
                              display={"flex"}
                              minWidth={"100%"}
                            >
                              <LockIcon className={classes.lockIcon} />
                            </Grid>
                          ) : (
                            <>
                              <span className={classes.oddType}>
                                {oddTypeFormatter(overUnder[lineIndex])}
                              </span>
                              <h5 className={classes.oddType}>
                                {
                                  <OddAnimation
                                    odd={overUnder[lineIndex]?.odd}
                                    isSocketUpdated={isSocketUpdated}
                                  />
                                }
                              </h5>
                            </>
                          )}
                        </Grid>
                      </Button>
                    </li>
                    <Grid className={classes.lineArrows}>
                      <Grid
                        onClick={() => {
                          if (overUnder && lineIndex < overUnder?.length - 2) {
                            setLineindex((lineIndex) => lineIndex + 2);
                          }
                        }}
                        disabled={
                          overUnder && !(lineIndex < overUnder?.length - 2)
                        }
                        className={"uparrow"}
                      >
                        <ArrowDropUpIcon fontSize="medium" />
                      </Grid>
                      <span>
                        {parseFloat(
                          overUnder[lineIndex]?.specialBetValue
                        ).toFixed(2)}
                      </span>
                      <Grid
                        onClick={() => {
                          if (overUnder && lineIndex > 0)
                            setLineindex((lineIndex) => lineIndex - 2);
                        }}
                        disabled={overUnder && !(lineIndex > 0)}
                        className={"downarrow"}
                      >
                        {" "}
                        <ArrowDropDownIcon fontSize="medium" />
                      </Grid>
                    </Grid>
                    <li key={lineIndex + 1} type="button">
                      <Button
                        onClick={() =>
                          oddSelectHandler(
                            overUnder[lineIndex + 1]?.providerOutcomeId,
                            overUnder[lineIndex + 1]?.specialBetValue,
                            overUnder[lineIndex + 1]?.variant,
                            overUnder[lineIndex + 1]?.providerMarketId
                          )
                        }
                      >
                        <Grid
                          className={`OverUnder ${
                            bets &&
                            bets?.length &&
                            bets.filter(
                              (bet) =>
                                bet?.providerOutcomeId ===
                                  overUnder[lineIndex + 1].providerOutcomeId &&
                                bet.matchMarketId == MatchMarketIdOverUnder &&
                                bet?.specialBetValue ===
                                  overUnder[lineIndex + 1]?.specialBetValue &&
                                bet?.variant ===
                                  overUnder[lineIndex + 1]?.variant
                            )?.length
                              ? "active"
                              : ""
                          }`}
                          disabled={
                            overUnder[lineIndex + 1]?.type == -1 ||
                            overUnder[lineIndex + 1]?.active == false ||
                            `${overUnder[lineIndex + 1]?.betStatus}` !== "1"
                          }
                        >
                          {!overUnder[lineIndex + 1]?.type ||
                          overUnder[lineIndex + 1]?.type == -1 ||
                          overUnder[lineIndex + 1]?.active == false ||
                          `${overUnder[lineIndex + 1]?.betStatus}` !== "1" ? (
                            <Grid
                              justifyContent={"center"}
                              display={"flex"}
                              minWidth={"100%"}
                            >
                              <LockIcon className={classes.lockIcon} />
                            </Grid>
                          ) : (
                            <>
                              <span>
                                {oddTypeFormatter(overUnder[lineIndex + 1])}
                              </span>
                              <h5>
                                {
                                  <OddAnimation
                                    odd={overUnder[lineIndex + 1]?.odd}
                                    isSocketUpdated={isSocketUpdated}
                                  />
                                }
                              </h5>
                            </>
                          )}
                        </Grid>
                      </Button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                    <li>
                      <Button>
                        {" "}
                        <Grid
                          display={"flex"}
                          justifyContent={"center"}
                          minWidth={"100%"}
                        >
                          {" "}
                          <LockIcon className={classes.lockIcon} />
                        </Grid>
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </Grid>
          </Grid>
        </TableCell>
      )}
    </React.Fragment>
  );
}

export default Markets;
