import { put, takeEvery } from "redux-saga/effects";

import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../redux-saga/redux/toast";
import {
  cashOutFailure,
  cashOutStart,
  cashOutSuccess,
  getCashoutAmountError,
  getCashoutAmountStart,
  getCashoutAmountSuccess,
  getMyBetsFailure,
  getMyBetsStart,
  getMyBetsSuccess,
} from "../../redux/mybets";
import MybetsService from "../../../sport-book-services/mybets";

function* myBetsWatcher() {
  yield takeEvery(
    [getMyBetsStart.type, cashOutStart.type, getCashoutAmountStart.type],
    myBetsWorker
  );
}

function* myBetsWorker(action) {
  try {
    switch (action.type) {
      case getMyBetsStart.type: {
        const { data, error } = yield MybetsService.getMybets(action.payload);
        if (data) {
          yield put(getMyBetsSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getMyBetsFailure());
        }
        break;
      }
      case cashOutStart.type: {
        const { data, error } = yield MybetsService.cashOut(action.payload);
        if (data) {
          if (data.payoutValue)
            put(
              showErrorMessage({
                msg: "Cashout amount changed Please try again...",
              })
            );
          yield put(cashOutSuccess(data));
          if (!data?.payoutValue)
            yield put(showSuccessMessage({ msg: "Cashout successful" }));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(cashOutFailure());
        }
        break;
      }
      case getCashoutAmountStart.type: {
        const { data, error } = yield MybetsService.getCashOutAmount(
          action.payload
        );
        if (data) {
          yield put(getCashoutAmountSuccess(data));
          if (data && !data?.payoutValue)
            yield put(
              showErrorMessage({
                msg: "Can't procress furthur Cashout amount is 0",
              })
            );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getCashoutAmountError());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case cashOutStart.type: {
        yield put(cashOutFailure());
        break;
      }
      case getMyBetsStart.type: {
        yield put(getMyBetsFailure());
        break;
      }
      case getCashoutAmountStart.type: {
        yield put(getCashoutAmountError());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default myBetsWatcher;
