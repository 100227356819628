import React, { useEffect, useState } from "react";

import SportBookLoader from "../components/sportbookLoader";

function SBwidget({ matchID }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (window?.SBWidget && window?.SBWidget?.ltm && matchID) {
      setLoading(false);

      window.SBWidget.ltm(`${matchID}`, ".widget-here", true);
      window.scrollBy(0, 10);
    } else {
      setTimeout(() => {
        setLoading(false);

        if (window?.SBWidget && window?.SBWidget?.ltm && matchID) {
          window.SBWidget.ltm(`${matchID}`, ".widget-here", true);
          window.scrollBy(0, 10);
        }
      }, 4000);
    }
  }, [matchID]);

  useEffect(() => {
    return () => {
      if (window?.SBWidget && window?.SBWidget?.ctm) {
        window.SBWidget.ctm();
      }
    };
  }, []);
  return (
    <div>
      {loading && <SportBookLoader />}
      <div className="widget-here"></div>
    </div>
  );
}

export default SBwidget;
