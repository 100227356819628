import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  favorites: [],
  favoritesCount: 0,
  numberOfPages: 0,
  loadMoreFavorites: false,
  favStatus: null,
  favrunning: [],
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    getFavoritesStart: (state, action) => {
      if (action.payload?.loadMoreFavorites) {
        state.loadMoreFavorites = true;
      }
      if (!action.payload?.loadMoreFavorites) {
        state.loading = true;
      }
      if (action.payload?.viewAll) {
        state = {
          success: false,
          error: false,
          favorites: [],
          favoritesCount: 0,
          numberOfPages: 0,
          loadMoreFavorites: false,
          favStatus: null,
          loading: true,
          page: 1,
        };
      }
    },
    getFavoritesSuccess: (state, action) => {
      if (action.payload?.loadMoreFavorites) {
        state.loadMoreFavorites = false;
        state.favorites = [...state.favorites, ...action.payload.favorites];
      }
      if (!action.payload?.loadMoreFavorites) {
        state.loading = false;
        state.favorites = action.payload.favorites;
      }
      state.success = true;
      state.numberOfPages = action.payload?.numberOfPages || 0;
      state.favoritesCount = action.payload?.favoritesCount || 0;
      state.page = action.payload?.page;
    },
    getFavoritesFailure: (state) => {
      state.loading = false;
      state.error = true;
      state.loadMoreFavorites = false;
    },
    addRemoveFavoritesStart: (state, action) => {
      state.addremoveLoading = true;
      if (!action.payload?.notShowLoading) {
        state.loading = true;
      }
      state.fetchNewFavourites = false;
      state.favrunning = [...state.favrunning, `${action?.payload?.gameId}`];
    },
    addRemoveFavoritesSuccess: (state, action) => {
      state.success = true;
      state.error = false;
      state.loading = false;
      state.favStatus = action.payload;
      state.fetchNewFavourites = true;
      state.addremoveLoading = false;
      state.favrunning = state.favrunning.filter(
        (gameId) => action?.payload?.data?.gameId != gameId
      );
    },
    addRemoveFavoritesFailure: (state) => {
      state.error = true;
      state.success = false;
      state.loading = false;
      state.addremoveLoading = false;
    },
    resetSuccess: (state) => {
      state.success = initialState.success;
      state.favStatus = initialState.favStatus;
    },
    resetFavoritesState: () => initialState,
  },
});

export const {
  getFavoritesStart,
  getFavoritesSuccess,
  getFavoritesFailure,
  addRemoveFavoritesStart,
  addRemoveFavoritesSuccess,
  addRemoveFavoritesFailure,
  resetSuccess,
  resetFavoritesState,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
