import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  MenuItem,
  Select,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import useStyle from "./sportbook.styles";
import {
  getSportBookBannersStart,
  getSportsStart,
  setSearchMatchName,
  setSelectedOddsFormat,
  updateSelectedSports,
} from "../../../sport-book-saga/redux/sportbook";
import ScrollableTab from "../components/ScrollbarTabs";
import Sports from "../components/Sports";
import BetSlip from "../components/BetSlip";
import { decodeSocketData, oddsFormats } from "../Utils/common";
import {
  betLimitStart,
  resetBetLimit,
  setIsFirstTime,
  updateBetsWithSocketData,
} from "../../../sport-book-saga/redux/betslip";
import { setActive } from "../../../redux-saga/redux/settings";
import session from "../../../utils/session";
import useLiveMatchSocketNameSpace, {
  liveMatchSocketInstance,
  preLiveMatchSocketInstance,
  usePreLiveMatchSocketNameSpace,
} from "../socket/hooks";
import SportBookLoader from "../components/sportbookLoader";
import {
  resetLiveMatches,
  resetPreLiveMatches,
} from "../../../sport-book-saga/redux/matches";

const allowHeader = [
  "/lobby",
  "/favorites",
  "/results",
  "/sportbookLobby",
  "/sportbookLobby/",
  "/lobby/",
  "/favorites/",
  "/results/",
];
const allowbetSlipBtn = [
  "/lobby",
  "/favorites",
  "/sportbookLobby",
  "/more-market",
];
const allBetListener = {};
function SportBook() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFirstTime = useSelector(
    (state) => state.sportbook.betslip.isFirstTime
  );
  const [selectedSport, setSelectedSport] = useState("");
  // const [isFirstTime, setIsFirstTime] = useState(true);
  const [openBetSlip, setOpenBetSlip] = useState(false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    sports: sportsList,
    loading,
    bannerLoading,
    selectedOddsFormat,
  } = useSelector((state) => state.sportbook.sports);
  // const { loading: featureMatchLoading } = useSelector(
  //   (state) => state.sportbook.featuredMatches
  // );
  // const search = useSelector((state) => state.sportbook.search);
  const { bets } = useSelector((state) => state.sportbook.betslip);
  const { isLoggedIn } = useSelector((state) => state.authentication);

  const location = useLocation();
  const handleSportTabClick = ({
    providerSportId,
    sportName,
    currentTab,
    selectedSportimg,
  }) => {
    setSelectedSport(currentTab);
    dispatch(resetPreLiveMatches());
    dispatch(resetLiveMatches());
    dispatch(
      updateSelectedSports({
        selectedSportId: providerSportId,
        selectedSportName: sportName,
        selectedSportImg: selectedSportimg,
      })
    );
    dispatch(setSearchMatchName(""));
    setSearchText("");
  };

  const [searchText, setSearchText] = useState("");
  const { listenAllPreLiveMatchMarketsUpdates } =
    usePreLiveMatchSocketNameSpace();
  const { listenAllMatchMarketsUpdates } = useLiveMatchSocketNameSpace();

  useEffect(() => {
    bets.forEach((bet) => {
      const matchRoomId =
        bet &&
        bet?.selectedSportId &&
        bet?.providerTournamentId &&
        bet?.providerMatchId &&
        `${bet?.selectedSportId}${bet.providerTournamentId}${bet.providerMatchId}`;
      if (matchRoomId && !allBetListener[`dashboard_cb_${matchRoomId}`]) {
        const obj1 = {
          [`dashboard_cb_${matchRoomId}`]: async (value) => {
            let socketData = await decodeSocketData(value);
            socketData = socketData?.data;
            if (socketData) {
              if (socketData?.providerMatchId)
                dispatch(updateBetsWithSocketData(socketData));
            }
          },
        };
        allBetListener[`dashboard_cb_${matchRoomId}`] = bet?.isLive
          ? listenAllMatchMarketsUpdates(
              matchRoomId,
              obj1[`dashboard_cb_${matchRoomId}`]
            )
          : listenAllPreLiveMatchMarketsUpdates(
              matchRoomId,
              obj1[`dashboard_cb_${matchRoomId}`]
            );
      }
    });

    return () => {
      const betsRoomIds = bets.forEach((bet) => {
        const id = `${bet?.selectedSportId}${bet.providerTournamentId}${bet.providerMatchId}`;
        return `dashboard_cb_${id}`;
      });
      if (betsRoomIds && betsRoomIds?.length) {
        for (const [key] of Object.entries(allBetListener)) {
          if (!betsRoomIds.includes(key)) {
            allBetListener[key]();
          }
        }
      }
    };
    // eslint-disable-next-line
  }, [bets?.length]);

  useEffect(() => {
    return () => {
      Object.keys(allBetListener).forEach((key) => {
        allBetListener[key]();
        delete allBetListener[key];
      });
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setSearchMatchName(searchText));
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);
  useEffect(() => {
    if (bets?.length === 1 && isFirstTime) {
      setOpenBetSlip(true);
      dispatch(setIsFirstTime(false));
    }
    if (bets?.length === 0) {
      setOpenBetSlip(false);
      dispatch(setIsFirstTime(true));
    }
  }, [bets, dispatch, isFirstTime]);
  useEffect(() => {
    dispatch(getSportsStart());
    dispatch(getSportBookBannersStart({ category: 0 }));
  }, [dispatch]);

  useEffect(() => {
    isLoggedIn && dispatch(betLimitStart({ type: 1 }));
    return () => {
      dispatch(resetBetLimit());
    };
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (sportsList && sportsList?.length) {
      setSelectedSport(0);
      dispatch(
        updateSelectedSports({
          selectedSportId: sportsList[0]?.providerSportId,
          selectedSportName: sportsList[0].sportName,
          selectedSportImg: sportsList[0].icon,
        })
      );
      dispatch(setSearchMatchName(""));
      setSearchText("");
    }
  }, [sportsList, dispatch]);

  useEffect(() => {
    // Event listener for beforeunload event
    const handleBeforeUnload = () => {
      // Close Socket.IO connections before the page unloads
      console.log("disconnected from socket switch tab");
      liveMatchSocketInstance.disconnect();
      preLiveMatchSocketInstance.disconnect();
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);
    liveMatchSocketInstance.on("error", (error) => {
      console.log("Socket error:", error);
      // Handle the error
    });
    preLiveMatchSocketInstance.on("error", (error) => {
      console.log("Socket error:", error);
      // Handle the error
    });

    liveMatchSocketInstance.on("reconnect", (attemptNumber) => {
      console.log("Reconnected to the server");
      console.log("Reconnection attempt:", attemptNumber);
    });

    liveMatchSocketInstance.on("reconnect_error", (error) => {
      console.log("Reconnection error:", error);
    });

    liveMatchSocketInstance.on("reconnect_failed", () => {
      console.log("Failed to reconnect to the server");
    });

    preLiveMatchSocketInstance.on("reconnect", (attemptNumber) => {
      console.log("Reconnected to the server");
      console.log("Reconnection attempt:", attemptNumber);
    });

    preLiveMatchSocketInstance.on("reconnect_error", (error) => {
      console.log("Reconnection error:", error);
    });

    preLiveMatchSocketInstance.on("reconnect_failed", () => {
      console.log("Failed to reconnect to the server");
    });

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      liveMatchSocketInstance.disconnect();
      preLiveMatchSocketInstance.disconnect();
    };
  }, []);

  const betSlipOpenhandler = () => {
    if (bets?.length === 0) {
      toast.error(t("SPORTS.TOAST_ERROR_MESSAGE"));
      return;
    }
    setOpenBetSlip(true);
  };

  useEffect(() => {
    if (location.pathname === "/" && location.pathname !== "/lobby") {
      dispatch(setActive("/lobby"));
      navigate("/lobby");
    }
    if (sportsList?.length) {
      // setSelectedSport(0);
      // dispatch(
      //   updateSelectedSports({
      //     selectedSportId: sportsList[0]?.providerSportId,
      //     selectedSportName: getName(sportsList[0].sportName, language),
      //     selectedSportImg: sportsList[0].icon,
      //   })
      // );
      dispatch(setSearchMatchName(""));
      setSearchText("");
    }
  }, [navigate]);

  useEffect(() => {
    if (
      !isLoggedIn &&
      !session.getSessionCred &&
      (location.pathname === "/my-bets" || location.pathname === "/favorites")
    ) {
      navigate("/lobby");
    }
  }, [isLoggedIn, navigate]);

  const selectedFavoritesOption = useSelector(
    (state) => state.sportbook.favourite.favouritesOption
  );

  return loading || bannerLoading ? (
    <Grid
      display={"flex"}
      minWidth={"100%"}
      minHeight={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SportBookLoader />
    </Grid>
  ) : (
    <Fragment>
      <Grid container className={classes.sportbook_container}>
        {allowHeader.includes(location?.pathname) &&
          selectedFavoritesOption === "sportsBook" && (
            <>
              <Grid className={classes.sportsFilter}>
                <Grid container spacing={2}>
                  <Grid xs={12} item lg={7} className={classes.tab_container}>
                    {!!sportsList?.length && (
                      <ScrollableTab
                        value={selectedSport}
                        list={sportsList}
                        Container={Sports}
                        handleTabClick={handleSportTabClick}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={3}
                    container
                    className={classes.search_container}
                    flexWrap="nowrap"
                  >
                    {/* <Grid className={classes.search_content_wrap}>
      <Typography variant="body1" gutterBottom>
        See All Sports
      </Typography>
    </Grid> */}

                    <Grid className={classes.sportbetSearchWrap}>
                      {" "}
                      <TextField
                        id="input-with-icon-textfield"
                        InputProps={
                          {
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //   </InputAdornment>
                            // ),
                          }
                        }
                        variant="standard"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        placeholder={t("SPORTS.SEARCH")}
                      />
                      <img src="/images/search-icon.svg" alt="Search" />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={2}
                    container
                    flexWrap="nowrap"
                    className={classes.selectOddFormat}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedOddsFormat}
                      variant="outlined"
                      className={`${classes.selectTab} ${"active"}`}
                      onChange={(e) => {
                        dispatch(setSelectedOddsFormat(e.target.value));
                      }}
                    >
                      {oddsFormats?.length > 0 &&
                        oddsFormats.map((item) => (
                          <MenuItem key={item} value={item}>
                            {t("SPORTS." + item.toUpperCase())}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

        {allowbetSlipBtn.includes(location?.pathname) && (
          <>
            <Button
              variant="outline"
              className={classes.betslip_open_btn}
              onClick={betSlipOpenhandler}
            >
              <img
                src="/images/sport-book/betslip-btn-icon.svg"
                alt="betslip-icon"
                height={"60px"}
              />

              {!!(bets && bets?.length) && <>{`Bets: ${bets?.length}`}</>}
            </Button>
            <Dialog
              onClose={() => setOpenBetSlip(false)}
              aria-labelledby="customized-dialog-title"
              open={openBetSlip}
            >
              <BetSlip handleClose={() => setOpenBetSlip(false)} />
            </Dialog>
          </>
        )}

        <Outlet />
      </Grid>
    </Fragment>
  );
}

export default SportBook;
