import React, { useEffect } from "react";

import { Grid, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useTranslation } from "react-i18next";

import useStyles from "./Lobby.styles";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { formatCategoryName } from "../../../utils/helper";
import { homePageGamesLimit } from "../../../utils/constants";
import Game from "../Game";
import CasinoLoader from "../CasinoLoader";

// import Carousel from "react-material-ui-carousel";
const Games = ({ selectedCategory, selectedProvider }) => {
  const classes = useStyles();
  const { games, loading } = useSelector((state) => state.casinoGames);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      getGamesStart({
        limit: homePageGamesLimit,
        isMobile,
      })
    );
  }, [dispatch]);

  const LeftButton = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
    return (
      <Button
        className={classes.leftArrow}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);
    return (
      <Button
        disabled={isLastItemVisible}
        className={classes.rightArrow}
        onClick={() => scrollNext()}
      >
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  return (
    <>
      {loading ? (
        <Grid textAlign="center">
          <CasinoLoader />
        </Grid>
      ) : games?.length > 0 ? (
        games.map((gameType) => {
          if (selectedCategory === "all" && selectedProvider === 0) {
            return gameType?.MasterCasinoGames?.length >= 5 ? (
              <Grid key={gameType.name} className={classes.contentWrap}>
                <Grid className={classes.innerHeading}>
                  <Grid className={classes.innerHeadingLeft}>
                    <img src="/images/slots.svg" alt="slots Icon" />
                    <Typography variant="h6" gutterBottom>
                      {formatCategoryName(gameType.name) || ""}
                    </Typography>
                  </Grid>

                  {gameType?.MasterCasinoGames?.length >=
                    homePageGamesLimit && (
                    <Link
                      to={`/view-all/false/${gameType.masterGameSubCategoryId}/${gameType.name}`}
                      className={classes.btnSecondary}
                    >
                      {t("COMMON.VIEW_ALL")}
                    </Link>
                  )}
                </Grid>
                <Grid container>
                  <ScrollMenu
                    LeftArrow={
                      gameType?.MasterCasinoGames?.length > 5 && LeftButton
                    }
                    RightArrow={
                      gameType?.MasterCasinoGames?.length > 5 && RightButton
                    }
                    wrapperClassName="horizontalScrollWrapper"
                  >
                    {gameType?.MasterCasinoGames?.map((game, index) => (
                      <Game
                        key={`${game.id}${index}`}
                        thumbnailUrl={game.thumbnailUrl}
                        name={game.name}
                        gameId={game.id}
                        filterBy={"Category"}
                        filterName={formatCategoryName(gameType.name)}
                        isViewAll={false}
                        isFavorite={game.isFavorite}
                        providerName={game?.MasterCasinoProvider?.name}
                      />
                    ))}
                  </ScrollMenu>
                </Grid>
              </Grid>
            ) : null;
          } else {
            return gameType?.MasterCasinoGames?.length > 0 ? (
              <Grid key={gameType.name} className={classes.contentWrap}>
                <Grid className={classes.innerHeading}>
                  <Grid className={classes.innerHeadingLeft}>
                    <img src="/images/slots.svg" alt="slots Icon" />
                    <Typography variant="h6" gutterBottom>
                      {formatCategoryName(gameType.name) || ""}
                    </Typography>
                  </Grid>

                  {gameType?.MasterCasinoGames?.length >=
                    homePageGamesLimit && (
                    <Link
                      to={`/view-all/false/${gameType.masterGameSubCategoryId}/${gameType.name}`}
                      className={classes.btnSecondary}
                    >
                      {t("COMMON.VIEW_ALL")}
                    </Link>
                  )}
                </Grid>
                <Grid container>
                  <ScrollMenu
                    LeftArrow={
                      gameType?.MasterCasinoGames?.length > 5 && LeftButton
                    }
                    RightArrow={
                      gameType?.MasterCasinoGames?.length > 5 && RightButton
                    }
                    wrapperClassName="horizontalScrollWrapper"
                  >
                    {gameType?.MasterCasinoGames?.map((game, index) => (
                      <Game
                        key={`${game.id}${index}`}
                        thumbnailUrl={game.thumbnailUrl}
                        name={game.name}
                        gameId={game.id}
                        filterBy={"Category"}
                        filterName={formatCategoryName(gameType.name)}
                        isViewAll={false}
                        isFavorite={game.isFavorite}
                      />
                    ))}
                  </ScrollMenu>
                </Grid>
              </Grid>
            ) : null;
          }
        })
      ) : (
        <h1 className={classes.noGamesMsg}>{t("COMMON.NO_GAMES_FOUND")}</h1>
      )}
    </>
  );
};

export default Games;
