import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  debounce,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import useStyles from "./ViewAll.styles";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { formatCategoryName } from "../../../utils/helper";
import {
  getProvidersStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby/casinoLobby";
import { viewAllGamesLimit } from "../../../utils/constants";
import Game from "../Game";
import CasinoLoader from "../CasinoLoader";
import { getFavoritesStart } from "../../../redux-saga/redux/casino/lobby/favorites";

const ViewAll = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { provider, category, name } = params;
  const [searchInput, setSearchInput] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const offset = useRef(1);
  const {
    // subCategory,
    providers,
  } = useSelector((state) => state.casinoLobby);
  // let { games, loading, gamesCount, moreGamesLoading } = useSelector(
  //   (state) => state.casinoGames
  // );

  let games = useSelector((state) => state.casinoGames.games);
  let loading = useSelector((state) => state.casinoGames.loading);
  let gamesCount = useSelector((state) => state.casinoGames.gamesCount);
  let moreGamesLoading = useSelector(
    (state) => state.casinoGames.moreGamesLoading
  );

  let favData = useSelector((state) => state.favorites);
  let fetchNewFavourites = "";
  if (name === "Favourite") {
    games = favData?.favorites;
    loading = favData?.loading;
    gamesCount = favData?.favoritesCount;
    moreGamesLoading = favData?.loadMoreFavorites;
    fetchNewFavourites = favData?.fetchNewFavourites;
  }

  const initialLoad = useRef(0);

  const handleChangeSearch = (event) => {
    setSearchInput(event.target.value);
    searchGames(event.target.value);
  };

  useEffect(() => {
    resetFilter();
  }, [isLoggedIn]);
  useEffect(() => {
    if (name === "Favourite") {
      dispatch(
        getFavoritesStart({
          limit: 18,
          isMobile,
          page: 1,
          viewAll: true,
          isfav: true,
        })
      );
    }
    if (provider !== "false") {
      dispatch(
        getGamesStart({
          providerId: provider,
          limit: viewAllGamesLimit,
          pageNumber: 1,
          isMobile,
          isHomePage: false,
        })
      );
      dispatch(getSubCategoryStart());
    }
    if (category !== "false") {
      dispatch(
        getGamesStart({
          subCategoryId: category,
          limit: viewAllGamesLimit,
          pageNumber: 1,
          isMobile,
          isHomePage: false,
        })
      );
      name !== "Favourite" && dispatch(getProvidersStart());
    }
    initialLoad.current = 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, provider, category, name]);

  useEffect(() => {
    if (fetchNewFavourites) {
      offset.current = 1;
      dispatch(
        getFavoritesStart({
          limit: 18,
          isMobile,
          page: 1,
          viewAll: true,
          isfav: true,
        })
      );
    }
  }, [fetchNewFavourites]);

  const getGames = ({
    searchValue = null,
    providerId = null,
    categoryId = null,
  }) => {
    if (initialLoad.current !== 2) {
      initialLoad.current += 1;
    }
    let filters = {
      limit: viewAllGamesLimit,
      search: searchInput || null,
    };
    if (searchValue !== null) {
      filters.search = searchValue || null;
      filters.subCategoryId = selectedCategory || null;
      filters.providerId = selectedProvider || null;
    }
    if (provider !== "false") {
      filters.providerId = provider;
      if (searchValue === null) {
        filters.subCategoryId = categoryId || null;
      }
    }
    if (category !== "false") {
      filters.subCategoryId = category;
      if (searchValue === null) {
        filters.providerId = providerId || null;
      }
    }
    filters.pageNumber = 1;
    filters.isMobile = isMobile;
    dispatch(getGamesStart({ ...filters, isHomePage: false }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce((searchValue) => {
      getGames({ searchValue });
      offset.current = 1;
    }, 500),
    [selectedProvider, selectedCategory]
  );

  const handleMoreGames = () => {
    offset.current += 1;
    if (name !== "Favourite")
      dispatch(
        getGamesStart({
          limit: viewAllGamesLimit,
          search: searchInput || null,
          providerId:
            (provider !== "false" ? provider : selectedProvider) || null,
          subCategoryId:
            (category !== "false" ? category : selectedCategory) || null,
          pageNumber: offset.current,
          loadMoreGames: true,
          isMobile,
          isHomePage: false,
        })
      );

    if (name === "Favourite") {
      dispatch(
        getFavoritesStart({
          limit: 18,
          isMobile,
          page: offset.current,
          viewAll: true,
          loadMoreFavorites: true,
        })
      );
    }
  };

  // const handleChangeCategory = (event) => {
  //   offset.current = 1;
  //   getGames({ categoryId: event.target.value });
  //   setSelectedCategory(event.target.value);
  // };

  const handleChangeProvider = (event) => {
    offset.current = 1;
    getGames({ providerId: event.target.value });
    setSelectedProvider(event.target.value);
  };

  const resetFilter = () => {
    setSelectedProvider(0);
    setSelectedCategory(0);
    setSearchInput("");
    offset.current = 1;
    dispatch(
      getGamesStart({
        limit: viewAllGamesLimit,
        providerId: provider !== "false" ? provider : null,
        subCategoryId: category !== "false" ? category : null,
        pageNumber: offset.current,
        isMobile,
        isHomePage: false,
      })
    );
  };
  return (
    <div className={classes.myclass}>
      <Grid className={classes.contentWrap}>
        <Grid className={classes.innerHeading}>
          <Grid className={classes.innerHeadingLeft}>
            {name === "Favourite" ? (
              <img src="/images/heart-icon-filled.png" alt="Star Icon" />
            ) : (
              <img src="/images/slots.svg" alt="slots" />
            )}
            <Typography variant="h6" gutterBottom>
              {formatCategoryName(name) || ""}
            </Typography>
          </Grid>
          {name !== "Favourite" && (
            <Grid className={classes.homeFilter}>
              <Grid className={classes.viewAllSearch}>
                <TextField
                  id="search"
                  className={classes.searchInput}
                  type="text"
                  variant="outlined"
                  disabled={
                    !!(initialLoad.current === 1 && !(games?.length > 0))
                  }
                  value={searchInput}
                  onChange={handleChangeSearch}
                  autoComplete="search"
                  placeholder={t("COMMON.SEARCH")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="/images/search.svg" alt="search" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {provider !== "false" ? null : (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProvider}
                  disabled={
                    !(providers?.length > 0) ||
                    (initialLoad.current === 1 && !(games?.length > 0))
                  }
                  className={classes.selectTab}
                  variant="outlined"
                  label="Provider"
                  onChange={handleChangeProvider}
                >
                  <MenuItem value={0}> {t("COMMON.PROVIDERS")}</MenuItem>
                  {providers?.length > 0 &&
                    providers.map((item) => (
                      <MenuItem
                        key={item.name}
                        value={item.masterCasinoProviderId}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
              <Grid className={classes.viewAllCtaWrap}>
                <Button
                  onClick={() => resetFilter()}
                  className={classes.btnPrimary}
                >
                  {t("COMMON.RESET")}
                </Button>
                <Link className={classes.btnPrimary} to="/casino">
                  <Typography>{t("COMMON.LOBBY")}</Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          {name === "Favourite" && (
            <Grid className={classes.homeFilter}>
              <Grid className={classes.viewAllCtaWrap}>
                <Link className={classes.btnPrimary} to="/casino">
                  <Typography>{t("COMMON.LOBBY")}</Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.gamesWrapper}>
          {loading ? (
            <Typography className={classes.circulerLoader}>
              <CasinoLoader />
            </Typography>
          ) : games?.length > 0 ? (
            games.map((game, index) => {
              return (
                // <Grid
                //   key={index}
                //   className={classes.viewAllGrid}
                //   item
                //   xs={6}
                //   sm={3}
                //   md={2}
                //   lg={2}
                // >

                // </Grid>
                <Game
                  key={`${game.casinoGameId}${index}`}
                  thumbnailUrl={game.thumbnailUrl}
                  name={game.name}
                  gameId={game.masterCasinoGameId}
                  filterName={formatCategoryName(name)}
                  filterBy={provider !== "false" ? "Provider" : "Category"}
                  isViewAll={true}
                  isFavorite={game.isFavorite}
                  providerName={game?.MasterCasinoProvider?.name}
                />
              );
            })
          ) : (
            <h1 className={classes.noGamesMsg}>{t("COMMON.NO_GAMES_FOUND")}</h1>
          )}
        </Grid>
        <Grid textAlign="center">
          {!moreGamesLoading && !loading && gamesCount > games?.length && (
            <Button
              onClick={() => handleMoreGames()}
              className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
            >
              {t("LOBBY.LOAD_MORE_GAME")}
            </Button>
          )}
          {moreGamesLoading && (
            <Typography>
              <CasinoLoader className={classes.moreGamesLoader} />
              {/* <CircularProgress className={classes.moreGamesLoader} size={50} /> */}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewAll;
