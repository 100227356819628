import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  contentWrap: {
    margin: "0px 20px 15px 80px",
    padding: "24px 0px 24px 24px",
    position: "relative",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px",
      margin: "0px 15px",
    },
    // "&:after": {
    //   width: "350px",20px
    //   height: "100%",
    //   content: "' '",
    //   position: "absolute",
    //   background:
    //     "linear-gradient(270deg, #FFFFFF 12.65%, rgba(255, 255, 255, 0) 62.65%)",
    //   right: "0px",
    //   top: "0px",
    // },
  },

  providerBg: {
    background: "#f2f4ff",
    borderRadius: "13px",
    width: "244px",
    height: "130px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "160px",
    },
  },

  subHeading: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#0D2867",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "30px",
    marginTop: "15px",
    marginLeft: "-10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginLeft: "0",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
}));
