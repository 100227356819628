import React, { useMemo, useRef } from "react";

import { useSelector } from "react-redux";
import { Grid, Tooltip } from "@mui/material";

import useStyle from "./country.styles";
import { countriesDefaultImages, getName } from "../../Utils/common";

function Countries({
  sportCountryId,
  handleTabClick,
  country,
  selectedTab,
  currentTab,
  providerCountryId,
}) {
  const imgRef = useRef();
  const classes = useStyle();
  const { language } = useSelector((state) => state.sportbook.language);
  const countryImg = useMemo(() => {
    return `${process.env.REACT_APP_S3_COUNTRY_IMG_URL}/${
      getName(country?.countryName, "en") || countriesDefaultImages
    }.svg`;
  }, []);
  return (
    <Tooltip title={getName(country?.countryName, language)} placement="top">
      <Grid
        className={`${classes.locationCard} ${
          selectedTab === currentTab ? " clicked" : ""
        }`}
        onClick={() =>
          handleTabClick({
            sportCountryId,
            countryName: country?.countryName,
            currentTab,
            providerCountryId,
          })
        }
      >
        <Grid className={classes.imgContainer}>
          <img
            src={countryImg}
            alt="Flag"
            ref={imgRef}
            onError={() => {
              imgRef.current.src = countriesDefaultImages;
            }}
          />
        </Grid>
        <span> {getName(country?.countryName, language)}</span>
      </Grid>
    </Tooltip>
  );
}

export default React.memo(Countries);
