import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Grid,
  TextField,
  Typography,
  capitalize,
  Box,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import useStyle from "./Mybet.styles.js";
import {
  BET_SETTLEMENT_STATUS,
  betStatus,
  copyTextToCursor,
  getName,
  oddTypeFormatter,
  replaceCompetitor,
  sportsDefault,
} from "../Utils/common.js";
import {
  cashOutStart,
  getCashoutAmountStart,
  getMyBetsStart,
  resetCashout,
} from "../../../sport-book-saga/redux/mybets/index.js";
import LoadMore from "../components/LoadMore";
import TeamImg from "../components/TeamImg/index.js";
import MybetsDialog from "../components/MybetsDialog/index.js";
import SportBookLoader from "../components/sportbookLoader/index.js";
const betStatusClasses = {
  in_game: "pending",
  won: "won",
  lost: "lost",
};

const EachBetStatus = ({ betStatus, isLive }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  switch (parseInt(betStatus)) {
    case BET_SETTLEMENT_STATUS["WON"]: {
      return (
        <Typography className={classes.betStatus + " won"}>
          {t("MYBET.WON")}
        </Typography>
      );
    }

    case BET_SETTLEMENT_STATUS["LOST"]: {
      return (
        <Typography className={classes.betStatus + " lost"}>
          {t("MYBET.LOST")}
        </Typography>
      );
    }

    default: {
      if (isLive)
        return (
          <Typography className={classes.liveStatus}>
            {t("MYBET.LIVE")}
          </Typography>
        );
      else
        return (
          <Typography className={classes.preLiveStatus}>
            {t("MYBET.OPEN")}
          </Typography>
        );
    }
  }
};

const CopyText = ({ value, text, className }) => {
  //value is the text that you want to copy to cursur
  const textRef = useRef();
  return (
    <Grid className={className}>
      <span>{text}</span>
      <span ref={textRef}>{value}</span>
      <Button
        onClick={() => copyTextToCursor(textRef)}
        style={{ cursor: "pointer" }}
      >
        <img src="/images/ticket-copy.svg" alt="Copy Icon" />
      </Button>
    </Grid>
  );
};

const getEndDate = (fromDate = dayjs()) => {
  let fromDateWithCurrentTime = "";
  if (dayjs(fromDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
    fromDateWithCurrentTime = dayjs(fromDate).utc().format();
  } else {
    fromDateWithCurrentTime = dayjs(fromDate).endOf("day").utc().format();
  }
  return fromDateWithCurrentTime;
};
const getStartDate = (endDate) => {
  return dayjs(endDate).startOf("day").utc().format();
};

const isMatchPassed = (betData) => {
  return betData?.bets?.filter((eachBet) => {
    return (
      dayjs().isAfter(dayjs(eachBet?.matchDate)) ||
      dayjs().isSame(dayjs(eachBet?.matchDate))
    );
  })?.length;
};

export default function Mybets() {
  const { t } = useTranslation();
  const classes = useStyle();
  const [showIndex, setShowIndex] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedBetStatus, setValue] = React.useState(betStatus["all"]);
  const [activeButton, setActiveButton] = useState(0);

  const currentDate = dayjs();
  // Subtract 7 days from the current date
  const earlierDate = currentDate.subtract(7, "day");
  const [date, setDate] = useState(new Date(earlierDate.toISOString()));
  const [endDate, setEndDate] = useState();
  const [calculatedStartDate, setCalculatedStartDate] = useState(
    getStartDate(new Date(earlierDate.toISOString()))
  );
  const [calculatedEndDate, setCalculatedEndDate] = useState(getEndDate());
  const [betSlipId, setbetSlipid] = useState(null);
  const {
    myBets,
    currentPage,
    loading,
    hasNext,
    cashout,
    cashoutAmount,
    cashoutLoading,
    amountLoading,
    betslipId,
  } = useSelector((state) => state.sportbook.myBets);

  const { language } = useSelector((state) => state.sportbook.language);
  const dispatch = useDispatch();
  const handleTabChange = (newValue, index) => {
    setValue(newValue);
    setActiveButton(index);
  };
  useEffect(() => {
    !cashoutLoading && setShowConfirm(false);
  }, [cashoutLoading]);
  useEffect(() => {
    if (cashout) {
      dispatch(resetCashout());
      handleTabChange(
        betStatus["cashout"],
        Object.keys(betStatus).findIndex(
          (status) => status === betStatus["cashout"]
        )
      );
    } else {
      setCalculatedEndDate(getEndDate(endDate));
      setCalculatedStartDate(getStartDate(date));
      dispatch(
        getMyBetsStart({
          betStatus: selectedBetStatus,
          page: 1,
          limit: 6,
          fromDate: getStartDate(date),
          endDate: getEndDate(endDate),
        })
      );
    }
  }, [date, endDate, selectedBetStatus, dispatch, cashout]);

  useEffect(() => {
    if (cashoutAmount) setShowConfirm(true);
    else setShowConfirm(false);
  }, [cashoutAmount]);

  const loadmoreBets = () => {
    dispatch(
      getMyBetsStart({
        // userId: "1",
        betStatus: selectedBetStatus,
        page: parseInt(currentPage) + 1,
        fromDate: calculatedStartDate,
        endDate: calculatedEndDate,
        limit: 6,
      })
    );
  };
  const eachTotalOdds =
    myBets &&
    myBets[showIndex] &&
    myBets[showIndex]?.bets &&
    myBets[showIndex]?.bets?.length &&
    myBets[showIndex]?.bets?.reduce((accumalate, current) => {
      return accumalate * current.price;
    }, 1);

  return (
    <>
      <Grid className={classes.mybets_container}>
        {
          <>
            <Grid className={classes.betstatus_btn}>
              <Grid className={classes.betstatus_btn_container_left}>
                {Object.keys(betStatus).map((status, index) => {
                  return (
                    <Button
                      className={activeButton === index ? " activeButton " : ""}
                      key={status}
                      name={index}
                      variant="contained"
                      onClick={() => handleTabChange(status, index)}
                    >
                      {t("MYBET." + status.replace("_", "").toUpperCase())}
                    </Button>
                  );
                })}
              </Grid>
              <Grid className={classes.rights_container}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language || "en"}
                >
                  <Box sx={{ width: 1 }}>
                    <div className={classes.datepickerStyle}>
                      <div
                        className={
                          classes.formGroup + " " + classes.customDatePicker
                        }
                      >
                        <span>{t("MYBET.START_DATE")}</span>
                        <DesktopDatePicker
                          id="start-date1"
                          placeholder={"Date"}
                          variant="outlined"
                          disableFuture
                          value={date}
                          format={"DD/MM/YYYY"}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(fromDate) => {
                            setDate(fromDate);
                            setCalculatedStartDate(getStartDate(fromDate));
                          }}
                        />
                      </div>
                      <div
                        className={
                          classes.formGroup + " " + classes.customDatePicker
                        }
                      >
                        {" "}
                        <span>{t("MYBET.END_DATE")}</span>
                        <DesktopDatePicker
                          id="end-date1"
                          placeholder={"Date"}
                          variant="outlined"
                          disableFuture
                          value={endDate}
                          format={"DD/MM/YYYY"}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(toDate) => {
                            setEndDate(toDate);
                            setCalculatedEndDate(getEndDate(toDate));
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
            {loading && !hasNext ? (
              <Grid className={classes.iframeCirculerLoader} textAlign="center">
                <SportBookLoader />
              </Grid>
            ) : (
              <div className={classes.myBetsWrap}>
                <Grid container spacing>
                  {myBets && myBets?.length ? (
                    myBets.map((betData, index) => {
                      let totalOdds = null;

                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          key={betData.betslipId}
                        >
                          <Grid className={classes.myBetCard}>
                            <Grid className={classes.mybetHeader}>
                              <Grid className={classes.mybetHeaderLeft}>
                                <span className={classes.betType}>
                                  {betData?.bets?.length > 1
                                    ? t("MYBET.MULTIPLE")
                                    : t("MYBET.SINGLE")}
                                </span>
                                <span>
                                  {dayjs(betData?.createdAt).format(
                                    "DD/MM/YYYY, HH:mm"
                                  )}
                                </span>
                              </Grid>
                              <Grid
                                className={`${classes.betStatus} ${
                                  betData?.betslipStatus &&
                                  betStatusClasses[betData?.settlementStatus]
                                }`}
                              >
                                {capitalize(
                                  `${betData?.settlementStatus}`
                                )?.replaceAll("_", "")}
                              </Grid>
                            </Grid>
                            <Grid className={classes.multibet_container}>
                              {[betData?.bets[0]]?.map((bet, i) => {
                                totalOdds = betData?.bets?.reduce(
                                  (accumalate, current) => {
                                    return accumalate * current.price;
                                  },
                                  1
                                );
                                return (
                                  <Grid
                                    className={classes.mybetMatchCard}
                                    key={i}
                                  >
                                    <Grid className={classes.tableMatchHeader}>
                                      <Grid
                                        className={classes.tableMatchHeaderLeft}
                                      >
                                        <img
                                          src={
                                            bet?.sport?.icon ||
                                            sportsDefault["default"]
                                          }
                                          alt="Soccer"
                                        />
                                        <span>
                                          {getName(bet?.countryName, language)}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {getName(
                                            bet?.tournamentName,
                                            language
                                          )}
                                        </span>
                                      </Grid>
                                      <Grid
                                        className={
                                          classes.tableMatchHeaderRight
                                        }
                                      >
                                        <Grid className={classes.betStatus}>
                                          <VisibilityIcon
                                            onClick={() => {
                                              setShowAll(true);
                                              setShowIndex(index);
                                            }}
                                          />
                                          {/* <Button>View all</Button> */}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className={classes.tableMatchTeamsWrap}
                                    >
                                      <Grid className={classes.tableMatchLeft}>
                                        {
                                          <TeamImg
                                            teamId={bet?.ProviderHomeTeamId}
                                            type="home"
                                            isLive={bet?.isLive}
                                          />
                                        }
                                        <Typography>
                                          {getName(bet?.HomeTeamName, language)}
                                        </Typography>
                                      </Grid>
                                      <span>VS</span>
                                      <Grid className={classes.tableMatchRight}>
                                        {
                                          <TeamImg
                                            teamId={bet?.ProviderAwayTeamId}
                                            type="away"
                                            isLive={bet?.isLive}
                                          />
                                        }
                                        <Typography>
                                          {getName(bet?.awayTeamName, language)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {
                                      <Grid
                                        className={classes.myBetMarket}
                                        container
                                      >
                                        <Grid item xs={12} sm={6}>
                                          <span>
                                            {replaceCompetitor(
                                              getName(bet?.marketName, language)
                                            ) +
                                              " : " +
                                              oddTypeFormatter(bet?.outcome)}
                                          </span>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                          <span>
                                            {dayjs(bet?.matchDate).format(
                                              "DD/MM/YYYY, HH:mm"
                                            )}
                                          </span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={3}
                                          textAlign={"end"}
                                        >
                                          <span>{bet?.price}</span>
                                        </Grid>
                                      </Grid>
                                    }
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Grid className={classes.myBetTotalWrap}>
                              <Grid className={classes.myBetTotal}>
                                <span>{t("MYBET.TOTAL_ODDS_MSG")}</span>
                                <span>{totalOdds.toFixed(2)}</span>
                              </Grid>
                              <Grid className={classes.myBetTotal}>
                                <span>{t("MYBET.STACK_MSG")}</span>
                                <span>{`$ ${betData?.stake}`}</span>
                              </Grid>
                              <Grid className={classes.myBetTotal}>
                                <Typography>
                                  {betData?.settlementStatus === "won" ||
                                  betData?.settlementStatus === "lost"
                                    ? t("MYBET.Total_Win")
                                    : betData?.settlementStatus === "cashout"
                                    ? "Cashout Amount"
                                    : t("MYBET.POSSIBLE_WIN_AMOUNT_MSG")}
                                </Typography>
                                <Typography>
                                  {betData?.settlementStatus === "lost"
                                    ? "$ 0.0"
                                    : betData?.settlementStatus === "cashout"
                                    ? betData?.cashout?.amount
                                    : `$ ${betData.possibleWinAmount}`}
                                </Typography>
                              </Grid>
                              <CopyText
                                value={betData.betslipId}
                                text={`Betslip Id:
                              `}
                                className={classes.myBetTicketNumber}
                              />
                            </Grid>
                            {(betData?.settlementStatus ===
                              betStatus["in_game"] ||
                              selectedBetStatus === "all") && (
                              <Grid className={classes.cashoutBtn}>
                                <Button
                                  className={classes.btnPrimary}
                                  onClick={() => {
                                    if (isMatchPassed(betData)) {
                                      toast.error("Match Already Passed");
                                      return;
                                    }
                                    setbetSlipid(betData?.betslipId);
                                    dispatch(
                                      getCashoutAmountStart({
                                        betslipId: betData?.betslipId,
                                      })
                                    );
                                  }}
                                  disabled={
                                    betData?.settlementStatus !==
                                      betStatus["in_game"] ||
                                    amountLoading ||
                                    isMatchPassed(betData)
                                  }
                                >
                                  {`${t("MYBET.CASH_OUT_MSG")}`}
                                  {amountLoading &&
                                    betslipId === betData?.betslipId && (
                                      <CircularProgress
                                        size={"1rem"}
                                        className={classes.loader}
                                      ></CircularProgress>
                                    )}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <span className={classes.noBets}>No Bets Found</span>
                  )}
                </Grid>
                <LoadMore
                  hasNext={hasNext}
                  loading={loading}
                  loadmoreMatches={loadmoreBets}
                />
              </div>
            )}
          </>
        }
      </Grid>
      {showAll && (
        <MybetsDialog
          title={"All Bets"}
          setShowAll={() => setShowAll(false)}
          showAll={showAll}
        >
          {
            <>
              <Grid className={classes.mybetHeaderLeft}>
                <span className={classes.betType}>
                  {myBets[showIndex]?.bets?.length > 1
                    ? t("MYBET.MULTIPLE")
                    : t("MYBET.SINGLE")}
                </span>
                <span>
                  {dayjs(myBets[showIndex]?.createdAt).format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </span>
              </Grid>
              <Grid className={classes.multibet_container}>
                {myBets[showIndex]?.bets?.map((bet, i) => {
                  //console.log("bet?.sport?.icon", bet?.sport?.icon);
                  return (
                    <Grid className={classes.mybetMatchCard} key={i}>
                      <Grid className={classes.tableMatchHeader}>
                        <Grid className={classes.tableMatchHeaderLeft}>
                          <img
                            src={bet?.sport?.icon || sportsDefault["default"]}
                            alt="Soccer"
                          />
                          <span>{getName(bet?.countryName, language)}</span> -{" "}
                          <span>{getName(bet?.tournamentName, language)}</span>
                        </Grid>
                        <Grid className={classes.tableMatchHeaderRight}>
                          <span>
                            <EachBetStatus
                              betStatus={bet?.settlementStatus}
                              isLive={bet?.isLive}
                            />
                          </span>
                        </Grid>
                      </Grid>
                      <Grid className={classes.tableMatchTeamsWrap}>
                        <Grid className={classes.tableMatchLeft}>
                          {
                            <TeamImg
                              teamId={bet?.ProviderHomeTeamId}
                              type="home"
                              isLive={bet?.isLive}
                            />
                          }
                          <Typography>
                            {getName(bet?.HomeTeamName, language)}
                          </Typography>
                        </Grid>
                        <span>VS</span>
                        <Grid className={classes.tableMatchRight}>
                          {
                            <TeamImg
                              teamId={bet?.ProviderAwayTeamId}
                              type="away"
                              isLive={bet?.isLive}
                            />
                          }
                          <Typography>
                            {getName(bet?.awayTeamName, language)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid className={classes.myBetMarket} container>
                        <Grid item xs={12} sm={6}>
                          <span>
                            {replaceCompetitor(
                              getName(bet?.marketName, language)
                            ) +
                              " : " +
                              oddTypeFormatter(bet?.outcome)}
                          </span>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <span>
                            {dayjs(bet?.matchDate).format("DD/MM/YYYY, HH:mm")}
                          </span>
                        </Grid>
                        <Grid item xs={6} sm={3} textAlign={"end"}>
                          <span>{bet?.price}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}

                <Grid className={classes.myBetTotalWrap}>
                  <Grid className={classes.myBetTotal}>
                    <span>{t("MYBET.TOTAL_ODDS_MSG")}</span>
                    <span>{eachTotalOdds.toFixed(2)}</span>
                  </Grid>
                  <Grid className={classes.myBetTotal}>
                    <span>{t("MYBET.STACK_MSG")}</span>
                    <span>{`$ ${myBets[showIndex]?.stake}`}</span>
                  </Grid>
                  <Grid className={classes.myBetTotal}>
                    <Typography>
                      {myBets[showIndex]?.settlementStatus === "won" ||
                      myBets[showIndex]?.settlementStatus === "lost"
                        ? t("MYBET.Total_Win")
                        : myBets[showIndex]?.settlementStatus === "cashout"
                        ? "Cashout Amount"
                        : t("MYBET.POSSIBLE_WIN_AMOUNT_MSG")}
                    </Typography>
                    <Typography>
                      {myBets[showIndex]?.settlementStatus === "lost"
                        ? "$ 0.0"
                        : myBets[showIndex]?.settlementStatus === "cashout"
                        ? myBets[showIndex]?.cashout?.amount
                        : `$ ${myBets[showIndex].possibleWinAmount}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        </MybetsDialog>
      )}
      {showConfirm && (
        <MybetsDialog
          title={"Cashout Confirmation"}
          setShowAll={() => setShowConfirm(false)}
          showAll={showConfirm}
        >
          <Grid>
            <Typography variant="h6">{t("MYBET.CONFIRM_CASHOUT")}</Typography>
            <Grid display={"flex"} mt="1rem" gap={"1rem"}>
              <Typography variant="subtitle1">
                {t("MYBET.CASHOUT_AMOUNT")}
              </Typography>
              <Typography>
                <b> {`$ ${parseFloat(cashoutAmount).toFixed(2) || 0.0}`}</b>
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"2rem"}
              columnGap={"4rem"}
            >
              <Button
                onClick={() => {
                  dispatch(
                    cashOutStart({
                      betslipId: betSlipId,
                      cashOutAmount: cashoutAmount,
                    })
                  );
                }}
                className={classes.confirmButton}
                disabled={cashoutLoading}
              >
                {t("MYBET.CONFIRM")}
                {cashoutLoading && (
                  <CircularProgress
                    size={"1rem"}
                    className={classes?.loader}
                  ></CircularProgress>
                )}
              </Button>
              <Button
                className={classes.cancelButton}
                onClick={() => setShowConfirm(false)}
              >
                {t("MYBET.CANCEL")}
              </Button>
            </Grid>
          </Grid>
        </MybetsDialog>
      )}
    </>
  );
}
