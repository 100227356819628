import { combineReducers } from "@reduxjs/toolkit";

import sports from "./sportbook";
import matches from "./matches";
import betslip from "./betslip";
import moreMarkets from "./more-markets";
import myBets from "./mybets";
import favourite from "./favourite";
import matchResult from "./results";
import language from "./language";
import featuredMatches from "./featuredMatches";
function lastAction(state = null, action) {
  return action;
}
const sportReducer = combineReducers({
  sports,
  matches,
  betslip,
  moreMarkets,
  myBets,
  favourite,
  matchResult,
  language,
  featuredMatches,
  lastAction,
});

export default sportReducer;
