import React, { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  // Grid
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
// import { isMobile } from "react-device-detect";

import {
  addRemoveFavoritesStart,
  resetSuccess,
} from "../../../../redux-saga/redux/casino/lobby/favorites";
import useStyle from "./FavouriteButton.style.js";
const FavoriteButton = ({
  isFavorite = null,
  favBtnClass = null,
  gameId,
  classes = null,
  isViewAll,
}) => {
  const favClass = useStyle();
  const { favStatus } = useSelector((state) => state.favorites);
  const { metamaskLoggedIn, isLoggedIn } = useSelector(
    (state) => state.authentication
  );
  const [FavBtnActive, setFavBtnActive] = useState(isFavorite);
  const { addremoveLoading, favrunning } = useSelector(
    (state) => state.favorites
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addRemoveFavorite = (e) => {
    e.stopPropagation();
    if (!(metamaskLoggedIn || isLoggedIn)) {
      toast.error(t("GAME.GAME_LOGIN_MSG"), {
        id: "GAME_LOGIN_MSG",
      });
      return;
    }
    dispatch(
      addRemoveFavoritesStart({
        gameId: parseInt(gameId),
        favStatus: FavBtnActive,
        isViewAll,
      })
    );
  };

  useEffect(() => {
    if (parseInt(favStatus?.data?.gameId) === parseInt(gameId)) {
      setFavBtnActive((current) => !current);
      dispatch(resetSuccess());
    }
  }, [favStatus, dispatch]);

  // useEffect(() => {
  //   if (isFavorite) {
  //     setFavBtnActive(true);
  //   } else {
  //     setFavBtnActive(false);
  //   }
  // }, [isFavorite]);

  return (
    <>
      {addremoveLoading && favrunning.includes(gameId) ? (
        <CircularProgress className={favClass.loaderfav} />
      ) : (
        <Button
          className={favBtnClass}
          onClick={addRemoveFavorite}
          disabled={addremoveLoading && favrunning.includes(gameId)}
        >
          {FavBtnActive ? (
            <img src="/images/heart-icon-filled.svg" alt="Heart" />
          ) : (
            <img src="/images/iframe-heart.svg" alt="Heart" />
          )}
        </Button>
      )}
    </>
  );
};

export default React.memo(FavoriteButton);
