import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  sportsItem: {
    minWidth: "150px",
  },

  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  accountRight: {
    height: "100%",
  },
  myprofileTop: {
    background: "#0d0d0d",
    borderRadius: "10px",
    padding: "20px 0px",
  },
  myprofileBalance: {
    display: "flex",
    padding: "0px 40px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
      justifyContent: "center",
    },
  },
  myprofileBalanceLeft: {
    "& .MuiTypography-body1": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1",
      marginBottom: "10px",
      "& span": {
        marginRight: "10px",
      },
    },
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "50px",
    padding: "6px 20px",
    color: "#25262B",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  btnSecondary: {
    background: "#F7616A",
    borderRadius: "50px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    marginTop: "25px",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  balanceBtnWrap: {
    marginTop: "10px",
    "& button": {
      height: "40px",
      padding: "6px 40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& span": {
        marginRight: "15px",
        display: "flex",
      },
    },
  },
  myprofileBalanceRight: {
    textAlign: "center",
  },
  referFriend: {
    padding: "20px 40px",
    marginTop: "20px",
    textAlign: "center",
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    "& .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
    "& .MuiInputBase-root ": {
      background: "#292929",
      borderRadius: "10px",
    },
  },
  referFriendInput: {
    position: "relative",
    "& img": {
      position: "absolute",
      right: "30px",
      top: "30px",
      cursor: "pointer",
    },
  },
  myprofileForm: {
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "13px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  myprofileBottom: {
    background: "#0D2867",
    borderRadius: "10px",
    // marginTop: "10px",
    padding: "30px 55px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
    },
    "& .MuiInputBase-formControl": {
      backgroundColor: "#fff",
      borderRadius: "10px",
      color: "#25262B",
      "& .MuiIconButton-edgeEnd": {
        "& svg": {
          "& path": {
            fill: "#25262B",
          },
        },
      },
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      // background: "#292929",
      backgroundColor: "#fff",
      borderRadius: "10px",
      color: "#25262B",
      lineHeight: "40px",
      fontSize: "14px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "none",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiTypography-h4": {
      fontWeight: "600",
      textTransform: "capitalize",
      // marginBottom: "50px",
      fontSize: "35px",
      color: "#F7616A",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },
    },
    "& .MuiSelect-select.Mui-disabled": {
      opacity: "0.8",
      background: "#bac3da",
      cursor: "not-allowed",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  tableContainer: {
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            background: "#BCC3DB",
            borderBottom: "0",
            color: "#25262B",
          },
        },
      },
      "& tbody": {
        "& tr": {
          backgroundColor: "#0D2867",
          "& td": {
            borderColor: "#BCC3DB",
          },
        },
      },
    },
  },
  dateContainer: {
    marginTop: "-40px",
  },
}));
