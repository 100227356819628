import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class FeaturedMatchesService {
  static getFeaturedMatches(params) {
    return axiosClient
      .get("/betting/getFeaturedMatches", {
        params,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return getErrorObj(error);
      });
  }
}
