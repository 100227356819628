import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

import reducer from "./redux";
import saga from "./saga";
import { loadState, saveState } from "../container/SportsBook/Utils/common";
import {
  ClearAllBat,
  addBet,
  betLimitFailure,
  betLimitStart,
  betLimitSuccess,
  removeBet,
  resetBet,
  setIsFirstTime,
  updateBetsWithSocketData,
  updateStake,
} from "../sport-book-saga/redux/betslip";

const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false }),
    sagaMiddleware,
  ],
  preloadedState: persistedState,
});

const betSlipActions = [
  betLimitStart.type,
  betLimitSuccess.type,
  betLimitFailure.type,
  addBet.type,
  removeBet.type,
  updateStake.type,
  resetBet.type,
  ClearAllBat.type,
  updateBetsWithSocketData.type,
  setIsFirstTime.type,
];
store.subscribe(() => {
  const action = store.getState().sportbook.lastAction;
  const actionType = action && action?.type;
  if (actionType && betSlipActions.includes(actionType)) {
    setTimeout(() => {
      let state = store.getState().sportbook;
      saveState({
        sportbook: {
          betslip: {
            ...state.betslip,
            betlimit: {
              maxWinAmount: 0,
              maxBetAmount: 0,
              minBetAmount: 0,
              maxBetCount: 0,
            },
            isTouched: false,
          },
          moreMarkets: {
            loading: false,
            success: false,
            error: false,
            moreMarkets: {
              matchMarket: [],
            },
            reloaded: state.moreMarkets.reloaded,
          },
        },
      });
    }, 2000);
  }
});

sagaMiddleware.run(saga);

export default store;
