import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  loaderfav: {
    width: "20px !important",
    top: "10px",
    height: "20px !important",
    position: "absolute",
    right: "10px",
    color: "#F7616A",
    [theme.breakpoints.down("sm")]: {
      width: "15px !important",

      height: "15px !important",
    },
  },
}));
