import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    marginTop: "103px",
  },

  homeBanner: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 20px 0px 90px",

    "& .carousel-root": {
      height: "auto",
      width: "100%",
      padding: "0",
      margin: "0",

      "& .LazyLoad": {
        "& img": {
          borderRadius: "0",
        },
      },
      "&  .MuiButtonBase-root": {
        // maxWidth: "32px",
        // borderRadius: "30px",
        // height: "100%",
        // minWidth: "20px",
        // padding: "0",
        // [theme.breakpoints.down("md")]: {

        // },
        "& img": {
          width: "20px",
          background: "#F7616A",
          borderRadius: "30px",
          minWidth: "20px",
          padding: "10px 0",
          [theme.breakpoints.down("md")]: {
            width: "15px",
          },
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      height: "100%",
    },

    "& .control-arrow": {
      "&:hover": {
        background: "transparent !important",
      },
    },
  },
  sliderPrev: {
    position: "absolute",
    top: "0%",
    left: "-15px",
    cursor: "pointer",
    zIndex: "1",
    height: "100%",
    // [theme.breakpoints.down("md")]: {
    //   top: "15%",
    // },
  },
  sliderNext: {
    position: "absolute",
    top: "0%",
    right: "-15px",
    cursor: "pointer",
    height: "100%",
    // [theme.breakpoints.down("md")]: {
    //   top: "15%",
    // },
  },

  bannerImg: {
    width: "100%",
    // marginTop: "100px",
  },

  tabWrapper: {
    alignItems: "center",
    position: "relative",
    "& .MuiTabs-scrollButtons": {
      background: "#F7616A",
      borderRadius: "30px",
      padding: "0",
      height: "40px",
      width: "15px",
      zIndex: "1",
      display: "flex",
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    "&:after": {
      top: "0px",
      right: "0px",
      width: "116px",
      height: "100%",
      content: "' '",
      position: "absolute",
      background:
        "linear-gradient(270deg, #FFFFFF 12.65%, rgba(255, 255, 255, 0) 62.65%)",
    },
  },

  categoryTab: {
    textDecoration: "none",
    marginRight: "5px",
    color: "#0D2867",
    padding: "10px 30px",
    background: "#BCC3DB",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      minHeight: "35px",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
    "&.active": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  btnWrap: {
    margin: "10px 0",
    display: "block",
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#25262B",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background: "#BCC3DB",
    },
  },
  favIconWrap: {
    position: "absolute",
    top: "10px",
    right: "-20px",
    zIndex: "99",
    height: "20px",
    width: "auto !important",
    cursor: "pointer",
    "& img": {
      position: "absolute !important",
      right: "10px !important",
      top: "0px !important",
      transform: "none !important",
    },
  },
  btnSecondary: {
    // background: "#BCC3DB",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    // background: "#0D2867",
    borderRadius: "30px",
    padding: "6px 20px",
    // color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
    textDecoration: "none",
    textTransform: "capitalize",
    marginRight: "20px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  homeFilter: {
    display: "flex",
    alignItems: "center",
    padding: "30px 20px 30px 80px",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "30px 15px",
      overflowX: "auto",
    },
    "& .MuiInputBase-colorPrimary": {
      background: "#BCC3DB",
      // background: "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      color: "#0D2867",
      WebkitTextFillColor: "#0D2867",
      height: "48px",
      padding: "0 20px",
      marginRight: "5px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "50%",
        minHeight: "35px",
        maxHeight: "35px",
      },

      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
      "&.active": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 10px 10px 20px",
      color: "#25262B",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        minWidth: "70px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#0D2867",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled ": {
      opacity: "0.3",
    },
  },
  homeFilterListing: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    margin: "0",
    whiteSpace: "nowrap",
    "& li": {
      margin: "0 5px",
      "& a": {
        display: "inline-block",
        textDecoration: "none",
        color: "#fff",
        padding: "10px 30px",
        height: "40px",
        background: "#F7616A",
        borderRadius: "30px",
        fontSize: "14px",
        "&:hover": {
          background: "#F7616A",
          color: "#25262B",
        },
      },
    },
  },
  contentWrap: {
    margin: "0px 20px 15px 80px",
    position: "relative",
    backgroundColor: "#F2F4FF",
    padding: "0px 0px 24px 24px",
    borderRadius: "20px",
    boxShadow: "0 0 5px #ccc",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px",
      margin: "0 0 15px 0",
      paddingRight: "0",
    },
  },
  casinoCardViewAll: {
    // height: "260px",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
    },
    "& img": {
      height: "100%",
      width: "100%",
      aspectRatio: "1",
      borderRadius: "10px",
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "120px",
      },
    },
    "& span": {
      width: "100%",
      height: "100%",
    },
  },
  casinoCard: {
    height: "180px",
    width: "100%",
    borderRadius: "10px !important",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      // height: "330px",
      padding: "0",
      height: "160px",
    },
    "& img": {
      height: "100%",
      width: "100%",
      objectPosition: "40% 100%",
      borderRadius: "10px",
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "120px",
      },
    },
    "& span": {
      width: "100%",
      height: "100%",
    },
  },

  leftArrow: {
    backgroundColor: "rgb(106 90 205)",
    marginRight: "-20px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(106, 90, 205, 0.6)",
    width: "35px",
    minWidth: "35px",
    height: "35px",
    color: "#fff",
    "& $label": {
      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    },
    label: {
      color: theme.palette.common.white,
    },
  },
  rightArrow: {
    backgroundColor: "rgba(106, 90, 205, 0.3)",
    marginleft: "-50px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(106, 90, 205, 0.6)",
    width: 35,
    minWidth: 35,
    height: 35,
    color: "#fff",
    "& $label": {
      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    },
    label: {
      color: theme.palette.common.white,
    },
  },
  casinoOverlay: {
    // marginBottom: "40px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    "& .MuiTypography-body1": {
      paddingRight: "10px",
      color: "#000",
      fontSize: "13px",
      fontWeight: "500",
      maxWidth: "110px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        textOverflow: "ellipsis",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingRight: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
    "&:hover": {
      "&  > .MuiGrid-root": {
        display: "block",
        minWidth: "100%",
      },
    },
    "&  > .MuiGrid-root": {
      "& .MuiTypography-body1": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
          whiteSpace: "unset",
        },
      },
    },
  },
  infoBtn: {
    position: "relative",
    right: "10px",
    background: "#fff",
    borderRadius: "3px",
    "& img": {
      height: "12px",
      width: "12px",
    },
    "&:hover": {
      "& > .MuiGrid-root": {
        display: "block",
      },
    },
  },
  customInfoModal: {
    borderRadius: "10px",
    background: "#0D2867",
    position: "absolute",
    minWidth: "200px",
    left: "50%",
    right: "auto",
    bottom: "0",
    padding: "5px",
    zIndex: "5",
    display: "none",
    transform: "translate(-50%, -50%)",
  },
  customInfoCard: {
    padding: "5px",
    background: "#BCC3DB",
    margin: "2px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      fontSize: "10px",
      color: "#0D2867",
      marginRight: "5px",
    },
    "& .MuiTypography-body1": {
      fontSize: "10px",
      color: "#0D2867",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 4px",
      alignItems: "flex-start",
    },
  },
  innerHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "15px",
    paddingTop: "15px",
    "& a": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        padding: "6px 10px",
        borderRadius: "5px",
      },
    },
  },
  innerHeadingLeft: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
      width: "30px",
      [theme.breakpoints.down("sm")]: {
        width: "15px",
      },
    },
    "& .MuiTypography-h6": {
      margin: "0",
      color: "#0D2867",
      fontWeight: "600",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  noGamesMsg: {
    textAlign: "center",
    color: "#0D2867",
  },
  categoryLoader: {
    margin: "40px",
  },
  customModalWrap: {
    position: "relative",
  },
  inputBox: {
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-colorPrimary": {
      backgroundColor: "transparent",
      border: "1px solid #F7616A",
      width: "100%",
      "&:hover": {
        background: "transparent",
      },
    },
  },

  viewAllSearch: {
    position: "relative",
  },

  searchIcon: {
    position: "absolute",
    left: "15px",
    top: "14px",
  },

  casinoPlayCard: {
    "& button": {
      "&:hover": {
        "& .MuiBox-root": {
          opacity: "1",
        },
        "& img:nth-child(1)": {
          transform: "scale(1.3) rotate(20deg)",
          transition: "all 400ms ease-in-out",
        },
      },
    },
  },

  playIcon: {
    position: "absolute",
    top: "0",
    opacity: "0",
    transition: "top 0.5s",
    left: "0",
    right: "0",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: "0 auto",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    background:
      "linear-gradient(90deg, rgba(199, 87, 94, 0.9) 0%, rgba(247, 97, 106, 0.9) 0%, rgba(188, 56, 64, 0.9) 0.01%, rgba(247, 97, 106, 0.9) 97.5%)",
    backdropFilter: "blur(3px)",
    cursor: "pointer",
    borderRadius: "10px",
    "& img": {
      width: "25px",
      overflow: "visible",
      transform: "inherit !important",
    },
    "& p": {
      margin: "0",
      color: "#fff",
      fontSize: "14px",
    },
    "& .favIconWrap": {
      position: "absolute",
      top: "0px",
      right: "10px",
      zindex: "9999",
      height: "20px",
      width: "20px",
      cursor: "pointer",
      "& img": {
        position: "absolute !important",
        right: "10px !important",
        top: "0px !important",
        transform: "none !important",
      },
    },
  },

  sliderGame: {
    display: "inline-block",
  },
  selectTab: {
    "& .MuiPaper-root": {
      background: "#4e0077 !important",
      maxHeight: "200px",
    },
  },
  skeleton: {
    backgroundColor: "#BCC3DB",
    marginRight: "10px",
    borderRadius: "45px",
    padding: "10px",
    minWidth: "131px",
    height: "48px",
    background: "#1b214f",
  },
}));
