import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  verifyEmail: {
    width: "100%",
    margin: "50px 0 50px",
    "& .MuiTypography-root": {
      textAlign: "center",
      color: "#F7616A",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
  content: {
    maxWidth: "320px",
    margin: "0 auto",
  },
  verifyEmailWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  verifyEmailContainer: {
    background: "#0D2867",
    minHeight: "calc(100vh - 370px)",
    borderRadius: "10px",
    marginTop: "81px",
    padding: "20px 40px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
}));
