import React, { useEffect } from "react";

import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
// import { isMobile } from "react-device-detect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import useStyles from "./Header.styles";
import { startPoll } from "../../redux-saga/redux/profile/myProfile";
import { getUserProfileDetailsStart } from "../../redux-saga/redux/profile/myProfile";
import { resetSuccess } from "../../redux-saga/redux/auth/authentication";
import session from "../../utils/session";

// import { setActive } from "../../redux-saga/redux/settings";

const Wallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  // const navigate = useNavigate();

  const { metamaskLoggedIn, isLoggedIn, success, metamaskSuccess } =
    useSelector((state) => state.authentication);
  const { nonCashAmount, walletAmount, walletLoading } = useSelector(
    (state) => state.myProfile
  );

  useEffect(() => {
    if ((metamaskLoggedIn && metamaskSuccess) || (isLoggedIn && success)) {
      dispatch(getUserProfileDetailsStart({ walletLoading: true }));
      const user = session.getSessionCred;
      dispatch(startPoll(user.userId));
      dispatch(resetSuccess());
    }
  }, [dispatch, isLoggedIn, metamaskLoggedIn, metamaskSuccess, success]);
  let withdrawableBalance =
    parseFloat(walletAmount) - parseFloat(nonCashAmount);
  return (
    <Grid className={classes.balanceBtnWrap}>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Grid
              onClick={() => {
                // if (isMobile) {
                //   dispatch(setActive("/user/deposit"));
                //   navigate("/user/deposit");
                // }
                dispatch(getUserProfileDetailsStart({ walletLoading: false }));
              }}
            >
              <Button
                className={classes.btnPrimary}
                {...bindTrigger(popupState)}
              >
                <span className={classes.walletIcon}>
                  <img src="/images/wallet.svg" alt="Wallet icon" />
                </span>
                {walletLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  // eslint-disable-line no-use-before-define
                  `$ ${walletAmount || 0.0}`
                )}

                <KeyboardArrowDownIcon />
              </Button>
              <Menu {...bindMenu(popupState)} className={classes.cashPopup}>
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  minWidth={"24rem"}
                  padding={"1rem"}
                  onClick={popupState.close}
                  gap={"0.5rem"}
                >
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Typography>{t("HEADER.REAL_BALANCE")}</Typography>
                    <Typography>{`$ ${
                      parseFloat(walletAmount).toFixed(2) || 0.0
                    }`}</Typography>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Typography>{t("HEADER.BONUS_BALANCE")}</Typography>
                    <Typography>
                      {`$ ${parseFloat(nonCashAmount).toFixed(2) || 0.0}`}
                    </Typography>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Typography>{t("HEADER.WITHDRAWABLE_BALANCE")}</Typography>
                    <Typography>
                      {`$ ${parseFloat(withdrawableBalance).toFixed(2) || 0.0}`}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Grid display={"flex"} justifyContent={"center"}>
                  <Button
                    onClick={() => {
                      dispatch(setActive("/user/deposit"));
                      navigate("/user/deposit");
                      popupState.close();
                    }}
                    className={classes.buttonDeposit}
                  >
                    Deposit
                  </Button>
                </Grid> */}
              </Menu>
            </Grid>
          </React.Fragment>
        )}
      </PopupState>
    </Grid>
  );
};

export default Wallet;
