import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  oddType: {
    maxWidth: "120px",
    minWidth: "120px",
  },

  lineArrows: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    borderRight: "2px solid #BCC3DB",
    maxHeight: "47px",
    minHeight: "47px",
    minWidth: "38px",
    "& .MuiSvgIcon-root": {
      fontSize: "26px",
      color: "gray",
      "&:hover": {
        color: "rgb(247, 97, 106)",
      },
    },
    "& span": {
      fontSize: "12px",
    },

    "& .uparrow": { position: "absolute", top: "-6px" },
    "& .downarrow": { position: "absolute", bottom: "-10px" },
    "&.MuiGrid-root.downarrow.disabled": {
      opacity: "0.5",
    },
    "& .MuiGrid-root[disabled]": {
      opacity: "0.5",
      "& .MuiSvgIcon-root": {
        "&:hover": {
          color: "gray",
        },
      },
    },
  },
  moremarket: {
    marginLeft: "5px",
    textTransform: "capitalize",
  },
  circulerLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  noFeaturedMatchMsg: {
    color: "rgb(247, 97, 106)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      border: "1px solid rgb(247, 97, 106)",
      borderRadius: "5px",
      padding: "10px",
      marginTop: "20px",
      fontSize: "12px",
    },
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
    background: "#fff",
  },
  leftArrow: {
    backgroundColor: "rgb(106 90 205)",
    marginRight: "-20px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(106, 90, 205, 0.6)",
    width: "35px",
    minWidth: "35px",
    height: "35px",
    color: "#fff",
    "& $label": {
      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    },
    // "&:hover": {
    //   backgroundColor: "rgba(106, 90, 205, 0.6)",
    //   borderColor: theme.palette.common.purple,
    //   "& $label": {
    //     transform: "scale(1.3)",
    //     [theme.breakpoints.up("md")]: {
    //       transform: "scale(1.7)",
    //     },
    //   },
    // },
    label: {
      color: theme.palette.common.white,
    },
  },
  rightArrow: {
    backgroundColor: "rgba(106, 90, 205, 0.3)",
    marginleft: "-50px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(106, 90, 205, 0.6)",
    width: 35,
    minWidth: 35,
    height: 35,
    color: "#fff",
    "& $label": {
      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    },
    // "&:hover": {
    //   backgroundColor: "rgba(106, 90, 205, 0.6)",
    //   borderColor: theme.palette.common.purple,
    //   "& $label": {
    //     transform: "scale(1.3)",
    //     [theme.breakpoints.up("md")]: {
    //       transform: "scale(1.7)",
    //     },
    //   },
    // },
    label: {
      color: theme.palette.common.white,
    },
  },
  sportbook_container: {
    // marginLeft: "70px",
    color: "#F7616A",
    padding: "20px 10px 20px 80px",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    marginTop: "103px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
    },
  },
  sportsFilter: {
    marginBottom: "15px",
    width: "100%",
    "& .MuiTabScrollButton-root": {
      width: "20px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      "& svg": {
        color: "#fff",
      },
    },
  },

  sportsheader: {
    display: "flex",
    flexWrap: "wrap",
  },
  tab_container: {
    // marginTop: "1rem",
    paddingTop: "0",
    "& .MuiTabs-scroller": {
      margin: "0 10px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: "0.3",
    },
    "& MuiTabs-indicator": {
      background: "transparent",
    },
    "& .MuiTabScrollButton-root": {
      width: "20px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      "& svg": {
        color: "#fff",
      },
    },
  },
  search_container: {
    paddingTop: "0",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      color: "#000",
      margin: 0,
    },
    "& .MuiFormLabel-root": {
      color: "#000",
    },
    "& .MuiInputBase-root": {
      color: "#000",
      borderRadius: "30px",
      border: "1px solid #F7616A",
      minWidth: "150px",
      background: "#F2F4FF",
      padding: "5px 10px",
      fontSize: "14px",
      "& svg": {
        color: "#F7616A",
      },
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  sportbetSearchWrap: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flex: "1",
    marginLeft: "20px",
    "& img": {
      position: "absolute",
      left: "15px",
      top: "18px",
      [theme.breakpoints.down("lg")]: {
        top: "8px",
      },
    },
    "& .MuiInputBase-root": {
      paddingLeft: "50px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginRight: "50px",
    },
  },
  search_content_wrap: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: "500",
    },
  },
  sport_banner: {
    // background: "url('/images/sport-book/sports-banner.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    // minHeight: "300px",
    padding: "15px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    aspectRatio: "12/3",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  banner_content: {
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    "& h1": {
      color: "#0f122a",
      fontWeight: "800",
      fontSize: "40px",
      lineHeight: "50px",
      marginBottom: "0",
      "& span": {
        color: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "35px",
      },
    },
    "& p": {
      color: "#fff",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "20px",
      },
    },
  },
  sports_heading: {
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
    "& .MuiTypography-root": {
      color: "#000",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },

  sports_card: {
    background: "url('/images/sport-book/sports-card-bg.png'), #1C2251",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "80% 0%",
    minHeight: "158px",
    padding: "15px",
    borderRadius: "14px",
    color: "#fff",
    margin: "0 10px",
    maxWidth: "232px",
  },
  sport_status_icon: {},
  sports_card_header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  sports_header_left: {
    fontSize: "11px",
    display: "flex",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "60px",
    },
  },
  sports_header_right: {
    fontSize: "10px",
    display: "flex",
    "& img": {
      marginLeft: "5px",
    },
  },
  teamName: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
    "& p": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#fff",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "90%",
    },
  },
  sportsTeamDetails: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
    columnGap: "4px",
  },
  // oddsWrap: {
  //   background:
  //     "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
  //   padding: "1px",
  //   borderRadius: "9px",
  //   position: "relative",
  //   overflow: "hidden",
  // },
  matchDetailsCard: {
    background: "#1c2251",
    borderRadius: "9px",
    border: "1px solid #F7616A",
    overflow: "hidden",
    "& ul": {
      display: "flex",
      padding: "0",
      alignItems: "center",
      margin: "0",
      "& li": {
        flexFlow: "row",
        display: "flex",
        justifyContent: "space-between",
        // minWidth: "110px",
        cursor: "pointer",
        // padding:"12px 10px",
        "& .MuiButtonBase-root": {
          padding: "5px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#fff",
          flexFlow: "column",
          fontWeight: "600",
          "&:hover": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
            color: "#fff",
          },
        },
        "& h5": {
          margin: "0",
          fontSize: "",
        },
        "&:hover": {
          background:
            "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        },
      },
    },
  },
  sportsIconCard: {
    height: "60px",
    width: "60px",
    background: "#1b214f",
    borderRadius: "100%",
    minWidth: "60px",
    margin: "0 5px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "40px",
      width: "40px",
      minWidth: "40px",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "20px",
      },
      width: "30px",
      margin: "0 auto",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "&.clicked": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  locationCard: {
    marginRight: "10px",
    minWidth: "150px",
    background: "#1b214f",
    borderRadius: "45px",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "&.clicked": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "& img": {
      marginRight: "5px",
    },
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
  tableMatchName: {
    maxWidth: "24vw",
    color: "#0D2867",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "10px",
    marginTop: "0px",
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      maxWidth: "100%",
      marginTop: "0",
    },
  },
  tableMatchDetails: {
    display: "flex",
    alignItems: "center",
  },
  matchStatus: {
    minWidth: "4rem",
    marginRight: "0.5rem",
    color: "#000",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "& .time": {
      fontWeight: "300",
      textAlign: "left",
    },
    "& img": {
      width: "25px",
      marginRight: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  tableTeamDetails: {
    display: "flex",
    flexDirection: "column",
    rowGap: "0.5rem",
    marginLeft: "10px",
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      marginBottom: "0px",
      color: "#000",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "350px",
      flex: "1",
      textAlign: "left",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        marginBottom: "0",
      },
    },
  },
  marketNameWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  marketName: {
    color: "#000",
    fontWeight: "500",
    fontSize: "14px",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "12px",
      margin: "5px 0",
    },
  },
  oddsWra: {
    minWidth: "100px",
    padding: "1px",
    borderRadius: "9px",
    position: "relative",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    "&:after": {
      position: "absolute",
      right: "-15px",
      top: "0",
      content: "",
      width: "1px",
      height: "100%",
      background: "#485997",
    },
  },
  // marketBtn:{
  //   background: "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
  //   padding: "1px",
  //   borderRadius: "9px",
  //   position: "relative",
  //   color:"#fff",
  //   fontSize:"16px",
  //   "& span" :{
  //     background:"#0f122a",
  //   }
  // },

  matchDetailsCardOdds: {
    "& .OverUnder": {
      maxWidth: "100px",
      minWidth: "100px !important",
      flexDirection: "column",
    },
    background: "#FFF",
    borderRadius: "9px",
    border: "2px solid #BCC3DB",
    overflow: "hidden",
    "& ul": {
      display: "flex",
      padding: "0",
      alignItems: "center",
      margin: "0",
      justifyContent: "space-between",
      "& li": {
        flexFlow: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        width: "100%",
        borderRight: "2px solid #BCC3DB",
        height: "47px",
        // minHeight: "3rem",
        // height: "3rem",
        // [theme.breakpoints.down("md")]: {
        //   minWidth: "110px",
        // },
        "& .MuiButtonBase-root": {
          width: "100%",
          height: "100%",
          padding: "5px 10px",
          gap: "5px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000",
          flexFlow: "row",
          fontWeight: "600",
          justifyContent: "space-between",
          borderRadius: "0",
          flexDirection: "column",
          "& .MuiGrid-root": {
            minHeight: "100%",
            justifyContent: "center",
            display: "flex",
            minWidth: "100%",
            alignItems: "center",
          },
          "&:hover": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
            color: "#fff",
            [theme.breakpoints.down("md")]: {
              background: "transparent",
              color: "#000",
            },
          },
          "&.active": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%) !important",
            color: "#fff",
            "& span": {
              color: "#fff",
            },
            "& h5": {
              color: "#fff",
            },
          },
          [theme.breakpoints.down("lg")]: {
            padding: "5px 10px",
          },
        },
        "& h5": {
          margin: "0",
          color: "#000",
          fontSize: "12px",
          fontWeight: "600",
        },
        "& span": {
          textWrap: "nowrap",
          textTransform: "capitalize",
          color: "#000",
          fontWeight: "normal",
          [theme.breakpoints.down("md")]: {
            fontSize: "10px",
          },
        },
        "&:hover": {
          background:
            "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
          [theme.breakpoints.down("md")]: {
            background: "transparent",
            color: "#000",
          },
          "& h5": {
            margin: "0",
            color: "#fff",
            [theme.breakpoints.down("md")]: {
              color: "#000",
            },
          },
          "& span": {
            color: "#fff",
            [theme.breakpoints.down("md")]: {
              color: "#000",
            },
          },
        },
        "&:last-child": {
          borderRight: "none",
        },
        // [theme.breakpoints.down("xl")]: {
        //   minWidth: "110px",
        // },
        // [theme.breakpoints.down("md")]: {
        //   minWidth: "100px",
        // },
      },
    },
  },
  marketBtnWrap: {
    border: "2px solid #BCC3DB",
    borderRadius: "9px",
    color: "#fff",
    position: "relative",
    top: "10px",
    "& .MuiButtonBase-root": {
      width: "100%",
      color: "#000",
      fontSize: "12px",
      padding: "0 10px",
      flexDirection: "column",
      minHeight: "47px",
      borderRadius: "8px",
      fontWeight: "normal",
      position: "relative",
      "& span": {
        position: "relative",
      },
      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        color: "#fff",
      },
    },
  },
  SportsTableWrap: {
    "& .MuiTable-root": {
      background: "#fff",
      boxShadow: "none",
      "& .MuiTableCell-root": {
        borderColor: "#ccc",
        [theme.breakpoints.down("xl")]: {
          padding: "20px 5px",
        },
      },
    },
    "& .MuiTableRow-root": {
      "& .MuiTableCell-root": {
        textAlign: "center",
        display: "table-cell",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    "& .MuiTableContainer-root": {
      [theme.breakpoints.down("md")]: {
        overflowX: "unset",
      },
    },
  },
  favIconMob: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  mobtableCell: {
    [theme.breakpoints.down("md")]: {
      display: "block !important",
      padding: "10px 5px 20px",
    },
  },
  SportsTableHeader: {
    borderRadius: "10px 10px 0px 0px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "0px",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "10px",
      color: "#fff",
    },
    "& img": {
      width: "25px",
    },
  },
  favIcon: {
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  tableMatchMob: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  tableMatchDetailsLeft: {
    display: "flex",
    alignItems: "center",
  },
  tableMatchDetailsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sport_card_container: {
    minHeight: "250px",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
    "& .horizontalScrollWrapper": {
      width: "100% !important",
      [theme.breakpoints.down("md")]: {
        paddingRight: "0",
      },
    },
    "& .react-horizontal-scrolling-menu--arrow-right": {
      right: "0px",
      top: "0",
      height: "30px",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        height: "30px",
      },
      "& .MuiButtonBase-root": {
        background: "transparent",
        "& .MuiSvgIcon-root": {
          fill: "#F7616A",
        },
      },
    },
    "& .react-horizontal-scrolling-menu--scroll-container": {
      whiteSpace: "nowrap",
      padding: "30px 0 0",
    },
    "& .react-horizontal-scrolling-menu--arrow-left": {
      right: "40px",
      left: "auto",
      height: "30px",
      top: "0",
      cursor: "pointer",
      zIndex: "5",
      [theme.breakpoints.down("md")]: {
        height: "30px",
        right: "40px",
      },
      "& .MuiButtonBase-root": {
        background: "transparent",
        "& .MuiSvgIcon-root": {
          fill: "#F7616A",
        },
      },
    },
  },
  betslip_open_btn: {
    position: "fixed",
    right: "20px",
    bottom: "5px",
    zIndex: "2",
    // width: "4rem",
    height: "2.5rem",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    "&:hover::before": {
      // eslint-disable-next-line quotes
      content: '"BetSlip"',
    },
    "&:hover": {
      // width: "11rem",
    },
    "& img": {
      // padding: "0.5rem",
      width: "30px",
    },
  },
  betslip_dailog_content: {
    background: "#fff",
  },

  noCountries: {
    textAlign: "center",
    display: "inline-block",
    width: "100%",
    marginBottom: "20px",
  },

  loadMoreBtn: {
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },

  lockIcon: {
    color: "gray",
    fontSize: "14px",
    cursor: "not-allowed",
  },
  wrapper: {
    marginTop: "103px",
  },

  [theme.breakpoints.down(1199)]: {
    marketHideOU: {
      display: "table-cell !important",
    },
    marketHideDoubleChange: {
      display: "none !important",
    },
  },

  [theme.breakpoints.down(1024)]: {
    marketHideOU: {
      display: "none !important",
    },
    marketHideDoubleChange: {
      display: "none !important",
    },
  },
}));
