import React, { useCallback } from "react";

import { Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import useStyles from "./Search.styles";
import {
  getSearchedGamesStart,
  resetSearchGames,
} from "../../../../../redux-saga/redux/casino/lobby/casinoGames";

const SearchField = ({
  searchInput,
  offset,
  setSearch,
  makeProviderCategoryFilter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChangeSearch = useCallback(
    (event) => {
      offset.current = 1;
      const searchValue = event.target.value;
      setSearch(searchValue);
      const providerCategoryFilter = makeProviderCategoryFilter();
      if (
        !providerCategoryFilter.providerId &&
        !providerCategoryFilter.categoryId &&
        !searchValue?.length
      ) {
        dispatch(resetSearchGames());
        return;
      }
      dispatch(
        getSearchedGamesStart({
          page: 1,
          limit: 12,
          search: (searchValue && searchValue.trim()) || null,
          ...providerCategoryFilter,
        })
      );
    },
    [dispatch, makeProviderCategoryFilter, offset, setSearch]
  );

  return (
    <Grid className={classes.headerSearch}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={t("COMMON.SEARCH_ALL_GAMES")}
        onChange={handleChangeSearch}
        value={searchInput}
      />
      <img src="/images/search-icon.svg" alt="Search" />
      {/* <TextField
        type="search"
        value={searchInput}
        placeholder="Search all games"
        onChange={handleChangeSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}
    </Grid>
  );
};

export default SearchField;
