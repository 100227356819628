import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  myBetMarket: {
    textTransform: "capitalize",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    color: "#879096",
    padding: "5px 0",
    "& span": {
      fontSize: "11px",
      fontWeight: "600",
    },
    "& .MuiTypography-body1": {
      color: "#fff",
      fontSize: "12px",
      fontWeight: "600",
      letterSpacing: "0.7px",
    },
  },
  loader: {
    marginLeft: "0.5rem",
  },
  liveStatus: {
    color: "#00a973",
    padding: "0px 10px",
    fontSize: "13px",
    fontWeight: "600",
  },
  preLiveStatus: {
    padding: "0px 10px",
    color: "#fdb739",
    fontSize: "13px",
    fontWeight: "600",
  },
  mybets_container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  multibet_container: {
    maxHeight: "31rem",
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    color: "#000",
    "& .MuiInputBase-root": {
      border: "1px solid red",
      color: "black",
      height: "2.5rem",
      width: "11rem",
      [theme.breakpoints.down("sm")]: {
        width: "9.3rem",
      },
    },
    "& .MuiButtonBase-root": {
      color: "red",
    },
  },

  betstatus_btn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.down(635)]: {
      justifyContent: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.down("lg")]: {
      rowGap: "1rem",
      marginTop: "1rem",
    },
  },

  betstatus_btn_container_left: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(635)]: {
      overflowX: "scroll",
      flexWrap: "nowrap",
      maxWidth: "100%",
    },
    "& .MuiButtonBase-root": {
      borderRadius: "30px",
      fontSize: "12px",
      padding: "8px 20px",
      marginRight: "10px",
      background: "#0D2867",
      minWidth: "140px",
      textTransform: "capitalize",
      [theme.breakpoints.down(635)]: {
        margin: "10px 10px 10px 0",
      },
      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      },
      "&.activeButton": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  stakeInputWrap: {
    width: "100%",
  },
  myBetsWrap: {
    width: "100%",
    height: "100%",
  },

  loadMoreBtn: {
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },

  noBets: {
    height: "72vh",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  myBetCard: {
    background: "#1b214f",
    borderRadius: "16px",
    padding: "15px 20px",
    margin: "0 10px",
    // marginBottom:"20px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
    },
  },
  mybetHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mybetHeaderLeft: {
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#879096",
      fontSize: "12px",
    },
  },
  betType: {
    color: "#fff !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginRight: "20px",
    display: "inline-block",
    letterSpacing: "0.5px",
  },
  betStatus: {
    fontWeight: "600",
    fontSize: "12px",
    "&.pending": {
      color: "#FFF",
      letterSpacing: "0.5px",
    },
    "&.won": {
      color: "#05ff00",
      letterSpacing: "0.5px",
    },
    "&.lost": {
      color: "#FF0000",
      letterSpacing: "0.5px",
    },
  },
  mybetMatchCard: {
    background: "#15193c",
    borderRadius: "10px",
    padding: "10px",
    margin: "2px 5px 2px",
    position: "relative",
    minHeight: "7.3rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
    "& .MuiButton-root": {
      top: "12px",
      position: "absolute",
      right: 0,
      padding: "0px 8px",
      textTransform: "capitalize",
    },
  },
  tableMatchHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#879096",
    marginBottom: "10px",
    "& span": {
      fontSize: "12px",
      color: "#879096",
      display: "block",
      padding: "0 8px",
    },
  },
  tableMatchHeaderLeft: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: "12px",
      color: "#879096",
      display: "block",
      padding: "0 8px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 5px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "70px",
        maxHeight: "20px",
      },
    },
    "& img": {
      height: "16px",
      width: "16px",
    },
  },
  tableMatchTeamsWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    "& img": {
      width: "25px",
      marginRight: "5px",
    },
    "& .MuiTypography-body1": {
      color: "#fff",
      fontSize: "11px",
      fontWeight: "600",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100px",
      letterSpacing: "0.5px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "130px",
      },
      [theme.breakpoints.down(390)]: {
        maxWidth: "73px",
      },
    },
    "& span": {
      display: "block",
      padding: "0 20px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "400",
      [theme.breakpoints.down("sm")]: {
        padding: "0 10px",
      },
    },
  },
  myBetTotal: {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#879096",
    padding: "5px 0",
    "& span": {
      fontSize: "11px",
      fontWeight: "600",
    },
    "& .MuiTypography-body1": {
      color: "#fff",
      fontSize: "12px",
      fontWeight: "600",
      letterSpacing: "0.7px",
    },
  },

  tableMatchLeft: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  tableMatchRight: {
    display: "flex",
    alignItems: "center",
    // position: "absolute",
    // top: 0,
    // bottom: 0,
    // right: 0,
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  myBetTicketNumber: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "5px",
    "& span": {
      fontSize: "11px",
      color: "#879096",

      fontWeight: "600",
    },
    "& img": {
      marginLeft: "5px",
    },
  },
  teamPositionCount: {
    fontSize: "63px",
    color: "#1B214F",
    position: "absolute",
    right: "40px",
    fontWeight: "600",
  },
  cashoutBtn: {
    margin: "10px 0",
  },
  btnPrimary: {
    borderRadius: "30px",
    padding: "2px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    width: "100%",

    "&:hover": {
      background:
        "linear-gradient(273deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    textTransform: "capitalize",
  },
  customDatePicker: {
    alignItems: "center",
    gap: "8px",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "& .MuiInputBase-root": {
      border: "1px solid #F7616A",
      borderRadius: "30px",
      color: "#0D2867",
      "& .MuiInputBase-input": {
        fontSize: "14px",
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
          padding: "5px 10px",
          fontSize: "13px",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "#0D2867",
      },
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  datepickerStyle: {
    display: "flex",
    columnGap: "0.5rem",
    [theme.breakpoints.down(635)]: {
      justifyContent: "space-between",
      paddingRight: "1rem",
    },
  },

  mybetModal: {
    background: "#15193c",
  },
  confirmButton: {
    textTransform: "capitalize",
    borderRadius: "30px",
    padding: "8px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    width: "100%",

    "&:hover": {
      background:
        "linear-gradient(273deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  cancelButton: {
    borderRadius: "30px",
    padding: "8px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    width: "100%",

    "&:hover": {
      background:
        "linear-gradient(273deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    textTransform: "capitalize",
  },
}));
