import React from "react";

import { Button, Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";

import useStyles from "../Lobby/Lobby.styles";
import FavoriteButton from "../Lobby/FavoriteButton/FavoriteButton";

import "react-lazy-load-image-component/src/effects/blur.css";

const Game = ({
  thumbnailUrl,
  name,
  gameId,
  filterBy = null,
  filterName = null,
  isViewAll,
  providerName = null,
  categoryName = null,
  isFavorite = null,
  gameTag = null,
}) => {
  // console.log("isFavorite", isFavorite);
  const { metamaskLoggedIn, isLoggedIn } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();
  const classes = useStyles();

  const handleGameLaunch = () => {
    if (!(metamaskLoggedIn || isLoggedIn)) {
      toast.error("Please Login to Continue");
      return;
    }
    navigate(`/game/${gameId}`);
  };
  return (
    <Grid
      item
      // xs={2}
      className={classes.sliderGame}
      style={{
        width: "180px",
        // height: "300px",
        marginRight: "8px",
        marginBottom: "10px",
      }}
    >
      <Grid
        className={classes.casinoPlayCard}
        onClick={(e) => handleGameLaunch()}
      >
        <Button
          className={isViewAll ? classes.casinoCardViewAll : classes.casinoCard}
        >
          <LazyLoadImage
            alt={"Casino Card"}
            effect="black-and-white"
            src={thumbnailUrl || "/images/casino-img.png"}
            placeholderSrc={"/images/casino-img.png"}
          />

          <Box className={classes.playIcon}>
            <div>
              <img src="/images/play-icon.png" alt="ply icon" />
            </div>
            <p>Play Now</p>
          </Box>
          {
            <FavoriteButton
              isFavorite={isFavorite ? true : false}
              favBtnClass={classes.favIconWrap}
              gameId={gameId}
              isViewAll={isViewAll}
            />
          }
        </Button>
      </Grid>

      <Grid className={classes.casinoOverlay}>
        <Typography ml={1}>
          {name?.length > 16 ? `${name?.slice(0, 14)}...` : name || "Game"}
        </Typography>
        <Link className={classes.infoBtn} to="#">
          <img src="/images/info.png" alt="Info Icon" />
          {/* <Grid className={classes.customInfoModal}>
            <Grid className={classes.customInfoCard}>
              <span>Game Name</span>
              <Typography>Hot Road Racer </Typography>
            </Grid>
          </Grid> */}
        </Link>
        <Grid className={classes.customInfoModal}>
          <Grid className={classes.customInfoCard}>
            <span>Name</span>
            <Typography>{name}</Typography>
          </Grid>
          {filterBy && filterName && (
            <Grid className={classes.customInfoCard}>
              <span>{filterBy || ""}</span>
              <Typography>{filterName || ""}</Typography>
            </Grid>
          )}
          {providerName && (
            <Grid className={classes.customInfoCard}>
              <span>Provider</span>
              <Typography>{providerName}</Typography>
            </Grid>
          )}
          {categoryName && (
            <Grid className={classes.customInfoCard}>
              <span>Category</span>
              <Typography>{categoryName}</Typography>
            </Grid>
          )}
          {/* {isMobile && (
            <FavoriteButton
              classes={classes.favIconWrap}
              isFavorite={isFavorite ? true : false}
              gameId={gameId}
              isViewAll={isViewAll}
            />
          )} */}

          {/* <Grid className={classes.customInfoCard}>
              <span>Game Name</span>
              <Typography>Hot Road Racer </Typography>
            </Grid>
            <Grid className={classes.customInfoCard}>
              <span>Game Name</span>
              <Typography>Hot Road Racer </Typography>
            </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Game;
