import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  skeleton: {
    backgroundColor: "#1C2251",
    marginRight: "10px",
    borderRadius: "45px",
    padding: "10px",
    minWidth: "150px",
    background: "#1b214f",
    maxHeight: "60px",
  },
}));
