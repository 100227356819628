import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";

import {
  ClearAllBat,
  placeBetStart,
} from "../../../../sport-book-saga/redux/betslip";
import useStyle from "./betslip.style";
import {
  removeBet,
  updateStake,
} from "../../../../sport-book-saga/redux/betslip";
import OddAnimation from "../OddAnimation";
import { quickBetOptions } from "../../Utils/common";

const BetSlip = (props) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { handleClose } = props;

  const bets = useSelector((state) => state.sportbook.betslip.bets);
  const isTouched = useSelector((state) => state.sportbook.betslip.isTouched);
  const loading = useSelector((state) => state.sportbook.betslip.loading);
  const { walletAmount } = useSelector((state) => state.myProfile);
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { maxWinAmount, maxBetAmount, minBetAmount } = useSelector(
    (state) => state.sportbook.betslip.betlimit
  );
  let stake = useSelector((state) => state.sportbook.betslip.stake);
  let totalOdds =
    bets?.length > 0
      ? bets?.reduce((accumalate, current) => {
          return accumalate * current.odds;
        }, 1)
      : 0;
  stake = parseFloat(stake);
  const possibleWinAmount = useSelector(
    (state) => state.sportbook.betslip.possibleWinAmount
  );
  const limit = useSelector((state) => state.sportbook.betslip.limit);
  const dispatch = useDispatch();
  const [isFastbet, setIsFastbet] = React.useState(true);
  const [login, setLogin] = useState(false);
  const [localStake, setLocalStake] = useState("");

  const fastBetHandler = (event) => {
    setIsFastbet(event.target.checked);
  };

  const handelPlaceBetslip = () => {
    if (isLoggedIn) {
      const betsDataFormatted = bets.map((bet) => {
        const betData = {
          ...bet,
        };
        return betData;
      });

      const possibleWinAmountFixed = possibleWinAmount;
      dispatch(
        placeBetStart({
          bettype: betsDataFormatted?.length > 1 ? 2 : 1,
          isFastBet: isFastbet,
          stake,
          possibleWinAmount: possibleWinAmountFixed,
          bets: betsDataFormatted,
        })
      );
    } else {
      setLogin(true);
    }
  };

  useEffect(() => {
    if (stake > 0) {
      setLocalStake(stake);
    }
  }, [stake]);

  return (
    <Grid>
      <Grid className={classes.betslip_container}>
        <Grid className={classes.betslipHeader}>
          <img src="/images/betslip-header.png" alt="Betslip" />
          <Grid className={classes.betslipHeaderContent}>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={isFastbet}
                  onChange={fastBetHandler}
                  aria-label="Fast-Bet"
                />
              }
              label={t("BET_SLIP.FAST_BET")}
              labelPlacement="start"
            />

            <Grid
              className={classes.betslipArrow}
              onClick={() => {
                handleClose();
              }}
            >
              <img src="/images/bet-down-arrow.png" alt="Betslip Arrow" />
            </Grid>
            <Typography
              className={classes.betslipClearbets}
              onClick={() => {
                localStorage.removeItem("state");
                dispatch(ClearAllBat());
                handleClose();
              }}
            >
              {t("BET_SLIP.CLEAR_ALL_BET")}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.betslipContentWrap}>
          <Grid className={classes.betscroll_wrap}>
            <Grid className={classes.betslip_listing}>
              {bets && bets?.length
                ? bets.map((bet) => (
                    <Grid
                      className={`${
                        bet?.isSettled
                          ? classes.bet_card_disable
                          : classes.bet_card
                      }`}
                      key={bet?.providerOutcomeId + bet.matchMarketId}
                    >
                      <Grid lg={12} className={classes.bet_card_content}>
                        <Grid lg={12} className={classes.league_name}>
                          <Typography variant="body2" gutterBottom>
                            {bet?.tournament}
                          </Typography>
                          <Button
                            onClick={() =>
                              dispatch(
                                removeBet({
                                  matchMarketId: bet.matchMarketId,
                                  providerOutcomeId: bet.providerOutcomeId,
                                  isLive: bet.isLive,
                                  specialBetValue: bet?.specialBetValue,
                                  variant: bet?.variant,
                                  matchId: bet?.matchId,
                                })
                              )
                            }
                            justifyContent={"flex-end"}
                            paddingRight={"0"}
                          >
                            <img
                              src="/images/sport-book/trash.svg"
                              alt="trash"
                              style={{ width: "15px" }}
                            />
                          </Button>
                        </Grid>
                        <Grid lg={12} className={classes.bet_team_name}>
                          <Typography>{bet.homeTeam}</Typography>
                          <span>v/s</span>
                          <Typography>{bet.awayTeam}</Typography>
                        </Grid>
                        <Grid
                          lg={12}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            className={classes.bet_odd}
                            display={"flex"}
                          >
                            {bet?.market}
                            <Grid ml={"5px"}>{bet?.outcomeType}</Grid>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            className={classes.bet_odd}
                          >
                            {bet?.isSettled ||
                            bet?.active == false ||
                            `${bet?.betStatus}` !== "1" ? (
                              <LockIcon />
                            ) : (
                              <OddAnimation
                                odd={bet?.odds}
                                isSocketUpdated={true}
                                style={classes.animation}
                              />
                            )}
                          </Typography>
                        </Grid>
                        {bet?.NotBetPlaced && (
                          <Alert severity="error">{bet?.NotBetPlaced}</Alert>
                        )}
                      </Grid>
                    </Grid>
                  ))
                : t("BET_SLIP.NO_ODDS_SELECTED")}
            </Grid>
          </Grid>
          <Grid mt={2}>
            {isTouched && stake < 0 && (
              <Alert className={classes.notLoggedIn} severity="error">
                {t("BET_SLIP.STACK_VALUE_MSG")}
              </Alert>
            )}

            {isLoggedIn && isTouched && stake > walletAmount && (
              <Alert className={classes.notLoggedIn} severity="error">
                {t("BET_SLIP.INSIFFICIENT_FOUND_MSG")}
              </Alert>
            )}

            {login && (
              <Alert className={classes.notLoggedIn} severity="error">
                {t("BET_SLIP.NOT_LOGED_IN_MSG")}
              </Alert>
            )}

            {isTouched && limit && (
              <Alert className={classes.notLoggedIn} severity="error">
                {limit}
              </Alert>
            )}

            {isLoggedIn && isTouched && stake > maxBetAmount && (
              <Alert className={classes.notLoggedIn} severity="error">
                {t("BET_SLIP.MAX_BET_AMOUNT_LIMIT_EXCEED")}
              </Alert>
            )}

            {isLoggedIn && isTouched && stake < minBetAmount && (
              <Alert className={classes.notLoggedIn} severity="error">
                {t("BET_SLIP.MIN_BET_AMOUNT_SHOULD_BE")}{" "}
                {parseFloat(minBetAmount).toFixed(2)}
              </Alert>
            )}

            {isLoggedIn && isTouched && possibleWinAmount > maxWinAmount && (
              <Alert severity="warning" className={classes.warning}>
                {t("BET_SLIP.MAX_WIN_AMOUNT_LIMIT_EXCEED")}
              </Alert>
            )}

            <Divider />
          </Grid>
          <Grid className={classes.betslip_bottom_container}>
            <Grid container className={classes.betslip_bottom}>
              {/* <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                max
              >
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Bet Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={0}
                    variant="outlined"
                    className={classes.selectTab}
                    onChange={() => {}}
                  >
                    <MenuItem value={0}>System Bet</MenuItem>
                    {betType?.length > 0 &&
                      betType.map((item) => (
                        <MenuItem onClick={() => {}} key={item} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid> */}
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                mt={1}
                alignItems={"center"}
              >
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    {t("BET_SLIP.STACK_USD")}
                  </Typography>
                </Grid>
                <Grid item xs={8} position={"relative"}>
                  <Grid className={classes.balance_btn_container}>
                    <Button
                      variant="outline"
                      className={classes.balance_btn}
                      onClick={() => {
                        setLocalStake(parseFloat(stake) - 1);
                        dispatch(updateStake(parseFloat(stake) - 1));
                      }}
                      disabled={stake == 0}
                    >
                      <img src="/images/sport-book/minus.svg" alt="" />
                    </Button>
                    <Button
                      variant="outline"
                      className={classes.balance_btn}
                      onClick={() => {
                        dispatch(updateStake(parseFloat(stake) + 1));
                      }}
                    >
                      <img src="/images/sport-book/plus.svg" alt="" />
                    </Button>
                  </Grid>
                  <TextField
                    className={classes.stakeInputWrap}
                    id="margin-none"
                    value={localStake}
                    onChange={(event) => {
                      setLocalStake(event.target.value);
                      if (event.target.value === "") {
                        dispatch(updateStake(0));
                      }
                      if (parseFloat(event.target.value) >= 0.0)
                        dispatch(updateStake(event.target.value));
                    }}
                    placeholder="0"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid item container className={classes.quick_bet_btn_container}>
                {quickBetOptions.map((incrementByValue, i) => {
                  return (
                    <Grid item key={i}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          dispatch(
                            updateStake(parseFloat(stake) + incrementByValue)
                          );
                        }}
                      >
                        +{parseInt(incrementByValue)}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"space-between"}
                mt={2}
                alignItems={"center"}
              >
                <Typography variant="body2" gutterBottom>
                  {t("BET_SLIP.TOTAL_BET")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {t("BET_SLIP.USD")} {stake || 0}
                </Typography>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body2" gutterBottom>
                  {t("BET_SLIP.TOTAL_ODDS")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`${parseFloat(totalOdds).toFixed(2)}`}
                </Typography>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body2" gutterBottom>
                  {t("BET_SLIP.POSSIBLE_WIN_AMOUNT_MSG")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`$ ${
                    possibleWinAmount > maxWinAmount
                      ? parseFloat(maxWinAmount).toFixed(2)
                      : parseFloat(possibleWinAmount).toFixed(2) || 0
                  }`}
                </Typography>
              </Grid>
              <Grid item lg={12} mt={1} className={classes.place_bet_btn_div}>
                <Button
                  variant="outline"
                  disabled={
                    (isLoggedIn && stake < minBetAmount) ||
                    (isLoggedIn && stake > maxBetAmount) ||
                    (isLoggedIn && stake > walletAmount) ||
                    !bets?.length ||
                    limit ||
                    stake <= 0 ||
                    loading ||
                    (bets &&
                      bets?.length &&
                      bets.filter((bet) => {
                        return (
                          bet?.NotBetPlaced ||
                          bet?.isSettled ||
                          bet?.active == false ||
                          `${bet?.betStatus}` !== "1"
                        );
                      })?.length)
                  }
                  className={classes.place_bet_btn}
                  onClick={handelPlaceBetslip}
                >
                  {t("BET_SLIP.PLACE_BET_BTN")}
                  {loading && (
                    <CircularProgress
                      size="1.5rem"
                      className={classes.place_bet_loader}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BetSlip;
