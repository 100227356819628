import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  selectFav: {
    alignItems: "center",
    "& .MuiInputBase-colorPrimary": {
      width: "100%",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      color: "#fff",
      WebkitTextFillColor: "#fff",
      height: "48px",
      padding: "0 20px",
      marginRight: "5px",
      fontSize: "12px",

      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
      "&.active": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 10px 10px 20px",
      color: "#25262B",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        minWidth: "70px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "none",
    },
  },
  moremarket: {
    marginLeft: "5px",
    textTransform: "capitalize",
  },
  tableMatchName: {
    maxWidth: "24vw",
    color: "#0D2867",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "10px",
    textAlign: "left",
    marginTop: "0px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      maxWidth: "100%",
      marginTop: "0",
    },
  },
  tableMatchDetails: {
    display: "flex",
    alignItems: "center",
  },
  matchStatus: {
    minWidth: "4rem",
    marginRight: "0.5rem",
    color: "#000",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "& .time": {
      fontWeight: "300",
      textAlign: "left",
    },
    "& img": {
      width: "25px",
      marginRight: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  tableTeamDetails: {
    display: "flex",
    flexDirection: "column",
    rowGap: "0.5rem",
    marginLeft: "10px",
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      marginBottom: "0px",
      color: "#000",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "350px",
      flex: "1",
      textAlign: "left",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        marginBottom: "0",
      },
    },
  },
  marketNameWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  marketName: {
    color: "#000",
    fontWeight: "400",
    fontSize: "16px",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "12px",
      margin: "5px 0",
    },
  },
  oddsWra: {
    minWidth: "100px",
    padding: "1px",
    borderRadius: "9px",
    position: "relative",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    "&:after": {
      position: "absolute",
      right: "-15px",
      top: "0",
      content: "",
      width: "1px",
      height: "100%",
      background: "#485997",
    },
  },
  // marketBtn:{
  //   background: "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
  //   padding: "1px",
  //   borderRadius: "9px",
  //   position: "relative",
  //   color:"#fff",
  //   fontSize:"16px",
  //   "& span" :{
  //     background:"#0f122a",
  //   }
  // },

  matchDetailsCardOdds: {
    background: "#FFF",
    borderRadius: "9px",
    border: "2px solid #BCC3DB",
    overflow: "hidden",
    "& ul": {
      display: "flex",
      padding: "0",
      alignItems: "center",
      margin: "0",
      justifyContent: "space-between",
      "& li": {
        flexFlow: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        width: "100%",
        borderRight: "2px solid #BCC3DB",
        height: "47px",
        // minWidth: "80px",
        // maxWidth: "80px",
        // [theme.breakpoints.down("lg")]: {
        //   minWidth: "inherit",
        //   maxWidth: "inherit",
        // },
        // minHeight: "3rem",
        // height: "3rem",
        // [theme.breakpoints.down("md")]: {
        //   minWidth: "110px",
        // },
        "& .MuiButtonBase-root": {
          width: "100%",
          height: "100%",
          padding: "5px 10px",
          gap: "5px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000",
          flexFlow: "row",
          fontWeight: "600",
          justifyContent: "space-between",
          borderRadius: "0",
          flexDirection: "column",
          "& .MuiGrid-root": {
            minHeight: "100%",
            justifyContent: "center",
            display: "flex",
            minWidth: "100%",
            alignItems: "center",
          },
          "&:hover": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
            color: "#fff",
          },
          "&.active": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
            color: "#fff",
            "& span": {
              color: "#fff",
            },
            "& h5": {
              color: "#fff",
            },
          },
          [theme.breakpoints.down("lg")]: {
            padding: "5px 5px",
          },
        },
        "& h5": {
          margin: "0",
          color: "#000",
          fontSize: "14px",
        },
        "& span": {
          textTransform: "capitalize",
          color: "#000",
          fontWeight: "normal",
          [theme.breakpoints.down("md")]: {
            fontSize: "10px",
          },
        },
        "&:hover": {
          background:
            "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
          "& h5": {
            margin: "0",
            color: "#fff",
          },
          "& span": {
            color: "#fff",
          },
        },
        "&:last-child": {
          borderRight: "none",
        },
        // [theme.breakpoints.down("xl")]: {
        //   minWidth: "110px",
        // },
        // [theme.breakpoints.down("md")]: {
        //   minWidth: "100px",
        // },
      },
    },
  },
  marketBtnWrap: {
    border: "2px solid #BCC3DB",
    borderRadius: "9px",
    color: "#fff",
    position: "relative",
    top: "18px",
    "& .MuiButtonBase-root": {
      width: "100%",
      color: "#000",
      fontSize: "12px",
      padding: "0 10px",
      flexDirection: "column",
      minHeight: "47px",
      borderRadius: "8px",
      fontWeight: "normal",
      "& span": {
        position: "relative",
      },
      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        color: "#fff",
      },
    },
  },
  SportsTableWrap: {
    "& .MuiTable-root": {
      background: "#fff",
      boxShadow: "none",
      "& .MuiTableCell-root": {
        borderColor: "#ccc",
        [theme.breakpoints.down("xl")]: {
          padding: "20px 5px",
        },
      },
    },
    "& .MuiTableRow-root": {
      "& .MuiTableCell-root": {
        textAlign: "center",
        display: "table-cell",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    "& .MuiTableContainer-root": {
      [theme.breakpoints.down("md")]: {
        overflowX: "unset",
      },
    },
  },
  favIconMob: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  mobtableCell: {
    [theme.breakpoints.down("md")]: {
      display: "block !important",
      padding: "10px 5px 20px",
    },
  },
  SportsTableHeader: {
    borderRadius: "10px 10px 0px 0px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "0px",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "10px",
      color: "#fff",
    },
    "& img": {
      width: "25px",
    },
  },
  favIcon: {
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  tableMatchMob: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  tableMatchDetailsLeft: {
    display: "flex",
    alignItems: "center",
  },
  tableMatchDetailsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
