import React, { useEffect } from "react";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ForgotForm from "./ForgotForm";
import useStyles from "./ForgotPassword.styles";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const { isLoggedIn } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (isLoggedIn) {
      toast.error(t("HEADER.USER_LOGGED_IN"));
      navigate("/");
    }
    if (id === null || token === null || !id || !token) {
      navigate("/");
    }
  }, [isLoggedIn, navigate, id, token]);

  return (
    <div className={classes.forgotPassword}>
      {!isLoggedIn && <ForgotForm id={id} token={token} />}
    </div>
  );
};

export default ForgotPassword;
