import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  sidebarMenu: {
    display: "inline-block",
    width: "70px",
    background: "#0D2867",
    position: "fixed",
    top: "101px",
    zIndex: "9",
    overflow: "hidden",
    transition: "width 0.5s",
    "&.active": {
      textDecoration: "none",
      background:
        "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
    },
    "&:hover": {
      width: "200px",
      "& span": {
        display: "block",
        whiteSpace: "nowrap",
        paddingLeft: "10px",
      },
    },
    borderRadius: "0px 10px 10px 0px",
    "& ul": {
      padding: "0",
      margin: "0",
      "& li": {
        margin: "0",
        padding: "0",
        lineHeight: "55px",
        minHeight: "55px",
        "& a": {
          // fontWeight: "500",
          fontSize: "14px",
          // lineHeight: "66px",
          color: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          paddingRight: "10px",
          paddingLeft: "20px",
          textDecoration: "none",
          position: "relative",
          minHeight: "55px",
          textTransform: "capitalize",

          "&:hover": {
            textDecoration: "none",
            background:
              "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
            "& p": {
              width: "4px",
              height: "37px",
              clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
              background: "#fff",
              display: "inline-block",
            },
          },
          "& span": {
            display: "none",
          },
          "& p": {
            position: "absolute",
            left: "0",
            width: "4px",
            height: "37px",
            display: "inline-block",
          },
          "& img": {
            width: "25px",
            marginRight: "15px",
          },
        },
        "& span": {
          display: "none",
        },
        "& p": {
          position: "absolute",
          left: "0",
          width: "4px",
          height: "37px",
          display: "inline-block",
        },
        "& img": {
          width: "25px",
          marginRight: "0.5rem",
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  mybetAccordian: {
    padding: "0px",
    margin: "0px !important",
    background: "#002965",
    position: "relative",
    minHeight: "inherit !important",
    "& .MuiAccordionSummary-content": {
      padding: "0",
      margin: "0 !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      position: "absolute",
      right: "3px",
    },
  },

  accordianDetail: {
    padding: "0",
    marginLeft: "50px",
  },
  accordianWrapper: {
    border: "0",
    boxShadow: "inherit",
    width: "100%",
    // paddingRight: "10px",
    background: "transparent",
  },
  mybets: {
    display: "flex",
    padding: "0 20px",
    alignItems: "center",
    columnGap: "0.5rem",
  },

  bg_salmon: {
    background:
      "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
  },
}));
