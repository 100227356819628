import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Grid,
  DialogContent,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isMobile } from "react-device-detect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Modal from "../../hoc/modal/Modal";
import Login from "./Login";
import SignUp from "./SignUp";
import useStyles from "./Header.styles";
import session from "../../utils/session";
import {
  logoutStart,
  refreshLoginStart,
  // refreshMetamaskLoginStart,
  resetSuccess,
} from "../../redux-saga/redux/auth/authentication";
import { setActive } from "../../redux-saga/redux/settings/index";
import headerMenu from "./HeaderMenu.list.json";
import Wallet from "./Wallet";
import MobileSidebar from "./MobileSideBar";
import {
  casinoPaths,
  sportBookPaths,
  blogPaths,
} from "../../container/SportsBook/Utils/common";
import {
  getUserProfileDetailsStart,
  startPoll,
} from "../../redux-saga/redux/profile/myProfile";
import Languages from "./Languages.list.json";
import i18n from "../../i18n";
import { changeLanguageBE } from "../../sport-book-saga/redux/language";

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { orientation, active } = useSelector((state) => state.settings);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const offset = useRef(0);

  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const affiliate = searchParams.get("affiliate");

  const {
    loading,
    metamaskLoading,
    isLoggedIn,
    metamaskConnected,
    metamaskLoggedIn,
    success,
    signup,
    metamaskSuccess,
  } = useSelector((state) => state.authentication);
  const { userDetails } = useSelector((state) => state.myProfile);
  const { nonCashAmount, walletAmount, walletLoading } = useSelector(
    (state) => state.myProfile
  );
  const openAccount = Boolean(anchorElAccount);
  const openLanguage = Boolean(anchorElLanguage);

  const handleOpenLogin = () => {
    setOpenLogin((prevState) => !prevState);
  };

  const handleOpenSignUp = () => {
    setOpenSignUp((prevState) => !prevState);
  };
  const handleOpenAccount = (event) => {
    if (isMobile) setIsActive(false);
    setAnchorElAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseLanguage = (event, language) => {
    i18n.changeLanguage(language);
    dispatch(changeLanguageBE(language));
    setAnchorElLanguage(null);
  };

  const handleLogout = () => {
    setAnchorElAccount(null);
    dispatch(logoutStart());
  };

  useEffect(() => {
    if (affiliate) handleOpenSignUp();
  }, [affiliate]);

  useEffect(() => {
    if (isLoggedIn && success) {
      setOpenLogin(false);
      dispatch(resetSuccess());
    }

    if (metamaskLoggedIn && metamaskSuccess) {
      setOpenLogin(false);
      dispatch(resetSuccess());
    }

    if (success && !isLoggedIn && !metamaskLoggedIn) {
      dispatch(resetSuccess());
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
    if (signup && success) {
      setOpenSignUp(false);
      dispatch(resetSuccess());
    }
  }, [
    dispatch,
    isLoggedIn,
    success,
    signup,
    setOpenLogin,
    loading,
    metamaskLoading,
    metamaskConnected,
    t,
    navigate,
    metamaskLoggedIn,
    metamaskSuccess,
  ]);
  useEffect(() => {
    dispatch(setActive(location.pathname));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (offset.current !== 1) {
      if (session.getSessionCred) {
        const user = session.getSessionCred;
        if (user?.userId) {
          dispatch(refreshLoginStart());
        }
      }
      offset.current = 1;
    }
  }, [dispatch]);
  const handleClick = (event) => {
    setIsActive(!isActive);
    event.preventDefault();
  };

  useEffect(() => {
    if (isMobile) {
      if (isLoggedIn && success) {
        dispatch(getUserProfileDetailsStart({ walletLoading: true }));
        const user = session.getSessionCred;
        dispatch(startPoll(user.userId));
        dispatch(resetSuccess());
      }
    }
  }, [dispatch, isLoggedIn, success]);

  useEffect(() => {
    dispatch(changeLanguageBE(session.getLanguage || "en"));
  }, [dispatch]);

  if (isGameStarted && isMobile) {
    if (orientation !== "PORTRAIT") {
      return;
    }
  }

  return (
    <div>
      <Grid className={classes.header}>
        <Grid className={classes.headerLeft}>
          {/* MOBILE SIDEBAR TOGGLE BUTTON START FROM HERE */}
          {/* <Grid className={classes.mobToggle}>
            <img src="images/menu-icon.png" alt="Menu Icon" />
          </Grid> */}
          {/* MOBILE SIDEBAR TOGGLE BUTTON END HERE */}

          <Link to="/" className={classes.menuLogo} onClick={handleClick}>
            {!isActive ? (
              <img src="/images/menu-icon.png" alt="menu icon" />
            ) : (
              <img src="/images/close.png" alt="close icon" />
            )}
          </Link>

          <Link to="/" className={classes.headerLogo}>
            <img src="/images/logo.svg" alt="Brand Logo" />
          </Link>
        </Grid>

        <Grid className={classes.headerCenter}>
          {/* <Link className={classes.headerLink} to="#">
          {t("HEADER.SPORTS_BETS")}
        </Link> */}
          <NavLink
            className={`${classes.headerLink} ${
              sportBookPaths.includes(active || location.pathname)
                ? "active"
                : null
            }`}
            to="/lobby"
            onClick={() => dispatch(setActive("/lobby"))}
          >
            <img src="/images/casino.svg" alt="" />
            {t("HEADER.SPORTSBOOK")}
          </NavLink>
          <NavLink
            className={`${classes.headerLink} ${
              casinoPaths.includes(active || location.pathname)
                ? "active"
                : null
            }`}
            to="/casino"
            onClick={() => dispatch(setActive("/casino"))}
          >
            <img src="/images/sportbook.svg" alt="" />
            {t("HEADER.CASINO")}
          </NavLink>
          <NavLink
            className={`${classes.headerLink} ${
              blogPaths.includes(active || location.pathname) ? "active" : null
            }`}
            to="/blog"
            onClick={() => dispatch(setActive("/blog"))}
          >
            <img src="/images/blog.svg" alt="" />
            {t("HEADER.BLOG")}
          </NavLink>
          {/* <Link className={classes.headerLink} to="/liveCasino">
            {t("HEADER.LIVE_CASINO")}
          </Link> */}
          {/* {isLoggedIn || metamaskLoggedIn ? (
          <>
            <Link className={classes.headerLink} to="#">
              {t("HEADER.MY_BETS")}
            </Link>
            <Link className={classes.headerLink} to="#">
              {t("HEADER.RESULTS")}
            </Link>
          </>
        ) : null} */}
        </Grid>

        <Grid className={classes.headerRight}>
          <Grid display={"flex"} className={classes.afterLogin}>
            {!isMobile && (
              <>
                <Button
                  id="language-button"
                  className={`${classes.btnPrimary} ${classes.accountBtn}`}
                  aria-controls={openLanguage ? "language-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLanguage ? "true" : undefined}
                  onClick={(event) => setAnchorElLanguage(event.currentTarget)}
                  startIcon={
                    <img
                      align="right"
                      src={`/images/${
                        Languages?.[i18n.language?.toUpperCase() || "EN"]?.flag
                      }`}
                      alt="country flag"
                      className={classes.countryFlagImg}
                    />
                  }
                  endIcon={
                    openLanguage ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                >
                  {Languages?.[i18n.language?.toUpperCase() || "EN"]?.name}
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={anchorElLanguage}
                  open={openLanguage}
                  onClose={() => setAnchorElLanguage(null)}
                  className={classes.languageMenu}
                  MenuListProps={{
                    "aria-labelledby": "language-button",
                  }}
                >
                  {Object.keys(Languages).map((lan) => {
                    return (
                      <MenuItem
                        onClick={(event) =>
                          handleCloseLanguage(event, lan.toLowerCase())
                        }
                        key={lan}
                      >
                        <img
                          src={`/images/${Languages[lan].flag}`}
                          alt="country flag"
                          className={classes.countryFlagImg}
                        />
                        {Languages[lan].name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
            {(isLoggedIn || metamaskLoggedIn) && (
              <>
                {!isMobile && <Wallet />}
                <Button
                  id="account-button"
                  className={`${classes.btnPrimary} ${classes.accountBtn}`}
                  aria-controls={openAccount ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAccount ? "true" : undefined}
                  onClick={handleOpenAccount}
                  startIcon={
                    userDetails && userDetails?.profileImage ? (
                      <img
                        src={userDetails?.profileImage}
                        alt={"profile"}
                        className={classes.profileImg}
                      />
                    ) : (
                      <Person />
                    )
                  }
                  endIcon={
                    openAccount ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                >
                  {/* {t("HEADER.ACCOUNT")} */}
                  {userDetails.userName}
                </Button>
                <Menu
                  id="account-menu"
                  anchorEl={anchorElAccount}
                  open={openAccount}
                  className={classes.accountMenu}
                  onClose={handleCloseAccount}
                  MenuListProps={{
                    "aria-labelledby": "account-button",
                  }}
                >
                  {headerMenu.map((item) => {
                    if (metamaskLoggedIn && item.to === "/user/resetPassword") {
                      return null;
                    }
                    // if (item?.isMyBet) {
                    //   return (
                    //     <MenuItem key={item.to}>
                    //       <Accordion
                    //         key={item.content}
                    //         className={classes.accordianWrapper}
                    //       >
                    //         <AccordionSummary
                    //           expandIcon={<ExpandMoreIcon />}
                    //           aria-controls="panel1a-content"
                    //           id="panel1a-header"
                    //           className={classes?.mybetAccordian}
                    //         >
                    //           <Grid
                    //             display={"flex"}
                    //             alignItems={"center"}
                    //             paddingX={"10px"}
                    //             paddingY={"4px"}
                    //             className={
                    //               active === item.to.sportbook && "active"
                    //             }
                    //           >
                    //             <img
                    //               src={item.src}
                    //               alt={item.alt}
                    //               style={{
                    //                 width: "16px",
                    //                 height: "16px",
                    //               }}
                    //             />
                    //             <Typography
                    //               paddingLeft={"20px"}
                    //               fontWeight={"300"}
                    //             >
                    //               {t(item.content)}
                    //             </Typography>
                    //           </Grid>
                    //         </AccordionSummary>
                    //         <AccordionDetails
                    //           className={classes.accordianDetail}
                    //         >
                    //           {/* <Link
                    //             to={item.to["casion"]}
                    //             onClick={handleCloseAccount}
                    //             className={classes.myAccountLink}
                    //           >
                    //             {item.contentData["casion"]}
                    //           </Link> */}
                    //           <Link
                    //             to={item.to["sportbook"]}
                    //             onClick={() => {
                    //               dispatch(setActive(item.to.sportbook));
                    //               handleCloseAccount();
                    //             }}
                    //             className={`${classes.myAccountLink} ${
                    //               active == item.to.sportbook && "active"
                    //             }`}
                    //           >
                    //             {item.contentData["sportbook"]}
                    //           </Link>
                    //         </AccordionDetails>
                    //       </Accordion>
                    //     </MenuItem>
                    //   );
                    // }
                    return (
                      <MenuItem key={item.to} onClick={handleCloseAccount}>
                        {!item?.isMyBet && (
                          <Link
                            to={item.to}
                            onClick={() => dispatch(setActive(item.to))}
                            className={`${classes.myAccountLink} ${
                              active == item.to && "active"
                            }`}
                          >
                            <span>
                              <img src={item.src} alt={item.alt} />
                            </span>
                            {t(item.content)}
                          </Link>
                        )}
                      </MenuItem>
                    );
                  })}
                  {isMobile && (
                    <MenuItem key={"wallet"}>
                      <Accordion
                        key={"walletacc"}
                        className={classes.accordianWrapper}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes?.mybetAccordian}
                        >
                          <Grid
                            display={"flex"}
                            alignItems={"center"}
                            paddingX={"10px"}
                            paddingY={"4px"}
                            // className={
                            //   active === item.to.sportbook && "active"
                            // }
                          >
                            <img
                              src="/images/wallet.svg"
                              alt="Wallet icon"
                              style={{
                                width: "16px",
                                height: "16px",
                              }}
                            />
                            <Typography
                              paddingLeft={"20px"}
                              fontWeight={"300"}
                              style={{ fontSize: "14px" }}
                            >
                              {walletLoading ? (
                                <CircularProgress size={25} />
                              ) : (
                                // eslint-disable-line no-use-before-define
                                `$ ${walletAmount || 0.0}`
                              )}
                            </Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordianDetail}>
                          <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography>{t("HEADER.BONUS_BALANCE")}</Typography>
                            <Typography></Typography>
                            {`$ ${parseFloat(nonCashAmount).toFixed(2) || 0.0}`}
                          </Grid>
                          <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography>
                              {t("HEADER.WITHDRAWABLE_BALANCE").split(" ")[0]}
                            </Typography>
                            <Typography></Typography>
                            {`$ ${
                              parseFloat(
                                parseFloat(walletAmount) -
                                  parseFloat(nonCashAmount)
                              ).toFixed(2) || 0.0
                            }`}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleLogout}
                    className={classes.myAccountLink}
                  >
                    <span>
                      {" "}
                      <img src="/images/logout.svg" alt="logout Icon" />{" "}
                    </span>
                    {t("HEADER.LOG_OUT")}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>
          {!isLoggedIn && !loading && !metamaskLoggedIn && !metamaskLoading ? (
            <>
              <Button className={classes.btnPrimary} onClick={handleOpenSignUp}>
                <span className={classes.iconBg}>
                  <img
                    src="/images/signup-icon.svg"
                    alt="signup icon"
                    className={classes.loginIcon}
                  />
                </span>
                {t("HEADER.SIGN_UP")}
              </Button>

              <Button className={classes.btnPrimary} onClick={handleOpenLogin}>
                <span className={classes.iconBg}>
                  <img
                    src="/images/login-icon.svg"
                    alt="login icon"
                    className={classes.loginIcon}
                  />
                </span>
                {t("HEADER.LOGIN")}
              </Button>
            </>
          ) : null}
        </Grid>
        <Modal
          isShowing={openLogin}
          content={
            <DialogContent className={classes.loginWrap}>
              <Login
                handleCloseLogin={handleOpenLogin}
                handleOpenSignUp={handleOpenSignUp}
              />
            </DialogContent>
          }
          hide={handleOpenLogin}
        />
        <Modal
          isShowing={openSignUp}
          content={
            <DialogContent className={classes.signupWrap}>
              <SignUp
                handleOpenLogin={handleOpenLogin}
                handleOpenSignUp={handleOpenSignUp}
              />
            </DialogContent>
          }
          hide={handleOpenSignUp}
        />
        {/* MOBILE SIDEBAR START FROM HERE */}
        <Grid className={classes.mobSidebar}>
          <Grid className={classes.sidebarClose}>
            <img src="images/close.png" alt="Close icon" />
          </Grid>
          <Link to="#">{t("HEADER.CASINO")}</Link>
          <Link to="/">{t("HEADER.LIVE_CASINO")}</Link>
          <Link to="#">{t("HEADER.PROMOTIONS")}</Link>
        </Grid>
        {/* MOBILE SIDEBAR END HERE */}
      </Grid>
      {isActive && (
        <MobileSidebar
          isActive={isActive}
          setIsActive={setIsActive}
          active={active}
          setActive={setActive}
        />
      )}
    </div>
  );
};

export default Header;
