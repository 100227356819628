import { put, takeEvery } from "redux-saga/effects";

import cmsService from "../../../services/cms";
import { getCmsStart, getCmsSuccess, getCmsFailure } from "../../redux/cms";
import { showErrorMessage } from "../../redux/toast";

function* cmsWatcher() {
  yield takeEvery(getCmsStart.type, cmsWorker);
}

function* cmsWorker() {
  try {
    const { data, error } = yield cmsService.getCmsData();
    if (!error) {
      yield put(getCmsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getCmsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getCmsFailure());
  }
}

export default cmsWatcher;
