import { getErrorObj } from "../../utils/helper";
import axiosClient from "../axios";

export default class userProfileService {
  static async getUserProfile() {
    return axiosClient
      .get("/user/userProfile")
      .then(({ data }) => data)
      .catch((error) => {
        return getErrorObj(error);
      });
  }

  static async updateUserProfile(payload) {
    try {
      const { data } = await axiosClient.post("/user/updateProfile", payload);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async updateUserProfileAvatar(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/uploadProfileImage",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }

  static async resetPassword(payload) {
    try {
      const { data } = await axiosClient.post("/user/changePassword", payload);
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
