import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: null,
  transactions: [],
  totalPages: 0,
  totalBets: 0,
  bets: [],
  hasNext: false,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getTransactionsStart: (state, action) => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, action) => {
      let currentPage = parseInt(action.payload.currentPage);
      let totalPage = parseInt(action.payload.totalPage);
      state.hasNext = currentPage < totalPage;

      if (currentPage > 1) {
        state.transactions = action.payload.transactionList;
      }

      if (currentPage === 1) {
        state.transactions = action.payload?.transactionList;
      }

      state.loading = false;
      state.success = true;
      state.currentPage = currentPage;
      state.totalPages = totalPage;
    },
    getTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllBetsStart: (state) => {
      state.loading = true;
    },
    getAllBetsSuccess: (state, action) => {
      state.bets = action.payload?.transactionList;
      state.totalPages = action.payload?.totalPage;
      state.loading = false;
      state.success = true;
    },
    getAllBetsStartFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetTransactionState: (state) => {
      state.transactions = initialState.transactions;
      state.bets = initialState.bets;
      state.totalPages = 0;
      state.hasNext = false;
    },
    getSportBookTransactionsStart: (state, action) => {
      state.loading = true;
    },
    getSportBookTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action.payload.transactions;
      state.totalPages = action.payload.totalPage;
    },
    getSportBookTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getTransactionsStart,
  getTransactionsSuccess,
  getTransactionsFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsStartFailure,
  resetTransactionState,
  getSportBookTransactionsStart,
  getSportBookTransactionsSuccess,
  getSportBookTransactionsFailure,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
