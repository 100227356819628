import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  blog: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    fontFamily: "'Poppins', sans-serif",
  },
  sectionTitle: {
    fontSize: "2rem",
    marginBottom: "20px",
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
  },
  article: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Poppins', sans-serif",
    color: "#333",
  },
  articleTitle: {
    fontSize: "1.5rem",
    marginBottom: "12px",
    fontFamily: "'Poppins', sans-serif",
    color: "#333",
  },
  articleImg: {
    Width: "100%",
    maxWidth: "300px",
    height: "auto",
    display: "block",
    margin: "0 auto 12px",
    borderRadius: "8px",
  },
  articleContent: {
    fontSize: "1rem",
    lineHeight: "1.6",
    marginBottom: "12px",
    fontFamily: "'Poppins', sans-serif",
  },
  btn: {
    display: "inline-block",
    padding: "8px 16px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#1b214f",
    border: "none",
    borderRadius: "4px",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
});

export default useStyles;
