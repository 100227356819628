import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  sidebarWrap: {
    background: "#0D2867",
    width: "324px",
    borderRadius: "10px",
    flex: "0 0 324px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xl")]: {
      flex: "0 0 210px",
    },
  },
  accountWrap: {
    display: "flex",
    padding: "20px 100px",
    marginTop: "101px",
    marginBottom: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
  },
  accountRightWrap: {
    width: "100%",
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  sidebarUserIcon: {
    textAlign: "center",
    height: "80px",
    width: "80px",
    margin: "20px auto",
    borderRadius: "100%",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  sidebarUserName: {
    textAlign: "center",
    marginTop: "10px",
    paddingBottom: "20px",
    marginBottom: "20px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
  },
  sidebarListing: {
    paddingLeft: "30px",
    lineHeight: "55px",
    borderBottom: "1px solid #3F4997",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      position: "relative",
      "&:hover": {
        // color: "#F7616A",
        background:
          "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
        "&:before": {
          content: "' '",
          width: "4px",
          position: "absolute",
          left: "-30px",
          height: "37px",
          clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
          background: "#fff",
        },
      },
    },
    "& .active": {
      position: "relative",
      background:
        "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
      "&:before": {
        // content: "' '",
        // width: "6px",
        // height: "100px",
        // clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
        // background: "#F7616A",
        // position: "absolute",
        // left: "-30px",

        content: "' '",
        width: "4px",
        position: "absolute",
        left: "-30px",
        height: "37px",
        clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
        background: "#fff",
      },
    },
    "& span": {
      marginRight: "20px",
      display: "flex",
      "& img": {
        width: "18px",
      },
    },
  },
  mybetAccordian: {
    padding: "0px",
    margin: "0px !important",
    background: "#002965",
    position: "relative",
    minHeight: "inherit !important",
    // "& .MuiAccordionSummary-content": {
    //   padding: "0",
    //   margin: "0 !important",
    // },
    "& .MuiAccordionSummary-expandIconWrapper": {
      position: "absolute",
      right: "3px",
    },
  },

  accordianDetail: {
    padding: "0",
  },
  accordianWrapper: {
    border: "0",
    boxShadow: "inherit",
    width: "100%",
    // paddingRight: "10px",
    background: "transparent",
  },
}));
