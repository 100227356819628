import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-material-ui";
import dayjs from "dayjs";

import useStyles from "./SignUp.styles";
import countries from "./countries.json";
import {
  validateEmail,
  validatePassword,
  validateLetters,
  validateCharLimit,
  validateLimit,
  ageValidation,
  // validateEmailOrUserName,
  validateUserName,
} from "../../../utils/helper";
import { signUpStart } from "../../../redux-saga/redux/auth/authentication";

const SignUp = ({ handleOpenLogin, handleOpenSignUp }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [promoCode, setPromoCode] = useState("");
  const [show, setShow] = useState([true, true]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [countryCode, setCountryCode] = useState("AR");
  const [ageConfirm, setAgeConfirm] = useState(true);
  const [emailSubscription, setemailSubscription] = useState(true);
  const [dob, setDob] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const affiliate = searchParams.get("affiliate");

  const handleClickShowPassword = (index) =>
    setShow((arr) => {
      let passShowArr = arr.map((obj, i) => {
        if (i === index) return !arr[i];
        else return obj;
      });
      return passShowArr;
    });
  const { loading, referralCode, success } = useSelector(
    (state) => state.authentication
  );

  const handleSignupOnEnter = (e) => {
    if (e.key === "Enter") {
      handleSignUp();
      return;
    }
  };

  const handleSignUp = () => {
    const validation = [];

    if (firstName?.length === 0) {
      validation.push(t("HEADER.FIRST_NAME_VALIDATION"));
    } else {
      if (!validateCharLimit(firstName)) {
        validation.push(t("COMMON.FIRST_NAME_CHAR_LIMIT"));
      }
      if (!validateLetters(firstName)) {
        validation.push(t("COMMON.FIRST_NAME_CONTAIN_CHARACTERS_ERROR"));
      }
    }
    if (lastName?.length === 0) {
      validation.push(t("HEADER.LAST_NAME_VALIDATION"));
    } else {
      if (!validateCharLimit(lastName)) {
        validation.push(t("COMMON.LAST_NAME_CHAR_LIMIT"));
      }
      if (!validateLetters(lastName)) {
        validation.push(t("COMMON.LAST_NAME_CONTAIN_CHARACTERS_ERROR"));
      }
    }

    if (
      userName?.length === 0 ||
      !validateCharLimit(userName) ||
      !validateUserName(userName)
    ) {
      if (userName?.length === 0) {
        validation.push(t("COMMON.USER_NAME_EMPTY"));
      }
      if (!validateCharLimit(userName)) {
        validation.push(t("COMMON.USER_NAME_CHAR_LIMIT"));
      }
      if (!validateUserName(userName)) {
        validation.push(t("COMMON.USERNAME_NOT_VALID"));
      }
    }
    if (password !== confirmPassword) {
      validation.push(t("CHANGE_PASSWORD.PASSWORD_MATCH_VALIDATION"));
    }
    if (!validatePassword(password)) {
      validation.push(t("COMMON.PASSWORD_VALIDATION"));
    }
    if (!validateEmail(email) || !validateLimit(email)) {
      if (!validateEmail(email)) {
        validation.push(t("COMMON.EMAIL_VALIDATION"));
      }
      if (!validateLimit(email)) {
        validation.push(t("COMMON.EMAIL_LIMIT_VALIDATION"));
      }
    }
    if (countryCode?.length === 0) {
      validation.push(t("MYPROFILE.COUNTRY_VALIDATION"));
    }
    if (!ageConfirm) {
      validation.push(t("HEADER.AGE_CONFIRMATION"));
    }
    if (dayjs(dob).format() === "Invalid Date")
      validation.push(t("COMMON.NOT_VALID_DOB"));
    if (!mobileNumber.match(/^[0-9]{6,20}$/g)?.length)
      validation.push(t("COMMON.NOT_VALID_MOBILE"));
    if (!ageValidation(dob)) {
      validation.push(t("COMMON.AGE_VALID"));
    }
    if (validation?.length !== 0) {
      toast.error(validation[0]);
      return;
    }

    const userData = {
      firstName,
      lastName,
      userName,
      email,
      password: window.btoa(password),
      countryCode,
      promoCode,
      phone: mobileNumber,
      dateOfBirth: dayjs(dob).startOf("day").format("YYYY-MM-DD"),
    };

    if (referralCode) {
      userData.referralCode = referralCode;
    }
    if (!userData?.promoCode) delete userData.promoCode;
    dispatch(
      signUpStart({
        userData,
        affiliate,
      })
    );
  };

  useEffect(() => {
    if (success) handleOpenLogin();
  }, [success]);

  return (
    <div>
      <Grid className={classes.signupContent}>
        {/* <Grid className={classes.signupHeader}>
          <Typography>Signing up takes less than a minute</Typography>
          <Typography>Fast registration</Typography>
        </Grid> */}
        <Grid className={classes.loginContent}>
          <Typography variant="h5">{t("HEADER.SIGN_UP")}</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={classes.signupLeft}>
              {/* <Typography variant="h5">{t("HEADER.SIGN_UP")}</Typography> */}
              {/* <Typography>{t("HEADER.CREATE_ACCOUNT_MSG")}</Typography> */}
              <Grid className={classes.signupFromWrap}>
                <Grid container spacing={2} className={classes.signupFromFname}>
                  <Grid item xs={6} className={classes.signupFname}>
                    <span className={classes.labelTag}>
                      {t("COMMON.FIRST_NAME") + "*"}
                    </span>
                    <TextField
                      fullWidth
                      id="f-name"
                      placeholder={t("COMMON.FIRST_NAME")}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      variant="outlined"
                      className={classes.formFname}
                      onChange={(e) => setFirstName(e.target.value)}
                      onKeyDown={handleSignupOnEnter}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.signupLname}>
                    <span className={classes.labelTag}>
                      {t("COMMON.LAST_NAME") + "*"}
                    </span>
                    <TextField
                      fullWidth
                      id="l-name"
                      placeholder={t("COMMON.LAST_NAME")}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyDown={handleSignupOnEnter}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.signupFromFname}>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.USERNAME") + "*"}
                    </span>
                    <TextField
                      id="userName"
                      type="text"
                      placeholder={t("COMMON.USERNAME")}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) => setUserName(e.target.value)}
                      onKeyDown={handleSignupOnEnter}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.EMAIL_ADDRESS") + "*"}
                    </span>
                    <TextField
                      id="email"
                      type="email"
                      placeholder={t("COMMON.EMAIL_ADDRESS")}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleSignupOnEnter}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.signupFromFname}>
                  <Grid item xs={6}>
                    <Grid className={classes.formGroup}>
                      <span className={classes.labelTag}>
                        {t("COMMON.PASSWORD") + "*"}
                      </span>
                      <TextField
                        type={!show[0] ? "text" : "password"}
                        id="pwd"
                        InputLabelProps={{
                          style: {
                            color: "#8492B7",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "25px",
                          },
                        }}
                        placeholder={t("COMMON.PASSWORD")}
                        variant="outlined"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleSignupOnEnter}
                      />
                      <Grid className={classes.showLink}>
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#002965" }}
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(0)}
                            onMouseDown={() => handleClickShowPassword(0)}
                          >
                            {!show[0] ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid className={classes.formGroup}>
                      <span className={classes.labelTag}>
                        {t("CHANGE_PASSWORD.CONFIRM_PASSWORD")}
                      </span>
                      <TextField
                        id="confirm-new-password"
                        InputLabelProps={{
                          style: {
                            color: "#8492B7",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "25px",
                          },
                        }}
                        placeholder={t("CHANGE_PASSWORD.CONFIRM_PASSWORD")}
                        variant="outlined"
                        value={confirmPassword}
                        type={!show[1] ? "text" : "password"}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                      />
                      <Grid className={classes.showLink}>
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#002965" }}
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(1)}
                            onMouseDown={() => handleClickShowPassword(1)}
                          >
                            {!show[1] ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.signupFromFname}>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.COUNTRY_REGION") + "*"}
                    </span>
                    <TextField
                      id="country"
                      select
                      value={countryCode}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      placeholder={t("COMMON.COUNTRY_REGION")}
                      variant="outlined"
                      onChange={(e) => setCountryCode(e.target.value)}
                      onKeyDown={handleSignupOnEnter}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          <Grid style={{ marginTop: "8px" }}>
                            {country.name}
                          </Grid>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.PROMOCODE")}
                    </span>
                    <TextField
                      id="promocode"
                      type="text"
                      placeholder={t("COMMON.PROMOCODE")}
                      InputLabelProps={{
                        style: {
                          color: "#8492B7",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "25px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) => setPromoCode(e.target.value)}
                      value={promoCode}
                      onKeyDown={handleSignupOnEnter}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.DATE_OF_BIRTH")}
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        id="dob"
                        placeholder={t("COMMON.DATE_OF_BIRTH")}
                        variant="outlined"
                        format="DD/MM/YYYY"
                        disableFuture
                        value={dob}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              style: {
                                color: "#8492B7",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "25px",
                              },
                            }}
                            variant="outlined"
                          />
                        )}
                        onChange={(date) => {
                          setDob(date);
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} className={classes.formGroup}>
                    <span className={classes.labelTag}>
                      {t("COMMON.MOBILE_NUMBER")}
                    </span>
                    <ReactPhoneInput
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e);
                      }} // passed function receives the phone value
                      component={TextField}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.signupCheck}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={ageConfirm}
                          onChange={() =>
                            setAgeConfirm((prevState) => !prevState)
                          }
                        />
                      }
                      label={t("HEADER.AGE_CONFIRM_MSG")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.emailSubscription}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={emailSubscription}
                          onChange={() =>
                            setemailSubscription((prevState) => !prevState)
                          }
                        />
                      }
                      label={t("HEADER.OPT_IN_CONFIRMATION_MSG")}
                    />
                  </FormGroup>
                </Grid>
                <Grid className={classes.authBtnWrap}>
                  <Button className={classes.btnPrimary} onClick={handleSignUp}>
                    {loading ? (
                      <CircularProgress size={"1rem"} />
                    ) : (
                      t("HEADER.SIGN_UP")
                    )}
                  </Button>
                </Grid>

                <Grid className={classes.dividerSpace} />

                <Grid className={classes.authText}>
                  <Typography>{t("HEADER.PROMOTIONS_MSG")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Grid className={classes.signupBg}>
              <Typography variant="h5">
                <Trans components={{ br: <br /> }}>HEADER.WELCOME_BONUS</Trans>
              </Typography>
              <Typography>{t("HEADER.MIN_DEPOSIT")}</Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
