import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currencyLoading: false,
  loading: false,
  success: false,
  error: false,
  targetUrl: null,
  convertedBalance: "",
  currencies: [],
  depositAddress: "",
  isWithdrawAddressValid: false,
  convertedBalanceWithExtraCharge: "",
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    DepositStart: (state, action) => {
      state.loading = true;
      state.targetUrl = null;
    },
    DepositSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.targetUrl = action.payload;
    },
    DepositFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    WithdrawStart: (state, action) => {
      state.withdraLoading = true;
      state.targetUrl = null;
      state.success = false;
    },
    WithdrawSuccess: (state, action) => {
      state.withdraLoading = false;
      state.success = true;
    },
    WithdrawFailure: (state, action) => {
      state.withdraLoading = false;
      state.error = true;
      state.success = false;
    },
    resetTarget: (state, action) => {
      state.targetUrl = null;
      state.success = false;
      state.convertedBalance = "";
      state.depositAddress = "";
      state.isWithdrawAddressValid = false;
      state.loading = false;
      state.error = false;
      state.redirectUrl = "";
      state.convertedBalanceWithExtraCharge = "";
      state.creditCardDepositLoading = false;
      state.creditCardRedirectUrl = "";
    },
    getCurrencyStart: (state, action) => {
      state.currencyLoading = true;
      state.currencies = action.payload;
    },
    getCurrencySuccess: (state, action) => {
      state.currencyLoading = false;
      state.currencies = action.payload;
    },
    getCurrencyFailure: (state, action) => {
      state.currencyLoading = false;
      state.currencies = action.payload;
    },
    getConvertedBalanceStart: (state, action) => {
      if (action.payload.extraCharge) {
        state.convertedBalanceWithExtraCharge = "";
      } else {
        state.convertLoading = true;
        state.convertedBalance = "";
      }
    },
    getConvertedBalanceSuccess: (state, action) => {
      if (action.payload.extraCharge) {
        state.convertedBalanceWithExtraCharge =
          action.payload?.estimated_amount;
      } else {
        state.convertLoading = false;
        state.convertedBalance = action.payload?.estimated_amount;
      }
    },
    getConvertedBalanceFailure: (state, action) => {
      state.convertLoading = false;
      state.convertedBalance = action.payload;
    },
    getGeneratedAddressStart: (state, action) => {
      state.depositLoading = true;
      state.depositAddress = "";
      state.success = false;
      state.redirectUrl = "";
    },
    getGeneratedAddressSuccess: (state, action) => {
      state.depositLoading = false;
      state.depositAddress = action?.payload?.pay_address;
      state.success = true;
      state.redirectUrl = action?.payload?.invoice_url;
    },
    getGeneratedAddressFailure: (state, action) => {
      state.depositLoading = false;
      state.depositAddress = "";
      state.success = true;
      state.redirectUrl = "";
    },
    chargeMoneyCreateStart: (state, action) => {
      state.creditCardDepositLoading = true;
      state.depositAddress = "";
      state.success = false;
      state.creditCardRedirectUrl = "";
    },
    chargeMoneyCreateSuccess: (state, action) => {
      state.creditCardDepositLoading = false;
      state.depositAddress = action?.payload?.pay_address;
      state.success = true;
      state.creditCardRedirectUrl = action?.payload?.redirect_3ds_url;
    },
    chargeMoneyCreateFailure: (state, action) => {
      state.creditCardDepositLoading = false;
      state.depositAddress = "";
      state.success = true;
      state.creditCardRedirectUrl = "";
    },
    validateAddressStart: (state, action) => {
      state.loading = true;
      state.isWithdrawAddressValid = false;
    },
    validateAddressSuccess: (state, action) => {
      state.loading = false;
      state.isWithdrawAddressValid = action.payload;
    },
    validateAddressFailure: (state, action) => {
      state.loading = false;
      state.isWithdrawAddressValid = false;
    },
    validatePromoCodeStart: (state, action) => {
      state.loading = true;
      state.promoCodeValidation = null;
      state.error = null;
    },
    validatePromoCodeSuccess: (state, action) => {
      state.loading = false;
      state.promoCodeValidation = action.payload;
      state.error = null;
    },
    validatePromoCodeFailure: (state, action) => {
      state.loading = false;
      state.promoCodeValidation = null;
      state.error = action.payload;
    },
  },
});

export const {
  validateAddressStart,
  validateAddressSuccess,
  validateAddressFailure,
  getGeneratedAddressStart,
  chargeMoneyCreateStart,
  chargeMoneyCreateSuccess,
  chargeMoneyCreateFailure,
  getGeneratedAddressSuccess,
  getGeneratedAddressFailure,
  getConvertedBalanceStart,
  getConvertedBalanceSuccess,
  getConvertedBalanceFailure,
  getCurrencyStart,
  getCurrencySuccess,
  getCurrencyFailure,
  DepositStart,
  DepositSuccess,
  DepositFailure,
  resetTarget,
  WithdrawStart,
  WithdrawSuccess,
  WithdrawFailure,
  validatePromoCodeStart,
  validatePromoCodeSuccess,
  validatePromoCodeFailure,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
