import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  left_container: {
    display: "flex",
  },

  rights_container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  customDatePicker: {
    alignItems: "center",
    gap: "8px",
    "& span": {
      fontSize: "12px",
      fontWeight: "500",
      whiteSpace: "nowrap",
    },
    "& .MuiInputBase-root": {
      borderRadius: "30px",
      color: "#fff",
      "& .MuiInputBase-input": {
        fontSize: "14px",
        padding: "12px",
        [theme.breakpoints.down("sm")]: {
          padding: "5px 10px",
          fontSize: "14px",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },

  formGroup: {
    position: "relative",
    display: "flex",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    color: "#fff",
    "& .MuiInputBase-root": {
      border: "1px solid #fff",
      color: "#fff",
      height: "2.5rem",
      width: "11rem",
    },
    "& .MuiButtonBase-root": {
      color: "fff",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      "& .MuiInputBase-root": {
        width: "10rem",
      },
    },
  },

  customDatePickerMob: {
    alignItems: "center",
    gap: "8px",
    "& span": {
      fontSize: "12px",
      fontWeight: "500",
      whiteSpace: "nowrap",
    },
    "& .MuiInputBase-root": {
      borderRadius: "30px",
      color: "#F7616A",
      "& .MuiInputBase-input": {
        fontSize: "12px",
        width: "70%",
      },
      "& .MuiSvgIcon-root": {
        color: "#F7616A",
        fontSize: "16px",
      },
    },
  },

  formGroupMob: {
    position: "relative",
    display: "flex",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    color: "#F7616A",
    "& .MuiInputBase-root": {
      border: "1px solid #F7616A",
      color: "#F7616A",
      height: "2.5rem",
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      color: "#F7616A",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  spanTo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      marginLeft: "4%",
      marginRight: "4%",
    },
  },

  datepickerStyle: {
    display: "flex",
    columnGap: "0.5rem",
    flexWrap: "wrap",
    rowGap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },

  datepickerStyleMob: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  noMatchResultMsg: {
    display: "flex",
    justifyContent: "center",
    minWidth: "100%",
    color: "#f5325b",
    padding: "1rem",
  },
  tab_container: {
    // marginTop: "1rem",
    paddingTop: "0",
    marginTop: "10px",
    marginBottom: "10px",
    "& .MuiTabs-hideScrollbar": {
      margin: "0 10px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: "0.3",
    },
    "& MuiTabs-indicator": {
      background: "transparent",
    },
    "& .MuiTabScrollButton-root": {
      width: "20px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      "& svg": {
        color: "#fff",
      },
    },
  },
  tableMatchName: {
    color: "#0D2867",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "10px",
    marginTop: "0px",
    textAlign: "left",
  },
  tableMatchDetails: {
    display: "flex",
    alignItems: "center",
  },
  matchStatus: {
    marginRight: "0.5rem",
    color: "#000",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "& .time": {
      fontWeight: "300",
      textAlign: "left",
    },
    "& img": {
      width: "25px",
      marginRight: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  tableTeamDetails: {
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      marginBottom: "0px",
      color: "#000",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "350px",
      flex: "1",
      textAlign: "left",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        marginBottom: "0",
      },
    },
  },

  SportsTableWrap: {
    "& .MuiTable-root": {
      background: "#fff",
      boxShadow: "none",
      "& .MuiTableCell-root": {
        color: "black",
        borderColor: "#ccc",
        [theme.breakpoints.down("xl")]: {
          padding: "20px 5px",
          color: "black",
        },
      },
    },
    "& .MuiTableRow-root": {
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      "& .MuiTableCell-root": {
        display: "table-cell",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#F2F4FF",
      },
    },
    "& .MuiTableContainer-root": {
      [theme.breakpoints.down("md")]: {
        overflowX: "unset",
      },
    },
  },
  SportsTableHeader: {
    borderRadius: "10px 10px 0px 0px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "0px",
    justifyContent: "space-between",
    flexWrap: "wrap",
    rowGap: "1rem",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "10px",
      color: "#fff",
    },
    "& img": {
      width: "25px",
    },
  },
  tableMatchMob: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  tableMatchDetailsLeft: {
    display: "flex",
    alignItems: "center",
  },
  tableMatchDetailsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  matchName: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2px",
    "& p": {
      margin: "0",
    },
  },
}));
