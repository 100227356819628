import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  btnPrimary: {
    background: "#F7616A",
    borderRadius: "90px",
    padding: "10px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    // height: "60px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background: "#F7616A",
    },
  },

  loginLogo: {
    textAlign: "center",
    margin: "20px 0",
  },

  loginContent: {
    textAlign: "center",
    margin: "0",
    // background: "#010101",
    color: "#F7616A",
    padding: "0",
    "& .MuiTypography-h5": {
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: "30px",
      padding: "10px",
    },
  },

  loginFormWrap: {
    padding: "20px 40px",
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      color: "#25262B",
      background: "#F2F4FF",
      borderRadius: "7px",
      fontSize: "14px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "inherit",
          "&:hover": {
            borderColor: "inherit",
          },
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px",
      borderColor: "inherit !important",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#8f66ab",
    },
  },
  authBtnWrap: {
    margin: "30px 0",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: "600",
    },
  },

  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
  },
}));
