import { put, takeEvery } from "redux-saga/effects";

import {
  getCountriesFailure,
  getCountriesStart,
  getCountriesSuccess,
  getSportBookBannersStart,
  getSportBookBannersSuccess,
  getSportBookBannersFailure,
  getSportsFailure,
  getSportsStart,
  getSportsSuccess,
} from "../../redux/sportbook";
import SportsService from "../../../sport-book-services/sports";
import { showErrorMessage } from "../../../redux-saga/redux/toast";

function* LobbyBannersWorker(action) {
  try {
    const { data, error } = yield SportsService.getBannersData(action.payload);
    if (!error) {
      yield put(getSportBookBannersSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getSportBookBannersFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getSportBookBannersFailure());
  }
}
function* sportbookWatcher() {
  yield takeEvery(
    [getSportsStart.type, getCountriesStart.type],
    sportBookWorker
  );
  yield takeEvery(getSportBookBannersStart.type, LobbyBannersWorker);
}

function* sportBookWorker(action) {
  try {
    switch (action.type) {
      case getSportsStart.type: {
        const { data, error } = yield SportsService.getSports();
        if (!error) {
          yield put(getSportsSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getSportsFailure());
        }
        break;
      }
      case getCountriesStart.type: {
        const { data, error } = yield SportsService.getCountries(
          action.payload
        );
        if (!error) {
          yield put(
            getCountriesSuccess({
              isLive: action.payload.isLive,
              data: data?.finAllCountries,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getCountriesFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getSportsStart.type: {
        yield put(getSportsFailure());
        break;
      }
      case getCountriesStart.type: {
        yield put(getCountriesFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default sportbookWatcher;
