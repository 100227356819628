import { all } from "redux-saga/effects";

import authenticationWatcher from "./auth/authentication";
import userProfileWatcher from "./profile/myProfile";
import transactionsWatcher from "./profile/transactions";
import resetPasswordWatcher from "./auth/resetPassword";
import casinoLobbyWatcher from "./casino/lobby/casinoLobby";
import casinoGamesWatcher from "./casino/lobby/casinoGames";
import walletWatcher from "./profile/wallet";
import cmsWatcher from "./cms";
import favoritesWatcher from "./casino/lobby/favorites";
import paymentsWatcher from "./payments";
import advertisementWatcher from "./advertisement";
import sportbookWatcher from "../../sport-book-saga/saga/sportbook";
import matchesWatcher from "../../sport-book-saga/saga/matches";
import placebetslipWatcher from "../../sport-book-saga/saga/betslip";
import moreMarketsWatcher from "../../sport-book-saga/saga/more-markets";
import myBetsWatcher from "../../sport-book-saga/saga/myBets";
import favouriteWatcher from "../../sport-book-saga/saga/favourite";
import ResultsWatcher from "../../sport-book-saga/saga/results";
import featuredMatchesWatcher from "../../sport-book-saga/saga/featuredMatches";

export default function* rootSaga() {
  yield all([
    authenticationWatcher(),
    userProfileWatcher(),
    transactionsWatcher(),
    resetPasswordWatcher(),
    casinoLobbyWatcher(),
    casinoGamesWatcher(),
    walletWatcher(),
    cmsWatcher(),
    advertisementWatcher(),
    paymentsWatcher(),
    favoritesWatcher(),

    // sport book watchers, don't put sportbook unrelated watchers below this comment
    sportbookWatcher(),
    matchesWatcher(),
    placebetslipWatcher(),
    moreMarketsWatcher(),
    myBetsWatcher(),
    favouriteWatcher(),
    ResultsWatcher(),
    featuredMatchesWatcher(),
  ]);
}
