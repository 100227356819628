import React from "react";

import { Box } from "@mui/material";

const NotASubscriber = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      height={"calc(100vh - 66px)"}
    >
      <img alt="yo" src="/images/error404.png" width="500px" height={"700px"} />
    </Box>
  );
};

export default NotASubscriber;
