import {
  LIVE_MATCH_NAMESPACE,
  PRE_LIVE_MATCH_NAMESPACE,
} from "../namespaces/index";
import {
  CHANGE,
  CHANGE_DASHBOARD,
  PRE_CHANGE_DASHBOARD,
  PRE_CHANGE,
} from "../event-constants/liveMatches/index";
import config from "../../../../config";
import createSocketConnection from "../socket-connection/index";

export const liveMatchSocketInstance = createSocketConnection(
  `${config.sportbook_socket_url}${LIVE_MATCH_NAMESPACE}`
);

export const preLiveMatchSocketInstance = createSocketConnection(
  `${config.sportbook_socket_url}${PRE_LIVE_MATCH_NAMESPACE}`
);

const useLiveMatchSocketNameSpace = () => {
  const listenDashboardMatchMarketUpdates = (eventName, cb) => {
    const DYNAMIC_EVENT_NAME = `${CHANGE_DASHBOARD}/${eventName}`;
    if (cb) {
      liveMatchSocketInstance.on(DYNAMIC_EVENT_NAME, cb);
    }
    return () => {
      liveMatchSocketInstance.off(DYNAMIC_EVENT_NAME, cb);
    };
  };

  const listenAllMatchMarketsUpdates = (eventName, cb) => {
    const DYNAMIC_EVENT_NAME = `${CHANGE}/${eventName}`;
    if (cb) {
      liveMatchSocketInstance.on(DYNAMIC_EVENT_NAME, cb);
    }
    return () => {
      liveMatchSocketInstance.off(DYNAMIC_EVENT_NAME, cb);
    };
  };

  return {
    listenDashboardMatchMarketUpdates,
    listenAllMatchMarketsUpdates,
  };
};

const usePreLiveMatchSocketNameSpace = () => {
  const listenDashboardMatchMarketUpdates = (eventName, cb) => {
    const DYNAMIC_EVENT_NAME = `${PRE_CHANGE_DASHBOARD}/${eventName}`;
    if (cb) {
      preLiveMatchSocketInstance.on(DYNAMIC_EVENT_NAME, cb);
    }
    return () => {
      preLiveMatchSocketInstance.off(DYNAMIC_EVENT_NAME, cb);
    };
  };

  const listenAllPreLiveMatchMarketsUpdates = (eventName, cb) => {
    const DYNAMIC_EVENT_NAME = `${PRE_CHANGE}/${eventName}`;
    if (cb) {
      preLiveMatchSocketInstance.on(DYNAMIC_EVENT_NAME, cb);
    }
    return () => {
      preLiveMatchSocketInstance.off(DYNAMIC_EVENT_NAME, cb);
    };
  };

  return {
    listenDashboardMatchMarketUpdates,
    listenAllPreLiveMatchMarketsUpdates,
  };
};

export default useLiveMatchSocketNameSpace;
export { usePreLiveMatchSocketNameSpace };
