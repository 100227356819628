import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  List,
  Typography,
  ListItem,
  Container,
  // Button,
} from "@mui/material";
import {
  useLocation,
  // Link,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import useStyles from "./Footer.styles";
import { getCmsStart } from "../../redux-saga/redux/cms";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { cmsData } = useSelector((state) => state.cms);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const classes = useStyles();
  const navigate = useNavigate();
  // const { isLoggedIn } = useSelector((state) => state.authentication);
  useEffect(() => {
    dispatch(getCmsStart());
  }, [dispatch]);

  if (isGameStarted && isMobile) {
    return;
  }
  return (
    <div id="mainfooter">
      <Grid className={classes.footerSection}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Grid className={classes.footerLogo}>
                <img src="/images/logo.svg" alt="Brand Logo" />
              </Grid>

              <Grid className={classes.socialLinks}>
                <ul>
                  <li>
                    <a href={location.pathname}>
                      <img src="/images/telegram.svg" alt="telegram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/betworldsportio">
                      <img src="/images/twitter.svg" alt="twitter" />
                    </a>
                  </li>
                  {/*<li>
                    <a href={location.pathname}>
                      <img src="/images/whatsapp.svg" alt="whatsapp" />
                    </a>
                   </li>*/}
                  <li>
                    <a href="https://www.instagram.com/betworldsportsio/">
                      <img src="/images/instagram.svg" alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={location.pathname}>
                      <img src="/images/youtube.svg" alt="youtube" />
                    </a>
                  </li>
                  <li>
                    <a href={location.pathname}>
                      <img src="/images/18plus.svg" alt="18+" />
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4}>
                  <Typography className={classes.footerHeading}>
                    {t("FOOTER.HELP_ADVICE")}
                  </Typography>

                  <List className={classes.footerWraper}>
                    {cmsData?.length > 0
                      ? cmsData.map((item) => (
                          <>
                            <ListItem
                              className={classes.footerLink}
                              onClick={() =>
                                navigate(`/cms/${item.id}/${item.slug}`, {
                                  state: item.content,
                                })
                              }
                            >
                              {/* <Link to="/" className={classes.footerLink}> */}
                              {item.title}
                              {/* </Link> */}
                            </ListItem>
                            {/* <Button
                              varient="outlined"
                              key={item.id}
                              style={{ backgroundColor: "none" }}
                              onClick={() =>
                                navigate(`/cms/${item.id}/${item.slug}`, {
                                  state: item.content,
                                })
                              }
                              className={`${classes.footerBtn} ${
                                location.pathname ===
                                `/cms/${item.id}/${item.slug}`
                                  ? classes.activeLink
                                  : null
                              }`}
                            >
                              {item.title}
                            </Button> */}
                          </>
                        ))
                      : null}
                    {/* <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Play Responsibility
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        About Us
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Terms & Conditions
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Privacy Policy
                      </Link>
                    </ListItem> */}

                    {/* <ListItem>
                    <Link to="/" className={classes.footerLink}>
                    Support
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link to="/" className={classes.footerLink}>
                    Cookie Policy
                    </Link>
                  </ListItem> */}
                  </List>
                </Grid>
                {/* {isLoggedIn && (
                  <Grid item sm={4} md={4}>
                    <Typography className={classes.footerHeading}>
                      Offers
                    </Typography>
                    <List className={classes.footerWraper}>
                      <ListItem>
                        <Link to="/user/bonus" className={classes.footerLink}>
                          Rewards & Bonuses
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/promotions" className={classes.footerLink}>
                          Promotions
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                )} */}
                {/* <Grid item sm={4} md={4}>
                  <Typography className={classes.footerHeading}>
                    Games
                  </Typography>
                  <List className={classes.footerWraper}>
                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Soccer
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Basketball
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Golf
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Baseball
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Iceball
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link to="/" className={classes.footerLink}>
                        Cricket
                      </Link>
                    </ListItem>
                  </List>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid className={classes.footerCopyright}>
        <Typography>{t("FOOTER.COPYRIGHT_MSG")}</Typography>
      </Grid>
    </div>
  );
};

export default Footer;
