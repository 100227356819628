import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  hasNext: false,
  favourites: [],
  currentPage: 1,
  totalPage: 0,
  updateToFavourite: false,
  favLoading: false,
  removeFromFavMatchId: "",
  favouritesOption: "sportsBook",
};

const FavouriteSlice = createSlice({
  name: "Favourite",
  initialState,
  reducers: {
    getFavouriteMatchesStart: (state, action) => {
      state.loading = true;
      state.error = false;
      state.updateToFavourite = false;
    },
    getFavouriteMatchesSuccess: (state, action) => {
      let currentPage = parseInt(action.payload.currentPage);
      let totalPage = parseInt(action.payload.totalPage);
      state.hasNext = currentPage < totalPage;

      if (currentPage > 1) {
        state.favourites = [
          ...state.favourites,
          ...action.payload.findFavorites,
        ];
      }

      if (currentPage === 1) {
        state.favourites = action.payload?.findFavorites;
      }

      state.loading = false;
      state.success = true;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
    },
    getFavouriteMatchesFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    addToFavouriteStart: (state, action) => {
      state.favLoading = true;
      state.error = false;
      state.success = false;
      state.addToFavMatchId = action.payload.matchId;
    },
    addToFavouriteSuccess: (state, action) => {
      let payload = action.payload;
      delete payload.isLive;
      state.favourites = state.favourites.map((favMatch) => {
        if (favMatch?.matchId === state.addToFavMatchId) {
          return {
            ...favMatch,
            FavoriteMatch: {
              ...payload,
            },
          };
        }
        return favMatch;
      });
      state.addToFavMatchId = "";
      state.success = true;
      state.favLoading = false;
    },
    addToFavouriteFailure: (state) => {
      state.addToFavMatchId = "";
      state.error = true;
      state.favLoading = false;
    },
    removeFromFavouriteStart: (state, action) => {
      state.favLoading = true;
      state.error = false;
      state.success = false;
      state.removeFromFavMatchId = action.payload.matchId;
    },
    removeFromFavouriteSuccess: (state) => {
      state.favourites = state.favourites.filter((favMatch) => {
        if (favMatch?.matchId === state.removeFromFavMatchId) {
          return false;
        }
        return true;
      });
      state.favLoading = false;
      state.success = true;
      state.removeFromFavMatchId = "";
    },
    removeFromFavouriteFailure: (state) => {
      state.favLoading = false;
      state.error = true;
    },
    setFavoritesOption: (state, action) => {
      state.favouritesOption = action.payload;
    },
  },
});

export const {
  getFavouriteMatchesStart,
  getFavouriteMatchesSuccess,
  getFavouriteMatchesFailure,
  addToFavouriteStart,
  addToFavouriteSuccess,
  addToFavouriteFailure,
  removeFromFavouriteStart,
  removeFromFavouriteSuccess,
  removeFromFavouriteFailure,
  setFavoritesOption,
} = FavouriteSlice.actions;

export default FavouriteSlice.reducer;
