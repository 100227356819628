import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  btnPrimary: {
    background: "#F7616A",
    borderRadius: "90px",
    padding: "10px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    // height: "60px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  loginLogo: {
    textAlign: "center",
    margin: "20px 0",
  },
  loginContent: {
    textAlign: "center",
    margin: "0",
    // background: "#010101",
    color: "#F7616A",
    padding: "10px",
    "& .MuiTypography-h5": {
      fontWeight: "bold",
      // textTransform: "uppercase",
      fontSize: "30px",
      textTransform: "capitalize",
    },
  },
  loginFormWrap: {
    padding: "20px 40px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      color: "#25262B",
      background: "#F2F4FF",
      borderRadius: "7px",
      fontSize: "14px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "inherit",
          "&:hover": {
            borderColor: "inherit",
          },
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px",
      borderColor: "inherit !important",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "#fff",
    },
    "& .MuiInputLabel-shrink": {
      color: "#fff",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "42px",
    fontSize: "10px",
    textDecoration: "none",
    color: "#F7616A",
  },
  authBtnWrap: {
    margin: "30px 0",
    textAlign: "center",
  },
  forgotLink: {
    margin: "0 0px 10px 0",
    textAlign: "center",
    "& .MuiButtonBase-root ": {
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "400",
      color: "#8492B7",
      lineHeight: "normal",
      textTransform: "capitalize",
      padding: "0",
    },
  },
  signUpLink: {
    textAlign: "center",
    color: "#fff",
    fontSize: "14px",
    "& .MuiButtonBase-root ": {
      textDecoration: "none",
      color: "#F7616A",
      marginLeft: "2px",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },

  dividerSpace: {
    border: "1px solid #F2F4FF",
    marginTop: "15px",
    marginBottom: "15px",
  },
  loginBottomText: {
    "& p": {
      textAlign: "center",
      padding: "0",
      margin: "0",
      fontSize: "12px",
      fontWeight: "normal",
      color: "#8492B7",
      "& a": {
        color: "#F7616A",
        textDecoration: "none",
      },
    },
  },
  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
    // marginBottom: "8px",
  },
}));
