import { createSlice } from "@reduxjs/toolkit";

import {
  allcountryObj,
  oddsFormats,
} from "../../../container/SportsBook/Utils/common";

const initialState = {
  loading: false,
  success: false,
  error: false,
  countries: [], // for prelive
  liveCountries: [], // for live
  sports: [],
  selectedSportId: "",
  selectedSportName: "",
  search: "",
  selectedSportImg: "/images/sport-book/default-sports.svg",
  selectedOddsFormat: oddsFormats[0],
};

const SportBookSlice = createSlice({
  name: "SportBook",
  initialState,
  reducers: {
    getSportsStart: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    getSportsSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.sports = action.payload;
    },
    getSportsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getCountriesStart: (state, action) => {
      state.isCountryLoading = true;
      state.error = false;
    },
    getCountriesSuccess: (state, action) => {
      state.isCountryLoading = false;
      state.success = true;
      if (action?.payload?.data) {
        if (action.payload.isLive) {
          state.liveCountries = [allcountryObj, ...action.payload.data];
        } else {
          state.countries = [allcountryObj, ...action.payload.data];
        }
      }
    },
    getCountriesFailure: (state) => {
      state.isCountryLoading = false;
      state.error = true;
    },
    updateSelectedSports: (state, action) => {
      state.selectedSportId = action?.payload?.selectedSportId;
      state.selectedSportName = action?.payload?.selectedSportName;
      state.selectedSportImg = action?.payload?.selectedSportImg;
    },
    setSearchMatchName: (state, action) => {
      state.search = action?.payload;
    },
    getSportBookBannersStart: (state, action) => {
      state.bannerLoading = true;
    },
    getSportBookBannersSuccess: (state, action) => {
      state.bannerLoading = false;
      state.lobbyBannerList = action.payload;
    },
    getSportBookBannersFailure: (state, action) => {
      state.bannerLoading = false;
      state.error = true;
    },
    setSelectedOddsFormat: (state, action) => {
      state.selectedOddsFormat = action.payload;
    },
  },
});

export const {
  setSelectedOddsFormat,
  getSportsStart,
  getSportsSuccess,
  getSportsFailure,
  getCountriesStart,
  getCountriesSuccess,
  getCountriesFailure,
  updateSelectedSports,
  setSearchMatchName,
  getSportBookBannersStart,
  getSportBookBannersSuccess,
  getSportBookBannersFailure,
} = SportBookSlice.actions;

export default SportBookSlice.reducer;
