import React from "react";

import {
  // CircularProgress,
  Grid,
} from "@mui/material";

function CasinoLoader() {
  return (
    <Grid
      // className={classes.iframeCirculerLoader}
      textAlign="center"
      padding={"5px"}
    >
      {/* <CircularProgress size={60} /> */}
      <img
        src={"/images/bwsLoader.gif"}
        alt="loader"
        style={{ width: "120px", zIndex: "10000", position: "relative" }}
      />
    </Grid>
  );
}

export default CasinoLoader;
