import { createSlice } from "@reduxjs/toolkit";

import { allcountryObj } from "../../../container/SportsBook/Utils/common";

const initialState = {
  isCountryLoading: false,
  success: false,
  error: false,
  resultCountries: [],
  currentPage: 0,
  totalPage: 0,
  preliveMatchResults: {
    loading: false,
    success: false,
    error: false,
    hasNext: false,
    results: [],
    currentPage: 0,
    totalPage: 0,
    matchResultType: [],
  },
};

const ResultsSlice = createSlice({
  name: "Results",
  initialState,
  reducers: {
    getResultCountriesStart: (state, action) => {
      state.isCountryLoading = true;
      state.error = false;
      state.success = true;
    },
    getResultCountriesSuccess: (state, action) => {
      state.resultCountries = [allcountryObj, ...action.payload];
      state.isCountryLoading = false;
      state.error = false;
    },
    getResultCountriesFailure: (state) => {
      state.isCountryLoading = false;
      state.error = true;
    },
    getResultStart: (state, action) => {
      state.preliveMatchResults.loading = true;
      state.preliveMatchResults.error = false;
      if (action?.payload?.page === 1)
        state.preliveMatchResults.hasNext = false;
    },
    getResultSuccess: (state, action) => {
      let currentPage = parseInt(action.payload.currentPage);
      let totalPage = parseInt(action.payload.totalPage);
      let hasNext = currentPage < totalPage;

      if (currentPage > 1) {
        state.preliveMatchResults.results = [
          ...state.preliveMatchResults.results,
          ...action.payload.findResult,
        ];
      }

      if (currentPage === 1) {
        state.preliveMatchResults.results = action.payload?.findResult;
      }
      state.preliveMatchResults.hasNext = hasNext;
      state.preliveMatchResults.loading = false;
      state.preliveMatchResults.success = true;
      state.preliveMatchResults.currentPage = currentPage;
      state.preliveMatchResults.totalPage = totalPage;
    },
    getResultFailure: (state) => {
      state.preliveMatchResults.loading = false;
      state.preliveMatchResults.error = true;
    },
    resetPreLiveMatchResult: (state) => {
      state.preliveMatchResults = {
        currentPage: "1",
        loading: false,
        success: false,
        error: false,
        hasNext: false,
        results: [],
        totalPage: 0,
      };
    },
  },
});

export const {
  getResultCountriesStart,
  getResultCountriesSuccess,
  getResultCountriesFailure,
  getResultStart,
  getResultSuccess,
  getResultFailure,
  resetPreLiveMatchResult,
} = ResultsSlice.actions;

export default ResultsSlice.reducer;
