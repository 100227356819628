import React, { useEffect, useState, useRef, useCallback } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";

import {
  getTransactionsStart,
  resetTransactionState,
} from "../../../redux-saga/redux/profile/transactions";
import useStyles from "./Transaction.styles";
import SportBookLoader from "../../../container/SportsBook/components/sportbookLoader";
import { getUserProfileDetailsStart } from "../../../redux-saga/redux/profile/myProfile";

const TransactionTypes = {
  wallet: [
    {
      value: "2",
      content: "TRANSACTIONS.ALL",
    },
    { value: "3", content: "Deposit" },
    { value: "4", content: "Withdraw" },
    { value: "20", content: "Bonus" },
  ],
  sportsbook: [
    { value: "2", content: "TRANSACTIONS.ALL" },
    { value: "0", content: "TRANSACTIONS.DEBIT" },
    { value: "1", content: "TRANSACTIONS.CREDIT" },
  ],
  casino: [
    { value: "2", content: "TRANSACTIONS.ALL" },
    { value: "win", content: "Win" },
    { value: "loss", content: "Loss" },
    { value: "bet", content: "Bet" },
  ],
};

const getEndDate = (fromDate = dayjs()) => {
  let fromDateWithCurrentTime = "";
  if (dayjs(fromDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
    fromDateWithCurrentTime = dayjs(fromDate).utc().format();
  } else {
    fromDateWithCurrentTime = dayjs(fromDate).endOf("day").utc().format();
  }
  return fromDateWithCurrentTime;
};
const getStartDate = (endDate) => {
  return dayjs(endDate).startOf("day").utc().format();
};
const Transaction = () => {
  const classes = useStyles();
  const { transactions, totalPages, loading } = useSelector(
    (state) => state.transactions
  );
  const currentDate = dayjs();

  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(
    new Date(currentDate.subtract(1, "month").toISOString())
  );
  const [endDate, setEndDate] = useState();
  const [calculatedStartDate, setCalculatedStartDate] = useState(
    getStartDate(new Date(currentDate.subtract(1, "month").toISOString()))
  );
  const [calculatedEndDate, setCalculatedEndDate] = useState(getEndDate());
  const [activityType, setActivityType] = useState("2");
  const [tab, setTab] = useState("sportsbook");
  const dispatch = useDispatch();
  const offset = useRef(0);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const transactionFilters = useCallback(() => {
    const filters = {};
    filters.fromDate = calculatedStartDate;
    filters.endDate = calculatedEndDate;
    if (activityType !== "2") {
      filters.transactionType = activityType;
    }
    return filters;
  }, [activityType, calculatedEndDate, calculatedStartDate]);

  useEffect(() => {
    const filters = transactionFilters();
    if (offset.current === 0) {
      dispatch(
        getTransactionsStart({ ...filters, page: 1, limit: 10, type: tab })
      );
      offset.current = 1;
    }
  }, [dispatch]);

  useEffect(() => {
    const status = searchParams.get("status");

    if (status === "0" || status === "fail") {
      toast.error(t("PAYMENT.PAYMENT_FAILED_MSG"));
    } else if (status === "success") {
      toast.success(t("PAYMENT.PAYMENT_SUCCESS_MSG"));
    }
    dispatch(getUserProfileDetailsStart());
    navigate("/user/transaction");
  }, [searchParams, dispatch, navigate, t]);

  const handlePageChange = (event, value) => {
    const filters = transactionFilters();
    dispatch(
      getTransactionsStart({ ...filters, page: value, limit: 10, type: tab })
    );
    setPage(value);
  };

  const filterData = () => {
    const filters = transactionFilters();
    dispatch(
      getTransactionsStart({ ...filters, page: 1, limit: 10, type: tab })
    );
    setPage(1);
  };

  const handleTabChange = (event, newval) => {
    setCalculatedEndDate(getEndDate(endDate));
    setCalculatedStartDate(getStartDate(fromDate));
    dispatch(resetTransactionState());
    const filters = transactionFilters();
    delete filters.transactionType;
    dispatch(
      getTransactionsStart({
        ...{
          fromDate: getStartDate(fromDate),
          endDate: getEndDate(endDate),
        },
        page: 1,
        limit: 10,
        type: newval,
      })
    );
    setTab(newval);
    setActivityType("2");
  };

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        <Typography variant="h4" gutterBottom>
          {t("TRANSACTIONS.TRANSACTIONS_UPPER")}
        </Typography>
        <br></br>
        <Grid
          display={"flex"}
          justifyContent="flex-start"
          xs={12}
          paddingY={"2rem"}
        >
          {
            <div className={classes.formGroup}>
              <TextField
                id="transaction-type"
                variant="outlined"
                select
                value={tab}
                onChange={(e) => {
                  handleTabChange(e, e.target.value);
                }}
                className={classes.sportsItem}
              >
                {" "}
                <MenuItem value={"wallet"}>{t("TRANSACTIONS.WALLET")}</MenuItem>
                <MenuItem value={"sportsbook"}>
                  {t("TRANSACTIONS.SPORTBOOK")}
                </MenuItem>
                <MenuItem value={"casino"}>{t("TRANSACTIONS.CASINO")}</MenuItem>
              </TextField>
            </div>
          }
        </Grid>
        {
          <>
            <Grid className={classes.myprofileForm}>
              <Grid className={classes.signupFromFname}>
                <Grid className={classes.dateContainer} container spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item md={6} lg={3} xs={12}>
                      <div className={classes.formGroup}>
                        <span>{t("TRANSACTIONS.FROM_DATE")}*</span>
                        <DesktopDatePicker
                          id="start-date"
                          placeholder={t("TRANSACTIONS.FROM_DATE")}
                          variant="outlined"
                          disableFuture
                          value={fromDate}
                          format="DD/MM/YYYY"
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(date) => {
                            setFromDate(date);
                            setCalculatedStartDate(getStartDate(date));
                          }}
                          maxDate={endDate ? endDate : null}
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                      <div className={classes.formGroup}>
                        <span>{t("TRANSACTIONS.TO_DATE")}*</span>
                        <DesktopDatePicker
                          id="end-date"
                          placeholder={t("TRANSACTIONS.TO_DATE")}
                          variant="outlined"
                          format="DD/MM/YYYY"
                          disableFuture
                          value={endDate}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(date) => {
                            setEndDate(date);
                            setCalculatedEndDate(getEndDate(date));
                          }}
                          minDate={fromDate ? fromDate : null}
                        />
                      </div>
                    </Grid>
                  </LocalizationProvider>
                  {
                    <Grid item md={6} lg={3} xs={12}>
                      <div className={classes.formGroup}>
                        <span>{t("TRANSACTIONS.ACTIVITY_TYPE")}</span>
                        <TextField
                          id="activity-type"
                          placeholder={t("TRANSACTIONS.ACTIVITY_TYPE")}
                          variant="outlined"
                          select
                          value={activityType}
                          onChange={(e) => {
                            setActivityType(e.target.value);
                          }}
                          disabled={tab === "all"}
                        >
                          {TransactionTypes[tab]?.map((TransactionType, i) => {
                            return (
                              <MenuItem value={TransactionType.value} key={i}>
                                {t(TransactionType.content)}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </Grid>
                  }
                  <Grid item md={6} lg={3} xs={12}>
                    <Button
                      onClick={() => filterData()}
                      className={classes.btnSecondary}
                    >
                      {t("COMMON.SHOW")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.tableContainer}>
              {loading ? (
                <Stack alignItems="center">
                  <SportBookLoader />
                </Stack>
              ) : transactions?.length > 0 ? (
                <>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("TRANSACTIONS.DATE_TIME")}</TableCell>
                            <TableCell>TXID</TableCell>
                            <TableCell>{t("TRANSACTIONS.TYPE")}</TableCell>
                            <TableCell>{t("TRANSACTIONS.AMOUNT")}</TableCell>
                            {tab !== "sportsbook" && tab !== "casino" && (
                              <TableCell>{t("TRANSACTIONS.STATUS")}</TableCell>
                            )}
                            {tab !== "casino" && (
                              <TableCell>
                                {t("TRANSACTIONS.COMMENTS")}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactions?.length > 0 &&
                            transactions.map((transaction) => (
                              <TableRow
                                key={
                                  transaction?.id || transaction?.transactionId
                                }
                              >
                                <TableCell>
                                  {dayjs(transaction.created_at).format(
                                    "DD/MM/YYYY,HH:mm"
                                  )}
                                </TableCell>
                                <TableCell>{transaction?.id || "--"}</TableCell>
                                <TableCell>
                                  {transaction.transaction_type}
                                </TableCell>
                                <TableCell>
                                  {`$ ${parseFloat(transaction.amount).toFixed(
                                    2
                                  )}`}
                                </TableCell>
                                {tab !== "sportsbook" && tab !== "casino" && (
                                  <TableCell>
                                    {transaction.status || "--"}
                                  </TableCell>
                                )}
                                {tab !== "casino" && (
                                  <TableCell>
                                    {transaction.comments || "--"}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  {totalPages > 1 && (
                    <Stack alignItems="center">
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  )}
                </>
              ) : (
                <Stack alignItems="center">
                  <h2>{t("COMMON.NO_TRANSACTION_MSG")}</h2>
                </Stack>
              )}
            </div>
          </>
        }
      </Grid>
    </div>
  );
};

export default Transaction;
