import { put, takeEvery } from "redux-saga/effects";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";

import lobbyService from "../../../../services/casino/lobby";
import {
  getFavoritesStart,
  getFavoritesSuccess,
  getFavoritesFailure,
  addRemoveFavoritesStart,
  addRemoveFavoritesSuccess,
  addRemoveFavoritesFailure,
} from "../../../redux/casino/lobby/favorites";
import { showErrorMessage } from "../../../redux/toast";
import { homePageGamesLimit } from "../../../../utils/constants";

function* favoritesWatcher() {
  yield takeEvery(
    [getFavoritesStart.type, addRemoveFavoritesStart.type],
    favoritesWorker
  );
}

function* favoritesWorker(action) {
  try {
    switch (action.type) {
      case getFavoritesStart.type: {
        const { data, error } = yield lobbyService.getfavorites({
          ...action.payload,
          loadMoreFavorites: null,
        });
        if (data) {
          yield put(
            getFavoritesSuccess({
              favorites: data?.games,
              numberOfPages: data?.numberOfPages || 0,
              favoritesCount: data?.queriedCount || 0,
              loadMoreFavorites: action.payload?.loadMoreFavorites,
              page: action.payload.page,
            })
          );
        }
        if (!data) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getFavoritesFailure());
        }
        break;
      }

      case addRemoveFavoritesStart.type: {
        const resObj = { ...action.payload };
        const { data, errors } = yield lobbyService.addRemoveFavorites(resObj);
        if (!action.payload?.favStatus) {
          yield toast.success("Added to favorites successfully");
        } else {
          yield toast.success("Removed from favorites successfully");
        }

        if (data) {
          if (!action.payload.isViewAll) {
            yield put(
              getFavoritesStart({
                limit: homePageGamesLimit,
                page: 1,
                isMobile,
              })
            );
          }

          yield put(addRemoveFavoritesSuccess(data));
        }
        if (!data) {
          yield put(showErrorMessage({ msg: errors.message }));
          yield put(addRemoveFavoritesFailure());
        }
        break;
      }

      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getFavoritesStart.type: {
        yield put(getFavoritesFailure());
        break;
      }
      case addRemoveFavoritesStart.type: {
        yield put(addRemoveFavoritesFailure());
        break;
      }

      default:
        break;
    }
    yield put(showErrorMessage({ msg: error.message }));
  }
}

export default favoritesWatcher;
