import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "5px",
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "90px",
    padding: "10px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  forgotForm: {
    maxWidth: "320px",
    margin: "0 auto",
    marginTop: "30px",
  },
  forgotFormWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "38px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  forgotFormContainer: {
    background: "#0D2867",
    borderRadius: "10px",
    marginTop: "81px",
    padding: "48px 40px",
    "& .MuiTypography-root": {
      fontSize: "30px",
      fontWeight: "bold",
      textTransform: "capitalize",
      color: "#F7616A",
      margin: "0 auto",
      maxWidth: "320px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      background: "#fff",
      borderRadius: "10px",
      color: "#25262B",
      fontSize: "14px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F7616A",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "16px",
      fontWeight: "600",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
  },
}));
