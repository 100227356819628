import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  Grid,
  Typography,
  Button,
  TextField,
  // MenuItem,
  CircularProgress,
  Autocomplete,
  Box,
  Checkbox,
} from "@mui/material";

import useStyles from "./Withdraw.styles";
import {
  getConvertedBalanceStart,
  getCurrencyStart,
  resetTarget,
  validateAddressStart,
  WithdrawStart,
} from "../../../redux-saga/redux/payments";
import SportBookLoader from "../../../container/SportsBook/components/sportbookLoader";

const Withdraw = () => {
  const timer = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const [Amount, setAmount] = useState();
  const [WalletAddress, setWalletAddress] = useState();
  // const [currency, setCurrency] = useState("USD");
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const dispatch = useDispatch();

  const {
    currencyLoading,
    convertLoading,
    success,
    withdraLoading,
    currencies,
    convertedBalance,
    convertedBalanceWithExtraCharge,
    isWithdrawAddressValid,
  } = useSelector((state) => state.payments);
  const defaultCode = useSelector(
    (state) => state.myProfile?.userDetails?.Wallet?.currencyCode
  );
  const [selectedCurrency, setselectedCurrency] = useState({});
  const amountWithExtraCharge =
    parseFloat(Amount) + (parseFloat(Amount) * 2.0) / 100.0;
  useEffect(() => {
    if (success && isWithdrawAddressValid) {
      setAmount("");
      setWalletAddress("");
      toast.success("Request Sent successfully");
      dispatch(resetTarget());
      setChecked(false);
    }
  }, [success]);

  const handleSubmitAmount = () => {
    if (Amount < 10) {
      toast.error(t("PAYMENT.MINIMUM_AMOUNT_ERROR_MSG") + " 10 " + defaultCode);
      return;
    }
    const regex = new RegExp("^[1-9][0-9]*$");
    if (!regex.test(Amount)) {
      toast.error("Please Enter Valid Amount ");
      return;
    }
    if (!selectedCurrency?.code) {
      toast.error(t("PAYMENT.SELECT_CURRENCY_ERROR_MSG"));
      return;
    }
    dispatch(
      WithdrawStart({
        fiatAmount: parseFloat(amountWithExtraCharge),
        withdrawalAddress: WalletAddress,
        withdrawalAmount: parseFloat(convertedBalance),
        currency: defaultCode,
        cryptoCurrencyCode: selectedCurrency?.code,
        actualAmount: parseFloat(convertedBalanceWithExtraCharge),
      })
    );
  };
  useEffect(() => {
    if (
      convertedBalance &&
      defaultCode &&
      amountWithExtraCharge &&
      selectedCurrency?.code
    ) {
      dispatch(
        getConvertedBalanceStart({
          currencyCode: defaultCode,
          amount: amountWithExtraCharge,
          convertToCurrencyCode: selectedCurrency?.code,
          extraCharge: true,
        })
      );
    }
  }, [convertedBalance]);
  useEffect(() => {
    dispatch(getCurrencyStart());
    return () => {
      dispatch(resetTarget());
    };
  }, []);

  useEffect(() => {
    if (currencies?.length) setselectedCurrency(currencies[0]);
  }, [currencies]);

  useEffect(() => {
    if (Amount && selectedCurrency?.code) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        dispatch(
          getConvertedBalanceStart({
            currencyCode: defaultCode,
            amount: Amount,
            convertToCurrencyCode: selectedCurrency?.code,
          })
        );
      }, 200);
    }
  }, [dispatch, Amount, selectedCurrency?.code, defaultCode]);

  useEffect(() => {
    if (selectedCurrency?.code && WalletAddress)
      dispatch(
        validateAddressStart({
          currencyCode: selectedCurrency?.code,
          address: WalletAddress,
        })
      );
  }, [WalletAddress, dispatch, selectedCurrency?.code]);

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        <Typography variant="h4" gutterBottom>
          {t("HEADER.WITHDRAW")}
        </Typography>
        <Grid className={classes.myprofileForm}></Grid>
        {currencyLoading ? (
          <SportBookLoader />
        ) : (
          <Grid className={classes.customWithdrawWrap}>
            <Grid>
              <div className={classes.signupFromFname}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.formGroup}>
                      <Autocomplete
                        value={selectedCurrency}
                        id="country-select-demo"
                        sx={{ width: 300 }}
                        options={currencies || []}
                        autoHighlight
                        onChange={(e, newValue) => {
                          dispatch(resetTarget());
                          setselectedCurrency(newValue);
                          setAmount(0);
                          setWalletAddress("");
                        }}
                        getOptionLabel={(option) => option?.code}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            className={classes.autocompletepopup}
                          >
                            {option.code}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            className={classes.sportsItem}
                            placeholder={t("COMMON.SELECT_CURRENCY")}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formGroup}>
                      <span>{`${t(
                        "TRANSACTIONS.AMOUNT"
                      )} (${defaultCode})`}</span>
                      <TextField
                        id="customAmount"
                        placeholder={`${t(
                          "TRANSACTIONS.AMOUNT"
                        )} (${defaultCode})`}
                        type={"number"}
                        variant="outlined"
                        onChange={(e) => {
                          if (!selectedCurrency?.code) {
                            toast.error(t("PAYMENT.SELECT_CURRENCY_ERROR_MSG"));
                            return;
                          }
                          setAmount(e.target.value);
                          if (!e.target.value) {
                            dispatch(resetTarget());
                          }
                        }}
                        value={Amount}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formGroup}>
                      <span>{`${t("TRANSACTIONS.AMOUNT")} (${
                        selectedCurrency?.code || ""
                      })`}</span>
                      <TextField
                        id="customAmount"
                        placeholder={
                          convertLoading
                            ? "Converting ..."
                            : `${t("TRANSACTIONS.AMOUNT")} (${
                                selectedCurrency?.code || ""
                              })`
                        }
                        type={"number"}
                        variant="outlined"
                        value={convertedBalance}
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formGroup}>
                      <span>{t("MYPROFILE.WALLET_ADDRESS")}</span>
                      <TextField
                        id="address"
                        placeholder="Wallet Address"
                        variant="outlined"
                        onChange={(e) => {
                          if (!selectedCurrency?.code) {
                            toast.error(t("PAYMENT.SELECT_CURRENCY_ERROR_MSG"));
                            return;
                          }
                          if (e.target.value?.length > 100) {
                            toast.error(
                              "wallet address can't be more than 100 characters"
                            );
                            return;
                          }
                          if (e.target.value !== "")
                            dispatch(
                              validateAddressStart({
                                currencyCode: selectedCurrency?.code,
                                address: e.target.value,
                              })
                            );
                          setWalletAddress(e.target.value);
                        }}
                        value={WalletAddress}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                    <span>
                      {t("PAYMENT.EXTRA_CHARGE_MSG") +
                        (convertedBalanceWithExtraCharge || 0.0)}
                    </span>
                  </Grid>
                  {/* <Grid style={{ display: "none" }} item xs={12}>
                  <div className={classes.formGroup}>
                    <span>currency</span>
                    <TextField
                      id="currency"
                      label="Currency"
                      placeholder="USD"
                      variant="outlined"
                      disabled
                      onChange={(e) => setCurrency(e.target.value)}
                    />
                  </div>
                </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      className={classes.btnPrimary}
                      disabled={
                        !isWithdrawAddressValid ||
                        !WalletAddress ||
                        !Amount ||
                        !selectedCurrency?.code ||
                        withdraLoading ||
                        convertLoading ||
                        !checked ||
                        !convertedBalanceWithExtraCharge
                      }
                      onClick={handleSubmitAmount}
                    >
                      {withdraLoading ? (
                        <CircularProgress size={25} />
                      ) : (
                        t("PAYMENT.REQUEST_WITHDRAW")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}

        {/* <Grid> */}
        {/* <Grid>
            <Button
              className={classes.btnPrimary}
              disabled={Amount && WalletAddress ? false : true}
              onClick={handleSubmitAmount}
            >
              Request Withdraw
            </Button>
          </Grid> */}
        {/* </Grid> */}
      </Grid>
    </div>
  );
};

export default Withdraw;
