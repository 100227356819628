import axiosClient from "../axios";

export default class advertisementService {
  static async getAdvertisementData() {
    try {
      const { data } = await axiosClient.get(
        "/user/advertisement/getAdvertisement"
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async recordAdvertisementData(params) {
    try {
      const { data } = await axiosClient.get(
        "/user/advertisement/advertisementClick",
        {
          params,
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }
}
