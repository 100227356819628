import React from "react";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

import useStyles from "./blog.style";

const ArticlePreview = ({ post }) => {
  const classes = useStyles();
  if (!post) return null;

  const excerpt = parse(post.excerpt?.rendered || "");
  const title = parse(post.title?.rendered || "Untitled");

  // Get the featured image URL from embedded data
  const featuredImage =
    post._embedded &&
    post._embedded["wp:featuredmedia"] &&
    post._embedded["wp:featuredmedia"][0].source_url;

  const postUrl = `/blog/${post.slug}`;

  return (
    <div className={classes.article}>
      <h1 className={classes.title}>{title}</h1>
      <Link to={postUrl} className={classes.blackLink}>
        {featuredImage ? (
          <img
            className={classes.articleImg}
            alt="article header"
            src={featuredImage}
          />
        ) : null}
        <div className={classes.content}>{excerpt}</div>
      </Link>
      <Link to={postUrl}>
        <button className={classes.btn}>Read More</button>
      </Link>
    </div>
  );
};

export default ArticlePreview;
