import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  modal: {
    backgroundColor: "#11162A",
  },
  modalheader: {
    padding: "1rem",
    backgroundColor: "#2F3857",
  },
}));
