import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  accountRight: {
    height: "100%",
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "30px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  myprofileForm: {
    maxWidth: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    marginRight: "20px",
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
      background: "#fff",
      borderRadius: "10px",
      "& .MuiFocused": {
        border: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "6px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  profileUpdateRight: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0 30px",
    "& .MuiButton-root": {
      width: "auto",
      height: "40px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  profileUpdateIcon: {
    borderRadius: "100%",
    marginRight: "20px",
    "& img": {
      width: "100%",
      height: "100%",
      ObjectFit: "cover",
    },
    "& .MuiAvatar-root": {
      height: "70px",
      width: "70px",
      flex: "0 0 70px",

      border: "3px solid #F7616A",
    },
    "& .MuiBadge-standard": {
      "& .MuiIconButton-root": {
        background: "#F7616A",
        padding: "2px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "24px",
        padding: "2px",
      },
    },
  },
  emailSubscription: {
    "& .MuiTypography-body1": {
      fontSize: "10px",
      textAlign: "left",
    },
  },
  myprofileBottom: {
    background: "#0D2867",
    borderRadius: "10px",
    padding: "30px 55px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      background: "#fff",
      borderRadius: "10px",
      lineHeight: "40px",
      WebkitTextFillColor: "#25262B",
      color: "#25262B",
      fontSize: "14px",
    },
    "& .Mui-disabled": {
      color: "#25262B",
      lineHeight: "40px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },

    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .MuiTypography-h4": {
      fontWeight: "600",
      textTransform: "capitalize",
      marginBottom: "50px",
      fontSize: "35px",
      color: "#F7616A",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "none",
        background: "none",
        outline: "none",
      },
    },
    "& fieldset": {
      border: "transparent",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    textAlign: "center",
    "& button": {
      "& button": {
        color: "#fff",
        height: "45px",
        padding: "6px 20px",
        fontSize: "18px",
        background: "#F7616A",
        fontWeight: "600",
        borderRadius: "50px",
        marginBottom: "10px",
        textTransform: "capitalize",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  emailBtnwrap: {
    border: "1px solid #EDAC41",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "20px",
  },
  messagesInfo: {
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  metamaskBtn: {
    marginBottom: "10px",
    background: "#F7616A",
    borderRadius: "50px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#BCC3DB",
      color: "#25262B",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "6px 10px",
    },
  },
  walletAddress: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      wordBreak: "break-all",
    },
  },
  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
  },
}));
