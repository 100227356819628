import { put, takeEvery } from "redux-saga/effects";

import {
  getResultCountriesFailure,
  getResultCountriesStart,
  getResultCountriesSuccess,
  getResultFailure,
  getResultStart,
  getResultSuccess,
} from "../../redux/results";
import ResultsService from "../../../sport-book-services/results";

function* ResultsWatcher() {
  yield takeEvery(
    [getResultCountriesStart.type, getResultStart.type],
    ResultsWorker
  );
}

function* ResultsWorker(action) {
  try {
    switch (action.type) {
      case getResultCountriesStart.type: {
        const { data, error } = yield ResultsService.getResultCountries(
          action.payload
        );
        if (data) {
          yield put(getResultCountriesSuccess(data?.finAllCountries));
        }
        if (error) {
          yield put(getResultCountriesFailure());
        }
        break;
      }
      case getResultStart.type: {
        const { data, error } = yield ResultsService.getResults(action.payload);
        if (data) {
          yield put(getResultSuccess(data));
        }
        if (error) {
          yield put(getResultFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getResultCountriesStart.type: {
        yield put(getResultCountriesFailure());
        break;
      }
      case getResultStart.type: {
        yield put(getResultFailure());
        break;
      }
      default: {
        break;
      }
    }
  }
}

export default ResultsWatcher;
