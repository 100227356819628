import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  homeBanner: {
    "& .carousel-root": {
      height: "auto",
      width: "100%",
      padding: "0",
      margin: "0",

      "& .LazyLoad": {
        "& img": {
          borderRadius: "0",
        },
      },
      "&  .MuiButtonBase-root": {
        // maxWidth: "32px",
        // borderRadius: "30px",
        // height: "100%",
        // minWidth: "20px",
        // padding: "0",
        // [theme.breakpoints.down("md")]: {

        // },
        "& img": {
          width: "20px",
          background: "#F7616A",
          borderRadius: "30px",
          minWidth: "20px",
          padding: "10px 0",
          [theme.breakpoints.down("md")]: {
            width: "15px",
          },
        },
      },
    },
  },
  sliderPrev: {
    position: "absolute",
    top: "0%",
    left: "-15px",
    cursor: "pointer",
    zIndex: "1",
    height: "100%",
    // [theme.breakpoints.down("md")]: {
    //   top: "15%",
    // },
  },
  sliderNext: {
    position: "absolute",
    top: "0%",
    right: "-15px",
    cursor: "pointer",
    height: "100%",
    // [theme.breakpoints.down("md")]: {
    //   top: "15%",
    // },
  },
  sport_banner: {
    // background: "url('/images/sport-book/sports-banner.png')",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "100% 100% !important",
    backgroundPosition: "center !important",
    minHeight: "270px",
    padding: "15px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("md")]: {
      aspectRatio: "12/3",
      minHeight: "inherit",
    },
  },
  banner_content: {
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    "& h1": {
      color: "#0f122a",
      fontWeight: "800",
      fontSize: "40px",
      lineHeight: "50px",
      marginBottom: "0",
      "& span": {
        color: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "35px",
      },
    },
    "& p": {
      color: "#fff",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "20px",
      },
    },
  },
  sports_heading: {
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
    "& .MuiTypography-root": {
      color: "#000",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
}));
