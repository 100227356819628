import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  selectOddFormat: {
    alignItems: "center",
    "& .MuiInputBase-colorPrimary": {
      width: "100%",
      background: "#BCC3DB",
      // background: "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      color: "#0D2867",
      WebkitTextFillColor: "#0D2867",
      height: "48px",
      padding: "0 20px",
      marginRight: "5px",
      fontSize: "12px",

      "&:hover": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
      "&.active": {
        background:
          "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        "& .MuiSelect-select": {
          color: "#fff",
          WebkitTextFillColor: "#fff",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 10px 10px 20px",
      color: "#25262B",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        minWidth: "70px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "none",
    },
  },
  selectTab: {
    "& .MuiPaper-root": {
      background: "#4e0077 !important",
      maxHeight: "200px",
    },
  },
  sportContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100px",
    marginTop: "20px",
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
      textAlign: "center",
      fontWeight: "bold",
      marginTop: "4px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "90px",
    },
  },
  sportbook_container: {
    // marginLeft: "70px",
    color: "#F7616A",
    padding: "20px 10px 20px 80px",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    marginTop: "103px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      marginTop: "50px",
    },
  },
  sportsFilter: {
    marginBottom: "15px",
    width: "100%",
    "& .MuiTabScrollButton-root": {
      width: "20px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      "& svg": {
        color: "#fff",
      },
    },
  },
  tab_container: {
    // marginTop: "1rem",
    "& .MuiTabs-root": {
      display: "flex",
      alignItems: "center",
    },
    paddingTop: "0",
    "& .MuiTabs-scroller": {
      margin: "0 10px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: "0.3",
    },
    "& MuiTabs-indicator": {
      background: "transparent",
    },
    "& .MuiTabScrollButton-root": {
      width: "20px",
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      borderRadius: "30px",
      height: "60px",
      "& svg": {
        color: "#fff",
      },
    },
  },
  search_container: {
    paddingTop: "0",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      color: "#000",
      margin: 0,
    },
    "& .MuiFormLabel-root": {
      color: "#000",
    },
    "& .MuiInputBase-root": {
      color: "#000",
      borderRadius: "30px",
      border: "1px solid #F7616A",
      minWidth: "150px",
      background: "#F2F4FF",
      padding: "5px 10px",
      fontSize: "14px",
      "& svg": {
        color: "#F7616A",
      },
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  sportbetSearchWrap: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flex: "1",
    marginLeft: "0px",
    "& img": {
      position: "absolute",
      left: "15px",
      top: "0px",
      bottom: "0px",
      margin: "auto",
      // [theme.breakpoints.down("lg")]: {
      //   top: "8px",
      // },
    },
    "& .MuiInputBase-root": {
      paddingLeft: "50px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginRight: "5px",
    },
  },
  matchDetailsCard: {
    background: "#1c2251",
    borderRadius: "9px",
    border: "1px solid #F7616A",
    overflow: "hidden",
    "& ul": {
      display: "flex",
      padding: "0",
      alignItems: "center",
      margin: "0",
      "& li": {
        flexFlow: "row",
        display: "flex",
        justifyContent: "space-between",
        // minWidth: "110px",
        cursor: "pointer",
        // padding:"12px 10px",
        "& .MuiButtonBase-root": {
          padding: "5px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#fff",
          flexFlow: "column",
          fontWeight: "600",
          "&:hover": {
            background:
              "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
            color: "#fff",
          },
        },
        "& h5": {
          margin: "0",
          fontSize: "",
        },
        "&:hover": {
          background:
            "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
        },
      },
    },
  },
  sportsIconCard: {
    height: "60px",
    width: "60px",
    background: "#1b214f",
    borderRadius: "100%",
    minWidth: "60px",
    margin: "0 5px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "40px",
      width: "40px",
      minWidth: "40px",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "20px",
      },
      width: "30px",
      margin: "0 auto",
    },
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
    "&.clicked": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    },
  },
  betslip_open_btn: {
    position: "fixed",
    right: "20px",
    bottom: "5px",
    zIndex: "2",
    // width: "4rem",
    height: "2.5rem",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    "&:hover::before": {
      // eslint-disable-next-line quotes
      content: '"BetSlip"',
    },
    "&:hover": {
      // width: "11rem",
    },
    "& img": {
      // padding: "0.5rem",
      width: "30px",
    },
  },
}));
