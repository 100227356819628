import React, { useCallback } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Game from "../../../Game";
import useStyles from "./Search.styles";
import { getSearchedGamesStart } from "../../../../../redux-saga/redux/casino/lobby/casinoGames";
import CasinoLoader from "../../../CasinoLoader";

const GamesSection = ({
  selectedCategory,
  selectedProvider,
  searchInput,
  offset,
  makeProviderCategoryFilter,
  setShowSearch,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { searchGames, searchLoading, searchCount, moreSearchLoading } =
    useSelector((state) => state.casinoGames);
  const dispatch = useDispatch();

  const handleMoreGames = () => {
    offset.current += 1;
    const providerCategoryFilter = makeProviderCategoryFilter();

    dispatch(
      getSearchedGamesStart({
        page: offset.current,
        limit: 12,
        search: searchInput?.length ? searchInput : null,
        loadMoreSeachedGames: true,
        ...providerCategoryFilter,
      })
    );
  };

  const getFilterMsg = useCallback(() => {
    let msg = t("COMMON.NO_GAMES_FOUND");
    if (
      !Object.keys(selectedProvider)?.length &&
      !Object.keys(selectedCategory)?.length &&
      !searchInput?.length
    ) {
      msg = t("COMMON.NO_FILTER_MSG");
    }

    return (
      <Typography variant="h6" align="justify">
        {msg}
      </Typography>
    );
  }, [searchInput, selectedCategory, selectedProvider]);

  return (
    <>
      <Grid>
        <Grid
          container
          spacing={2}
          className={
            !searchGames?.length || searchLoading ? classes.searchGames : ""
          }
        >
          {searchLoading ? (
            <Grid display={"flex"} justifyContent={"center"}>
              <CasinoLoader />
            </Grid>
          ) : searchGames?.length > 0 ? (
            searchGames.map((game, index) => (
              <Game
                key={`${game.masterCasinoGameId}${index}`}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                gameId={game.masterCasinoGameId}
                // filterName={formatCategoryName(name)}
                // filterBy={"Provider"}
                setShowSearch={setShowSearch}
                providerName={game?.MasterCasinoProvider?.name}
                categoryName={game?.MasterGameSubCategory?.name}
                fav={"search"}
              />
            ))
          ) : (
            <Grid className={classes.searchImg}>
              <img src="/images/no-search-img.png" alt="Search" />
              {getFilterMsg()}
            </Grid>
          )}
        </Grid>
        <Grid textAlign="center">
          {!!(
            searchGames?.length &&
            !moreSearchLoading &&
            !searchLoading &&
            searchCount > searchGames?.length
          ) && (
            <Button
              onClick={() => handleMoreGames()}
              className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
            >
              Load More Games
            </Button>
          )}
          {moreSearchLoading && (
            <Typography>
              <CasinoLoader />
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GamesSection;
