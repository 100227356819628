import dayjs from "dayjs";

/**
 * To check the email validation
 * @function validateEmail
 *
 * @param {string} email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const validate = String(email).match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return !!validate;
};

export const validateEmailOrUserName = (email) => {
  const validate =
    String(email).match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) || String(email).match(/^[A-Za-z][A-Za-z0-9_]{3,30}$/);
  return !!validate;
};

export const validateUserName = (email) => {
  const validate = String(email).match(/^[A-Za-z][A-Za-z0-9_]{3,30}$/);
  return !!validate;
};
/**
 * To check if two passwords match
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
export const isMatchPassword = (password, confirmPassword) => {
  return password === confirmPassword;
};
/**
 * Encode the password using TextEncoder
 *
 * @param {string} password
 * @returns
 */
export function encodePassword(password) {
  const encoder = new TextEncoder();
  const data = encoder.encode(password);
  const base64 = btoa(String.fromCharCode(...data));
  return base64;
}

/**
 * To check if password is alphanumeric
 * and between 4 and 8 characters
 *
 * @param {string} password
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  const validate = String(password).match(
    /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z][A-Za-z0-9 !"#$%&'()*+,-.\\/:;<=>?@[\]^_`{|}~]{3,}$/
  );
  return !!validate;
};

/**
 * To check Phone Number Validation
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validatePhoneNumber = (string) => {
  const validate = String(string).match(
    /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
  );
  return !!validate;
};

/**
 * To check string contain only numbers
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateNumber = (string) => {
  const validate = String(string).match(/^[0-9]*$/);
  return !!validate;
};

/**
 * To check string contain only letters
 * Vaidates for a-z and A-Z and white space
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateLetters = (string) => {
  const validate = String(string).match(/^[a-zA-Z ]+$/);
  return !!validate;
};
export const validateCharLimit = (string) => {
  return string?.length <= 20;
};
export const validateLimit = (string) => {
  return string?.length <= 30;
};

export const formatCategoryName = (name) => {
  if (name) {
    let tempName = name.replace("-", " ");
    tempName = tempName.charAt(0).toUpperCase() + tempName.slice(1);
    return tempName;
  }
  return "";
};

export const ageValidation = (date) => {
  const currentDate = dayjs();

  const givenDate = dayjs(date).startOf("day");

  // Calculate the difference between the current date and the given date
  const ageInYears = currentDate.diff(givenDate, "year");

  // Check if the age is less than 18 years
  if (ageInYears < 18) {
    return false;
  } else {
    return true;
  }
};

export function getErrorObj(error) {
  if (error?.data?.status) return { error: error?.data?.status };
  if (
    error &&
    error?.data &&
    error?.data?.errors &&
    error?.data?.errors?.length &&
    error?.data?.errors?.[0]?.description
  ) {
    return { error: error?.data?.errors?.[0]?.description };
  }
  return { error: "" };
}
