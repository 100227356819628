import React, { useEffect } from "react";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline, Grid } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import { toast, ToastBar, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import { currentOrientation } from "./redux-saga/redux/settings";
import AppRoutes from "./App.routes";
import getDarkTheme from "./theme/dark";
import i18n from "./i18n";
import ErrorBoundary from "./components/ErrorBoundary";

import "./App.css";

const handleToastClick = (id) => {
  toast.dismiss(id);
};
function App() {
  let selectTheme = getDarkTheme();
  const theme = createTheme(selectTheme);
  const dispatch = useDispatch();
  useEffect(() => {
    var mediaQueryRule = window.matchMedia("(orientation: portrait)");
    mediaQueryRule.onchange = function () {
      if (window.matchMedia("(orientation: portrait)").matches) {
        dispatch(currentOrientation("PORTRAIT"));
      }
      if (window.matchMedia("(orientation: landscape)").matches) {
        dispatch(currentOrientation("LANSCAPE"));
      }
    };
  }, [dispatch]);
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
            <Toaster position="top-right">
              {(t) => (
                <Grid onClick={() => handleToastClick(t.id)}>
                  <ToastBar toast={t} />
                </Grid>
              )}
            </Toaster>
          </I18nextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
