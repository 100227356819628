import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  footerWrap: {
    background: "#0d0d0d",
    padding: "30px 40px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0 0px",
    },
  },
  footerPartnerListing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexWrap: "nowrap",
      overflowX: "auto",
      justifyContent: "flex-start",
    },
    "& .MuiLink-root": {
      width: "12%",
      padding: "10px 5px",
      margin: "0 5px",
      [theme.breakpoints.down("md")]: {
        width: "auto",
      },
    },
  },
  footerLinking: {
    padding: "10px 15px",
    borderTop: " 2px solid rgba(0,0,0,.3)",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },

    "& .MuiLink-underlineAlways": {
      textDecoration: "none",
      color: "#fff",
      fontSize: "14px",
      padding: "10px 40px",
      [theme.breakpoints.down("md")]: {
        padding: "10px",
        whiteSpace: "nowrap",
        fontSize: "12px",
      },
    },
  },
  footerCopyright: {
    background: "#0D2867",
    padding: "20px 100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
      flexFlow: "column-reverse",
    },
    "& .MuiTypography-body1": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#F2F4FF",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        marginTop: "10px",
        fontSize: "11px",
      },
    },
  },
  socialListing: {
    display: "flex",
    alignItems: "center",
    "& .MuiLink-underlineAlways": {
      margin: "0 5px",
      display: "flex",
    },
  },
  cmsMainContent: {
    color: "#000",
    marginLeft: "80px",
    marginTop: "110px",
    // backgroundColor:"#BCC3DB"
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      padding: "0 15px",
    },
  },
  socialLinks: {
    "& ul": {
      padding: "0",
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      margin: "0",
      "& li a": {
        padding: "10px 5px",
        display: "inline-block",
      },
    },
  },
  footerSection: {
    background: "#F2F4FF",
    width: "100%",
    borderTop: "1px solid #8492B7",
    padding: "38px 0",
  },
  footerHeading: {
    fontWeight: "600",
    fontSize: "23px",
    lineHeight: "38px",
    color: "#0D2867",
    minHeight: "38px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  footerLink: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "19px",
    color: "#0D2867",
    textDecoration: "none",
    cursor: "pointer",
  },

  footerWraper: {
    "& .MuiListItem-padding": {
      padding: "8px 0px",
    },
  },

  footerLogo: {
    borderBottom: "1px solid #B9CBF4",
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
  },
}));
