import { put, takeEvery, call } from "redux-saga/effects";

import paymentService from "../../../services/payments";
import {
  DepositStart,
  DepositFailure,
  DepositSuccess,
  WithdrawStart,
  WithdrawSuccess,
  WithdrawFailure,
  getCurrencyStart,
  getCurrencySuccess,
  getCurrencyFailure,
  getConvertedBalanceStart,
  getConvertedBalanceSuccess,
  getGeneratedAddressSuccess,
  getGeneratedAddressFailure,
  getGeneratedAddressStart,
  chargeMoneyCreateSuccess,
  chargeMoneyCreateFailure,
  chargeMoneyCreateStart,
  validateAddressStart,
  validateAddressSuccess,
  validateAddressFailure,
  getConvertedBalanceFailure,
  validatePromoCodeStart,
  validatePromoCodeSuccess,
  validatePromoCodeFailure,
} from "../../redux/payments";
import { showErrorMessage } from "../../redux/toast";

function* paymentsWatcher() {
  yield takeEvery(DepositStart.type, paymentsWorker);
  yield takeEvery(WithdrawStart.type, withdrawWorker);
  yield takeEvery(getCurrencyStart.type, currenciesWorker);
  yield takeEvery(getConvertedBalanceStart.type, getConvertedBalanceSaga);
  yield takeEvery(getGeneratedAddressStart.type, getGenerateAddressSaga);
  yield takeEvery(chargeMoneyCreateStart.type, chargeMoneyCreateSaga);
  yield takeEvery(validateAddressStart.type, validateAddressSaga);
  yield takeEvery(validatePromoCodeStart.type, validatePromoCodeSaga);
}

function* validateAddressSaga(action) {
  try {
    const { data, error } = yield call(
      paymentService.validateAddress,
      action.payload
    );
    if (!error) {
      if (`${data?.status}` === "200") {
        yield put(validateAddressSuccess(true));
      } else {
        yield put(showErrorMessage({ msg: "Please Enter Correct Address" }));
        yield put(validateAddressSuccess(false));
      }
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(validateAddressFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(validateAddressFailure());
  }
}

function* currenciesWorker(action) {
  try {
    const { data, error } = yield call(
      paymentService.getCurrencies,
      action.payload
    );
    if (data) {
      yield put(getCurrencySuccess(data?.data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getCurrencyFailure(error));
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getCurrencyFailure(error));
  }
}

function* paymentsWorker(action) {
  try {
    const { data, error } = yield paymentService.depositRequest(action.payload);
    if (!error) {
      yield put(DepositSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(DepositFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(DepositFailure());
  }
}

function* withdrawWorker(action) {
  try {
    const { data, error } = yield paymentService.withdrawRequest(
      action.payload
    );
    if (!error) {
      yield put(WithdrawSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(WithdrawFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(WithdrawFailure());
  }
}

function* getConvertedBalanceSaga(action) {
  try {
    const { data, error } = yield call(
      paymentService.getConvertedBalance,
      action.payload
    );
    if (!error) {
      if (`${data?.status}` === "200") {
        yield put(
          getConvertedBalanceSuccess({
            ...data?.data,
            extraCharge: action.payload.extraCharge,
          })
        );
      } else {
        yield put(showErrorMessage({ msg: data?.message }));
        yield put(getConvertedBalanceFailure());
      }
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getConvertedBalanceFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getConvertedBalanceFailure());
  }
}

function* getGenerateAddressSaga(action) {
  try {
    const { data, error } = yield call(
      paymentService.getGenerateAddress,
      action.payload
    );
    if (!error) {
      if (`${data?.status}` === "200") {
        yield put(getGeneratedAddressSuccess(data?.data));
      } else {
        yield put(showErrorMessage({ msg: data?.message }));
        yield put(getGeneratedAddressFailure());
      }
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(getGeneratedAddressFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(getGeneratedAddressFailure());
  }
}
function* chargeMoneyCreateSaga(action) {
  try {
    const { data, error } = yield call(
      paymentService.chargeMoneyCreate,
      action.payload
    );
    if (!error) {
      if (`${data?.status}` === "200") {
        yield put(chargeMoneyCreateSuccess(data?.data));
      } else {
        yield put(showErrorMessage({ msg: data?.message }));
        yield put(chargeMoneyCreateFailure());
      }
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(chargeMoneyCreateFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(chargeMoneyCreateFailure());
  }
}
function* validatePromoCodeSaga(action) {
  try {
    const { promoCode, bonusKind } = action.payload;
    const response = yield call(
      paymentService.validatePromoCode,
      promoCode,
      bonusKind
    );

    if (response.data.successful) {
      yield put(validatePromoCodeSuccess(response.data.result));
    } else {
      const errorMessage =
        response.data.errors && response.data.errors.length > 0
          ? response.data.errors[0]
          : "Validation failed";
      yield put(validatePromoCodeFailure(errorMessage));
    }
  } catch (error) {
    yield put(validatePromoCodeFailure(error.message || "An error occurred"));
  }
}

export default paymentsWatcher;
