import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class ResultsService {
  static getResultCountries(params) {
    return axiosClient
      .get("/betting/getResultCountries", {
        params,
      })
      .then(({ data }) => data)
      .catch((error) => {
        return getErrorObj(error);
      });
  }

  static getResults(params) {
    !params.search && delete params.search;
    if (params?.sportCountryId === "all") delete params.sportCountryId;
    if (params?.countryId === "all") delete params.countryId;

    return axiosClient
      .get("/betting/getResults", {
        params,
      })
      .then(({ data }) => data)
      .catch((error) => {
        return getErrorObj(error);
      });
  }
}
