import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en",
};

const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguageBE: (state, action) => {
      state.language = action.payload;
    },
  },
});
export const { changeLanguageBE } = LanguageSlice.actions;

export default LanguageSlice.reducer;
