import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postDetail: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#fff",
    color: "#000",
    position: "relative",
    fontFamily: "'Poppins', sans-serif",
  },
  title: {
    fontSize: "2rem",
    marginBottom: "20px",
    textAlign: "center",
    marginTop: "60px",
    fontFamily: "'Poppins', sans-serif",
  },
  articleImage: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "12px",
    borderRadius: "8px",
  },
  content: {
    fontSize: "1rem",
    lineHeight: "1.6",
    marginBottom: "12px",
    fontFamily: "'Poppins', sans-serif",
  },
  backButton: {
    display: "inline-block",
    padding: "8px 16px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#1b214f",
    border: "none",
    borderRadius: "4px",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
}));

export default useStyles;
