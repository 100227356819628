import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    color: "#fff",
    borderRadius: "50px",
    padding: "6px 30px",
    // color: "#0D2867",
    fontSize: "14px",
    fontWeight: "400",
    height: "43px",
    textTransform: "capitalize",
    width: "auto",
    margin: "0 10px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "82px",
      height: "43px",
    },
  },

  contentWrap: {
    width: "100%",
    marginTop: "1rem",
    padding: "0px 15px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px",
    },
  },

  gamesWrapper: {
    "& .MuiGrid-item": {
      float: "left",
      width: "calc(100% / 10) !important",
      marginRight: "0 !important",
      [theme.breakpoints.down("xl")]: {
        width: "calc(100% / 6) !important",
      },

      [theme.breakpoints.down("lg")]: {
        width: "calc(100% / 5) !important",
      },

      [theme.breakpoints.down("md")]: {
        width: "calc(100% / 4) !important",
      },

      [theme.breakpoints.down("sm")]: {
        width: "calc(100% / 2) !important",
      },
    },
  },

  noGamesMsg: {
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    color: "#0D2867",
  },
  circulerLoader: {
    margin: "auto",
  },
  loadMoreButton: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  moreGamesLoader: {
    marginBottom: "-20px",
    marginTop: "40px",
  },
}));
