import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  prelivematches: {
    currentPage: "1",
    matches: [],
    hasNext: false,
    totalPage: 0,
    loading: false,
    success: false,
  },
  livematches: {
    currentPage: "1",
    matches: [],
    hasNext: false,
    totalPage: 0,
    loading: false,
    success: false,
  },
  currentFavMatchId: "",
};

const MatchesSlice = createSlice({
  name: "Matches",
  initialState,
  reducers: {
    getMatchesStart: (state, action) => {
      if (action.payload.isLive) {
        state.livematches.loading = true;
        state.livematches.error = false;
        state.livematches.success = false;
        if (action.payload.page === 1) state.livematches.hasNext = false;
      } else {
        state.prelivematches.loading = true;
        state.prelivematches.error = false;
        if (action.payload.page === 1) state.prelivematches.hasNext = false;
      }
    },
    getMatchesSuccess: (state, action) => {
      if (action?.payload?.data) {
        let currentPage = parseInt(action?.payload?.data?.currentPage);
        let totalPage = parseInt(action?.payload?.data?.totalPage);

        if (action.payload.isLive) {
          let Livematches = {
            currentPage: currentPage,
            matches: [],
            hasNext: currentPage < totalPage,
            totalPage: totalPage,
            loading: false,
            success: true,
          };
          if (currentPage > 1) {
            state.livematches = {
              ...Livematches,
              matches: [
                ...state.livematches.matches,
                ...action.payload.data.findMatches,
              ],
            };
          }

          if (currentPage === 1) {
            state.livematches = {
              ...Livematches,
              matches: action.payload.data.findMatches,
            };
          }
        } else {
          let preLivematches = {
            currentPage: currentPage,
            matches: [],
            hasNext: currentPage < totalPage,
            totalPage: totalPage,
            loading: false,
            success: true,
          };
          if (currentPage > 1) {
            state.prelivematches = {
              ...preLivematches,
              matches: [
                ...state.prelivematches.matches,
                ...action.payload.data.findMatches,
              ],
            };
          }

          if (currentPage === 1) {
            state.prelivematches = {
              ...preLivematches,
              matches: action.payload.data.findMatches,
            };
          }
        }
      }
    },
    getMatchesFailure: (state) => {
      state.prelivematches.loading = false;
      state.prelivematches.error = true;
      state.livematches.loading = false;
      state.livematches.error = true;
    },
    resetLiveMatches: (state) => {
      state.livematches = {
        currentPage: "1",
        matches: [],
        hasNext: false,
        totalPage: 0,
      };
    },
    resetPreLiveMatches: (state) => {
      state.prelivematches = {
        currentPage: "1",
        matches: [],
        hasNext: false,
        totalPage: 0,
      };
    },
    updateLiveMatchDetails: (state, action) => {
      //not using this anywhere currently
      state.livematches = state.livematches.map((match) => {
        if (
          match?.providerMatchId === action.payload.providerMatchId &&
          match?.tournaments?.providerTournamentId ===
            action?.payload?.tournaments?.providerTournamentId
        ) {
          return {
            betStatus: action?.payload?.betStatus,
            isLive: action?.payload?.isLive,
            matchMarket: match?.matchMarket?.map((oldmarket) => {
              const filterMarket = action?.payload.matchMarket.filter(
                (newMarket) =>
                  newMarket?.matchMarketId == oldmarket?.matchMarketId
              );
              if (filterMarket && filterMarket?.length) {
                return {
                  ...oldmarket,
                  outcome: oldmarket.outcome?.map((oldoutcome) => {
                    const filteredOutcome = filterMarket[0]?.outcome.filter(
                      (newoutcome) =>
                        newoutcome?.providerOutcomeId ===
                        oldoutcome?.providerOutcomeId
                    );
                    if (filteredOutcome && filteredOutcome?.length) {
                      return {
                        ...oldoutcome,
                        type: filteredOutcome[0]?.type,
                        odd: filteredOutcome[0]?.odd,
                      };
                    }
                    return oldoutcome;
                  }),
                };
              }
              return oldmarket;
            }),
          };
        }

        return match;
      });
    },
    updateMarketsWithSocketData: (state, action) => {
      if (action?.payload?.isLive) {
        const filteredMatch = state?.livematches.matches.filter((match) => {
          return match?.providerMatchId === action?.payload?.providerMatchId;
        });
        if (
          filteredMatch &&
          filteredMatch?.length &&
          filteredMatch[0]?.matchMarket &&
          filteredMatch[0]?.matchMarket?.length
        ) {
          const filteredMarket = filteredMatch[0]?.matchMarket?.filter(
            (market) => {
              if (
                action?.payload?.settledMarkets?.find(
                  (settledMarket) =>
                    settledMarket?.matchMarketId == market?.matchMarketId
                )
              )
                return false;
              return true;
            }
          );

          if (filteredMarket && filteredMarket?.length) {
            filteredMatch[0]["matchMarketId"] = filteredMarket;
          }

          state.livematches.matches = state?.livematches.matches?.map(
            (match) => {
              if (
                match?.providerMatchId === filteredMatch[0]?.providerMatchId
              ) {
                return filteredMatch[0];
              }
              return match;
            }
          );
        }
      } else {
        const filteredMatch = state?.prelivematches.matches.filter((match) => {
          return match?.providerMatchId === action?.payload?.providerMatchId;
        });
        if (
          filteredMatch &&
          filteredMatch?.length &&
          filteredMatch[0]?.matchMarket &&
          filteredMatch[0]?.matchMarket?.length
        ) {
          const filteredMarket = filteredMatch[0]?.matchMarket?.filter(
            (market) => {
              if (
                action?.payload?.settledMarkets?.find(
                  (settledMarket) =>
                    settledMarket?.matchMarketId == market?.matchMarketId
                )
              )
                return false;
              return true;
            }
          );

          if (filteredMarket && filteredMarket?.length) {
            filteredMatch[0]["matchMarketId"] = filteredMarket;
          }

          state.prelivematches.matches = state?.prelivematches.matches.map(
            (match) => {
              if (
                match?.providerMatchId === filteredMatch[0]?.providerMatchId
              ) {
                return filteredMatch[0];
              }
              return match;
            }
          );
        }
      }
    },
    addToDashBoardFavouriteStart: (state, action) => {
      state.favLoading = true;
      state.error = false;
      state.success = false;
      state.currentFavMatchId = action.payload.matchId;
    },
    addToDashBoardFavouriteSuccess: (state, action) => {
      let payload = action.payload;
      if (!action.payload.isLive) {
        delete payload.isLive;
        const prelivematch = state.prelivematches.matches.map((favMatch) => {
          if (favMatch?.matchId === state.currentFavMatchId) {
            return {
              ...favMatch,
              FavoriteMatch: {
                ...payload,
              },
            };
          }
          return favMatch;
        });
        state.prelivematches.matches = prelivematch;
      } else {
        delete payload.isLive;
        state.livematches.matches = state.livematches.matches.map(
          (favMatch) => {
            if (favMatch?.matchId === state.addToFavMatchId) {
              return {
                FavoriteMatch: {
                  ...payload,
                },
                ...favMatch,
              };
            }
            return favMatch;
          }
        );
      }
      state.currentFavMatchId = "";
      state.success = true;
      state.favLoading = false;
    },
    addToDashBoardFavouriteFailure: (state) => {
      state.currentFavMatchId = "";
      state.error = true;
      state.favLoading = false;
    },
    removeFromDashBoardFavouriteStart: (state, action) => {
      state.favLoading = true;
      state.error = false;
      state.success = false;
      state.currentFavMatchId = action.payload.matchId;
    },
    removeFromDashBoardFavouriteSuccess: (state, action) => {
      if (!action.payload.isLive) {
        const prelivematch = state.prelivematches.matches.map((favMatch) => {
          if (favMatch?.matchId === state.currentFavMatchId) {
            return {
              ...favMatch,
              FavoriteMatch: null,
            };
          }
          return favMatch;
        });
        state.prelivematches.matches = prelivematch;
      } else {
        state.livematches.matches = state.livematches.matches.map(
          (favMatch) => {
            if (favMatch?.matchId === state.currentFavMatchId) {
              return {
                ...favMatch,
                FavoriteMatch: null,
              };
            }
            return favMatch;
          }
        );
      }
      state.currentFavMatchId = "";
      state.favLoading = false;
      state.success = true;
    },
    removeFromDashBoardFavouriteFailure: (state) => {
      state.favLoading = false;
      state.error = true;
      state.currentFavMatchId = "";
    },
  },
});

export const {
  getMatchesStart,
  getMatchesSuccess,
  getMatchesFailure,
  resetLiveMatches,
  resetPreLiveMatches,
  updateLiveMatchDetails,
  updateMarketsWithSocketData,
  addToDashBoardFavouriteStart,
  addToDashBoardFavouriteSuccess,
  addToDashBoardFavouriteFailure,
  removeFromDashBoardFavouriteStart,
  removeFromDashBoardFavouriteSuccess,
  removeFromDashBoardFavouriteFailure,
} = MatchesSlice.actions;

export default MatchesSlice.reducer;
