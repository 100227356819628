import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class MoreMarketService {
  static async getMoreMarkets({
    page,
    limit,
    sportCountryId,
    isLive,
    sportId,
    providerMatchId,
    countryId,
  }) {
    try {
      const { data } = await axiosClient.get(
        `/betting/getSingleMatch?page=${page}&limit=${limit}&sportCountryId=${sportCountryId}&isLive=${isLive}&sportId=${sportId}&providerMatchId=${providerMatchId}&countryId=${countryId}`
      );
      return data;
    } catch (error) {
      return getErrorObj(error);
    }
  }
}
