import React, { useEffect, useState } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isMobileOnly } from "react-device-detect";

import useStyle from "./matchResults.style.js";
import ScrollableTab from "../components/ScrollbarTabs/index.js";
import {
  getResultCountriesStart,
  getResultStart,
  // resetPreLiveMatchResult,
} from "../../../sport-book-saga/redux/results/index.js";
import Countries from "../components/Countries";
import {
  allcountryObj,
  getName,
  getProviderTeamId,
  getTeamName,
} from "../Utils/common.js";
import LoadMore from "../components/LoadMore/index.js";
import TeamImg from "../components/TeamImg/index.js";
import SportBookLoader from "../components/sportbookLoader/index.js";

dayjs.extend(utc);

// function HandleEmptyMatchResult({ result, matchResultType }) {
//   if (result?.MatchResults?.length !== matchResultType?.length) {
//     let emptyResult = [];
//     for (
//       let itration = 0;
//       itration < matchResultType?.length - result?.MatchResults?.length;
//       itration++
//     ) {
//       emptyResult = [
//         ...emptyResult,
//         <TableCell key={itration}>
//           <Grid>
//             <Grid>{"-"}</Grid>
//             <Grid>{"-"}</Grid>
//           </Grid>
//         </TableCell>,
//       ];
//     }

//     return emptyResult;
//   }
// }
const getEndDate = (fromDate = dayjs()) => {
  let fromDateWithCurrentTime = "";
  if (dayjs(fromDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
    fromDateWithCurrentTime = dayjs(fromDate).utc().format();
  } else {
    fromDateWithCurrentTime = dayjs(fromDate).endOf("day").utc().format();
  }
  return fromDateWithCurrentTime;
};
const getStartDate = (endDate) => {
  return dayjs(endDate).startOf("day").utc().format();
};
function MatchResults() {
  const { t } = useTranslation();
  const currentDate = dayjs();
  // Add 7 days to the current date
  const earlierDate = currentDate.subtract(7, "day");
  //console.log("earlierDate", earlierDate);

  const { language } = useSelector((state) => state.sportbook.language);
  const classes = useStyle();
  const [selectedCountryId, setSelectedCountryId] = useState(
    allcountryObj.sportCountryId
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryProviderId, setSelectedCountryProviderId] = useState(
    allcountryObj.providerCountryId
  );
  const search = useSelector((state) => state.sportbook.sports.search);
  const { isCountryLoading, resultCountries } = useSelector(
    (state) => state.sportbook.matchResult
  );
  const { currentPage, loading, hasNext, results } = useSelector(
    (state) => state.sportbook.matchResult.preliveMatchResults
  );
  const { selectedSportId, selectedSportName, selectedSportImg } = useSelector(
    (state) => state.sportbook.sports
  );
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date(earlierDate.toISOString()));
  const [endDate, setEndDate] = useState();
  const [calculatedStartDate, setCalculatedStartDate] = useState(
    getStartDate(new Date(earlierDate.toISOString()))
  );
  const [calculatedEndDate, setCalculatedEndDate] = useState(getEndDate());
  const loadmoreMatches = () => {
    dispatch(
      getResultStart({
        sportsId: selectedSportId,
        isLive: false,
        page: parseInt(currentPage) + 1,
        limit: 10,
        sportCountryId: selectedCountryId,
        countryId: selectedCountryProviderId,
        search,
        fromDate: calculatedStartDate,
        endDate: calculatedEndDate,
      })
    );
  };

  useEffect(() => {
    if (selectedSportId && selectedCountryId && selectedCountryProviderId) {
      setCalculatedEndDate(getEndDate(endDate));
      setCalculatedStartDate(getStartDate(date));
      dispatch(
        getResultStart({
          sportsId: selectedSportId,
          isLive: false,
          page: 1,
          limit: 10,
          sportCountryId: selectedCountryId,
          countryId: selectedCountryProviderId,
          search,
          fromDate: getStartDate(date),
          endDate: getEndDate(endDate),
        })
      );
    }
  }, [dispatch, selectedCountryId, selectedCountryProviderId, date, endDate]);

  useEffect(() => {
    if (selectedSportId) {
      setSelectedCountry(0);
      setSelectedCountryId(allcountryObj?.sportCountryId);
      setSelectedCountryProviderId(allcountryObj?.providerCountryId);
      setCalculatedEndDate(getEndDate(endDate));
      setCalculatedStartDate(getStartDate(date));
      dispatch(
        getResultStart({
          sportsId: selectedSportId,
          isLive: false,
          page: 1,
          limit: 10,
          sportCountryId: "all",
          countryId: "all",
          search,
          fromDate: getStartDate(date),
          endDate: getEndDate(endDate),
        })
      );
      dispatch(
        getResultCountriesStart({
          sportId: selectedSportId,
          isLive: false,
          search,
        })
      );
    }
  }, [dispatch, selectedSportId, search]);

  const handleCountryTabClick = ({
    sportCountryId,
    countryName,
    currentTab,
    providerCountryId,
  }) => {
    setSelectedCountry(currentTab);
    setSelectedCountryId(sportCountryId);
    setSelectedCountryProviderId(providerCountryId);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} lg={12} className={classes.tab_container}>
        <ScrollableTab
          tabsContent={resultCountries}
          value={selectedCountry}
          list={resultCountries}
          Container={Countries}
          handleTabClick={handleCountryTabClick}
          loading={isCountryLoading}
        />
      </Grid>
      <Grid className={classes.SportsTableHeader}>
        <Grid className={classes.left_container}>
          <img src={selectedSportImg} alt="Soccer" />
          <Typography>{getName(selectedSportName, language)}</Typography>
        </Grid>
        {isMobileOnly ? null : (
          <Grid className={classes.rights_container}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n?.language || "en"}
            >
              <div>
                <div className={classes.datepickerStyle}>
                  <div
                    className={
                      classes.formGroup + " " + classes.customDatePicker
                    }
                  >
                    <span>{t("MYBET.START_DATE")}</span>
                    <DesktopDatePicker
                      maxDate={endDate}
                      id="start-date1"
                      placeholder={"Date"}
                      variant="outlined"
                      disableFuture
                      value={date}
                      format={"DD/MM/YYYY"}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(fromDate) => {
                        if (
                          new Date(
                            dayjs(fromDate).format("DD/MM/YYYY")
                          ) instanceof Date
                        ) {
                          setDate(fromDate);
                          setCalculatedStartDate(getStartDate(fromDate));
                        }
                      }}
                    />
                  </div>
                  <div
                    className={
                      classes.formGroup + " " + classes.customDatePicker
                    }
                  >
                    {" "}
                    <span>{t("MYBET.END_DATE")}</span>
                    <DesktopDatePicker
                      minDate={date}
                      id="end-date1"
                      placeholder={"Date"}
                      variant="outlined"
                      disableFuture
                      value={endDate}
                      format={"DD/MM/YYYY"}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(toDate) => {
                        if (
                          new Date(
                            dayjs(toDate).format("DD/MM/YYYY")
                          ) instanceof Date
                        ) {
                          setEndDate(toDate);
                          setCalculatedEndDate(getEndDate(toDate));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </LocalizationProvider>
          </Grid>
        )}
      </Grid>
      {isMobileOnly ? (
        <Grid className={classes.rights_container}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n?.language || "en"}
          >
            <div className={classes.datepickerStyleMob}>
              <div
                className={
                  classes.formGroupMob + " " + classes.customDatePickerMob
                }
              >
                <DesktopDatePicker
                  maxDate={endDate}
                  id="start-date1"
                  placeholder={"Date"}
                  variant="outlined"
                  value={date}
                  format={"DD/MM/YYYY"}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(fromDate) => {
                    if (
                      new Date(dayjs(fromDate).format("DD/MM/YYYY")) instanceof
                      Date
                    ) {
                      setDate(fromDate);
                      setCalculatedStartDate(getStartDate(fromDate));
                    }
                  }}
                  disableFuture
                />
              </div>
              <span className={classes.spanTo}>To</span>
              <div
                className={
                  classes.formGroupMob + " " + classes.customDatePickerMob
                }
              >
                <DesktopDatePicker
                  disableFuture
                  minDate={date}
                  id="end-date1"
                  placeholder={"Date"}
                  variant="outlined"
                  disablePast={dayjs()}
                  value={endDate}
                  format={"DD/MM/YYYY"}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(toDate) => {
                    if (
                      new Date(dayjs(toDate).format("DD/MM/YYYY")) instanceof
                      Date
                    ) {
                      setEndDate(toDate);
                      setCalculatedEndDate(getEndDate(toDate));
                    }
                  }}
                />
              </div>
            </div>
          </LocalizationProvider>
        </Grid>
      ) : null}
      <TableContainer component={Paper} className={classes.SportsTableWrap}>
        <Table aria-label="simple table">
          <TableBody>
            {loading && !hasNext ? (
              <Grid className={classes.iframeCirculerLoader} textAlign="center">
                <SportBookLoader />
              </Grid>
            ) : results && results?.length ? (
              <>
                {results.map((result) => {
                  const resultTypes = result.MatchResults?.map(
                    ({ type }, i) => {
                      return (
                        <TableCell key={i} className={classes?.tableMatchName}>
                          {type}
                        </TableCell>
                      );
                    }
                  );

                  const firstRow = (
                    <TableRow key={result?.matchId} className="">
                      <TableCell className={classes?.tableMatchName}>
                        {getName(
                          result?.tournaments?.country?.countryName,
                          language
                        ) +
                          " / " +
                          getName(result?.tournaments.tournamentName, language)}
                      </TableCell>

                      {resultTypes}
                    </TableRow>
                  );

                  const remainRow = (
                    <TableRow>
                      <TableCell>
                        <Grid
                          display={"flex"}
                          columnGap={"1rem"}
                          container
                          alignItems={"center"}
                        >
                          <Grid item>
                            <Grid
                              maxWidth={"2.5rem"}
                              style={{ textAlign: "center" }}
                            >
                              <span>
                                <b>
                                  {dayjs(result?.matchDate).format("DD/MM")}
                                </b>
                              </span>
                              <br />
                              {dayjs(result?.matchDate).format("HH:mm")}
                            </Grid>
                          </Grid>
                          <Grid item className={classes.matchName}>
                            <Grid
                              display={"flex"}
                              alignItems={"center"}
                              columnGap={"1rem"}
                            >
                              <TeamImg
                                teamId={getProviderTeamId(
                                  result?.teams,
                                  "home"
                                )}
                                type="home"
                                isLive={result?.isLive}
                              />
                              <p>
                                {getTeamName(result?.teams, "home", language)}
                              </p>
                            </Grid>
                            <Grid
                              display={"flex"}
                              alignItems={"center"}
                              columnGap={"1rem"}
                            >
                              <TeamImg
                                teamId={getProviderTeamId(
                                  result?.teams,
                                  "away"
                                )}
                                type="away"
                                isLive={result?.isLive}
                              />
                              <p>
                                {getTeamName(result?.teams, "away", language)}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid item> {"V/S"}</Grid> */}
                          {/* <Grid item>{getTeamName(result?.teams, "away")}</Grid> */}
                        </Grid>
                      </TableCell>
                      {!!result &&
                        !!result?.MatchResults &&
                        !!result?.MatchResults?.length &&
                        result?.MatchResults?.map((scoreObj, i) => {
                          return (
                            <TableCell key={i}>
                              <Grid>
                                <Grid>{scoreObj?.score.split(":")[0]}</Grid>
                                <Grid>{scoreObj?.score.split(":")[1]}</Grid>
                              </Grid>
                            </TableCell>
                          );
                        })}

                      {/* <HandleEmptyMatchResult
                        result={result}
                        matchResultType={matchResultType}
                      /> */}
                    </TableRow>
                  );

                  return (
                    <>
                      {firstRow}

                      {remainRow}
                    </>
                  );
                })}
              </>
            ) : (
              <Grid className={classes.noMatchResultMsg}>
                {" "}
                {t("MATCH_RESULT.NO_RESULT_AVAILABLE")}
              </Grid>
            )}
          </TableBody>
        </Table>
        <LoadMore
          hasNext={hasNext}
          loading={loading}
          loadmoreMatches={loadmoreMatches}
        />
      </TableContainer>
    </React.Fragment>
  );
}

export default MatchResults;
