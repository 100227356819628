import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  moreMarkets: {},
  matchMarket: [],
  reloaded: false,
};

const MoreMarketSlice = createSlice({
  name: "MoreMarket",
  initialState,
  reducers: {
    getMoreMarketsStart: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    getMoreMarketsSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      if (action?.payload && action?.payload?.isDataFromScoket) {
        if (
          action?.payload?.matchMarket &&
          action?.payload?.matchMarket?.length
        ) {
          action?.payload?.matchMarket.forEach((marketFromSocket) => {
            const marketFoundIndex = state.matchMarket?.findIndex(
              (marketInStore) => {
                return (
                  `${marketInStore.matchMarketId}` ==
                  `${marketFromSocket.matchMarketId}`
                );
              }
            );

            if (marketFoundIndex !== -1) {
              state.matchMarket[marketFoundIndex] = marketFromSocket;
            } else {
              state.matchMarket.push(marketFromSocket);
            }
          });
        }
        let moreMarketData = { ...action.payload };
        delete moreMarketData.matchMarket;
        state.moreMarkets = {
          ...state.moreMarkets,
          ...moreMarketData,
        };
      }

      if (!action?.payload?.isDataFromScoket) {
        let matchMarket = action.payload.matchMarket;
        state.moreMarkets = {
          ...state.moreMarkets,
          ...action.payload,
        };
        state.matchMarket = matchMarket;
      }
    },
    getMoreMarketsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    resetMoreMarket: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.moreMarkets = {
        matchMarket: [],
      };
    },
    setIsReloaded: (state, action) => {
      state.reloaded = action.payload;
    },
  },
});

export const {
  setIsReloaded,
  getMoreMarketsStart,
  getMoreMarketsSuccess,
  getMoreMarketsFailure,
  resetMoreMarket,
} = MoreMarketSlice.actions;

export default MoreMarketSlice.reducer;
