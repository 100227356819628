import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  loadMoreButton: {
    color: "#fff",
    background:
      "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
    borderRadius: "50px",
  },
  searchContainer: {
    // padding: "1rem",
    display: "flex",
    minHeight: "80vh",
    // "& .MuiGrid-container":{
    //   minHeight: "26.5rem"
    // }
    "&  .MuiInputBase-root": {
      [theme.breakpoints.down("md")]: {
        background: "#fff !important",
      },
    },
  },

  category: {
    background: "#2F3857",
    padding: "0.2rem",
    "& .MuiAccordion-root": {
      background: "#070a29",
      "&:before": {
        display: "none",
      },
    },
  },
  search: {
    // paddingLeft: "0.5rem",
    padding: "20px 15px",
    display: "flex",
    flexDirection: "column",
    rowGap: "0.5rem",
  },

  selectemItemBtn: {
    marginTop: "10px",
    textTransform: "none",
  },
  searchGames: {
    minHeight: "23rem",
    margin: "0",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchGamesshow: {
    paddingLeft: "1rem",
  },
  headerSearch: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      borderRadius: "30px",
      background: "#fff",
      color: "#000",
      height: "40px",
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        background: "transparent",
        padding: "0",
      },
    },
    "& img": {
      top: "0px",
      right: "10px",
      position: "absolute",
      bottom: "0px",
      margin: "auto",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  searchImg: {
    textAlign: "center",
    width: "100%",
    "& img": {
      width: "300px",
    },
    "& .MuiTypography-h6": {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        background: "transparent",
        fontSize: "16px",
        textAlign: "center",
        padding: "0 10px",
      },
    },
  },

  searchGameResult: {
    "& .MuiGrid-container": {
      justifyContent: "center",
      marginTop: "1.5rem",
    },
    "& .MuiTypography-body1": {
      color: "#fff !important",
    },
  },
}));
