import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: null,
  userDetails: {},
  walletAmount: "",
  walletLoading: false,
  totalBets: 0,
  bets: [],
  profileImage: "",
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    getUserProfileDetailsStart: (state, action) => {
      if (action.payload?.walletLoading) {
        state.walletLoading = true;
      }
      state.loading = true;
    },
    getUserProfileDetailsSuccess: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      if (action.payload?.profileImage) {
        state.profileImage = action.payload.profileImage;
      }
    },
    getUserProfileDetailsFailure: (state, action) => {
      if (action.payload?.walletLoading) {
        state.walletLoading = false;
      }
      state.loading = false;
      state.error = action.payload;
      state.walletLoading = false;
    },
    updateUserProfileStart: (state, action) => {
      state.loading = true;
    },
    updateUserProfileSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.userDetails = action.payload;
    },
    updateUserProfileFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    getAllBetsStart: (state) => {
      state.loading = true;
    },
    getAllBetsSuccess: (state, action) => {
      state.bets = action.payload?.transactionList;
      state.totalPages = action.payload?.totalPage;
      state.loading = false;
      state.success = true;
    },
    getAllBetsStartFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getWalletBalanceSuccess: (state, action) => {
      if (!action?.payload?.isApi) {
        state.walletLoading = false;
        state.walletAmount = parseFloat(action.payload.totalAmount).toFixed(2);
        state.nonCashAmount = parseFloat(action.payload.nonCashAmount).toFixed(
          2
        );
      } else {
        state.walletLoading = false;
        state.walletAmount = parseFloat(action.payload.totalAmount).toFixed(2);
        state.nonCashAmount = parseFloat(action.payload.nonCashAmount).toFixed(
          2
        );
      }
    },
    startPoll: () => {},
    resetMyProfileState: (state) => {
      state.userDetails = initialState.userDetails;
      state.walletAmount = initialState.walletAmount;
    },
    resetMyprofileImage: (state) => {
      state.profileImage = "";
    },
  },
});

export const {
  resetMyprofileImage,
  getUserProfileDetailsStart,
  getUserProfileDetailsSuccess,
  getUserProfileDetailsFailure,
  updateUserProfileStart,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsStartFailure,
  getWalletBalanceSuccess,
  startPoll,
  resetMyProfileState,
} = myProfileSlice.actions;

export default myProfileSlice.reducer;
