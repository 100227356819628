import React from "react";

import axios from "axios";

import ArticlePreview from "./articlePreview";
import useStyles from "./blog.style";

const Blog = () => {
  const [posts, setPosts] = React.useState([]);
  const classes = useStyles(); // Use MUI styles

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/wp-json/wp/v2/posts?_embed`)
      .then((res) => {
        const uniquePosts = res.data.filter(
          (post, index, self) =>
            index === self.findIndex((p) => p.slug === post.slug)
        );
        setPosts(uniquePosts);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className={classes.blog}>
      <div className="anchor" id="blog" />
      <h1 className={classes.sectionTitle}>Articles</h1>
      {posts.map((post) => (
        <ArticlePreview key={post.slug} post={post} />
      ))}
    </div>
  );
};

export default Blog;
