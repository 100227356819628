import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orientation: "PORTRAIT",
  active: "",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    currentOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { currentOrientation, setActive } = settingsSlice.actions;

export default settingsSlice.reducer;
