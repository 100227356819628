import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  accountRight: {
    height: "100%",
  },
  btnPrimary: {
    background: "#F7616A",
    borderRadius: "30px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    minWidth: "100%",
    maxWidth: "440px",
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
  myprofileForm: {
    maxWidth: "540px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "7px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  myprofileBottom: {
    background: "#0D2867",
    borderRadius: "10px",
    padding: "30px 55px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "5px 10px",
      background: "#fff",
      borderRadius: "7px",
      color: "#25262B",
      fontSize: "14px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F7616A",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiTypography-h4": {
      fontWeight: "600",
      textTransform: "capitalize",
      marginBottom: "50px",
      fontSize: "35px",
      color: "#F7616A",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    textAlign: "center",
  },
  labelTag: {
    display: "inline-block",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#F2F4FF",
  },
}));
