export const status = {
  not_started: "Not started yet",
  pause1: "2nd pause",
  "1q": "1st quarter",
  "2q": "2nd quarter",
  pause2: "2nd pause",
  "3q": "3rd quarter",
  pause3: "3rd pause",
  "4q": "4th quarter",
  ended: "The match has ended",
  awaiting_ot: "Waiting for overtime to start",
  ot: "Overtime (in case a match goes to extra time)",
  after_ot: "Match finished after overtime",
  "1p_ot": "1st overtime period",
  ot_ht: "Pause between 1st and 2nd overtime period",
  "2p_ot": "2nd overtime period",
  awaiting_sd: "Before sudden death",
  SD: "Sudden death",
  after_sd: "Match finished after sudden death",
  interrupted: "The match has been interrupted",
  abandoned: "The match has been abandoned",
  "1set": "1st set",
  "2set": "2nd set",
  "3set": "3rd set",
  "4set": "4th set",
  pause4: "4th pause",
  "5set": "5th set",
  "walkover walkover1": "The home team won the match by walkover",
  "walkover walkover2": "The home team won the match by walkover",
  "retired retired1": "The away team won because the home team retired",
  "retired retired2": "The away team won because the home team retired",
  defaulted1: "The away team won because the home team defaulted",
  defaulted2: "The home team won because the away team retired",
  delayed: "The match start is delayed",
  "1IT": "1st inning top",
  BT1B1: "Break 1st top 1st bottom",
  "1IB": "1st inning bottom",
  BT2B1: "Break 2nd top 1st bottom",
  "2IT": "2nd inning top",
  BT2B2: "Break 2nd top 2nd bottom",
  "2IB": "2nd inning bottom",
  BT3B2: "Break 3rd top 2nd bottom",
  "3IT": "3rd inning top",
  BT3B3: "Break 3rd top 3rd bottom",
  "3IB": "3rd inning bottom",
  BT4B3: "Break 4th top 3rd bottom",
  "4IT": "4th inning top	",
  BT4B4: "Break 4th top 4th bottom",
  "4IB": "4th inning bottom",
  BT5B4: "Break 5th top 4th bottom",
  "5IT": "5th inning top",
  BT5B5: "Break 5th top 5th bottom",
  "5IB": "5th inning bottom",
  BT6B5: "Break 6th top 5th bottom",
  "6IT": "6th inning top",
  BT6B6: "Break 6th top 6th bottom",
  "6IB": "6th inning bottom",
  BT7B6: "Break 7th top 6th bottom",
  "7IT": "7th inning top",
  BT7B7: "Break 7th top 7th bottom",
  "7IB": "7th inning bottom",
  BT8B7: "Break 8th top 7th bottom",
  "8IT": "8th inning top",
  BT8B8: "Break 8th top 8th bottom",
  "8IB": "8th inning bottom",
  BT9B8: "Break 9th top 8th bottom",
  "9IT": "9th inning top",
  BT9B9: "Break 9th top 9th bottom",
  "9IB": "9th inning bottom",
  BTEIB9: "Break EI top 9th bottom",
  EIT: "Extra inning top",
  BTEIBEI: "Break EI top EI bottom",
  EIB: "Extra inning bottom",
  "1map": "1st map",
  "2map": "2nd map",
  "3map": "3rd map",
  "4map": "4thmap",
  "5map": "5th map",
  pause5: "5th pause",
  "6map": "6th map",
  pause6: "6th pause",
  "7map": "7th map",
  "1i_ht": "First innings, home team",
  "1i_at": "First innings, away team",
  "2i_ht": "Second innings, home team",
  "2i_at": "Second innings, away team",
  awaiting_so: "Awaiting super over",
  so_ht: "Super over, home team",
  so_at: "Super over, away team",
  after_so: "After super over",
  innings_break: "Innings break",
  so_break: "Super over break",
  lunch_break: "Lunch break",
  tea_break: "Tea break",
  stumps: "Stumps",
  in_progress: "The match is in progress",
  paused: "Match paused",
  pen: "Penalty shoot-out",
  awaiting_pen: "Waiting for penalty shoot-out to start",
  postponed: "The match has been postponed",
  "1st_quarter": "1st quarter",
  "1st_pause": "1st pause",
  "2nd_quarter": "2nd quarter",
  "2nd_pause": "2nd pause",
  "3rd_quarter": "3rd quarter",
  "3rd_pause": "3rd pause",
  "4th_quarter": "Waiting for penalty shoot - out to start",
  "1p": "1st period of the match",
  "2p": "2nd period of the match",
  "awaiting_pen pen": "Waiting for penalty shoot - out to start",
  "1g": "1st game",
  "2g": "2nd game",
  "3g": "3rd game",
  "4g": "4th game",
  "5g": "5th game",
  injury_break: "A break due to injury.",
  awaiting_gset: "Waiting for the golden set",
  gset: "Used if there is a golden set",
  after_gset: "Golden set has ended",
  started: "started",
  stopped: "stopped",
};
