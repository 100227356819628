import { toast } from "react-hot-toast";
import { io } from "socket.io-client";
const createSocketConnection = (connectionUrl, authToken) => {
  const ioConnection = io(connectionUrl, {
    transports: ["polling", "websocket"],
    ...(authToken
      ? {
          auth: {
            authorization: `Bearer ${authToken}`,
          },
        }
      : {}),
    path: "/userweb/socket/",
    forceNew: true,
    // parser: customParser
  });

  ioConnection.on("ping", (e) => {
    console.log("ping", e);
  });
  ioConnection.on("reconnect", (e) => {
    console.log("reconnect", e);
  });

  ioConnection.on("reconnect_attempt", (e) => {
    console.log("reconnect_attempt", e);
  });
  ioConnection.on("reconnect_error", (e) => {
    console.log("reconnect_error", e);
  });
  ioConnection.on("reconnect_failed", (e) => {
    console.log("reconnect_failed", e);
  });

  ioConnection.on("disconnect", (e) => {
    console.log(new Date() + "Socket disconnected", e);
  });

  ioConnection.on("error", (error) => {
    console.log("Socket error:", error);
    // Handle the error
  });

  // Handle socket connection errors
  ioConnection.on("connect_error", (err) => {
    console.log(new Date() + "Socket connect_error", err);
    console.log(err);
    if (err.message === "websocket error") {
      toast.error("Connection lost with server please refresh the page.");
    } else {
      // Handle other types of connection errors
      console.log("Socket connection error: " + err.message);
    }
  });

  return ioConnection;
};

export default createSocketConnection;
