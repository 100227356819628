import { put, takeLatest } from "redux-saga/effects";

import authenticationService from "../../../services/authentication";
import Metamask from "../../../services/metamask";
import session from "../../../utils/session";
import {
  connectMetamaskFailure,
  connectMetamaskStart,
  connectMetamaskSuccess,
  forgotPasswordFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  logoutFailure,
  logoutStart,
  logoutSuccess,
  refreshLoginFailure,
  refreshLoginStart,
  refreshLoginSuccess,
  refreshMetamaskLoginStart,
  refreshMetamaskLoginSuccess,
  resetReferralCode,
  signUpFailure,
  signUpStart,
  signUpSuccess,
  verifyEmailFailure,
  verifyEmailStart,
  verifyEmailSuccess,
  verifyPasswordTokenFailure,
  verifyPasswordTokenStart,
  verifyPasswordTokenSuccess,
} from "../../redux/auth/authentication";
import {
  metamaskLoginFailure,
  metamaskLoginStart,
  metamaskLoginSuccess,
} from "../../redux/auth/authentication";
import { resetMyProfileState } from "../../redux/profile/myProfile";
import { resetTransactionState } from "../../redux/profile/transactions";
import { showErrorMessage, showSuccessMessage } from "../../redux/toast";

function* authenticationWatcher() {
  yield takeLatest(loginStart.type, loginWorker);
  yield takeLatest(refreshLoginStart.type, refreshLoginWorker);
  yield takeLatest(refreshMetamaskLoginStart.type, refreshLoginWorker);
  yield takeLatest(connectMetamaskStart.type, connectedMetamaskWorker);
  yield takeLatest(metamaskLoginStart.type, metamaskLoginWorker);
  yield takeLatest(signUpStart.type, signUpWorker);
  yield takeLatest(logoutStart.type, logoutWorker);
  yield takeLatest(verifyEmailStart.type, verifyEmailWorker);
  yield takeLatest(forgotPasswordStart.type, forgotPasswordWorker);
  yield takeLatest(verifyPasswordTokenStart.type, verifyPasswordTokenWorker);
}

function* loginWorker(action) {
  try {
    const { data, error } = yield authenticationService.login(action.payload);
    if (data) {
      const { token, resToken, isMetaMaskUser, userId } = data;
      session.saveSessionCred({
        token,
        resToken,
        isMetaMaskUser,
        userId,
      });
      yield put(showSuccessMessage({ tKey: "HEADER.LOGIN_SUCCESS" }));
      yield put(loginSuccess(userId));
    }

    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(loginFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(loginFailure());
  }
}

function* refreshLoginWorker() {
  try {
    const { data, error } = yield authenticationService.refreshLogin();

    if (data) {
      const { token, resToken, isMetaMaskUser, walletAddress } = data.data;

      const { id } = JSON.parse(atob(token.split(".")[1]));

      session.saveSessionCred({
        token,
        resToken,
        isMetaMaskUser,
        userId: id,
      });
      if (isMetaMaskUser) {
        yield put(refreshMetamaskLoginSuccess({ walletAddress }));
      } else {
        yield put(refreshLoginSuccess({ id, walletAddress }));
      }
    }

    if (error) {
      yield put(refreshLoginFailure(error));
    }
  } catch (error) {
    yield put(refreshLoginFailure(error));
  }
}

function* connectedMetamaskWorker(action) {
  try {
    const { data, error } = yield Metamask.connectToMetamask(action.payload);
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "HEADER.METAMASK_CONNECT_SUCCESS" })
      );
      yield put(connectMetamaskSuccess());
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(connectMetamaskFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(connectMetamaskFailure());
  }
}

function* metamaskLoginWorker(action) {
  try {
    const { data, error } = yield Metamask.requestAccount({
      referralCode: action.payload,
    });
    yield put(resetReferralCode());
    if (data) {
      const { token, resToken, isMetaMaskUser, walletAddress } = data;
      session.saveSessionCred({
        token,
        resToken,
        isMetaMaskUser,
      });
      yield put(showSuccessMessage({ tKey: "HEADER.METAMASK_LOGIN_SUCCESS" }));
      yield put(metamaskLoginSuccess(walletAddress));
    }
    if (error) {
      yield put(showErrorMessage({ msg: "An error has occurred" }));
      yield put(metamaskLoginFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: "An error has occurred" }));
    yield put(metamaskLoginFailure());
  }
}

function* signUpWorker(action) {
  try {
    const { data, error } = yield authenticationService.signUp(action.payload);
    if (data) {
      yield put(showSuccessMessage({ tKey: "HEADER.EMAIL_SUCCESS" }));
      yield put(signUpSuccess());
      yield put(resetReferralCode());
    }
    if (error) {
      yield put(showErrorMessage({ msg: error }));
      yield put(signUpFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error }));
    yield put(signUpFailure());
  }
}

function* verifyEmailWorker(action) {
  try {
    const { data, error } = yield authenticationService.verifyEmail(
      action.payload
    );
    if (data?.emailVerified) {
      yield put(verifyEmailSuccess());
    }
    if (error) {
      yield put(verifyEmailFailure(error));
    }
  } catch (error) {
    yield put(verifyEmailFailure(error));
  }
}

function* forgotPasswordWorker(action) {
  try {
    const { data, error } = yield authenticationService.forgotPassword(
      action.payload
    );
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "HEADER.FORGOT_PASSWORD_EMAIL_MSG" })
      );
      yield put(forgotPasswordSuccess());
    }
    if (error) {
      yield put(forgotPasswordFailure(error));
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error));
  }
}

function* verifyPasswordTokenWorker(action) {
  try {
    const { data, error } = yield authenticationService.verifyPasswordToken(
      action.payload
    );
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "CHANGE_PASSWORD.PASSWORD_CHANGE_SUCCESS" })
      );
      yield put(verifyPasswordTokenSuccess());
    }
    if (error) {
      yield put(verifyPasswordTokenFailure(error));
    }
  } catch (error) {
    yield put(verifyPasswordTokenFailure(error));
  }
}

function* logoutWorker() {
  try {
    session.clearSessionCred();
    yield put(resetMyProfileState());
    yield put(resetTransactionState());
    yield put(showSuccessMessage({ tKey: "SIDEBAR.LOG_OUT_SUCCESS" }));
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

export default authenticationWatcher;
