import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import { showErrorMessage } from "../../../redux-saga/redux/toast";
import FavouriteService from "../../../sport-book-services/favourite";
import {
  addToFavouriteFailure,
  addToFavouriteStart,
  addToFavouriteSuccess,
  getFavouriteMatchesFailure,
  getFavouriteMatchesStart,
  getFavouriteMatchesSuccess,
  removeFromFavouriteFailure,
  removeFromFavouriteStart,
  removeFromFavouriteSuccess,
} from "../../redux/favourite";

function* favouriteWatcher() {
  yield takeEvery(
    [
      addToFavouriteStart.type,
      removeFromFavouriteStart.type,
      getFavouriteMatchesStart.type,
    ],
    favouriteWorker
  );
}

function* favouriteWorker(action) {
  try {
    switch (action.type) {
      case addToFavouriteStart.type: {
        const { data, error } = yield FavouriteService.addToFavourite(
          action.payload
        );
        if (data) {
          toast.success("Added to favourites successfully");
          yield put(
            addToFavouriteSuccess({
              ...data,
              isLive: action.payload.isLive,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(addToFavouriteFailure());
        }
        break;
      }
      case removeFromFavouriteStart.type: {
        let payload = {
          ...action.payload,
        };
        delete payload.matchId;
        delete payload.isLive;
        const { data, error } = yield FavouriteService.removeFromFavourite(
          payload
        );
        if (data) {
          toast.success("Removed from favourites successfully");
          yield put(
            removeFromFavouriteSuccess({
              data,
              isLive: action.payload.isLive,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error?.data || error }));
          yield put(removeFromFavouriteFailure());
        }
        break;
      }
      case getFavouriteMatchesStart.type: {
        const { data, error } = yield FavouriteService.getFavoriteMatches(
          action.payload
        );
        if (data) {
          yield put(getFavouriteMatchesSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getFavouriteMatchesFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case addToFavouriteStart.type: {
        yield put(addToFavouriteFailure());
        break;
      }
      case removeFromFavouriteStart.type: {
        yield put(removeFromFavouriteFailure());
        break;
      }
      case getFavouriteMatchesStart.type: {
        yield put(getFavouriteMatchesFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default favouriteWatcher;
