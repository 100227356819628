import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  providers: [],
  subCategory: [],
};

const casinoLobbySlice = createSlice({
  name: "casinoLobby",
  initialState,
  reducers: {
    getProvidersStart: (state, action) => {
      state.loading = true;
    },
    getProvidersSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.providers = action.payload;
    },
    getProvidersFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    getSubCategoryStart: (state, action) => {
      state.loading = true;
    },
    getSubCategorySuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.subCategory = action.payload;
    },
    getSubCategoryFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    getLobbyBannersStart: (state, action) => {
      state.loading = true;
    },
    getLobbyBannersSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.lobbyBannerList = action.payload;
    },
    getLobbyBannersFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
  getSubCategoryFailure,
  getLobbyBannersStart,
  getLobbyBannersSuccess,
  getLobbyBannersFailure,
} = casinoLobbySlice.actions;

export default casinoLobbySlice.reducer;
