import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
    background: "#fff",
  },
  iframeCirculerLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadMoreBtn: {
    "&:hover": {
      background:
        "linear-gradient(90deg, #C7575E 0%, #F7616A 0%, #BC3840 0.01%, #F7616A 97.5%)",
      color: "#fff",
    },
  },
}));
