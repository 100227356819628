import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import SportsService from "../../../sport-book-services/sports";
import { showErrorMessage } from "../../../redux-saga/redux/toast";
import {
  addToDashBoardFavouriteStart,
  addToDashBoardFavouriteFailure,
  addToDashBoardFavouriteSuccess,
  getMatchesFailure,
  getMatchesStart,
  getMatchesSuccess,
  removeFromDashBoardFavouriteFailure,
  removeFromDashBoardFavouriteStart,
  removeFromDashBoardFavouriteSuccess,
} from "../../redux/matches";
import FavouriteService from "../../../sport-book-services/favourite";

function* matchesWatcher() {
  yield takeEvery(
    [
      getMatchesStart.type,
      addToDashBoardFavouriteStart.type,
      removeFromDashBoardFavouriteStart.type,
    ],
    matchesWorker
  );
}

function* matchesWorker(action) {
  try {
    switch (action.type) {
      case getMatchesStart.type: {
        const { data, error } = yield SportsService.getMatches(action.payload);
        if (!error) {
          yield put(getMatchesSuccess({ data, isLive: action.payload.isLive }));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getMatchesFailure());
        }
        break;
      }
      case addToDashBoardFavouriteStart.type: {
        const { data, error } = yield FavouriteService.addToFavourite(
          action.payload
        );
        if (data) {
          toast.success("Added to favourites successfully");
          yield put(
            addToDashBoardFavouriteSuccess({
              ...data,
              isLive: action.payload.isLive,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(addToDashBoardFavouriteFailure());
        }
        break;
      }
      case removeFromDashBoardFavouriteStart.type: {
        let payload = {
          ...action.payload,
        };
        delete payload.matchId;
        delete payload.isLive;
        const { data, error } = yield FavouriteService.removeFromFavourite(
          payload
        );
        if (data) {
          toast.success("Removed from favourites successfully");
          yield put(
            removeFromDashBoardFavouriteSuccess({
              data,
              isLive: action.payload.isLive,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error?.data || error }));
          yield put(removeFromDashBoardFavouriteFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getMatchesStart.type: {
        yield put(getMatchesFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default matchesWatcher;
