import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Parser from "html-react-parser";
import { CircularProgress, Grid } from "@mui/material";

import useStyles from "./Footer.styles";
import { getCmsStart } from "../../redux-saga/redux/cms";

const Cms = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, cmsData } = useSelector((state) => state.cms);
  const { state } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    // if (state === null) {
    dispatch(getCmsStart());
    // }
    window.scrollTo(0, 0);
  }, [dispatch, state]);

  const getCmsData = () => {
    // if (state !== null) {
    //   return Parser(state || "");
    // }
    // if (state === null) {
    const cmsContent = cmsData.find((item) => item.id === id);
    return Parser(cmsContent?.content || "");
    // }
  };

  return (
    <div className={classes.cmsMainContent}>
      {loading ? (
        <Grid textAlign="center">
          <CircularProgress className={classes.cmsLoading} size={50} />
        </Grid>
      ) : (
        getCmsData()
      )}
    </div>
  );
};

export default Cms;
