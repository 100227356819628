import axiosClient from "../axios";

export default class authenticationService {
  static async login(payload) {
    try {
      const { data } = await axiosClient.post("/user/login", payload);
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async refreshLogin() {
    try {
      const data = await axiosClient.get("/user/refreshToken");
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async signUp(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/signUp",
        payload.userData,
        {
          params: {
            affiliate: payload?.affiliate,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async verifyEmail(payload) {
    try {
      const { data } = await axiosClient.post("/user/verifyEmail", payload);
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async verifyPasswordToken(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/verifyPasswordToken",
        payload
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async forgotPassword(payload) {
    try {
      const { data } = await axiosClient.post("/user/forgotPassword", payload);
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data.errors[0]?.description };
      }
    }
  }

  static async getMetamaskUser(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/metamask/getNonce",
        payload
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async verifyMetamaskSignature(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/metamask/verifySignature",
        payload
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
