import { put, takeEvery } from "redux-saga/effects";

import { showErrorMessage } from "../../../redux-saga/redux/toast";
import MoreMarketService from "../../../sport-book-services/more-market";
import {
  getMoreMarketsFailure,
  getMoreMarketsStart,
  getMoreMarketsSuccess,
} from "../../redux/more-markets";

function* moreMarketsWatcher() {
  yield takeEvery([getMoreMarketsStart.type], moreMarketsWorker);
}

function* moreMarketsWorker(action) {
  try {
    switch (action.type) {
      case getMoreMarketsStart.type: {
        const { data, error } = yield MoreMarketService.getMoreMarkets(
          action.payload
        );
        if (!error) {
          yield put(getMoreMarketsSuccess(data?.findMatch));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error }));
          yield put(getMoreMarketsFailure());
        }
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getMoreMarketsStart.type: {
        yield put(getMoreMarketsFailure());
        break;
      }
      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error }));
  }
}

export default moreMarketsWatcher;
