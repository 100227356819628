import React from "react";

import { Grid } from "@mui/material";
// import { CircularProgress, Grid } from "@mui/material";

function SportBookLoader({ isSmall, width, height }) {
  if (isSmall) {
    return (
      <Grid>
        {/* <CircularProgress size={60} /> */}
        <img
          src={"/images/sport-book/bwsLoader.gif"}
          alt="loader"
          style={{ width: width || "2.5rem", height: height || "auto" }}
        />
      </Grid>
    );
  }

  return (
    <Grid
      // className={classes.iframeCirculerLoader}
      textAlign="center"
      padding={"5px"}
    >
      {/* <CircularProgress size={60} /> */}
      <img
        src={"/images/sport-book/bwsLoader.gif"}
        alt="loader"
        style={{ width: "6rem", height: "6rem" }}
      />
    </Grid>
  );
}

export default SportBookLoader;
