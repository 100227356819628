import { makeStyles } from "@mui/styles";
// import { padding } from "@mui/system";

export default makeStyles((theme) => ({
  dialogBox: {
    backdropFilter: "blur(11px)",
    "& .MuiDialog-paperWidthsm": {
      minWidth: "100%",
    },
    "& .MuiPaper-rounded": {
      maxWidth: "100%",
      background: "#0D2867",
      boxShadow: "0px 0px 40px rgba(99, 99, 99, 0.25)",
      borderRadius: "22px",
    },
    "& .MuiDialogContent-root": {
      overflowX: "hidden",
      padding: "0px",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIconWrap: {
    zIndex: "10",
    top: "10px",
    right: "10px",
    width: "22px",
    height: "22px",
    display: "flex",
    padding: "8px",
    position: "absolute",
    borderRadius: "100%",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
    color: "#F7616A",
    border: "1px solid #F7616A",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      padding: "4px",
      width: "22px",
      height: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      right: "10px",
      top: "8px",
    },
  },
}));
