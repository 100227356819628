import React, { useState } from "react";

import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { encodePassword, validatePassword } from "../../../utils/helper";
import { resetPasswordStart } from "../../../redux-saga/redux/auth/resetPassword";
import useStyles from "./ResetPassword.styles";

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [password, setPassword] = useState(initialState);
  const { loading } = useSelector((state) => state.resetPassword);

  const handlePasswordChange = () => {
    const { currentPassword, newPassword, confirmPassword } = password;

    if (!currentPassword) {
      toast.error(t("CHANGE_PASSWORD.CURRENT_PASSWORD_VALIDATION"));
      return;
    }
    if (!validatePassword(newPassword)) {
      toast.error(t("COMMON.PASSWORD_VALIDATION"));
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error(t("CHANGE_PASSWORD.PASSWORD_MATCH_VALIDATION"));
      return;
    }
    dispatch(
      resetPasswordStart({
        oldPassword: encodePassword(currentPassword),
        newPassword: encodePassword(newPassword),
      })
    );
    setPassword(initialState);
  };

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        <Typography variant="h4" gutterBottom>
          {t("CHANGE_PASSWORD.PASSWORD")}
        </Typography>
        <Grid className={classes.myprofileForm}>
          <Grid className={classes.signupFromFname}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <span className={classes.labelTag}>
                    {t("CHANGE_PASSWORD.CURRENT_PASSWORD")}
                  </span>
                  <TextField
                    id="current-password"
                    InputLabelProps={{
                      style: {
                        color: "#8492B7",
                        fontWeight: "400",
                        fontSize: "17px",
                        lineHeight: "40px",
                      },
                    }}
                    placeholder={t("CHANGE_PASSWORD.CURRENT_PASSWORD")}
                    variant="outlined"
                    value={password.currentPassword}
                    onChange={(e) => {
                      setPassword((current) => ({
                        ...current,
                        currentPassword: e.target.value,
                      }));
                    }}
                    type={showPassword.current ? "text" : "password"}
                  />
                  <Grid className={classes.showLink}>
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginTop: "85px", color: "#002965" }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword((currentState) => ({
                            ...showPassword,
                            current: !currentState.current,
                          }));
                        }}
                      >
                        {!showPassword.current ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                  {/* <IconButton
                    onClick={() => {
                      setShowPassword((currentState) => ({
                        ...showPassword,
                        current: !currentState.current,
                      }));
                    }}
                    className={classes.showLink}
                  >
                    {showPassword.current ? t("COMMON.HIDE") : t("COMMON.SHOW")}
                  </IconButton> */}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <span className={classes.labelTag}>
                    {t("CHANGE_PASSWORD.NEW_PASSWORD")}
                  </span>
                  <TextField
                    id="new-password"
                    InputLabelProps={{
                      style: {
                        color: "#8492B7",
                        fontWeight: "400",
                        fontSize: "17px",
                        lineHeight: "40px",
                      },
                    }}
                    placeholder={t("CHANGE_PASSWORD.NEW_PASSWORD")}
                    type={showPassword.new ? "text" : "password"}
                    variant="outlined"
                    value={password.newPassword}
                    onChange={(e) => {
                      setPassword((current) => ({
                        ...current,
                        newPassword: e.target.value,
                      }));
                    }}
                  />
                  <Grid className={classes.showLink}>
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginTop: "85px", color: "#002965" }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword((newState) => ({
                            ...showPassword,
                            new: !newState.new,
                          }));
                        }}
                      >
                        {!showPassword.new ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                  {/* <IconButton
                    onClick={() => {
                      setShowPassword((currentState) => ({
                        ...showPassword,
                        new: !currentState.new,
                      }));
                    }}
                    className={classes.showLink}
                  >
                    {showPassword.new ? t("COMMON.HIDE") : t("COMMON.SHOW")}
                  </IconButton> */}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <span className={classes.labelTag}>
                    {t("CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD")}
                  </span>
                  <TextField
                    id="confirm-new-password"
                    InputLabelProps={{
                      style: {
                        color: "#8492B7",
                        fontWeight: "400",
                        fontSize: "17px",
                        lineHeight: "40px",
                      },
                    }}
                    placeholder={t("CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD")}
                    variant="outlined"
                    value={password.confirmPassword}
                    type={showPassword.confirm ? "text" : "password"}
                    onChange={(e) => {
                      setPassword((current) => ({
                        ...current,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                  <Grid className={classes.showLink}>
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginTop: "85px", color: "#002965" }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword((confirmState) => ({
                            ...showPassword,
                            confirm: !confirmState.confirm,
                          }));
                        }}
                      >
                        {!showPassword.confirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                  {/* <IconButton
                    onClick={() => {
                      setShowPassword((currentState) => ({
                        ...showPassword,
                        confirm: !currentState.confirm,
                      }));
                    }}
                    className={classes.showLink}
                  >
                    {showPassword.confirm ? t("COMMON.HIDE") : t("COMMON.SHOW")}
                  </IconButton> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.authBtnWrap}>
            <Button
              onClick={() => handlePasswordChange()}
              className={classes.btnPrimary}
            >
              {loading ? <CircularProgress /> : t("COMMON.SAVE_CHANGES")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
