import { getErrorObj } from "../../container/SportsBook/Utils/common";
import axiosClient from "../../services/axios";

export default class SportsService {
  static getSports() {
    return axiosClient
      .get("/betting/getSports")
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return getErrorObj(error);
      });
  }

  static getCountries(payload) {
    !payload.search && delete payload.search;

    return axiosClient
      .get("/betting/getCountries", {
        params: {
          sportId: payload.sportsId,
          ...payload,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return getErrorObj(error);
      });
  }

  static getMatches(payload) {
    !payload.search && delete payload.search;
    if (payload?.sportCountryId === "all") delete payload.sportCountryId;
    if (payload?.countryId === "all") delete payload.countryId;
    return axiosClient
      .get("/betting/getMatches", {
        params: {
          ...payload,
          sportId: payload.sportsId,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return getErrorObj(error);
      });
  }
  static async getBannersData(params) {
    return axiosClient
      .get("user/getBanner", {
        params,
      })
      .then(({ data }) => {
        return data?.data;
      })
      .catch((error) => {
        return getErrorObj(error);
      });
  }
}
