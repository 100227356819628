import React, { useState } from "react";

import { Link } from "react-router-dom";
// import toast from "react-hot-toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

import useStyles from "./AppSidebar.styles.js";
import AppsidebarItem from "./Appsidebar.list.json";
import { setActive } from "../../redux-saga/redux/settings/index.js";

export default function AppSidebar() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { active } = useSelector((state) => state.settings);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Box className={classes.sidebarMenu}>
        <List onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
          {AppsidebarItem.map((item) => {
            if (!isLoggedIn && item.isLogged) {
              return null;
            } else {
              if (item.isMyBet) {
                return (
                  <ListItem key={item.content}>
                    <Accordion className={classes.accordianWrapper}>
                      <AccordionSummary
                        expandIcon={isHovering && <ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`${classes?.mybetAccordian} ${
                          active === item.to.sportbook && !isHovering
                            ? classes.bg_salmon
                            : ""
                        }`}
                      >
                        <Grid className={classes.mybets}>
                          <p className={classes.leftShap} />
                          <img
                            src="/images/sidebar-4.svg"
                            alt="sidebar home icon"
                          />
                          <span>My Bet</span>
                        </Grid>
                      </AccordionSummary>
                      {isHovering && (
                        <AccordionDetails
                          className={classes.accordianDetail}
                          onClick={() => dispatch(setActive(item.to.sportbook))}
                        >
                          {/* <Link to={"/user/betHistory"}>{"Casino"}</Link> */}
                          <Link
                            onClick={() =>
                              dispatch(setActive(item.to.sportbook))
                            }
                            className={`${
                              active == item.to.sportbook &&
                              "active" &&
                              classes.bg_salmon
                            }`}
                            to={item.to["sportbook"]}
                          >
                            {"Sports Book"}
                          </Link>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  </ListItem>
                );
              }

              return (
                <>
                  <ListItem key={item.content}>
                    {
                      <Link
                        to={item.to}
                        onClick={() => dispatch(setActive(item.to))}
                        className={`${active == item.to && classes.bg_salmon}`}
                      >
                        <p className={classes.leftShap} />
                        <img src={item.src} alt={item.alt} />
                        <span>{t(item.content)}</span>
                      </Link>
                    }
                  </ListItem>
                </>
              );
            }
          })}
        </List>
      </Box>
    </>
  );
}
