import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  languageMenu: {
    "& ul": {
      background: "#0D2867",
      padding: "0",
      minWidth: "106px",
      "& li": {
        minHeight: "4rem",
        minWidth: "8rem",
        borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
        fontSize: "14px",
        display: "flex",
        columnGap: "1rem",
        fontWeight: "600",
        "&:hover": {
          color: "#fff",
          background:
            "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
  languageBtn: {
    fontSize: "12px",
    color: "#fff",
    minWidth: "100%",
    minHeight: "2.5rem",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    "& .MuiButton-startIcon": {
      marginRight: "0px",
      marginLeft: "0px",
    },
    "& .MuiButton-endIcon": {
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  sideBar: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "170px",
    },
    background: "#0D2867",
    borderRadius: "0 10px 10px 0",
    width: "250px",
    position: "fixed",
    zIndex: "9",
    top: "70px",
    left: "0px",
    "& ul": {
      "& li": {
        [theme.breakpoints.down("md")]: {
          padding: "0",
        },
        "& a": {
          color: "#FFFFFF",
          width: "100%",
          cursor: "pointer",
          fontSize: "16px",
          minHeight: "45px",
          alignItems: "center",
          fontWeight: "500",
          textDecoration: "none",
          display: "flex",
          columnGap: "2rem",
          padding: "0 1rem",
          "& img": {
            [theme.breakpoints.down("md")]: {
              width: "25px",
            },
          },
          "& span": {
            [theme.breakpoints.down("md")]: {
              fontSize: "12px",
            },
          },
        },
      },
    },
  },
  mybetAccordian: {
    padding: "0px",
    margin: "0px !important",
    background: "#002965",
    position: "relative",
    minHeight: "inherit !important",
    "& p": {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    "& .MuiAccordionSummary-content": {
      padding: "0",
      margin: "0 !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      position: "absolute",
      right: "3px",
    },
  },

  accordianDetail: {
    padding: "0",
  },
  accordianWrapper: {
    border: "0",
    boxShadow: "inherit",
    width: "100%",
    // paddingRight: "10px",
    background: "transparent",
  },
  bg_salmon: {
    background:
      "radial-gradient(82.07% 1187.01% at 100% 50%, #F7616A 0%, rgba(247, 97, 106, 0) 100%)",
  },
}));
