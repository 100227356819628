import React, { Fragment, Suspense } from "react";

import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazy-load";

import useStyle from "./lobby.style";
// import LiveMatches from "../components/LiveMatches";
const LiveMatches = React.lazy(() => import("../components/LiveMatches"));
// import PreLiveMatches from "../components/PreLiveMatches";
const PreLiveMatches = React.lazy(() => import("../components/PreLiveMatches"));
// import FeaturedMatches from "../components/FeaturedMatches";
const FeaturedMatches = React.lazy(() =>
  import("../components/FeaturedMatches")
);
// import { setIsReloaded } from "../../../sport-book-saga/redux/more-markets";

function SportBookLobby() {
  const { t } = useTranslation();
  const classes = useStyle();
  const { lobbyBannerList } = useSelector((state) => state.sportbook.sports);

  const getCrouselParams = () => {
    const sameConfig = {
      autoPlay: false,
      infiniteLoop: false,
      showThumbs: false,
      showStatus: false,
      interval: 4000,
    };
    if (lobbyBannerList?.length > 1) {
      return {
        ...sameConfig,
        renderArrowPrev: (clickHandler) => {
          return isMobile ? null : (
            <Button className={classes.sliderPrev} onClick={clickHandler}>
              <img src="/images/left.png" alt="" />
            </Button>
          );
        },
        renderArrowNext: (clickHandler) => {
          return isMobile ? null : (
            <Button onClick={clickHandler} className={classes.sliderNext}>
              <img src="/images/right.png" alt="" />
            </Button>
          );
        },
      };
    } else {
      return {
        ...sameConfig,
        renderArrowPrev: () => {},
        renderArrowNext: () => {},
        showIndicators: false,
      };
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Online Sports Betting ,Odds & Betting Lines- Bet World Sports
        </title>
        <meta
          name="description"
          content="BetWorldSports offers secure access to top sports odds and betting lines. Bet on popular sports with ease. Enjoy live betting, in-play odds, and more."
        />
      </Helmet>
      <h1 style={{ position: "absolute", left: "-9999px" }}>
        Bet on Your favorite Sports at BetWorldSports
      </h1>
      <Grid container>
        <Grid item xs={12} lg={7} className={classes.homeBanner}>
          <Carousel {...getCrouselParams()}>
            {lobbyBannerList?.length &&
              lobbyBannerList.map((banner, i) => {
                return (
                  <LazyLoad key={i}>
                    <Grid
                      style={{
                        background: `url('${banner?.image_url}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%",
                      }}
                      className={classes.sport_banner}
                      item
                      xs={12}
                      lg={12}
                      key={banner?.id}
                    >
                      {/* <Grid className={classes.banner_content}>
                      <h1>
                        {t("LOBBY.SPORTSBOOK_MSG")} <br />
                        <span>{t("LOBBY.FEATURE_MSG")}</span>
                      </h1>
                      <p>
                        Increase your profits up to 50% <br />
                        in one Betslip with 4+ winning events
                      </p>
                    </Grid> */}
                    </Grid>
                  </LazyLoad>
                );
              })}
          </Carousel>
        </Grid>
        <Grid item xs={12} lg={5} container rowGap={"0.7rem"}>
          <Grid item lg={12} className={classes.sports_heading}>
            <Typography variant="h4">
              {t("LOBBY.FEATURED_MATCHES_MSG")}
            </Typography>
          </Grid>

          <Suspense fallback={<div>Loading Featured Matches...</div>}>
            <FeaturedMatches />
          </Suspense>
        </Grid>
      </Grid>

      <Suspense fallback={<div>Loading Live Matches...</div>}>
        <LiveMatches />
      </Suspense>

      <Suspense fallback={<div>Loading Pre-Live Matches...</div>}>
        <PreLiveMatches />
      </Suspense>
    </Fragment>
  );
}

export default SportBookLobby;
