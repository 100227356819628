import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useStyle from "./favorites.style";
import {
  containSettledMarket,
  decodeSocketData,
  getName,
  getProviderTeamId,
  getTeamId,
  getTeamName,
  getUpdatedData,
} from "../Utils/common";
import {
  getFavouriteMatchesStart,
  removeFromFavouriteStart,
} from "../../../sport-book-saga/redux/favourite";
import Markets from "../components/Markets";
import useLiveMatchSocketNameSpace, {
  usePreLiveMatchSocketNameSpace,
} from "../socket/hooks";
// import { updateBetsWithSocketData } from "../../../sport-book-saga/redux/betslip";
import { updateMarketsWithSocketData } from "../../../sport-book-saga/redux/matches";
import LoadMore from "../components/LoadMore";
import TeamImg from "../components/TeamImg";
import SportBookLoader from "../components/sportbookLoader";
import FavoritesDropDown from "../../../components/FavoritesDropDown";
import CasinoFavorites from "../../../components/FavoritesDropDown/CasinoFavorites";
import { setFavoritesOption } from "../../../sport-book-saga/redux/favourite";

const initialState = {
  matchMarketsLiveUpdates: {},
};

let socketListeners = {};
const Favorites = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const { selectedSportId, selectedSportName, selectedSportImg } = useSelector(
    (state) => state.sportbook.sports
  );
  const search = useSelector((state) => state.sportbook.sports.search);
  const { language } = useSelector((state) => state.sportbook.language);
  const dispatch = useDispatch();
  const {
    loading,
    hasNext,
    currentPage,
    favourites,
    favLoading,
    removeFromFavMatchId,
  } = useSelector((state) => state?.sportbook?.favourite);
  const { isLoggedIn } = useSelector((state) => state?.authentication);
  const [homeState, setHomeState] = useState(initialState);
  const {
    listenDashboardMatchMarketUpdates: preLiveListenDashboardMatchMarketUpdates,
  } = usePreLiveMatchSocketNameSpace();
  const {
    listenDashboardMatchMarketUpdates: liveListenDashboardMatchMarketUpdates,
  } = useLiveMatchSocketNameSpace();

  const loadmoreMatches = () => {
    dispatch(
      getFavouriteMatchesStart({
        providerSportId: selectedSportId,
        page: parseInt(currentPage) + 1,
        limit: 10,
        search,
      })
    );
  };

  useEffect(() => {
    setHomeState({
      matchMarketsLiveUpdates: {},
    });
    selectedSportId &&
      dispatch(
        getFavouriteMatchesStart({
          providerSportId: selectedSportId,
          page: 1,
          limit: 10,
          search,
        })
      );
    return () => {
      for (const [key] of Object.entries(socketListeners)) {
        if (socketListeners[`${key}`]) socketListeners[`${key}`]();
      }
      socketListeners = {};
    };
  }, [dispatch, selectedSportId, search]);

  useEffect(() => {
    favourites &&
      favourites?.length &&
      favourites.forEach((favouriteMatch) => {
        const matchRoomId = `${selectedSportId}${favouriteMatch.providerTournamentId}${favouriteMatch.providerMatchId}`;
        if (!socketListeners[`dashboard_cb_${matchRoomId}`]) {
          const obj1 = {
            [`dashboard_cb_${matchRoomId}`]: async (value) => {
              let socketData = await decodeSocketData(value);
              socketData = socketData?.data;
              if (socketData) {
                const settledMarkets = containSettledMarket(socketData);
                if (settledMarkets?.length) {
                  dispatch(
                    updateMarketsWithSocketData({
                      ...socketData,
                      settledMarkets,
                    })
                  );
                }
                setHomeState((homestate) => {
                  let oldMarkets =
                    homestate.matchMarketsLiveUpdates[matchRoomId]?.matchMarket;
                  if (
                    !homestate.matchMarketsLiveUpdates[matchRoomId]?.noFirstTime
                  ) {
                    oldMarkets = favouriteMatch.matchMarket;
                  }
                  const newMarkets = getUpdatedData(
                    oldMarkets,
                    socketData?.matchMarket
                  );

                  const newState = {
                    ...homestate,
                    matchMarketsLiveUpdates: {
                      ...homestate.matchMarketsLiveUpdates,
                      [matchRoomId]: {
                        ...favouriteMatch,
                        ...socketData,
                        matchMarket: newMarkets,
                        noFirstTime: true,
                      },
                    },
                  };
                  return newState;
                });
              }
            },
          };

          socketListeners[`dashboard_cb_${matchRoomId}`] =
            favouriteMatch?.isLive
              ? liveListenDashboardMatchMarketUpdates(
                  matchRoomId,
                  obj1[`dashboard_cb_${matchRoomId}`]
                )
              : preLiveListenDashboardMatchMarketUpdates(
                  matchRoomId,
                  obj1[`dashboard_cb_${matchRoomId}`]
                );
        }
      });
  }, [dispatch, favourites]);

  useEffect(() => {
    return () => {
      dispatch(setFavoritesOption("sportsBook"));
    };
  }, []);

  function getTotalMarket(totalMarketSocket, totalMarket, length) {
    if (length >= 2) {
      return totalMarketSocket - 2 || totalMarket - 2;
    } else if (length === 1) {
      return totalMarketSocket - 1 || totalMarket - 1;
    } else {
      return totalMarketSocket || totalMarket;
    }
  }

  const selectedFavoritesOption = useSelector(
    (state) => state.sportbook.favourite.favouritesOption
  );
  const handleSelectOption = (event) => {
    dispatch(setFavoritesOption(event.target.value));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems={"center"} mt={"30px"} mb={"1rem"}>
        <Grid item xs={6} lg={10} container>
          <Grid item>
            <StarIcon
              style={{
                fontSize: "32px",
              }}
            />
          </Grid>
          <Grid item ml={"1rem"}>
            <Typography variant="h6" gutterBottom>
              {t("FAVORITES.FAVORITES_MSG")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={2} container className={classes.selectFav}>
          <FavoritesDropDown
            onDropDownOption={handleSelectOption}
            currentValue={selectedFavoritesOption}
          />
        </Grid>
      </Grid>
      {selectedFavoritesOption === "sportsBook" ? (
        <>
          <Grid className={classes.SportsTableHeader}>
            <img src={selectedSportImg} alt="Soccer" />
            <Typography>{getName(selectedSportName, language)}</Typography>
          </Grid>

          <TableContainer component={Paper} className={classes.SportsTableWrap}>
            <Table aria-label="simple table">
              <TableBody>
                {loading && !hasNext ? (
                  <Grid
                    className={classes.iframeCirculerLoader}
                    textAlign="center"
                  >
                    <SportBookLoader />
                  </Grid>
                ) : (
                  <>
                    {favourites && favourites?.length ? (
                      favourites.map((matchData, i) => {
                        const matchRoomId = `${selectedSportId}${matchData.providerTournamentId}${matchData.providerMatchId}`;
                        return (
                          <TableRow key={i}>
                            <TableCell
                              component=""
                              scope=""
                              className={classes.mobtableCell}
                            >
                              <h4 className={classes.tableMatchName}>
                                {getName(
                                  matchData?.SportCountry?.country?.countryName,
                                  language
                                ) +
                                  " / " +
                                  getName(
                                    matchData?.tournaments?.tournamentName,
                                    language
                                  )}
                              </h4>
                              <Grid className={classes.tableMatchDetailsWrap}>
                                <Grid className={classes.tableMatchDetailsLeft}>
                                  <Grid className={classes.matchStatus}>
                                    <span className="date">
                                      {dayjs(
                                        new Date(matchData?.matchDate)
                                      ).format("DD MMM")}
                                    </span>
                                    <span className="time">
                                      {dayjs(
                                        new Date(matchData?.matchDate)
                                      ).format("HH:mm")}
                                    </span>
                                  </Grid>
                                  <Grid className={classes.tableTeamDetails}>
                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                      columnGap={"1rem"}
                                    >
                                      <TeamImg
                                        teamId={getProviderTeamId(
                                          matchData?.teams,
                                          "home"
                                        )}
                                        type="home"
                                        isLive={matchData?.isLive}
                                      />
                                      <Typography>
                                        {getTeamName(
                                          matchData?.teams,
                                          "home",
                                          language
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                      columnGap={"1rem"}
                                    >
                                      <TeamImg
                                        teamId={getProviderTeamId(
                                          matchData?.teams,
                                          "away"
                                        )}
                                        type="away"
                                        isLive={matchData?.isLive}
                                      />
                                      <Typography>
                                        {getTeamName(
                                          matchData?.teams,
                                          "away",
                                          language
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {isLoggedIn && (
                                  <Grid className={classes.favIconMob}>
                                    <Link
                                      disabled={favLoading}
                                      component={"button"}
                                      onClick={() => {
                                        if (matchData?.FavoriteMatch) {
                                          dispatch(
                                            removeFromFavouriteStart({
                                              ...matchData?.FavoriteMatch,

                                              matchId: matchData?.matchId,
                                            })
                                          );
                                        }
                                      }}
                                    >
                                      {favLoading &&
                                      removeFromFavMatchId ===
                                        matchData?.matchId ? (
                                        <SportBookLoader
                                          isSmall={true}
                                          width={"24px"}
                                          height={"24px"}
                                        />
                                      ) : (
                                        <StarIcon />
                                      )}
                                    </Link>
                                  </Grid>
                                )}
                              </Grid>

                              <Markets
                                isMobile
                                matchMarkets={
                                  homeState.matchMarketsLiveUpdates[
                                    matchRoomId
                                  ] || matchData
                                }
                                totalMarket={getTotalMarket(
                                  homeState.matchMarketsLiveUpdates[matchRoomId]
                                    ?.totalMarket,
                                  matchData.totalMarket,
                                  homeState.matchMarketsLiveUpdates[matchRoomId]
                                    ?.matchMarket?.length ||
                                    matchData.matchMarket?.length
                                )}
                                homeTeam={getTeamName(
                                  matchData?.teams,
                                  "home",
                                  language
                                )}
                                awayTeam={getTeamName(
                                  matchData?.teams,
                                  "away",
                                  language
                                )}
                                homeTeamId={getTeamId(matchData?.teams, "home")}
                                awayTeamId={getTeamId(matchData?.teams, "away")}
                                sportId={selectedSportId}
                                countryId={
                                  matchData?.SportCountry?.providerCountryId
                                }
                                sportCountryId={
                                  matchData?.SportCountry?.sportCountryId
                                }
                                countryName={
                                  matchData?.SportCountry?.country?.countryName
                                }
                                isSocketUpdated={
                                  homeState.matchMarketsLiveUpdates[matchRoomId]
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                            {isLoggedIn && (
                              <TableCell align="center">
                                <Grid className={classes.favIcon}>
                                  <Link
                                    disabled={favLoading}
                                    component={"button"}
                                    onClick={() => {
                                      if (matchData?.FavoriteMatch) {
                                        dispatch(
                                          removeFromFavouriteStart({
                                            ...matchData?.FavoriteMatch,

                                            matchId: matchData?.matchId,
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    {favLoading &&
                                    removeFromFavMatchId ===
                                      matchData?.matchId ? (
                                      <SportBookLoader
                                        isSmall={true}
                                        width={"24px"}
                                        height={"24px"}
                                      />
                                    ) : (
                                      <StarIcon />
                                    )}
                                  </Link>
                                </Grid>
                              </TableCell>
                            )}
                            <Markets
                              matchMarkets={
                                homeState.matchMarketsLiveUpdates[
                                  matchRoomId
                                ] || matchData
                              }
                              homeTeam={getTeamName(
                                matchData?.teams,
                                "home",
                                language
                              )}
                              awayTeam={getTeamName(
                                matchData?.teams,
                                "away",
                                language
                              )}
                              homeTeamId={getTeamId(matchData?.teams, "home")}
                              awayTeamId={getTeamId(matchData?.teams, "away")}
                              totalMarket={getTotalMarket(
                                homeState.matchMarketsLiveUpdates[matchRoomId]
                                  ?.totalMarket,
                                matchData.totalMarket,
                                homeState.matchMarketsLiveUpdates[matchRoomId]
                                  ?.matchMarket?.length ||
                                  matchData.matchMarket?.length
                              )}
                              sportId={selectedSportId}
                              countryId={
                                matchData?.SportCountry?.providerCountryId
                              }
                              sportCountryId={
                                matchData?.SportCountry?.sportCountryId
                              }
                              countryName={
                                matchData?.SportCountry?.country?.countryName
                              }
                              isSocketUpdated={
                                homeState.matchMarketsLiveUpdates[matchRoomId]
                                  ? true
                                  : false
                              }
                            />
                            <TableCell align="center">
                              <Grid className={classes.marketBtnWrap}>
                                <Button
                                  disabled={
                                    !parseInt(
                                      getTotalMarket(
                                        homeState.matchMarketsLiveUpdates[
                                          matchRoomId
                                        ]?.totalMarket,
                                        matchData.totalMarket,
                                        homeState.matchMarketsLiveUpdates[
                                          matchRoomId
                                        ]?.matchMarket?.length ||
                                          matchData.matchMarket?.length
                                      )
                                    )
                                  }
                                  className={classes.marketBtn}
                                  onClick={() =>
                                    navigate("/more-market", {
                                      state: {
                                        sportId: selectedSportId,
                                        countryId:
                                          matchData?.SportCountry
                                            ?.providerCountryId,
                                        sportCountryId:
                                          matchData?.SportCountry
                                            ?.sportCountryId,
                                        isLive: matchData?.isLive,
                                        providerMatchId:
                                          matchData?.providerMatchId,
                                      },
                                    })
                                  }
                                >
                                  +
                                  {getTotalMarket(
                                    homeState.matchMarketsLiveUpdates[
                                      matchRoomId
                                    ]?.totalMarket,
                                    matchData.totalMarket,
                                    homeState.matchMarketsLiveUpdates[
                                      matchRoomId
                                    ]?.matchMarket?.length ||
                                      matchData.matchMarket?.length
                                  )}{" "}
                                  {t("FAVORITES.FAVORITES_MARKET_MSG")}
                                </Button>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <Grid
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        minHeight={"4rem"}
                      >
                        {" "}
                        <Typography style={{ color: "rgb(247, 97, 106)" }}>
                          {" "}
                          {t("FAVORITES.NO_FAVORITES_MATCHES_MSG")}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            <LoadMore
              hasNext={hasNext}
              loading={loading}
              loadmoreMatches={loadmoreMatches}
            />
          </TableContainer>
        </>
      ) : (
        <CasinoFavorites />
      )}
    </React.Fragment>
  );
};

export default Favorites;
