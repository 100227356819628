import React from "react";

import { Skeleton, Tab, Tabs } from "@mui/material";

import useStyle from "./scrollBarTabs.styles";

function ScrollableTab({
  list,
  Container,
  value,
  handleTabChange,
  handleTabClick,
  loading,
}) {
  // const [value, setValue] = React.useState(0);
  //const classes = useStyle();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const numberOfSkeleton = Array.from({ length: 15 });
  const classes = useStyle();
  return (
    <Tabs
      value={value}
      variant="scrollable"
      scrollButtons={true}
      aria-label="scrollable prevent tabs example"
      allowScrollButtonsMobile
      className={classes.sporsSliderWrap}
    >
      {loading ? (
        <>
          {numberOfSkeleton.map((index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={150}
              height={40}
              className={classes.skeleton}
              animation="pulse"
            />
          ))}
        </>
      ) : (
        list.map((tabContent, i) => {
          return (
            <Tab
              key={i}
              label=""
              component={() => (
                <Container
                  {...tabContent}
                  handleTabClick={handleTabClick}
                  selectedTab={value}
                  currentTab={i}
                />
              )}
            />
          );
        })
      )}
    </Tabs>
  );
}
export default React.memo(ScrollableTab);
